var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('domain-dialog',{attrs:{"form-title":_vm.formTitle,"show":_vm.dialog,"item":_vm.editedItem,"on-apply":_vm.save,"on-close":_vm.closeModals}}),_c('simple-dialog',{attrs:{"show":_vm.dialogDelete,"on-apply":_vm.confirmDelete,"on-close":_vm.closeModals,"item":_vm.editedItem}},[_vm._v(" Are you sure you want to delete this domain ? ")]),_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":_vm.mdSize}},[_c('d-data-table',{staticClass:"mt-1",attrs:{"items":_vm.domainsData,"headers":_vm.tableHeaders,"footer-props":_vm.footerProps,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"fill-height":!_vm.homePreview,"hide-default-footer":_vm.homePreview,"disable-sort":_vm.homePreview},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Domains")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('v-spacer'),(!_vm.homePreview)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.createDomain}},[_vm._v("New domain")]):_vm._e()],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"top":"","open-delay":350,"color":_vm.stringToColor(item.name),"dark":_vm.isDark(_vm.stringToColor(item.name))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.parentId",fn:function(ref){
var item = ref.item;
return [(_vm.getDomainById(item.parentId)[0])?_c('v-chip',{staticClass:"ma-1",attrs:{"top":"","open-delay":350,"color":_vm.stringToColor(_vm.getDomainById(item.parentId)[0].name),"dark":_vm.isDark(_vm.stringToColor(_vm.getDomainById(item.parentId)[0].name))}},[_vm._v(" "+_vm._s(_vm.getDomainById(item.parentId)[0].name)+" ")]):_vm._e()]}},{key:"item.children",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.children.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.editDomain(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteDomain(item)}}},[_vm._v("mdi-delete")])]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"app":"","color":"error"},model:{value:(_vm.hasError),callback:function ($$v) {_vm.hasError=$$v},expression:"hasError"}},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }