<script>
import {Pie, mixins} from 'vue-chartjs'

const {reactiveProp} = mixins

export default {
  name: 'PieChart',
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    options: {type: Object, required: false}
  },
  mounted() {
    // The chartData prop is declared in the mixin
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped>
</style>
