<template>
  <div class="fill-height">
    <h3 class="text-center text-capitalize">{{ title }}</h3>
    <div v-if="!data || !data.content" class="fill-height d-flex align-center justify-center">
      <h2 class="pa-0 ma-auto">{{ noFileMessage }}</h2>
    </div>
    <div v-else-if="loading" class="fill-height d-flex align-center justify-center">
      <v-progress-circular :size="40" indeterminate></v-progress-circular>
    </div>
    <div v-else class="fill-height">
      <d-data-table v-if="data" :items="segments" :headers="headers" :loading="loading" class="mt-5"
                    :items-per-page=-1 disable-sort hide-default-footer hide-default-header>
      </d-data-table>
    </div>
  </div>
</template>

<script>

import DDataTable from "@/components/wrappers/DDataTable";
import {capitalize} from "lodash";

const STAGE_TYPES = {
  DOCUMENT_2_TEXT: "document2text",
  TOKENIZER: "tokenizer",
  DOCUMENT_MATCHER: "documentMatcher"
}

export default {
  name: "TableViewer",
  components: {DDataTable},
  props: {
    data: {type: Object, required: false},
    isInput: {type: Boolean, required: true},
    showError: {type: Function, required: true},
  },
  data() {
    return {
      segments: undefined,
      loading: true,
      headers: [
        {text: 'Index', value: 'id'},
        {text: 'Tokenized Segments', value: 'seg'},
      ],
    }
  },
  watch: {
    data() {
      if (this.data !== undefined && this.data !== null && this.segments === undefined)
        this.computeSegments()
    }
  },
  mounted() {
    if (this.data !== undefined && this.data !== null && this.segments === undefined)
      this.computeSegments()
  },
  computed: {
    title() {
      let io = this.isInput || this.stage === "documentMatcher" ? "input" : "output"
      if (this.stage === "documentMatcher" && this.data !== undefined && this.data !== null)
        io += ": " + this.data.documentOrigin
      return this.stageType + " " + io
    },
    noFileMessage() {
      if (this.stage === "documentMatcher")
        return "The DocumentMatcher could not find a match"

      const io = this.isInput ? "input" : "output"
      return "There is no " + capitalize(this.stageType) + " " + io + " for this file"
    },
    stage() {
      return this.$route.params.stageName
    },
    stageType() {
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_2_TEXT))
        return STAGE_TYPES.DOCUMENT_2_TEXT
      if (this.stage.includes(STAGE_TYPES.TOKENIZER))
        return STAGE_TYPES.TOKENIZER
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_MATCHER))
        return STAGE_TYPES.DOCUMENT_MATCHER
      return undefined
    },
  },
  methods: {
    computeSegments() {
      let i = 1
      this.segments = this.data.content.split("\n").map(s => ({id: i++, seg: s}))
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
