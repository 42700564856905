<template>
  <v-dialog v-model="show" max-width="60%" persistent>
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Domain name" v-model="editedItem.name" autofocus></v-text-field>
            <v-select
                v-model="editedItem.parentId"
                :items="domainsData.filter(v => v.id !== editedItem.id)"
                label="Parent domain"
                chips
                item-text="name"
                item-value="id"
                hint="Select the parent domain">
              <template #selection="{ item }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-row>
              <v-col md="auto" class="mt-3 v-label theme--light">
                <p>Children domains:</p>
              </v-col>
              <v-col>
                <v-chip class="ma-1"
                        v-for="domain in editedItem.children"
                        :color="stringToColor(getDomainById(domain.id)[0].name)"
                        :key="domain.id"
                        :dark="isDark(stringToColor(getDomainById(domain.id)[0].name))">
                  {{ getDomainById(domain.id)[0].name }}
                </v-chip>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {cloneDeep, isEqual} from "lodash";
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";

const DEFAULT_DOMAIN = {
  id: undefined,
  name: '',
  parentId: undefined,
  children: []
}

export default {
  name: "DomainDialog",
  props: {
    formTitle: {type: String, required: true},
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      isDark,
      stringToColor,
      editedItem: Object.assign({}, DEFAULT_DOMAIN),
      originalItem: Object.assign({}, DEFAULT_DOMAIN),
      domainsData: [],
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      this.populateForm(newVal)
    },
  },
  computed: {
    hasBeenModified() {
      return !isEqual(this.editedItem, this.originalItem)
    }
  },
  methods: {
    populateForm(item) {
      if (item !== null && item !== undefined) {
        this.editedItem = {...DEFAULT_DOMAIN, ...cloneDeep(item)}
        this.originalItem = cloneDeep(this.editedItem)
      } else {
        this.editedItem = cloneDeep(DEFAULT_DOMAIN)
        this.originalItem = cloneDeep(this.editedItem)
      }
      this.fetchDomainsData()
    },
    fetchDomainsData() {
      this.loading = true
      this.error = null
      this.hasError = false

      axios.get(`/domains`)
          .then(response => {
            this.domainsData = response.data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    getDomainById(id) {
      return this.domainsData.filter(el => el.id === id)
    },
    apply() {
      if (this.hasBeenModified) {
        if (this.$refs.form.validate())
          this.onApply(this.editedItem)
      } else
        this.onClose()
    }
  }
}
</script>

<style scoped>

</style>
