import * as mutation_types from "@/store/mutation-types"
import * as action_types from "@/store/action-types"
import axios from "axios";

const state = () => ({
    project: {
        projectId: undefined,
        projectTitle: ""
    },
    crawling: {
        crawlingId: undefined,
        crawlingTitle: "",
    },
    pipeline: {
        pipelineId: undefined,
        pipelineTitle: "",
        pipelineType: "",
    },
    lastRoute: undefined
})
const getters = {
    hasLastRoute: state => state.lastRoute !== undefined,
}
const actions = {
    [action_types.SET_PROJECT]: ({commit}, projectId) =>
        new Promise((resolve, reject) => {
            axios.get(`/projects/${projectId}`)
                .then(response => {
                    let projectData = {projectTitle: response.data.title, projectId: response.data.id}
                    commit(mutation_types.SET_PROJECT, projectData)
                    resolve()
                })
                .catch(reject)
        }),
    [action_types.SET_CRAWLING]: ({commit}, crawlingId) =>
        new Promise((resolve, reject) => {
            axios.get(`/crawlings/${crawlingId}`)
                .then(response => {
                    const crawlingData = {crawlingTitle: response.data.title, crawlingId: response.data.id}
                    commit(mutation_types.SET_CRAWLING, crawlingData)
                    resolve()
                })
                .catch(reject)
        }),
    [action_types.SET_PIPELINE]: ({commit}, {pipelineId}) =>
        new Promise((resolve, reject) => {
            return axios.get(`/pipelines/${pipelineId}`)
                .then(response => {
                    const pipelineData = {
                        pipelineTitle: response.data.name,
                        pipelineId: response.data.id,
                        pipelineType: response.data.typeId,
                    }
                    commit(mutation_types.SET_PIPELINE, pipelineData)
                    resolve()
                })
                .catch(reject)
        }),
    [action_types.CLEAR_PROJECT]: ({commit}) =>
        new Promise((resolve => {
            commit(mutation_types.CLEAR_PROJECT)
            resolve();
        })),
    [action_types.CLEAR_PIPELINE]: ({commit}) =>
        new Promise((resolve => {
            commit(mutation_types.CLEAR_PIPELINE)
            resolve();
        })),
    [action_types.SET_LAST_ROUTE]: ({commit}, lastRoute) =>
        new Promise((resolve => {
            commit(mutation_types.SET_LAST_ROUTE, lastRoute)
            resolve()
        })),
}
const mutations = {
    [mutation_types.SET_PROJECT]: (state, project) => state.project = project,
    [mutation_types.SET_CRAWLING]: (state, crawling) => state.crawling = crawling,
    [mutation_types.SET_PIPELINE]: (state, pipeline) => state.pipeline = pipeline,
    [mutation_types.CLEAR_PROJECT]: state => state.project = {projectId: undefined, projectTitle: ""},
    [mutation_types.CLEAR_PIPELINE]: state => state.pipeline = {pipelineId: undefined, pipelineTitle: "", pipelineType: ""},
    [mutation_types.SET_LAST_ROUTE]: (state, lastRoute) => state.lastRoute = lastRoute,
}

export default {
    state,
    getters,
    actions,
    mutations
};
