<template>
  <div class="d-flex flex-column fill-height">
    <filter-dialog :filter="dialogFilter" :enums-values="enumsValues" :on-close="closeDialog"
                   :on-apply="handleDialogApply" :show="dialogShown"
                   :available-filter-details="filtersDetails"></filter-dialog>
    <div>
      <filter-bloc :filter-bloc="filterBloc" :id="0" :enums-values="enumsValues" :on-modify="handleFilterBlocModify"
                   :show-dialog="showDialog" :depth="0"></filter-bloc>
    </div>

    <div class="pt-12 mt-auto">
      <div>
        <v-btn @click="copyToClipboard" class="mr-3">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
        <v-btn @click="loadFromText" color="primary">
          Load
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
      <v-textarea v-model="textField" solo rows="6" outlined hide-details auto-grow class="mt-3 pa-1"></v-textarea>
    </div>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import FilterBloc from "@/components/parts/filters/FilterBloc";
import FilterDialog from "@/components/parts/filters/FilterDialog";
import * as action_types from "@/store/action-types"
import {cloneDeep} from "lodash";
import {
  DEFAULT_FILTERS,
  filterBlocToText,
  parseBlocOrFilter,
  SEGMENT_MANAGER_FILTERS_DETAILS
} from "@/components/parts/filters/filters-utils";

const MIN_FUZZY_SIMILARITY_PERCENTS = 66;
const MAX_FUZZY_SIMILARITY_PERCENTS = 100;

const DEFAULT_POST_FILTERS = {
  exactDuplicate: true,
  partialDuplicate: true,
  fuzzyDuplicate: true,
  notDuplicate: true,
  fuzzyMinSimilarityPercents: 82.5
}

export default {
  name: "Filters",
  components: {FilterDialog, FilterBloc},
  props: {
    onFilterChange: {type: Function, required: true},
    onPostFiltersChange: {type: Function, required: true},
    enumsValues: {type: Object, required: true}
  },
  data: () => ({
    textField: '',
    filterBloc: cloneDeep(DEFAULT_FILTERS),
    handleDialogApply: null,
    dialogFilter: null,
    dialogShown: false,
    hasError: false,
    error: null,
    postFilters: Object.assign({}, DEFAULT_POST_FILTERS),
    MIN_FUZZY_SIMILARITY_PERCENTS,
    MAX_FUZZY_SIMILARITY_PERCENTS,
    filtersDetails: SEGMENT_MANAGER_FILTERS_DETAILS,
  }),
  mounted() {
    this.textField = this.$store.getters.getfiltersQuery
    this.loadFromText()
    this.onFilterChange(cloneDeep(this.filterBloc))
    this.onPostFiltersChange(cloneDeep(this.postFilters))
  },
  watch: {
    filterBloc(newVal) {
      this.textField = filterBlocToText(newVal)
      this.onFilterChange(cloneDeep(newVal))
      this.$store.dispatch(action_types.SET_FILTERS, this.textField)
    },
    postFilters: {
      handler(newVal) {
        this.onPostFiltersChange(cloneDeep(newVal))
      },
      deep: true
    }
  },
  methods: {
    handleFilterBlocModify(_, filterBloc) {
      this.filterBloc = cloneDeep(filterBloc)
    },
    showDialog(onDialogApply, filter = null) {
      this.dialogFilter = filter
      this.handleDialogApply = onDialogApply
      this.dialogShown = true
    },
    closeDialog() {
      this.dialogFilter = null
      this.handleDialogApply = null
      this.dialogShown = false
    },
    loadFromText() {
      try {
        this.filterBloc = cloneDeep(parseBlocOrFilter(this.textField)[0])
        this.$store.dispatch(action_types.SET_FILTERS, this.textField)
      } catch (e) {
        console.error(e)
        this.error = "The filter is invalid, Please check the text"
        this.hasError = true
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.textField)
    }
  }
}
</script>

<style scoped>
.filters-slider-text-field {
  width: 50px;
  margin-top: 0;
  padding-top: 0;
}

.filters-slider {
  max-width: 630px;
}
</style>

<style>
/* Remove the arrows next to the number input field */
/* Chrome, Safari, Edge, Opera */
.filters-slider-text-field input::-webkit-outer-spin-button,
.filters-slider-text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.filters-slider-text-field input[type=number] {
  -moz-appearance: textfield;
}
</style>
