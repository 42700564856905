export const PIPELINE_STATUSES = {
    RUNNING: 'RUNNING',
    COMPLETED: 'COMPLETED',
    NEW: 'NEW',
    ERROR: 'ERROR',
    STARTING: 'STARTING'
}

export const PIPELINE_STATUSES_ICONS = {
    [PIPELINE_STATUSES.RUNNING]: {
        text: 'Running',
        icon: "mdi-autorenew",
        color: 'orange'
    },
    [PIPELINE_STATUSES.COMPLETED]: {
        text: 'Completed',
        icon: "mdi-check-circle",
        color: 'success'
    },
    [PIPELINE_STATUSES.NEW]: {
        text: 'New',
        icon: "mdi-plus-circle",
        color: "default"
    },
    [PIPELINE_STATUSES.ERROR]: {
        text: 'Crashed',
        icon: "mdi-alert",
        color: 'error'
    },
    [PIPELINE_STATUSES.STARTING]: {
        text: 'Starting',
        icon: 'mdi-timelapse',
        color: '#00b8d4'
    }
}

export const STAGE_STATUSES = {
    RUNNING: 'RUNNING',
    PENDING: 'PENDING',
    FINISHED: 'FINISHED',
    FAILED: 'FAILED',
    NEW: 'NEW',
    STOPPED: 'STOPPED',
    ERROR: 'ERROR'
}

export const CRAWLING_STATUSES = {
    NEW: 'new',
    RUNNING: 'running',
    FINISHED: 'finished',
    STOPPED: 'stopped',
}

export const LANGUAGE_BY_CODE = {
    fr: 'French',
    en: 'English',
    de: 'German',
    it: 'Italian',
    es: 'Spanish'
}

export const LANGUAGES = Object.values(LANGUAGE_BY_CODE)
export const LANGUAGES_SHORT = Object.keys(LANGUAGE_BY_CODE)

export const TOKENIZER_RULES_TYPES = {
    REPLACEMENTS: "REPLACEMENTS",
    EXCEPTIONS: "EXCEPTIONS"
}
