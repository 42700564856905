<template>
  <v-dialog persistent :value="show" max-width="60%" @click:outside="onClose" @keydown.esc="onClose">
    <v-form ref="form">
      <v-card>
        <v-card-title>Merge alignments</v-card-title>
        <v-card-text v-if="alignments">
          <h5 class="text-h5 mb-2">Initial data</h5>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea :value="initialText1" label="Initial texts 1" auto-grow outlined disabled no-resize
                          rows="1" :key="initialText1"></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea :value="initialText2" label="Initial texts 2" auto-grow outlined disabled no-resize
                          rows="1"></v-textarea>
            </v-col>
          </div>
          <h5 class="text-h5 mb-2">Merged data</h5>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea v-model="text1" label="Merged text 1" no-resize auto-grow outlined
                          :rules="rules.mergedText" @keydown.enter.prevent></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea v-model="text2" label="Merged text 2" no-resize auto-grow outlined
                          :rules="rules.mergedText" @keydown.enter.prevent></v-textarea>
            </v-col>
          </div>
          <span class="text-body-1">
            Maximum number of alignments between first and last : {{ maxIndexDifference - 1 }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="onClose">Close</v-btn>
          <v-btn color="success" text @click="apply">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {first, last} from "lodash";

export default {
  name: "MergeDialog",
  props: {
    show: {type: Boolean, required: true},
    alignments: {type: Array},
    rule: {type: Object, default: null},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
  },
  data() {
    return {
      text1: '',
      text2: '',
      maxIndexDifference: 0,
      rules: {
        mergedText: [
          v => !!v || 'Required',
          v => !/[\n\r]/.test(v) || 'No linebreak allowed'
        ]
      }
    }
  },
  computed: {
    initialText1() {
      if (!this.show)
          // To avoid an issue with auto-grow
        return ''

      return (this.rule
          ? this.rule.alignmentsToMerge.map(al => al.segment1)
          : this.alignments.map(al => al.initialText1))
          .join('\n\n')
    },
    initialText2() {
      if (!this.show)
          // To avoid an issue with auto-grow
        return ''

      return (this.rule
          ? this.rule.alignmentsToMerge.map(al => al.segment2)
          : this.alignments.map(al => al.initialText2))
          .join('\n\n')
    },
  },
  watch: {
    alignments() {
      if (!this.show)
        return

      this.populateForm()
    },
    show(newVal) {
      if (!newVal)
        return

      this.populateForm()
    }
  },
  methods: {
    apply() {
      if (!this.$refs.form.validate())
        return

      const alignmentsToMerge = this.rule
          ? this.rule.alignmentsToMerge
          : this.alignments.map(al => ({segment1: al.initialText1, segment2: al.initialText2}))

      const rule = {
        id: this.rule?.id,
        alignmentsToMerge: alignmentsToMerge,
        mergedSegment1: this.text1,
        mergedSegment2: this.text2,
        maxIndexDifference: this.maxIndexDifference
      }

      this.onApply(rule)
    },
    populateForm() {
      this.text1 = this.rule
          ? this.rule.mergedSegment1
          : this.alignments.map(alignment => alignment.initialText1).join(' ')
      this.text2 = this.rule
          ? this.rule.mergedSegment2
          : this.alignments.map(alignment => alignment.initialText2).join(' ')
      this.maxIndexDifference = this.rule
          ? this.rule.maxIndexDifference
          : Math.abs(last(this.alignments).indexInDocument - first(this.alignments).indexInDocument)
      this.$refs.form?.resetValidation()
    }
  }
}
</script>

<style scoped>

</style>
