<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-card elevation="4" class="pa-3">
          <v-card-text class="align-center">
            <h4 class="text-h5 text-center mb-4"> Download the extracted terms </h4>
            <div class="text-center">
              <v-btn @click="getFile" color="primary">Download</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </v-container>
</template>

<script>
import {downloadFile} from "@/util/js-utils";

export default {
  name: "DownloadTerms",
  data() {
    return {
      filename: undefined,
      hasError: false,
      error: undefined
    }
  },
  computed: {
    termsFileId(){
      return this.$route.params.termsFileId
    }
  },
  methods: {
    getFile() {
      const downloadName = `datalambic_terms_${this.termsFileId}.csv`
      downloadFile(`/segment-manager/extract-terms/${this.termsFileId}`, downloadName,
          'text/csv', 'get')
          .then(() => {
            this.loading = false
            this.downloaded = true
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
            this.downloaded = false
          })
    },
    showError(err) {
      console.error(err)
      if (err.response.status === 404) {
        const decoder = new TextDecoder("utf-8")
        this.error = JSON.parse(decoder.decode(err.response.data)).detail
      } else
        this.error = err
      this.hasError = true
    },
  }
}
</script>

<style scoped>

</style>
