<template>
  <v-dialog persistent :value="show" max-width="80%" @click:outside="onClose()">
    <v-card>
      <v-card-title>
        Document Matcher Configuration
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col sm="12" md="10" class="d-flex flex-column">
              <v-text-field outlined label="Stage Name" v-model="form.stageName"
                            placeholder="Document Matcher"></v-text-field>
              <v-radio-group v-model="form.matcherType" label="Matcher" row class="align-self-center">
                <v-radio value="LaBSE" label="Advanced (LaBSE)"></v-radio>
                <v-radio value="Simple" label="Simple (Filename)"></v-radio>
                <v-radio v-if="isCrawler" value="URL" label="URL Matcher (Combine LaBSE and URLs)"></v-radio>
              </v-radio-group>
              <v-checkbox
                  v-if="form.matcherType === 'LaBSE' || form.matcherType === 'Simple'"
                  v-model="form.matchExt"
                  label="Match the files extensions"
                  :true-value="true"
                  :false-value="false"></v-checkbox>
              <div v-if="form.matcherType === 'Simple'" class="d-flex">
                <v-select outlined label="Source language (tokenizer1)" v-model="form.srcLanguage" :items="languages"
                          item-text="label" item-value="value" class="mr-2"></v-select>
                <v-select outlined label="Target language (tokenizer2)" v-model="form.trgLanguage" :items="languages"
                          item-text="label" item-value="value" class="ml-2"></v-select>
              </div>
              <div>
                <v-slider
                    v-if="form.matcherType === 'URL'"
                    v-model="form.minPatternRatio"
                    label="Language pattern ratio"
                    :max="MAX_SCORE"
                    min="0"
                    step="0.01"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field v-model="form.minPatternRatio" class="slider-text-field" hide-details single-line
                                  type="number"></v-text-field>
                  </template>
                </v-slider>
                <v-slider
                    v-if="form.matcherType === 'LaBSE' || form.matcherType === 'URL'"
                    v-model="form.nbFirst"
                    label="Use the first N words for the embeddings"
                    :max="MAX_SEQ_LENGTH"
                    min="0"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field v-model="form.nbFirst" class="slider-text-field" hide-details single-line
                                  type="number"></v-text-field>
                  </template>
                </v-slider>
                <v-slider
                    v-if="form.matcherType === 'LaBSE' || form.matcherType === 'URL'"
                    v-model="form.nbLast"
                    label="Use the last N words for the embeddings"
                    :max="MAX_SEQ_LENGTH"
                    min="0"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="form.nbLast"
                        class="slider-text-field" hide-details single-line type="number"></v-text-field>
                  </template>
                </v-slider>
                <v-slider
                    v-if="form.matcherType === 'LaBSE' || form.matcherType === 'URL'"
                    v-model="form.matchMaxDist"
                    label="Maximum distance between two documents embeddings"
                    :max="MAX_DISTANCE"
                    min="0"
                    step="0.01"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="form.matchMaxDist"
                        class="slider-text-field" hide-details single-line type="number" step="0.01"></v-text-field>
                  </template>
                </v-slider>
                <v-slider
                    v-if="form.matcherType === 'Simple' || form.matcherType === 'URL'"
                    v-model="form.matchMinScore"
                    label="Minimum similarity between two filename/URLs"
                    :max="MAX_SCORE"
                    min="0"
                    step="0.01"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="form.matchMinScore"
                        class="slider-text-field" hide-details single-line step="0.01" type="number"></v-text-field>
                  </template>
                </v-slider>
                <v-slider
                    v-if="form.matcherType === 'URL'"
                    v-model="form.matchMinCandidateScore"
                    label="Minimum similarity between two candidate URLs"
                    :max="MAX_SCORE"
                    min="0"
                    step="0.01"
                    :thumb-size="24"
                    thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="form.matchMinCandidateScore"
                        class="slider-text-field" hide-details single-line step="0.01" type="number"></v-text-field>
                  </template>
                </v-slider>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="onClose">Close</v-btn>
        <v-btn color="success" text @click="apply">Apply & Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

const MAX_SEQ_LENGTH = 510
const MIN_SEQ_LENGTH = 10

const MAX_DISTANCE = 1.0
const MAX_SCORE = 1.0


export default {
  name: "DocumentMatcherDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null},
    isCrawler: {type: Boolean, required: true}
  },
  data() {
    return {
      languages: [
        {label: 'Detect', value: null},
        {label: 'French', value: 'fr'},
        {label: 'German', value: 'de'},
        {label: 'Italian', value: 'it'},
        {label: 'English', value: 'en'},
        {label: 'Spanish', value: 'es'},
      ],
      MAX_SEQ_LENGTH,
      MAX_DISTANCE,
      MAX_SCORE,
      form: {
        stageName: '',
        maxParallelPods: 1,
        matcherType: 'LaBSE',
        srcLanguage: null,
        trgLanguage: null,
        nbFirst: 300,
        nbLast: 200,
        matchMaxDist: 0.5,
        matchMinScore: 0.9,
        matchMinCandidateScore: 0.9,
        minPatternRatio: 0.7,
        matchExt: false
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal && this.item)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (newVal && this.show)
        this.populateForm(newVal)
    },
    "form.nbFirst"(newVal) {
      if (newVal === "") return ''
      newVal = parseInt(newVal)
      if (this.overflow(parseInt(newVal), this.form.nbLast)) {
        this.form.nbLast = MAX_SEQ_LENGTH - newVal
      }
      if (this.underflow(newVal, this.form.nbLast))
        this.form.nbLast = MIN_SEQ_LENGTH - newVal
      return newVal
    },
    "form.nbLast"(newVal) {
      if (newVal === "") return ''
      newVal = parseInt(newVal)
      if (this.overflow(this.form.nbFirst, newVal))
        this.form.nbFirst = MAX_SEQ_LENGTH - newVal
      if (this.underflow(this.form.nbFirst, newVal))
        this.form.nbFirst = MIN_SEQ_LENGTH - newVal
      return newVal
    }
  },
  methods: {
    populateForm(item) {
      const {config: {name, maxParallelPods, env}} = item

      this.form = {
        stageName: name,
        maxParallelPods,
        matcherType: env.USE_MACHINE_LEARNING ? 'LaBSE' : env.USE_URL_MATCHER ? 'URL' : 'Simple',
        nbFirst: "KEEP_N_FIRST" in env ? env.KEEP_N_FIRST : 300,
        nbLast: "KEEP_N_LAST" in env ? env.KEEP_N_LAST : 200,
        matchMaxDist: "MATCH_MAX_DIST" in env ? env.MATCH_MAX_DIST : 0.5,
        matchMinScore: "MATCH_MIN_SCORE" in env ? env.MATCH_MIN_SCORE : 0.9,
        matchMinCandidateScore: "MATCH_MIN_CANDIDATE_SCORE" in env ? env.MATCH_MIN_CANDIDATE_SCORE : 0.9,
        minPatternRatio: "MIN_PATTERN_RATIO" in env ? env.MIN_PATTERN_RATIO : 0.7,
        matchExt: "MATCH_EXT" in env ? env.MATCH_EXT : false,
        srcLanguage: env.PROCESS_LANGUAGES?.split(',')[0]?.trim(),
        trgLanguage: env.PROCESS_LANGUAGES?.split(',')[1]?.trim()
      }
    },
    overflow(nbFirst, nbLast) {
      return (nbFirst + nbLast) > MAX_SEQ_LENGTH
    },
    underflow(nbFirst, nbLast) {
      return (nbFirst + nbLast) < MIN_SEQ_LENGTH
    },
    apply() {
      const {
        maxParallelPods,
        stageName,
        matcherType,
        nbFirst,
        nbLast,
        srcLanguage,
        trgLanguage,
        matchMaxDist,
        matchMinScore,
        matchMinCandidateScore,
        minPatternRatio,
        matchExt
      } = this.form

      const config = {
        ...this.item.config,
        name: stageName,
        maxParallelPods,
        env: {
          USE_MACHINE_LEARNING: matcherType === 'LaBSE',
          USE_URL_MATCHER: matcherType === 'URL',
          KEEP_N_FIRST: nbFirst || 0,
          KEEP_N_LAST: nbLast || 0,
          PROCESS_LANGUAGES: srcLanguage + ', ' + trgLanguage,
          MATCH_MAX_DIST: matchMaxDist || 0.5,
          MATCH_MIN_SCORE: matchMinScore || 0.9,
          MATCH_MIN_CANDIDATE_SCORE: matchMinCandidateScore || 0.9,
          MIN_PATTERN_RATIO: minPatternRatio || 0.6,
          MATCH_EXT: matchExt || false,
        }
      }

      this.onApply(config)
    }
  }
}
</script>

<style scoped>
.slider-text-field {
  width: 60px;
  margin-top: 0;
  padding-top: 0;
}
</style>
