export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const PERMISSIONS_REQUESTS = 'PERMISSIONS_REQUESTS'

export const SET_PROJECT = 'SET_PROJECT'
export const SET_CRAWLING = 'SET_CRAWLING'
export const SET_PIPELINE = 'SET_PIPELINE'
export const CLEAR_PROJECT = 'CLEAR_PROJECT'
export const CLEAR_PIPELINE = 'CLEAR_PIPELINE'
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE'

export const SET_FILTERS = 'SET_FILTERS'
