<template>
  <div :class="mainDivClasses">
    <v-tabs v-model="selectedTab" grow>
      <v-tab href="#replacement" id="replacement">Replacement rules</v-tab>
      <v-tab href="#exceptions" id="exceptions">Exceptions</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="fill-height">
      <v-tab-item class="fill-height" value="replacement">
        <tokenizer-rules :tokenizer-rules-type="TOKENIZER_RULES_TYPES.REPLACEMENTS" :language="language"
                         :is-page="isPage" :pipeline-id="pipelineId"></tokenizer-rules>
      </v-tab-item>
      <v-tab-item class="fill-height" value="exceptions">
        <tokenizer-rules :tokenizer-rules-type="TOKENIZER_RULES_TYPES.EXCEPTIONS" :language="language"
                         :is-page="isPage" :pipeline-id="pipelineId"></tokenizer-rules>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import {TOKENIZER_RULES_TYPES} from "@/util/enums";
import TokenizerRules from "@/components/parts/manage/TokenizerRules";

export default {
  name: "TokenizerRulesTabs",
  props: {
    isPage: {type: Boolean, required: false, default: true},
    pipelineId: {type: Number, required: false},
    language: {type: String, required: false},
  },
  components: {TokenizerRules},
  data: () => ({
    selectedTab: "exceptions",
    hasError: false,
    error: null,
    TOKENIZER_RULES_TYPES
  }),
  computed: {
    mainDivClasses() {
      return this.isPage ? "d-flex flex-column fill-height" : ""
    }
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
  }
}
</script>

<style>

</style>
