<template>
  <v-list nav dense>
    <template v-for="item in items">
      <v-list-item v-if="!item.subItems" link :to="item.to" :key="item.title">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group v-else :key="item.title" :prepend-icon="item.icon">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="subItem in item.subItems" link :to="subItem.to" :key="subItem.title">
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {PERMISSIONS_REQUESTS} from "@/store/action-types";

const allItems = [
  {title: 'Home', icon: 'mdi-home', to: '/home', shown: ctx => ctx.isAuthenticated},
  {title: 'Projects', icon: 'mdi-inbox-multiple', to: '/projects', shown: ctx => ctx.isAuthenticated},
  {title: 'Crawlings', icon: 'mdi-spider', to: '/crawlings', shown: ctx => ctx.isAuthenticated},
  {
    title: 'Segment manager', icon: 'mdi-list-status', shown: ctx => ctx.isAuthenticated, to: '/segment-manager',
    permissions: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager']
  },
  {
    title: 'CHnell', permissions: ['admin_chnell', 'use_chnell'], icon: 'mdi-translate',
    to: '/chnell', shown: ctx => ctx.isAuthenticated
  },
  {
    title: 'Manage',
    icon: 'mdi-cog',
    shown: ctx => ctx.isAuthenticated,
    subItems: [
      {title: 'Users', permissions: ['admin_users'], icon: 'mdi-account-multiple-plus', to: '/manage/users'},
      {title: 'Groups', permissions: ['admin_groups'], icon: 'mdi-account-group', to: '/manage/groups'},
      {title: 'Owners', permissions: ['admin_owners'], icon: 'mdi-card-account-details', to: '/manage/owners'},
      {title: 'Domains', permissions: ['admin_domains'], icon: 'mdi-bookmark-multiple', to: '/manage/domains'},
      {title: 'Tags', permissions: ['admin_tags'], icon: 'mdi-tag', to: '/manage/tags'},
      {title: 'Sources', permissions: ['admin_sources'], icon: 'mdi-share-all', to: '/manage/sources'},
      {title: 'Tokenizer rules', permissions: ['admin_tokenizer-rules'], icon: 'mdi-book-cog', to: '/manage/tokenizer-rules'},
    ]
  }
]

export default {
  name: 'Drawer',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getPermissions']),
    ...mapState({
      authLoading: state => state.authStatus === 'loading'
    }),
    items() {
      if (this.getPermissions === undefined) {
        this.$store.dispatch(PERMISSIONS_REQUESTS)
        return []
      }
      return this.filtered(allItems, this.predicate, this.getPermissions)
    }
  },
  methods: {
    filtered(array, predicate, permissions) {
      return array.filter(item => {
        if (item.subItems !== undefined) {
          item.subItems = this.filtered(item.subItems, predicate, permissions)
          if (item.subItems.length === 0)
            return false
        }
        return predicate(item.permissions, permissions)
      })
    },
    predicate(toCheck, permissions) {
      if (toCheck === undefined)
        return true
      for (const perm of toCheck)
        if (permissions[perm])
          return true
      return false
    }
  }
}
</script>

<style scoped>

</style>
