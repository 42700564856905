<template>
  <div class="fill-height">
    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="12" md="10" class="fill-height d-flex flex-column">
          <div class="d-flex justify-center">
            <h2 class="text-h3 d-inline">{{ stage }} output</h2>
          </div>
          <d-data-table :items="items" :headers="headers" :loading="loading" class="mt-5 row-pointer" :items-per-page=50
                        :footer-props="footerProps" @click:row="navigateToDocument" :options.sync="paginationOptions"
                        :server-items-length="totalItems" page-select>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "PipelineStagesIO",
  components: {DDataTable},
  data() {
    return {
      loading: false,
      error: null,
      hasError: false,
      items: [],
      paginationOptions: {},
      totalItems: 0,
      footerProps: {
        itemsPerPageOptions: [50, 100, 200, -1]
      }
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    crawlingId() {
      return this.$route.params.crawlingId
    },
    pipelineId() {
      return this.$route.params.pipelineId
    },
    pipelineName() {
      return this.$store.state.navigation.pipeline.pipelineTitle
    },
    stage() {
      return this.$route.params.stageName
    },
    headers() {
      if (this.stage === "documentMatcher")
        return [
          {text: 'Left id', value: 'left.id'},
          {text: 'Left Language', value: 'left.language'},
          {text: 'Left File', value: 'left.documentOrigin'},
          {text: 'Right File', value: 'right.documentOrigin'},
          {text: 'Right Language', value: 'right.language'},
          {text: 'Right id', value: 'right.id'},
        ]
      return [
        {text: 'ID', value: 'id'},
        {text: 'Language', value: 'language'},
        {text: 'File', value: 'documentOrigin'},
        {text: 'Processed', value: 'hasOutput'},
      ]
    },
    isACrawlingPipeline() {
      return !!this.crawlingId
    },
    getUrlPart() {
      if (this.isACrawlingPipeline) {
        return `crawlings/${this.crawlingId}`
      } else {
        return `projects/${this.projectId}`
      }
    }
  },
  watch: {
    stage() {
      this.loadStageInputOutput()
    },
    paginationOptions() {
      this.loadStageInputOutput()
    }
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    loadStageInputOutput() {
      const {itemsPerPage, page, sortBy, sortDesc} = this.paginationOptions

      if (!itemsPerPage || !page)
        return

      this.loading = true
      this.error = null
      this.hasError = false

      const limit = itemsPerPage === -1 ? undefined : itemsPerPage
      const offset = itemsPerPage === -1 ? 0 : (page - 1) * limit
      const params = {
        offset,
        limit,
        orderBy: sortBy[0],
        orderDesc: sortDesc[0]
      }

      axios.get(`/pipelines/${this.pipelineId}/io/${this.stage}`, {params})
          .then(response => {
            const {documents, totalItems} = response.data

            this.items = documents
            this.totalItems = totalItems
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    navigateToDocument(item) {
      if (item.id !== undefined)
        this.$router.push(`/${this.getUrlPart}/${this.pipelineId}/io/${this.stage}/${item.id}`)
      else if (item.left !== null && item.left.id !== undefined)
        this.$router.push(`/${this.getUrlPart}/${this.pipelineId}/io/${this.stage}/${item.left.id}`)
      else if (item.right !== null && item.right.id !== undefined)
        this.$router.push(`/${this.getUrlPart}/${this.pipelineId}/io/${this.stage}/${item.right.id}`)
    }
  },
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
