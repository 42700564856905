var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"60%","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('simple-dialog',{attrs:{"show":_vm.dialogSwitchGroup,"on-apply":_vm.confirmSwitchSave,"on-no":_vm.confirmSwitch,"on-close":_vm.cancelSwitch,"max-width":"600px"}},[_vm._v(" Do you want to save before editing another group ? ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.apply($event)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Name","autofocus":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}}),_c('v-select',{attrs:{"items":_vm.groupsData,"label":"Parent group (permission inheritance)","chips":"","rules":_vm.rules.parentId,"item-text":"name","item-value":"id","hint":"Select the parent group"},on:{"change":_vm.refreshInheritedPermissions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.stringToColor(item.name),"dark":_vm.isDark(_vm.stringToColor(item.name)),"close":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchToGroup(item.id)},"click:close":_vm.removeParent}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.editedItem.parentId),callback:function ($$v) {_vm.$set(_vm.editedItem, "parentId", $$v)},expression:"editedItem.parentId"}}),(_vm.editedItem.children.length > 0)?_c('v-row',[_c('v-col',{staticClass:"mt-3 v-label theme--light",attrs:{"md":"auto"}},[_c('p',[_vm._v("Children groups :")])]),_c('v-col',_vm._l((_vm.editedItem.children),function(group){return _c('v-chip',{key:group,staticClass:"ma-1",attrs:{"color":_vm.stringToColor(_vm.groupById[group].name),"dark":_vm.isDark(_vm.stringToColor(_vm.groupById[group].name))},on:{"click":function($event){$event.preventDefault();return _vm.switchToGroup(group)}}},[_vm._v(" "+_vm._s(_vm.groupById[group].name)+" ")])}),1)],1):_vm._e(),_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Application Permissions")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"mt-1",attrs:{"items":_vm.editedItem.permissions,"headers":_vm.headers,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.inherited",fn:function(ref){
var item = ref.item;
return [(item.inherited.length === 0)?_c('span',[_vm._v("NO")]):_vm._l((item.inherited),function(group){return _c('v-chip',{key:group,staticClass:"ma-1",attrs:{"small":"","color":_vm.stringToColor(_vm.groupById[group].name),"dark":_vm.isDark(_vm.stringToColor(_vm.groupById[group].name))},on:{"click":function($event){return _vm.switchToGroup(group)}}},[_vm._v(" "+_vm._s(_vm.groupById[group].name)+" ")])})]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.permissions.admin)?[(item.permissions.admin.inherited)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_c('v-simple-checkbox',{attrs:{"dense":"","ripple":false},model:{value:(item.permissions.admin.granted),callback:function ($$v) {_vm.$set(item.permissions.admin, "granted", $$v)},expression:"item.permissions.admin.granted"}})]:_vm._e()]}},{key:"item.create",fn:function(ref){
var item = ref.item;
return [(item.permissions.create)?[(item.permissions.create.inherited)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_c('v-simple-checkbox',{attrs:{"dense":"","ripple":false},model:{value:(item.permissions.create.granted),callback:function ($$v) {_vm.$set(item.permissions.create, "granted", $$v)},expression:"item.permissions.create.granted"}})]:_vm._e()]}},{key:"item.use",fn:function(ref){
var item = ref.item;
return [(item.permissions.use)?[(item.permissions.use.inherited)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_c('v-simple-checkbox',{attrs:{"dense":"","ripple":false},model:{value:(item.permissions.use.granted),callback:function ($$v) {_vm.$set(item.permissions.use, "granted", $$v)},expression:"item.permissions.use.granted"}})]:_vm._e()]}}])})],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.onClose}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }