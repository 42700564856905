<template>
  <div>
    <v-stepper-step :step="3" :complete="step > 3" :editable="true">
      Export parameters
    </v-stepper-step>
    <v-stepper-content :step="3">
      <div>
        The export parameters will be included soon to the application. Please click continue to still proceed to the
        default export.
        <!-- TODO: Add export options and remove this message -->
      </div>
      <v-btn color="primary" @click="nextStep" class="my-3">
        Continue
      </v-btn>
    </v-stepper-content>
    <v-stepper-step :step="4" :complete="step > 4" :editable="true">
      Choose export file
    </v-stepper-step>
    <v-stepper-content :step="4">
      <v-radio-group
          v-model="export_method"
          row
          label="Export the alignments as"
      >
        <v-radio
            label="TMX"
            :value="EXPORT_TYPE.TMX"
        ></v-radio>
        <v-radio
            label="CSV"
            :value="EXPORT_TYPE.CSV"
        ></v-radio>
      </v-radio-group>
      <v-btn color="primary" @click="download" class="my-3">
        Download
      </v-btn>
    </v-stepper-content>


    <v-stepper-step :step="5" :complete="step === 5 && downloaded" :editable="step>=5">
      Download
    </v-stepper-step>
    <v-stepper-content :step="5">
      <div class="mb-3">
        <h6 class="text-h6">{{ downloadingTitle }}</h6>
      </div>
      <v-btn color="primary" @click="reset" :disabled="loading" :loading="loading">
        More actions ?
      </v-btn>
    </v-stepper-content>


  </div>
</template>

<script>
import {downloadFile} from "@/util/js-utils";

const EXPORT_TYPE = {
  TMX: 'TMX',
  CSV: 'CSV'
}

const formatDate = date => {
  const year = date.getFullYear()
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  let hours = '' + date.getHours()
  let minutes = '' + date.getMinutes()

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if (hours.length < 2)
    hours = '0' + hours;
  if (minutes.length < 2)
    minutes = '0' + minutes;

  return [year, month, day, hours, minutes].join('');
}

export default {
  name: "Export",
  components: {},
  props: {
    appliedFilters: {type: Object, required: true},
    data: {type: Object, required: true},
    step: {type: Number, required: true},
    nextStep: {type: Function, required: true},
    reset: {type: Function, required: true},
    showError: {type: Function, required: true}
  },
  data() {
    return {
      export_method: EXPORT_TYPE.TMX,
      loading: false,
      downloaded: false,
      EXPORT_TYPE
    }
  },
  computed: {
    downloadingTitle() {
      if (!this.loading && !this.downloaded)
        return "There was an error getting the file. It is probably not your fault."
      if (this.loading && !this.downloaded)
        return "Your export file is currently downloading"
      if (!this.loading && this.downloaded)
        return "Your file has been downloaded"
      return ""
    }
  },
  methods: {
    resetState() {
      this.loading = false
      this.downloaded = false
      this.export_method = EXPORT_TYPE.TMX
      this.reset()
    },
    download() {
      if (this.export_method === EXPORT_TYPE.TMX)
        this.downloadTmx()
      else if (this.export_method === EXPORT_TYPE.CSV)
        this.downloadCsv()
      this.nextStep()
    },
    downloadTmx() {
      const downloadName = `datalambic_export_${formatDate(new Date())}.zip`

      const data = {
        filters: this.appliedFilters.filters,
        postFilters: this.appliedFilters.postFilters,
      }

      this.loading = true

      downloadFile('/segment-manager/export-tmx', downloadName,
          'application/zip', 'post', data)
          .then(() => {
            this.loading = false
            this.downloaded = true
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
            this.downloaded = false
          })
    },
    downloadCsv() {
      const downloadName = `datalambic_export_${formatDate(new Date())}.csv`

      const data = {
        filters: this.appliedFilters.filters,
        postFilters: Object.keys(this.appliedFilters.postFilters).filter(key => this.appliedFilters.postFilters[key]),
      }

      downloadFile('/segment-manager/export-csv', downloadName,
          'text/csv', 'post', data)
          .then(() => {
            this.loading = false
            this.downloaded = true
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
            this.downloaded = false
          })
    }
  }
}
</script>

<style scoped>
</style>
