<template>
  <v-menu v-model="showContextMenu" :position-x="mouseX" :position-y="mouseY" absolute offset-y rounded="1">
    <v-list tile class="pa-0">
      <v-list-item v-for="(item, index) in contextMenuItems" :key="index" @click="item.action"
                   :disabled="item.disabled" dense>
        <v-list-item-icon class="mr-3">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "DocumentEditorContextMenu",
  props: {
    contextMenuItems: {type: Array, required: true},
    show: {type: Boolean, required: true},
    mouseX: {type: Number, required: true},
    mouseY: {type: Number, required: true},
  },
  data() {
    return {
      keyListener: undefined,
      showContextMenu: false
    }
  },
  watch: {
    show(newVal) {
      this.showContextMenu = newVal
    }
  },
}
</script>

<style scoped>

</style>
