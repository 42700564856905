<template>
  <v-dialog v-model="show" max-width="80%" @click:outside="onClose">
    <v-card>
      <v-card-title>
        <span class="headline">Document Details</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="document">
          <div class="text-body-1">
            <div class="my-4">
              Imported from
              <span v-if="document.pipelineId">
                <span class="text--primary">{{ projectLabel }}</span>
                and pipeline
                <span class="text--primary">{{ pipelineName }}</span>
              </span>
              <span v-else-if="tmxLabel" class="text--primary">{{ tmxLabel }}</span>
              <span v-else>a deleted pipeline</span>
              by
              <span v-if="document.importUser">
                user
                <span class="text--primary">{{ enumsValues.usersById[document.importUser].name }}</span>
              </span>
              <span v-else>
                a deleted user
              </span>
              on
              <span class="text--primary">{{ document.importDate }}</span>
            </div>
            <div class="d-flex flex-column">
              <v-row>
                <v-col cols="3">Origin documents/URLs:</v-col>
                <v-col class="text--primary">{{ document.document1Origin }}</v-col>
                <v-col class="text--primary">{{ document.document2Origin }}</v-col>
              </v-row>
              <v-row v-if="document.document1FriendlyName || document.document2FriendlyName">
                <v-col cols="3">Origin document descriptions:</v-col>
                <v-col class="text--primary">{{ document.document1FriendlyName }}</v-col>
                <v-col class="text--primary">{{ document.document2FriendlyName }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">Document languages:</v-col>
                <v-col class="text--primary">{{ document.document1Language }}</v-col>
                <v-col class="text--primary">{{ document.document2Language }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  Confidentiality level:
                  <span class="text--primary">
                    {{ enumsValues.confidentialityLevelById[document.confidentialityLevelId].name }}
                  </span>
                </v-col>
                <v-col>
                  Owner:
                  <v-chip :color="stringToColor(ownerName)" small :dark="isDark(stringToColor(ownerName))">
                    {{ ownerName }}
                  </v-chip>
                </v-col>
                <v-col>
                  Translated:
                  <span class="text--primary">{{ !!document.isLeftSource ? 'YES' : 'NO' }}</span>
                </v-col>
              </v-row>
            </div>
            <div v-if="hasTags" class="mt-2">
              Tags:
              <v-chip class="mr-1" v-for="(tag, key) in tagNames" :color="stringToColor(tag)" :key="key" small
                      :dark="isDark(stringToColor(tag))">
                {{ tag }}
              </v-chip>
            </div>
            <div v-if="hasDomains" class="mt-2">
              Domains:
              <v-chip class="mr-1" v-for="(domain, index) in domainNames" :color="stringToColor(domain)" :key="index"
                      small :dark="isDark(stringToColor(domain))">
                {{ domain }}
              </v-chip>
            </div>
            <div v-if="hasGroups">
              Groups:
              <v-chip class="mr-1" v-for="(group, index) in groupNames" :color="stringToColor(group)" :key="index" small
                      :dark="isDark(stringToColor(group))">
                {{ group }}
              </v-chip>
            </div>
            <div v-if="hasNotes">
              Document notes:
              <div class="text--primary" v-for="(note, index) in document.notes" :key="index">
                "{{ note }}"
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="onClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isDark, stringToColor} from "@/util/color";
import {round} from "lodash";

const RULES = {
  add: 'Added',
  modify: 'Modified',
  validate: 'Validated',
  merge: 'Merged'
}

export default {
  name: "DocumentDetails",
  props: {
    show: {type: Boolean, required: true},
    enumsValues: {type: Object, required: false},
    onClose: {type: Function, required: true},
    document: {type: Object, default: null}
  },
  data() {
    return {
      isDark,
      stringToColor,
    }
  },
  computed: {
    rule() {
      return this.document?.appliedRuleType ? RULES[this.document.appliedRuleType] : ""
    },
    hasTags() {
      return this.document?.tags.length > 0
    },
    tagNames() {
      return this.document?.tags.map(tagId => this.enumsValues.tagById[tagId]?.name ?? '[unknown]')
    },
    hasDomains() {
      return this.document?.domains.length > 0
    },
    domainNames() {
      return this.document?.domains.map(domainId => this.enumsValues.domainById[domainId]?.name ?? '[unknown]')
    },
    hasGroups() {
      return this.document?.groups.length > 0
    },
    hasNotes() {
      return this.document?.notes.length > 0
    },
    groupNames() {
      return this.document?.groups.map(groupId => this.enumsValues.groupById[groupId]?.name ?? '[unknown]')
    },
    ownerName() {
      return this.enumsValues.ownerById[this.document?.ownerId]?.name ?? '[unknown]'
    },
    projectLabel() {
      const projectName = this.enumsValues.projectById[this.document?.projectId]?.name
      const crawlingName = this.enumsValues.crawlingByProjectId[this.document?.projectId]?.name
      if (projectName)
        return `project "${projectName}"`
      else if (crawlingName)
        return `crawling project "${crawlingName}"`
      return '[unknown]'
    },
    tmxLabel() {
      if (this.document.document1Origin)
        return `tmx file "${this.document.document1Origin}"`
      return null
    },
    pipelineName() {
      return this.enumsValues.pipelinesById[this.document?.pipelineId].name
    },
    formattedScoreDistance() {
      return this.document?.scoreDistance >= 0 ? round(this.document.scoreDistance, 3) : 'N/A'
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
