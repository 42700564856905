import { reduceById } from '@/util/js-utils'

class State {
  id
  name
  color
  icon

  constructor (id, name, color, icon) {
    this.id = id
    this.name = name
    this.color = color
    this.icon = icon
  }
}

export const ALIGNMENT_STATES = Object.freeze({
  NO_STATE: new State('noState', 'No state'),
  MODIFIED: new State('modified', 'Modified', '#ffd600', 'mdi-pencil'),
  REMOVED: new State('removed', 'Removed', '#d50000', 'mdi-close'),
  VALIDATED: new State('validated', 'Validated', '#00c853', 'mdi-check'),
  MERGED: new State('merged', 'Merged', '#6200ea', 'mdi-arrow-collapse-vertical'),
  SPLIT: new State('split', 'Split', '#00b8d4', 'mdi-arrow-split-horizontal'),
  BATCH_REMOVED: new State('batchRemoved', 'Batch removed', '#ff5900', 'mdi-close-outline')
})

class RuleType {
  id
  state

  constructor (id, state) {
    this.id = id
    this.state = state
  }
}

export const RULE_TYPES = Object.freeze({
  NO_RULE: new RuleType(null, ALIGNMENT_STATES.NO_STATE),
  VALIDATE: new RuleType('validate', ALIGNMENT_STATES.VALIDATED),
  REMOVE: new RuleType('remove', ALIGNMENT_STATES.REMOVED),
  MODIFY: new RuleType('modify', ALIGNMENT_STATES.MODIFIED),
  MERGE: new RuleType('merge', ALIGNMENT_STATES.MERGED),
  SPLIT: new RuleType('split', ALIGNMENT_STATES.SPLIT),
  BATCH_REMOVE: new RuleType('batchRemove', ALIGNMENT_STATES.BATCH_REMOVED)
})

export const RULE_TYPE_BY_ID = reduceById(Object.values(RULE_TYPES))

export const BATCH_RULE_TYPE_IDS = new Set([RULE_TYPES.BATCH_REMOVE.id])
export const REMOVE_RULE_TYPE_IDS = new Set([RULE_TYPES.REMOVE.id, RULE_TYPES.BATCH_REMOVE.id])

class Action {
  allowed
  allowedStates

  constructor (allowed, allowedStates) {
    this.allowed = allowed
    this.allowedStates = new Set(allowedStates)
  }

  isAllowed (selectedAlignments) {
    if (!this.allowed) {
      return true
    }
    return this.allowed(selectedAlignments, this.allowedStates)
  }
}

export const ACTIONS = Object.freeze({
  RESET: new Action(selectedAlignments => selectedAlignments.length !== 0),
  VALIDATE: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)),
    [ALIGNMENT_STATES.NO_STATE, ALIGNMENT_STATES.VALIDATED,
      ALIGNMENT_STATES.BATCH_REMOVED]),
  EDIT: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length === 1 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)),
    [ALIGNMENT_STATES.NO_STATE, ALIGNMENT_STATES.MODIFIED,
      ALIGNMENT_STATES.BATCH_REMOVED]),
  REMOVE: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)),
    [ALIGNMENT_STATES.NO_STATE, ALIGNMENT_STATES.REMOVED,
      ALIGNMENT_STATES.BATCH_REMOVED]),
  NEW_MERGE: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length >= 2 &&
      selectedAlignments.every( // Only allow merging in same document
        ({ alignedDocumentId }) => alignedDocumentId === selectedAlignments[0].alignedDocumentId) &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)),
    [ALIGNMENT_STATES.NO_STATE, ALIGNMENT_STATES.BATCH_REMOVED]),
  EDIT_MERGE: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)) &&
      selectedAlignments.every(({ appliedRule }) => appliedRule.id === selectedAlignments[0].appliedRule.id),
    [ALIGNMENT_STATES.MERGED]),
  NEW_SPLIT: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length === 1 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)),
    [ALIGNMENT_STATES.NO_STATE, ALIGNMENT_STATES.BATCH_REMOVED]),
  EDIT_SPLIT: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)) &&
      selectedAlignments.every(({ appliedRule }) => appliedRule.id === selectedAlignments[0].appliedRule.id),
    [ALIGNMENT_STATES.SPLIT]),
  FLAG_UNCERTAIN: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)) &&
      selectedAlignments.every(({ uncertain }) => uncertain === selectedAlignments[0].uncertain),
    [ALIGNMENT_STATES.MODIFIED,
      ALIGNMENT_STATES.REMOVED, ALIGNMENT_STATES.VALIDATED,
      ALIGNMENT_STATES.MERGED, ALIGNMENT_STATES.SPLIT]),
  RESET_UNCERTAIN: new Action(
    (selectedAlignments, allowedStates) => selectedAlignments.length !== 0 &&
      selectedAlignments.every(({ state }) => allowedStates.has(state)) &&
      selectedAlignments.every(({ uncertain }) => uncertain === selectedAlignments[0].uncertain),
    [ALIGNMENT_STATES.MODIFIED,
      ALIGNMENT_STATES.REMOVED, ALIGNMENT_STATES.VALIDATED,
      ALIGNMENT_STATES.MERGED, ALIGNMENT_STATES.SPLIT]),
  SHOW_WITHOUT_FILTERS: new Action(selectedAlignments => selectedAlignments.length !== 0)
})
