<template>
  <v-dialog v-model="show" max-width="60%" persistent>
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="editedItem && enumsValues">
            <div>
              <h5 class="text-h5">Rule definition</h5>
              <v-text-field label="Comment (Optional)" v-model="editedItem.comment" autofocus></v-text-field>
              <v-select label="Language" v-model="editedItem.languageId" :items="enumsValues.languages"
                        :item-text="lang=>capitalize(lang.name)" item-value="id"
                        :rules="rules.language"></v-select>
              <v-text-field :label="textFieldLabel" v-model="editedItem.search" :rules="rules.search"></v-text-field>
              <v-text-field v-if="isReplacement" label="by ..." v-model="editedItem.value"></v-text-field>

              <div v-else-if="editedItem.value">
                <v-select label="When should the exception be applied" v-model="editedItem.value.type"
                          :items="exceptionTypes" item-text="label" item-value="type"
                          :rules="rules.exceptionType" @change="handleExceptionTypeChange"></v-select>

                <div v-if="editedItem.value.type === 'nextToken'">
                  <div v-for="(condition, index) in editedItem.value.value"
                       :key="index" class="d-flex align-center my-2">
                    <v-btn @click="editedItem.value.value.splice(index, 1)" class="mr-2">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-chip class="mx-2">
                      {{ nextTokenConditionName(condition) }}
                    </v-chip>
                    <v-text-field v-if="condition.type === 'literal'" label="Value"
                                  v-model="condition.value" hide-details></v-text-field>
                  </div>
                  <v-select label="Add a condition" v-model="selectedExceptionValue"
                            :items="filteredNextTokenConditions" @click="selectedExceptionValue = null"
                            item-text="label" return-object @change="addNextTokenCondition"
                            class="flex-grow-0" :rules="rules.nextTokenConditions"></v-select>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <h5 class="text-h5">Rule Scope</h5>
            </div>
            <v-checkbox label="Apply this rule to all projects" v-model="editedItem.isGlobal"
                        :true-value="true" :false-value="false"></v-checkbox>
            <d-data-table :items="enumsValues.pipelines" :headers="headers" show-select
                          :footer-props="footerProps" v-model="selectedPipelines" :disabled="editedItem.isGlobal"
                          :items-per-page=50 :loading="loading" group-by="projectId" single-expand item-key="id"
                          height="60vh" :fill-height="false" :enable-resize="false">
              <template v-slot:header.data-table-select></template>
              <template v-slot:group.header="{group, toggle, headers, isOpen}">
                <!-- Override to disable grouping removal and allow select -->
                <td :colspan="headers.length" @click="toggle" class="project-grouping" :ref="`project-${group}`">
                  <div class="d-flex">
                    <v-simple-checkbox class="mr-4" :ripple=false :disabled="editedItem.isGlobal"
                                       :value="selectedProjects[group]"
                                       @input="changeProjectSelection(group)"></v-simple-checkbox>
                    <span class="font-weight-bold mx-1">
                      {{ getProjectOrCrawlingTitle(group) }}
                    </span> |
                    <span class="font-weight-bold mx-1">
                      {{ enumsValues.pipelinesByProject[group].length }} pipelines
                    </span> |
                    <span class="font-weight-bold mx-1">
                      {{ nbrPipelineSelectedByProject[group] || 0 }} selected
                    </span>

                    <v-spacer></v-spacer>
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </div>
                </td>
              </template>
              <template v-slot:item.data-table-select="{item, isSelected, select}">
                <v-simple-checkbox :disabled="selectedProjects[item.projectId]" class="mr-4" :ripple=false
                                   :value="isSelected" @input="select(!isSelected)"></v-simple-checkbox>
              </template>
              <template v-slot:footer.prepend>
                <span class="text-body-1 mr-2">
                  {{ editedItem.pipelineIds && editedItem.pipelineIds.length || 0 }} pipelines selected
                </span>
                <span class="text-body-1">
                  {{ editedItem.projectIds && editedItem.projectIds.length || 0 }} projects selected
                </span>
              </template>
            </d-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-8">
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {capitalize, cloneDeep, groupBy, isEqual} from "lodash";
import axios from "axios";
import {reduceById, reduceByField} from "@/util/js-utils";
import DDataTable from "@/components/wrappers/DDataTable";

const EXCEPTION_TYPES = [
  {
    type: 'always',
    label: 'Always apply'
  },
  {
    type: 'nextToken',
    label: 'Apply if the next token matches one or more of the conditions'
  }
]

const NEXT_TOKEN_CONDITION_LABELS = {
  literal: 'Exact match',
  predefined: {
    startsWithUppercase: 'Starts with an uppercase letter',
    startsWithLowercase: 'Starts with a lowercase letter',
    startsWithDigit: 'Starts with a digit'
  }
}

const NEXT_TOKEN_CONDITIONS = [
  {
    type: 'literal',
    value: '',
    label: 'Exact match'
  },
  {
    type: 'predefined',
    value: 'startsWithUppercase',
    label: 'Starts with an uppercase letter'
  },
  {
    type: 'predefined',
    value: 'startsWithLowercase',
    label: 'Starts with a lowercase letter'
  },
  {
    type: 'predefined',
    value: 'startsWithDigit',
    label: 'Starts with a digit'
  }
]

const DEFAULT_EXCEPTION = {
  id: undefined,
  isGlobal: false,
  search: '',
  comment: '',
  languageId: '',
  projectIds: [],
  pipelineIds: [],
  type: 'exception',
  value: { // object, required
    type: 'always', // literal string, required -- 'always'|'nextToken'
    value: []
  }
}

const DEFAULT_REPLACEMENT = {
  id: undefined,
  isGlobal: false,
  search: '',
  comment: '',
  languageId: '',
  projectIds: [],
  pipelineIds: [],
  type: 'replacement',
  value: ''
}

export default {
  name: "TokenizerRulesDialog",
  components: {DDataTable},
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null},
    isReplacement: {type: Boolean, required: true},
    pipelineId: {type: Number, required: false},
    language: {type: String, required: false}
  },
  data() {
    return {
      exceptionTypes: EXCEPTION_TYPES,
      selectedExceptionType: undefined,
      nextTokenConditions: NEXT_TOKEN_CONDITIONS,
      selectedExceptionValue: null,
      loading: false,
      enumsValues: null,
      selectedPipelines: [],
      editedItem: {},
      originalItem: null,
      headers: [
        {text: '', value: 'projectId'}, // Only used for grouping
        {text: 'Name', value: 'name'},
        {text: 'Language 1', value: 'language1'},
        {text: 'Language 2', value: 'language2'},
        {text: 'ID', value: 'id'},
      ],
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },
      rules: {
        language: [
          v => !!v || 'Required'
        ],
        search: [
          v => !!v || 'Required'
        ],
        exceptionType: [
          v => !!v || 'Required'
        ],
        nextTokenConditions: [
          () => this.editedItem.value.value?.length > 0 || 'At least 1 is required'
        ],
      }
    }
  },
  created() {
    this.fetchEnums()
  },
  mounted() {
    this.collapse()
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
      this.collapse()
    },
    item(newVal) {
      this.populateForm(newVal)
    },
    selectedPipelines(newVal) {
      this.editedItem.pipelineIds = newVal.map(pipeline => (pipeline.id))
    },
    enumsValues: {
      handler() {
        this.collapse()
      },
      deep: true
    },
  },
  computed: {
    hasBeenModified() {
      return !isEqual(this.editedItem, this.originalItem)
    },
    title() {
      const type = this.isReplacement ? "replacement rule" : "exception"
      return !this.item ? "Create " + type : "Edit " + type
    },
    selectedProjects() {
      if (!this.editedItem.projectIds)
        return {}

      return this.enumsValues.projectAndCrawlingIds.reduce((obj, id) =>
          Object.assign(obj, {[id]: this.editedItem.projectIds.includes(id)}), {})
    },
    nbrPipelineSelectedByProject() {
      const selectedPipelinesByProject = groupBy(this.selectedPipelines, 'projectId')
      return Object.keys(selectedPipelinesByProject).reduce((obj, project) => ({
        ...obj,
        [project]: selectedPipelinesByProject[project].length
      }), {})
    },
    textFieldLabel() {
      return this.isReplacement ? "Replace ... (Required)" : "Do not split these tokens"
    },
    selectedNextTokenPredefinedConditions() {
      if (this.editedItem.type !== 'exception' || this.editedItem.value.type !== 'nextToken')
        return {}

      return this.editedItem.value.value
          .filter(condition => condition.type === 'predefined')
          .reduce((obj, condition) => Object.assign(obj, {[condition.value]: true}), {})
    },
    filteredNextTokenConditions() {
      return this.nextTokenConditions.filter(condition =>
          condition.type === 'literal' || !this.selectedNextTokenPredefinedConditions[condition.value])
    }
  },
  methods: {
    capitalize,
    collapse() {
      if (this.enumsValues && this.enumsValues.projectsAndCrawlings)
        this.$nextTick(() => {
          // Collapse all projects by default
          for (const project of this.enumsValues.projectsAndCrawlings) {
            const ref = this.$refs[`project-${project.projectId}`]
            if (ref)
              ref.click()
          }
        })
    },
    changeProjectSelection(projectId) {
      if (this.editedItem.projectIds.includes(projectId))
        this.editedItem.projectIds.splice(this.editedItem.projectIds.indexOf(projectId), 1)
      else
        this.editedItem.projectIds.push(projectId)
    },
    addNextTokenCondition(condition) {
      this.editedItem.value.value.push(condition)
    },
    nextTokenConditionName(condition) {
      const label = NEXT_TOKEN_CONDITION_LABELS[condition.type]
      return typeof label === 'string' ? label : label[condition.value]
    },
    populateForm(item) {
      const defaultItem = this.isReplacement ? DEFAULT_REPLACEMENT : DEFAULT_EXCEPTION
      if (item)
        this.editedItem = Object.assign({}, this.editedItem, cloneDeep(item))
      else {
        this.editedItem = Object.assign({}, this.editedItem, cloneDeep(defaultItem))
        if (this.pipelineId)
          this.editedItem.pipelineIds.push(this.pipelineId)
        if (this.language)
          this.editedItem.languageId = this.language
      }

      this.selectedPipelines = this.enumsValues.pipelines
          .filter(pipeline => this.editedItem.pipelineIds.includes(pipeline.id))
      this.originalItem = cloneDeep(this.editedItem)
    },
    apply() {
      if (this.hasBeenModified) {
        if (this.$refs.form.validate())
          this.onApply(this.editedItem)
      } else
        this.onClose()
    },
    fetchEnums() {
      this.loading = true
      this.error = null
      this.hasError = false

      const promises = [
        axios.get('/projects'),
        axios.get('/languages'),
        axios.get('/importable-pipelines'),
        axios.get('/crawlings'),
      ]

      Promise.all(promises)
          .then(enumResponses => {
            const projects = enumResponses[0].data.map(project => ({
              ...project,
              id: project.id,
              name: project.title,
              projectId: project.id
            }))
            const projectsIds = projects.map(project => project.id)

            const languages = enumResponses[1].data.map(language => ({
              id: language.id,
              name: language.name
            }))

            const pipelines = enumResponses[2].data.map(pipeline => ({
              ...pipeline,
              id: pipeline.id,
            }))

            const crawlings = enumResponses[3].data.map(crawling => ({
              ...crawling,
              name: crawling.title
            }))

            this.enumsValues = {
              projects,
              projectsIds,
              projectById: reduceById(cloneDeep(projects)),
              languages,
              languagesById: reduceById(cloneDeep(languages)),
              pipelines,
              pipelinesById: reduceById(cloneDeep(pipelines)),
              pipelinesByProject: groupBy(pipelines, 'projectId'),
              crawlingByProjectId: reduceByField(crawlings, 'projectId'),
              projectsAndCrawlings: projects.concat(crawlings),
              projectAndCrawlingIds:  projectsIds.concat(crawlings.map(crawling => crawling.projectId)),
            }
            this.loading = false
          })
          .catch(err => {
            this.error = err
            this.hasError = true
            this.loading = false
          })
    },
    handleExceptionTypeChange() {
      if (this.editedItem.value.type === 'nextToken' && !Array.isArray(this.editedItem.value.value))
        this.editedItem.value.value = []
    },
    getProjectOrCrawlingTitle(group) {
      return this.enumsValues.projectById[group]?.title ?? this.enumsValues.crawlingByProjectId[group]?.title
    }
  }
}
</script>

<style scoped>

</style>
