<template>
  <v-dialog v-model="show" max-width="60%" persistent>

    <simple-dialog :show="dialogSwitchGroup" :on-apply="confirmSwitchSave" :on-no="confirmSwitch"
                   :on-close="cancelSwitch" max-width="600px">
      Do you want to save before editing another group ?
    </simple-dialog>

    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Name" v-model="editedItem.name" autofocus></v-text-field>
            <v-select
                v-model="editedItem.parentId"
                :items="groupsData"
                label="Parent group (permission inheritance)"
                chips
                :rules="rules.parentId"
                item-text="name"
                item-value="id"
                @change="refreshInheritedPermissions"
                hint="Select the parent group">
              <template #selection="{ item }">
                <v-chip :color="stringToColor(item.name)"
                        @click.stop="switchToGroup(item.id)"
                        :dark="isDark(stringToColor(item.name))"
                        close @click:close="removeParent">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-row v-if="editedItem.children.length > 0">
              <v-col md="auto" class="mt-3 v-label theme--light"><p>Children groups :</p></v-col>
              <v-col>
                <v-chip class="ma-1"
                        v-for="group in editedItem.children"
                        @click.prevent="switchToGroup(group)"
                        :color="stringToColor(groupById[group].name)"
                        :key="group"
                        :dark="isDark(stringToColor(groupById[group].name))">
                  {{ groupById[group].name }}
                </v-chip>
              </v-col>
            </v-row>
            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>Application Permissions</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table :items="editedItem.permissions"
                                :headers="headers"
                                class="mt-1"
                                dense
                                disable-sort
                                hide-default-footer>
                    <template v-slot:item.inherited="{item}">
                      <span v-if="item.inherited.length === 0">NO</span>
                      <v-chip class="ma-1" v-else
                              v-for="group in item.inherited"
                              :key="group"
                              @click="switchToGroup(group)"
                              small
                              :color="stringToColor(groupById[group].name)"
                              :dark="isDark(stringToColor(groupById[group].name))">
                        {{ groupById[group].name }}
                      </v-chip>
                    </template>
                    <template v-slot:item.admin="{item}">
                      <template v-if="item.permissions.admin">
                        <v-simple-checkbox v-if="item.permissions.admin.inherited" :value=true dense :ripple=false
                                           disabled></v-simple-checkbox>
                        <v-simple-checkbox v-else v-model="item.permissions.admin.granted" dense
                                           :ripple=false></v-simple-checkbox>
                      </template>
                    </template>
                    <template v-slot:item.create="{item}">
                      <template v-if="item.permissions.create">
                        <v-simple-checkbox v-if="item.permissions.create.inherited" :value=true dense :ripple=false
                                           disabled></v-simple-checkbox>
                        <v-simple-checkbox v-else v-model="item.permissions.create.granted" dense
                                           :ripple=false></v-simple-checkbox>
                      </template>
                    </template>
                    <template v-slot:item.use="{item}">
                      <template v-if="item.permissions.use">
                        <v-simple-checkbox v-if="item.permissions.use.inherited" :value=true dense :ripple=false
                                           disabled></v-simple-checkbox>
                        <v-simple-checkbox v-else v-model="item.permissions.use.granted" dense
                                           :ripple=false></v-simple-checkbox>
                      </template>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {cloneDeep, groupBy, isEqual} from "lodash";
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import SimpleDialog from "@/components/parts/manage-dialogs/SimpleDialog";
import {reduceById} from "@/util/js-utils";

const DEFAULT_GROUP = {
  id: undefined,
  name: '',
  parentId: undefined,
  children: [],
  permissions: []
}

const flattenPermissions = permissions =>
    permissions.flatMap(resourcePermissions =>
        Object.entries(resourcePermissions.permissions)
            .filter(entry => entry[1].granted && !entry[1].inherited)
            .map(([permissionType]) => ({
              resource: resourcePermissions.resource,
              permissionType
            })))

const sortArrays = item => {
  if (item === null)
    return item
  if (Array.isArray(item))
    return item.sort()
  if (typeof item === 'object')
    return Object.entries(item)
        .reduce((obj, [key, value]) =>
            Object.assign(obj, {[key]: sortArrays(value)}), {})
  return item
}

export default {
  name: "GroupDialog",
  components: {SimpleDialog},
  props: {
    formTitle: {type: String, required: true},
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    onChangeGroup: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      isDark,
      stringToColor,
      switchGroup: undefined,
      dialogSwitchGroup: false,
      editedItem: {
        id: undefined,
        name: '',
        parentId: undefined,
        children: [],
        permissions: [] // Shape : [{resource: string, inherited: [int], permissions: {admin: {inherited: bool, granted: bool}}}, ...]
      },
      originalItem: null,
      groupsData: [],
      permissionsData: [],
      headers: [
        {text: 'Resource', value: 'resource', align: 'left', width: '20%'},
        {text: 'Inherited', value: 'inherited', align: 'left'},
        {text: 'Admin', value: 'admin', align: 'left', width: '1%'},
        {text: 'Create', value: 'create', align: 'left', width: '1%'},
        {text: 'Use', value: 'use', align: 'left', width: '1%'},
      ],
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (this.show)
        this.populateForm(newVal)
    }
  },
  computed: {
    hasBeenModified() {
      return !isEqual(sortArrays(cloneDeep(this.editedItem)), sortArrays(cloneDeep(this.originalItem)))
    },
    groupById() {
      return reduceById(this.groupsData)
    },
    rules() {
      return {
        parentId: [
          v => !this.isDescendant(v, v, true) || 'The parent must not be a descendant or itself'
        ]
      }
    }
  },
  methods: {
    populateForm(item) {
      this.loading = true
      this.error = null
      this.hasError = false

      Promise.all([axios.get(`/groups`), axios.get(`/application-permissions`)])
          .then(responses => {
            this.groupsData = responses[0].data
            this.permissionsData = responses[1].data
            this.loading = false

            if (item !== null && item !== undefined)
              this.editedItem = {
                ...cloneDeep(DEFAULT_GROUP), ...cloneDeep(item), ...{
                  permissions: this.structurePermissions(item.permissions, item.parentId)
                }
              }
            else
              this.editedItem = {
                ...cloneDeep(DEFAULT_GROUP), ...{
                  permissions: this.structurePermissions([])
                }
              }

            this.originalItem = cloneDeep(this.editedItem)
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    refreshInheritedPermissions() {
      const {parentId} = this.editedItem
      const isParentValid = this.rules.parentId.every(rule => rule(parentId) === true)
      if (isParentValid)
        this.editedItem.permissions = this.computeInheritedPermissions(
            this.editedItem.permissions, parentId, false)
    },
    computeInheritedPermissions(permissions, parentId, keepGranted) {
      const inheritedPermissions = this.buildInheritedPermissions(parentId)

      return permissions
          .map(resourcePermissions => {
            const {resource} = resourcePermissions

            const result = {resource, inherited: []}

            if (inheritedPermissions[resource])
              result.inherited = Object.keys(
                  Object.values(inheritedPermissions[resource])
                      .flatMap(groupIds => groupIds)
                      .reduce((obj, groupId) => Object.assign(obj, {[groupId]: true}), {}))

            result.permissions = Object.entries(resourcePermissions.permissions)
                .reduce((obj, [permissionType, value]) =>
                    Object.assign(obj, {
                      [permissionType]: {
                        granted: Boolean(keepGranted && value.granted),
                        inherited: Boolean(inheritedPermissions[resource]?.[permissionType])
                      }
                    }), {})

            return result
          })
    },
    structurePermissions(itemPermissions, parentId) {
      const groupedItemPermissions = Object.entries(groupBy(itemPermissions, 'resource'))
          .map(([resource, permissions]) => ({
            resource,
            permissions: permissions
                .reduce((obj, permission) =>
                    Object.assign(obj, {[permission.permissionType]: true}), {})
          }))
          .reduce((obj, resourcePermissions) =>
              Object.assign(obj, {[resourcePermissions.resource]: resourcePermissions.permissions}), {})

      const result = Object.entries(groupBy(this.permissionsData, 'resource'))
          .map(([resource, permissions]) => ({
            resource,
            permissions: permissions
                .reduce((obj, permission) =>
                    Object.assign(obj, {
                      [permission.permissionType]: {
                        granted: groupedItemPermissions[resource]?.[permission.permissionType] ?? false
                      }
                    }), {})
          }))

      return this.computeInheritedPermissions(result, parentId, true)
    },
    buildInheritedPermissions(groupId) {
      // Return example : {projects: {admin: [1, 2, 3], use: [4, 5, 6]}, groups: {...}}
      if (groupId === undefined || groupId === null)
        return {}

      const group = this.groupById[groupId]

      const groupPermissions = group.permissions
          .reduce((obj, permission) => {
            const {resource, permissionType} = permission
            if (!(resource in obj))
              obj[resource] = {}
            if (!(permissionType in obj[resource]))
              obj[resource][permissionType] = []

            obj[resource][permissionType].push(groupId)
            return obj
          }, {})

      const parentInheritedPermissions = this.buildInheritedPermissions(group.parentId)

      for (const [resource, permissions] of Object.entries(parentInheritedPermissions)) {
        if (!(resource in groupPermissions))
          groupPermissions[resource] = {}
        // noinspection JSCheckFunctionSignatures
        for (const [permissionType, groupIds] of Object.entries(permissions)) {
          if (!(permissionType in groupPermissions[resource]))
            groupPermissions[resource][permissionType] = []
          groupPermissions[resource][permissionType] = groupPermissions[resource][permissionType].concat(groupIds)
        }
      }

      return groupPermissions
    },
    apply() {
      if (this.hasBeenModified) {
        if (this.$refs.form.validate())
          this.onApply(Object.assign({}, this.editedItem,
              {permissions: flattenPermissions(this.editedItem.permissions)}))
      } else
        this.onClose()
    },
    confirmSwitch() {
      this.onChangeGroup(this.groupById[this.switchGroup])
      this.dialogSwitchGroup = false
    },
    confirmSwitchSave() {
      if (this.$refs.form.validate()) {
        this.onApply(Object.assign({}, this.editedItem,
            {permissions: flattenPermissions(this.editedItem.permissions)}), this.switchGroup)
        this.dialogSwitchGroup = false
      }
    },
    cancelSwitch() {
      this.dialogSwitchGroup = false
    },
    switchToGroup(groupId) {
      this.switchGroup = groupId
      if (this.hasBeenModified)
        this.dialogSwitchGroup = true
      else
        this.onChangeGroup(this.groupById[groupId])
    },
    removeParent() {
      const refresh = this.editedItem.parentId !== this.editedItem.id // Only refresh if the removed parent was not itself
      this.editedItem.parentId = null
      if (refresh)
        this.refreshInheritedPermissions()
    },
    isDescendant(groupId, initialGroupId, firstCall = false) {
      if (!groupId)
        return false

      if (firstCall) {
        if (groupId === this.editedItem.id)
          return true
      } else {
        if (groupId === initialGroupId) {
          console.warn("There is a cycle in the groups hierarchy")
          return true
        }
      }

      const group = this.groupById[groupId]
      if (group.parentId === this.editedItem.id)
        return true

      return this.isDescendant(group.parentId, initialGroupId, false)
    }
  }
}
</script>

<style scoped>

</style>
