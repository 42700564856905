<template>
  <div class="fill-height">
    <domain-dialog :form-title="formTitle" :show="dialog" :item="editedItem" :on-apply="save"
                   :on-close="closeModals"></domain-dialog>

    <simple-dialog :show="dialogDelete" :on-apply="confirmDelete" :on-close="closeModals" :item="editedItem">
      Are you sure you want to delete this domain ?
    </simple-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" :md="mdSize">
          <d-data-table :items="domainsData" :headers="tableHeaders" class="mt-1"
                        :footer-props="footerProps" :items-per-page="itemsPerPage" :loading="loading"
                        :fill-height="!homePreview" :hide-default-footer="homePreview" :disable-sort="homePreview">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Domains</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createDomain" class="ml-2" v-if="!homePreview">New domain</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{item}">
              <v-chip top :open-delay=350 class="ma-1"
                      :color="stringToColor(item.name)"
                      :dark="isDark(stringToColor(item.name))">
                {{ item.name }}
              </v-chip>
            </template>
            <template v-slot:item.parentId="{item}">
              <v-chip top :open-delay=350 class="ma-1"
                      v-if="getDomainById(item.parentId)[0]"
                      :color="stringToColor(getDomainById(item.parentId)[0].name)"
                      :dark="isDark(stringToColor(getDomainById(item.parentId)[0].name))">
                {{ getDomainById(item.parentId)[0].name }}
              </v-chip>
            </template>
            <template v-slot:item.children="{item}">
              {{ item.children.length }}
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editDomain(item)">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteDomain(item)">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {cloneDeep} from "lodash";
import DomainDialog from "@/components/parts/manage-dialogs/DomainDialog";
import SimpleDialog from "@/components/parts/manage-dialogs/SimpleDialog";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "Domains",
  components: {DomainDialog, SimpleDialog, DDataTable},
  props: {
    homePreview: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      editedItem: undefined,
      domainsData: [
        {id: 1, name: "Law", parentId: undefined, children: [2]},
        {id: 2, name: "Swiss Law", parentId: 1, children: [3, 4]},
        {id: 3, name: "Financial Law", parentId: 2, children: []},
        {id: 4, name: "Insurance Law", parentId: 2, children: []}
      ],
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      sending: false,
      edit: false,
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1]
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedItem?.id ? "Edit domain" : "Create domain"
    },
    mdSize() {
      return this.homePreview ? 12 : 8
    },
    itemsPerPage() {
      return this.homePreview ? 10 : 50
    },
    tableHeaders() {
      const headers = [
        {text: 'Domain Name', value: 'name', align: 'left'},
        {text: 'Parent domain', value: 'parentId', align: 'left'},
        {text: 'Children domains', value: 'children', align: 'left'}
      ]
      if (!this.homePreview) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    }
  },
  created() {
    this.fetchDomainsData()
  },
  methods: {
    fetchDomainsData() {
      this.loading = true
      this.error = null
      this.hasError = false

      axios.get(`/domains`)
          .then(response => {
            this.domainsData = response.data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    getDomainById(id) {
      return this.domainsData.filter(el => el.id === id)
    },
    createDomain() {
      this.editedItem = undefined
      this.edit = false
      this.dialog = true
    },
    editDomain(item) {
      this.editedItem = cloneDeep(item)
      this.edit = true
      this.dialog = true
    },
    deleteDomain(item) {
      this.editedItem = cloneDeep(item)
      this.dialogDelete = true
    },
    closeModals() {
      this.dialog = false
      this.dialogDelete = false
    },
    confirmDelete() {
      this.sending = true

      axios.delete(`/domains/${this.editedItem.id}`).then(this.thenHandler).catch(this.catchHandler)
    },
    save(item) {
      this.sending = true

      const data = {
        id: item.id,
        name: item.name,
        parentId: item.parentId,
      }

      if (item.id)
        axios.put(`/domains/${item.id}`, data)
            .then(this.thenHandler).catch(this.catchHandler)
      else
        axios.post(`/domains`, data).then(this.thenHandler).catch(this.catchHandler)
    },
    catchHandler(err) {
      this.sending = false
      this.error = err
      this.hasError = true
    },
    thenHandler() {
      this.fetchDomainsData()
      this.sending = false
      this.dialog = false
      this.dialogDelete = false
    }
  }
}
</script>

<style scoped>
</style>
