<template>
  <v-expansion-panels focusable :value=1 class="pa-3">
    <v-expansion-panel>
      <v-expansion-panel-header>Filters</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="searchTitle" label="Title" type="string" @input="filterItems">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select label="Owner" :items="owners" item-text="name" item-value="id" v-model="searchOwner"
                      clearable @input="filterItems">
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select label="Source" :items="projectSources" item-text="name" item-value="id" v-model="searchSource"
                      clearable @input="filterItems">
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-select label="Confidentiality level" :items="confidentialityLevels"
                      item-text="name" item-value="id" v-model="searchConfidentialityLevel"
                      clearable @input="filterItems">
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select label="Tag" :items="tags" item-text="name" item-value="id" v-model="searchTag"
                      clearable @input="filterItems">
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select label="Domain" :items="domains" item-text="name" item-value="id" v-model="searchDomain"
                      clearable @input="filterItems">
            </v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="searchID"  label="ID" type="number" @input="filterItems">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-menu ref="dateFromStartMenu" v-model="searchDateFromStartMenu" :close-on-content-click=false
                    :return-value.sync="searchDateFromStart" transition="scale-transition" offset-y
                    min-width="auto" @input="filterItems">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-model="searchDateFromStart" label="Date from - range start"
                              prepend-icon="mdi-calendar"
                              readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="searchDateFromStart" no-title scrollable>
                <v-btn text color="error" @click="searchDateFromStart = null">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="searchDateFromStartMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dateFromStartMenu.save(searchDateFromStart)">OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu ref="dateFromEndMenu" v-model="searchDateFromEndMenu" :close-on-content-click=false
                    :return-value.sync="searchDateFromEnd" transition="scale-transition" offset-y
                    min-width="auto" @input="filterItems">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-model="searchDateFromEnd" label="Date from - range end" p
                              repend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="searchDateFromEnd" no-title scrollable>
                <v-btn text color="error" @click="searchDateFromEnd = null">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="searchDateFromEndMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dateFromEndMenu.save(searchDateFromEnd)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-menu ref="dateToStartMenu" v-model="searchDateToStartMenu" :close-on-content-click=false
                    :return-value.sync="searchDateToStart" transition="scale-transition" offset-y
                    min-width="auto" @input="filterItems">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-model="searchDateToStart" label="Date to - range start"
                              prepend-icon="mdi-calendar"
                              readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="searchDateToStart" no-title scrollable>
                <v-btn text color="error" @click="searchDateToStart = null">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="searchDateToStartMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dateToStartMenu.save(searchDateToStart)">OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu ref="dateToEndMenu" v-model="searchDateToEndMenu" :close-on-content-click=false
                    :return-value.sync="searchDateToEnd" transition="scale-transition" offset-y
                    min-width="auto" @input="filterItems">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-model="searchDateToEnd" label="Date to - range end" prepend-icon="mdi-calendar"
                              readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="searchDateToEnd" no-title scrollable>
                <v-btn text color="error" @click="searchDateToEnd = null">Clear</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="searchDateToEndMenu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dateToEndMenu.save(searchDateToEnd)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="text-right">
            <v-btn color="default" @click="clearFilters">Clear filters</v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: "TableFilters",
  props: {
    owners: {type: Array, required: false},
    groups: {type: Array, required: false},
    tags: {type: Array, required: false},
    domains: {type: Array, required: false},
    projectSources: {type: Array, required: false},
    confidentialityLevels: {type: Array, required: false},
    projectPermissions: {type: Array, required: false},
    items: {type: Array, required: false},
    value: {type: Array, required: true},
  },
  data() {
    return {
      searchTitle: '',
      searchID: '',
      searchOwner: '',
      searchSource: '',
      searchConfidentialityLevel: '',
      searchTag: '',
      searchDomain: '',
      searchDateFromStartMenu: false,
      searchDateFromStart: '',
      searchDateFromEndMenu: false,
      searchDateFromEnd: '',
      searchDateToStartMenu: false,
      searchDateToStart: '',
      searchDateToEndMenu: false,
      searchDateToEnd: '',
    }
  },
  computed: {
    filteredItems: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    filterItems() {
      const conditions = [];
      if (this.searchTitle)
        conditions.push(this.titleFilter)
      if (this.searchID)
        conditions.push(this.idFilter)
      if (this.searchOwner)
        conditions.push(this.ownerFilter)
      if (this.searchSource)
        conditions.push(this.sourceFilter)
      if (this.searchConfidentialityLevel)
        conditions.push(this.confidentialityLevelFilter)
      if (this.searchTag)
        conditions.push(this.tagFilter)
      if (this.searchDomain)
        conditions.push(this.domainFilter)
      if (this.searchDateFromStart)
        conditions.push(this.dateFromStartFilter)
      if (this.searchDateFromEnd)
        conditions.push(this.dateFromEndFilter)
      if (this.searchDateToStart)
        conditions.push(this.dateToStartFilter)
      if (this.searchDateToEnd)
        conditions.push(this.dateToEndFilter)
      if (conditions.length > 0) {
        this.filteredItems = this.items.filter((item) => {
          return conditions.every((condition) => {
            return condition(item);
          })
        })
        return
      }
      this.filteredItems = this.items
    },
    titleFilter(item) {
      if (!this.searchTitle) return true
      return item.title.toLowerCase().includes(this.searchTitle.toLowerCase())
    },
    idFilter(item) {
      if (!this.searchID) return true
      return item.id === parseInt(this.searchID)
    },
    ownerFilter(item) {
      if (!this.searchOwner) return true
      return item.ownerId === parseInt(this.searchOwner)
    },
    sourceFilter(item) {
      if (!this.searchSource) return true
      return item.projectSources.includes(parseInt(this.searchSource))
    },
    confidentialityLevelFilter(item) {
      if (!this.searchConfidentialityLevel) return true
      return item.confidentialityLevelId === parseInt(this.searchConfidentialityLevel)
    },
    tagFilter(item) {
      if (!this.searchTag) return true
      return item.tags.includes(parseInt(this.searchTag))
    },
    domainFilter(item) {
      if (!this.searchDomain) return true
      return item.domains.includes(parseInt(this.searchDomain))
    },
    dateFromStartFilter(item) {
      if (!this.searchDateFromStart) return true
      return item.dateRangeStart >= this.searchDateFromStart
    },
    dateFromEndFilter(item) {
      if (!this.searchDateFromEnd) return true
      return item.dateRangeStart <= this.searchDateFromEnd
    },
    dateToStartFilter(item) {
      if (!this.searchDateToStart) return true
      return item.dateRangeEnd >= this.searchDateToStart
    },
    dateToEndFilter(item) {
      if (!this.searchDateToEnd) return true
      return item.dateRangeEnd <= this.searchDateToEnd
    },
    clearFilters() {
      this.searchTitle = ''
      this.searchID = ''
      this.searchOwner = ''
      this.searchSource = ''
      this.searchConfidentialityLevel = ''
      this.searchTag = ''
      this.searchDomain = ''
      this.searchDateFromStart = ''
      this.searchDateFromEnd = ''
      this.searchDateToStart = ''
      this.searchDateToEnd = ''
      this.filterItems()
    }
  }
}

</script>
