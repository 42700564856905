<template>
  <div class="fill-height">
    <source-dialog :show="dialog" :item="editedItem" :on-apply="save" :on-close="closeModals"></source-dialog>
    <simple-dialog :show="dialogDelete" :on-apply="confirmDelete" :on-close="closeModals" :item="editedItem">
      Are you sure you want to delete this source ?
    </simple-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" :md="mdSize">
          <d-data-table :items="sourcesData" :headers="tableHeaders" class="mt-1"
                        :footer-props="footerProps" :items-per-page="itemsPerPage"
                        :loading="loading" :fill-height="!homePreview"
                        :hide-default-footer="homePreview" :disable-sort="homePreview">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Sources</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createSource" class="ml-2" v-if="!homePreview">New source</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{item}">
              <v-chip top :open-delay=350 class="ma-1"
                      :color="stringToColor(item.name)"
                      :dark="isDark(stringToColor(item.name))">
                {{ item.name }}
              </v-chip>
            </template>
            <template v-slot:item.hasData="{item}">
              <v-icon>{{ item.hasData ? 'mdi-check' : 'mdi-close' }}</v-icon>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editSource(item)">mdi-pencil</v-icon>
              <v-tooltip top :disabled="!item.hasData">
                <template v-slot:activator="{on}">
                  <span v-on="on">
                    <v-icon @click.stop="deleteSource(item)" :disabled="item.hasData">mdi-delete</v-icon>
                  </span>
                </template>
                <span>Cannot delete a source that has data</span>
              </v-tooltip>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {cloneDeep} from "lodash";
import SimpleDialog from "@/components/parts/manage-dialogs/SimpleDialog";
import SourceDialog from "@/components/parts/manage-dialogs/SourceDialog";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "Sources",
  components: {SourceDialog, SimpleDialog, DDataTable},
  props: {
    homePreview: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      editedItem: undefined,
      sourcesData: [],
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      sending: false,
      edit: false,
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1]
      },
    }
  },
  computed: {
    mdSize() {
      return this.homePreview ? 12 : 8
    },
    itemsPerPage() {
      return this.homePreview ? 10 : 50
    },
    tableHeaders() {
      const headers = [
        {text: 'Source name', value: 'name', align: 'left'},
        {text: 'Has data', value: 'hasData', align: 'left'}
      ]
      if (!this.homePreview) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    }
  },
  created() {
    this.fetchSourcesData()
  },
  methods: {
    fetchSourcesData() {
      this.loading = true
      this.error = null
      this.hasError = false

      axios.get(`/sources`)
          .then(response => {
            this.sourcesData = response.data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    createSource() {
      this.editedItem = undefined
      this.edit = false
      this.dialog = true
    },
    editSource(item) {
      this.editedItem = cloneDeep(item)
      this.edit = true
      this.dialog = true
    },
    deleteSource(item) {
      if (item.hasData)
        return

      this.editedItem = cloneDeep(item)
      this.dialogDelete = true
    },
    closeModals() {
      this.dialog = false
      this.dialogDelete = false
    },
    confirmDelete() {
      this.sending = true

      axios.delete(`/sources/${this.editedItem.id}`).then(this.thenHandler).catch(this.catchHandler)
    },
    save(item) {
      this.sending = true

      const data = {
        id: item.id,
        name: item.name
      }

      if (item.id)
        axios.put(`/sources/${item.id}`, data)
            .then(this.thenHandler).catch(this.catchHandler)
      else
        axios.post(`/sources`, data).then(this.thenHandler).catch(this.catchHandler)
    },
    catchHandler(err) {
      this.sending = false
      this.error = err
      this.hasError = true
    },
    thenHandler() {
      this.fetchSourcesData()
      this.sending = false
      this.dialog = false
      this.dialogDelete = false
    }
  }
}
</script>

<style scoped>
</style>
