var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"60%","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.apply($event)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"label":"Name","disabled":_vm.modify,"rules":_vm.rules.username},model:{value:(_vm.editedItem.username),callback:function ($$v) {_vm.$set(_vm.editedItem, "username", $$v)},expression:"editedItem.username"}}),_c('v-text-field',{attrs:{"label":"Email","type":"email","rules":_vm.rules.email},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}}),_c('v-select',{attrs:{"items":_vm.groups,"label":"User groups","multiple":"","chips":"","deletable-chips":"","item-text":"name","item-value":"id","hint":"Select the groups"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-chip',{attrs:{"color":_vm.stringToColor(item.name),"dark":_vm.isDark(_vm.stringToColor(item.name)),"close":""},on:{"click:close":function($event){return _vm.deleteChip(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.editedItem.groups),callback:function ($$v) {_vm.$set(_vm.editedItem, "groups", $$v)},expression:"editedItem.groups"}}),_c('v-row',[_c('v-col',{attrs:{"md":"auto"}},[_c('v-btn',{on:{"click":_vm.generatePassword}},[_vm._v("GENERATE NEW PASSWORD")])],1),(_vm.editedItem.password!==undefined)?_c('v-text-field',{attrs:{"label":"Password (read-only)","readonly":"","rules":_vm.rules.password},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}}):_vm._e(),_c('v-col',{staticClass:"pt-3",attrs:{"md":"auto"}},[(_vm.editedItem.password !== undefined)?_c('v-btn',{attrs:{"disabled":_vm.editedItem.password === ''},on:{"click":_vm.copyToClipboard}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1)],1),(_vm.editedItem.password !== undefined)?_c('v-checkbox',{attrs:{"true-value":true,"false-value":false,"label":"The user must change this password"},model:{value:(_vm.editedItem.mustChangePassword),callback:function ($$v) {_vm.$set(_vm.editedItem, "mustChangePassword", $$v)},expression:"editedItem.mustChangePassword"}}):_vm._e(),(_vm.modify)?_c('v-expansion-panels',{staticClass:"mt-3",attrs:{"focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Application Permissions")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"mt-1",attrs:{"items":_vm.editedItem.applicationPermissions,"headers":_vm.headers,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_vm._e()]}},{key:"item.create",fn:function(ref){
var item = ref.item;
return [(item.create)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_vm._e()]}},{key:"item.use",fn:function(ref){
var item = ref.item;
return [(item.use)?_c('v-simple-checkbox',{attrs:{"value":true,"dense":"","ripple":false,"disabled":""}}):_vm._e()]}}],null,false,2102337666)})],1)],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.onClose}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }