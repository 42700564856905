<template>
  <div class="fill-height">
    <modify-dialog :alignment="editedAlignment" :on-apply="applyEdit" :on-close="closeDialogs"
                   :show="dialog.modify"
                   :pipeline-aligned-documents="enumsValues.pipelineAlignedDocuments || []"></modify-dialog>
    <batch-rules-dialog :filters="appliedFilters" :on-close="closeDialogs" :show="dialog.batch"
                        :enums-values="enumsValues" :show-error="showError" :loading="loading"
                        :batch-rules="batchRules" :on-create="handleBatchRuleCreate"
                        :on-delete-rule="handleBatchRuleDelete" :on-edit="handleBatchRuleEdit"></batch-rules-dialog>
    <split-dialog :show="dialog.split" :alignment="selectedAlignments[0]" :rule="splitDialogRule" :on-apply="applySplit"
                  :on-close="closeDialogs"></split-dialog>
    <merge-dialog :show="dialog.merge" :alignments="sortedSelectedAlignments" :rule="mergeDialogRule"
                  :on-apply="applyMerge" :on-close="closeDialogs" :merge-rule-by-id="mergeRuleById"></merge-dialog>

    <div class="fill-height d-flex flex-column ml-lg-8" style="max-height: 100%">
      <h4 class="text-h4 text-center mb-1">Document editor</h4>

      <document-editor-filters :applied-filters="appliedFilters" :applied-on-modified="appliedOnModified"
                               :enums-values="enumsValues" :fetch-alignments="fetchAlignments"
                               :loading="loading" ref="documentEditorFilters"></document-editor-filters>

      <document-editor-table :alignments="alignments" :actions="documentEditorActions" :loading="loading"
                             :enums-values="enumsValues"
                             :num-applied-filters="numAppliedFilters" :total-items="totalItems"
                             :selected-alignments.sync="selectedAlignments" :allowed-actions="allowedActions"
                             :pagination-options.sync="paginationOptions"></document-editor-table>

    </div>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios"
import {cloneDeep, isEqual, sortBy, uniqWith} from "lodash"
import {BLOC_OPERATORS, DEFAULT_FILTERS, numFiltersInBloc} from "@/components/parts/filters/filters-utils"
import {reduceById} from "@/util/js-utils"
import ModifyDialog from "@/components/parts/document-editor/ModifyDialog"
import SplitDialog from "@/components/parts/document-editor/SplitDialog"
import {ACTIONS, ALIGNMENT_STATES, BATCH_RULE_TYPE_IDS, RULE_TYPE_BY_ID, RULE_TYPES} from "@/util/document-editor"
import DocumentEditorFilters from "@/components/parts/document-editor/DocumentEditorFilters"
import DocumentEditorTable from "@/components/parts/document-editor/DocumentEditorTable"
import MergeDialog from "@/components/parts/document-editor/MergeDialog"
import BatchRulesDialog from "@/components/parts/document-editor/BatchRulesDialog"

const buildAlignedDocumentFilters = alignedDocumentId => ({
  type: 'bloc',
  operator: BLOC_OPERATORS.AND,
  children: [
    {
      type: 'filter',
      name: 'pipelineAlignedDocuments',
      params: [alignedDocumentId]
    }
  ]
})

export default {
  name: "DocumentEditor",
  components: {BatchRulesDialog, MergeDialog, DocumentEditorTable, DocumentEditorFilters, SplitDialog, ModifyDialog},
  data() {
    return {
      dialog: {
        split: false,
        merge: false,
        modify: false,
        batch: false,
      },
      selectedAlignments: [],
      editedAlignment: null,
      loading: false,
      error: null,
      hasError: false,
      alignments: [],
      totalItems: 0,
      paginationOptions: {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: []
      },
      keepSelected: false,
      appliedFilters: cloneDeep(DEFAULT_FILTERS),
      appliedOnModified: false,
      enumsValues: {},
      batchRules: [],
      mergeSplitRules: [],
      firstPaginationOptionsChange: true,
      documentEditorActions: {
        add: this.addAlignment,
        modify: this.editAlignment,
        split: this.splitAlignment,
        validate: this.validateAlignments,
        remove: this.removeAlignments,
        reset: this.resetAlignments,
        merge: this.mergeAlignments,
        showFilters: this.toggleFiltersPanel,
        refresh: this.refresh,
        toggleUncertain: this.toggleUncertain,
        showWithoutFilters: this.showWithoutFilters,
        nextAlignmentWithoutRules: this.firstUntouchedAlignments,
        openBatchDialog: () => this.dialog.batch = true
      }
    }
  },
  computed: {
    pipelineId() {
      return this.$route.params.pipelineId
    },
    numAppliedFilters() {
      if (!this.appliedFilters?.children)
        return 0

      return numFiltersInBloc(this.appliedFilters)
    },
    mergeRuleById() {
      return reduceById(this.mergeSplitRules.filter(rule => rule.type === RULE_TYPES.MERGE.id))
    },
    splitRuleById() {
      return reduceById(this.mergeSplitRules.filter(rule => rule.type === RULE_TYPES.SPLIT.id))
    },
    mergeDialogRule() {
      if (!this.allowEditMerge)
        return null

      return this.mergeRuleById[this.selectedAlignments[0].appliedRule.id]
    },
    splitDialogRule() {
      if (!this.allowEditSplit)
        return null

      return this.splitRuleById[this.selectedAlignments[0].appliedRule.id]
    },
    allowAdd() {
      // The "add" rule type is (temporarily?) disabled, but the button is still there 
      return false
    },
    allowValidate() {
      return ACTIONS.VALIDATE.isAllowed(this.selectedAlignments)
    },
    allowReset() {
      return ACTIONS.RESET.isAllowed(this.selectedAlignments)
    },
    allowEdit() {
      return ACTIONS.EDIT.isAllowed(this.selectedAlignments)
    },
    allowRemove() {
      return ACTIONS.REMOVE.isAllowed(this.selectedAlignments)
    },
    allowNewMerge() {
      return ACTIONS.NEW_MERGE.isAllowed(this.selectedAlignments)
    },
    allowEditMerge() {
      return ACTIONS.EDIT_MERGE.isAllowed(this.selectedAlignments)
    },
    allowNewSplit() {
      return ACTIONS.NEW_SPLIT.isAllowed(this.selectedAlignments)
    },
    allowEditSplit() {
      return ACTIONS.EDIT_SPLIT.isAllowed(this.selectedAlignments)
    },
    allowFlagUncertain() {
      return ACTIONS.FLAG_UNCERTAIN.isAllowed(this.selectedAlignments)
    },
    allowResetUncertain() {
      return ACTIONS.RESET_UNCERTAIN.isAllowed(this.selectedAlignments)
    },
    allowShowWithoutFilters() {
      return ACTIONS.SHOW_WITHOUT_FILTERS.isAllowed(this.selectedAlignments) && this.numAppliedFilters > 0
    },
    allowedActions() {
      return {
        add: this.allowAdd,
        validate: this.allowValidate,
        reset: this.allowReset,
        edit: this.allowEdit,
        remove: this.allowRemove,
        merge: this.allowNewMerge || this.allowEditMerge,
        split: this.allowNewSplit || this.allowEditSplit,
        toggleUncertain: this.allowFlagUncertain || this.allowResetUncertain,
        showWithoutFilters: this.allowShowWithoutFilters
      }
    },
    sortedSelectedAlignments() {
      return sortBy(this.selectedAlignments, ['alignedDocumentId', 'indexInDocument', 'splitIndex', 'id'])
    },
    pagination() {
      const {itemsPerPage, page, sortBy, sortDesc} = this.paginationOptions

      const limit = itemsPerPage === -1 ? undefined : itemsPerPage
      const offset = itemsPerPage === -1 ? 0 : (page - 1) * limit

      return {
        offset,
        limit,
        orderBy: sortBy[0],
        orderDesc: sortDesc[0]
      }
    }
  },
  mounted() {
    const query = Object.assign({}, this.$route.query)
    if (query.alignedDocumentId) {
      this.appliedFilters = buildAlignedDocumentFilters(parseInt(query.alignedDocumentId))
      delete query.alignedDocumentId
      this.$router.replace({query})
    }

    this.refresh()
  },
  watch: {
    paginationOptions() {
      if (this.firstPaginationChange) {
        this.firstPaginationChange = false
        return
      }

      this.fetchAlignments()
          .catch(this.showError)
    },
  },
  methods: {
    refresh() {
      this.loading = true
      this.reloadAll(true)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    reloadAll(withAlignments) {
      this.clearSelection()

      const promises = [
        this.loadEnumsValues(),
        this.fetchBatchRules(),
        this.fetchMergeSplitRules()
      ]
      if (withAlignments)
        promises.push(this.fetchAlignments())

      return Promise.all(promises)
    },
    toggleFiltersPanel() {
      this.$refs.documentEditorFilters.togglePanel()
    },
    clearSelection() {
      this.selectedAlignments = []
    },
    loadEnumsValues() {
      // Only languages and pipeline aligned documents are used for the filters (search)
      return Promise.all([
        axios.get('/languages'),
        axios.get(`/pipelines/${this.pipelineId}/aligned-documents`)
      ])
          .then(responses => {
            const languages = responses[0].data
            const pipelineAlignedDocuments = responses[1].data.map(alignedDocument => ({
              id: alignedDocument.id,
              name: `${alignedDocument.id} | ${alignedDocument.document1Origin} <-> ${alignedDocument.document2Origin}`,
              document_1_id: alignedDocument.document1Id,
              document_2_id: alignedDocument.document2Id,
            }))
            pipelineAlignedDocuments.sort((a, b) => a.id - b.id)

            this.enumsValues = {
              languages,
              pipelineAlignedDocuments,
              pipelineAlignedDocumentById: reduceById(pipelineAlignedDocuments)
            }
          })
    },
    fetchBatchRules() {
      return axios.get(`pipelines/${this.pipelineId}/batch-rules`)
          .then(response => {
            this.batchRules = response.data
          })
    },
    fetchMergeSplitRules() {
      return axios.get(`pipelines/${this.pipelineId}/merge-split-rules`)
          .then(response => {
            this.mergeSplitRules = response.data
          })
    },
    fetchAlignments(filters = cloneDeep(this.appliedFilters), onInitial = !this.appliedOnModified) {
      const data = {
        filters,
        onInitial
      }

      this.loading = true;
      return axios.post(`pipelines/${this.pipelineId}/filtered-alignments`, data, {params: this.pagination})
          .then(response => {
            const {alignments, totalItems} = response.data
            this.alignments = alignments
            this.totalItems = totalItems

            this.appliedFilters = cloneDeep(filters)
            this.appliedOnModified = !onInitial

            this.$refs.documentEditorFilters.hidePanel()

            if (this.keepSelected)
              this.$nextTick(() => {
                this.scrollToFirstSelectedAlignment()
                this.keepSelected = false
              })
            else
              this.clearSelection()
          })
          .finally(() => this.loading = false)
    },

    firstUntouchedAlignments(filters = cloneDeep(this.appliedFilters), onInitial = !this.appliedOnModified) {
      const data = {
        filters,
        onInitial
      }
      this.loading = true

      const pagination = {
        limit: this.pagination.limit,
        orderBy: this.pagination.orderBy,
        orderDesc: this.pagination.orderDesc
      }
      axios.post(`pipelines/${this.pipelineId}/first-untouched-alignments`, data, {params: pagination})
          .then(response => {
            const {alignments, totalItems, untouchedAlignment, offset} = response.data
            this.clearSelection()
            this.alignments = alignments
            this.totalItems = totalItems
            this.paginationOptions.offset = offset
            this.selectedAlignments = [this.setStateToUntouchedAlignments(untouchedAlignment)]
            this.$nextTick(this.scrollToFirstSelectedAlignment)
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    setStateToUntouchedAlignments(alignment) {
      return {
        ...alignment,
        state: alignment.appliedRule ? RULE_TYPE_BY_ID[alignment.appliedRule?.type].state : ALIGNMENT_STATES.NO_STATE
      }
    },
    scrollToFirstSelectedAlignment() {
      const selected = document.getElementsByClassName('v-data-table__selected')
      if (selected.length > 0)
        selected[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
    },

    // Validate
    validateAlignments() {
      this.loading = true
      this.closeDialogs()

      const validateRules = uniqWith(
          this.selectedAlignments.map(({initialText1, initialText2}) => ({
            segment1: initialText1,
            segment2: initialText2
          })), isEqual)

      axios.post(`/pipelines/${this.pipelineId}/rules/validate`, validateRules)
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Add & Edit
    addAlignment() {
      this.editedAlignment =
          this.dialog.modify = true
    },
    editAlignment() {
      this.editedAlignment = this.selectedAlignments[0]
      this.dialog.modify = true
    },
    applyEdit(modifyRule) {
      this.loading = true
      this.closeDialogs()

      let request
      const appliedRule = this.selectedAlignments[0].appliedRule
      if (appliedRule?.type === RULE_TYPES.MODIFY.id)
        request = axios.put(`/pipelines/${this.pipelineId}/rules/modify/${appliedRule.id}`, modifyRule)
      else
        request = axios.post(`/pipelines/${this.pipelineId}/rules/modify`, modifyRule)

      request
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Remove
    removeAlignments() {
      this.loading = true
      this.closeDialogs()

      const removeRules = uniqWith(
          this.selectedAlignments.map(({initialText1, initialText2}) => ({
            segment1: initialText1,
            segment2: initialText2
          })), isEqual)

      axios.post(`/pipelines/${this.pipelineId}/rules/remove`, removeRules)
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Reset
    resetAlignments() {
      this.loading = true

      const deleteData = uniqWith(
          this.selectedAlignments
              .filter(alignment => alignment.appliedRule
                  && !BATCH_RULE_TYPE_IDS.has(alignment.appliedRule.type)) // Don't allow resetting batch rules this way
              .map(({appliedRule}) => ({
                id: appliedRule.id,
                type: appliedRule.type
              })), isEqual)

      axios.delete(`/pipelines/${this.pipelineId}/rules`, {data: deleteData})
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Split
    splitAlignment() {
      this.dialog.split = true
    },
    applySplit(splitRule) {
      this.loading = true
      this.closeDialogs()

      const request = splitRule.id
          ? axios.put(`/pipelines/${this.pipelineId}/rules/split/${splitRule.id}`, splitRule)
          : axios.post(`/pipelines/${this.pipelineId}/rules/split`, splitRule)

      request
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Merge
    mergeAlignments() {
      this.dialog.merge = true
    },
    applyMerge(mergeRule) {
      this.loading = true
      this.closeDialogs()

      const request = mergeRule.id
          ? axios.put(`/pipelines/${this.pipelineId}/rules/merge/${mergeRule.id}`, mergeRule)
          : axios.post(`/pipelines/${this.pipelineId}/rules/merge`, mergeRule)
      request
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Uncertain
    toggleUncertain() {
      this.loading = true
      this.closeDialogs()

      const data = uniqWith(
          this.selectedAlignments
              .map(({appliedRule}) => ({
                id: appliedRule.id,
                type: appliedRule.type
              })), isEqual)

      const url = `/pipelines/${this.pipelineId}/rules/uncertain`
      const request = this.selectedAlignments[0].uncertain
          ? axios.delete(url, {data: data}) // TODO
          : axios.post(url, data)
      request
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    // Show without filters
    showWithoutFilters() {

      const {orderBy, orderDesc} = this.pagination

      this.loading = true
      axios.get(`/pipelines/${this.pipelineId}/alignment-index/${this.selectedAlignments[0].id}`,
          {params: {orderBy, orderDesc}})
          .then(response => {
            const index = response.data
            const itemsPerPage = this.pagination.limit
            const newPage = itemsPerPage === undefined ? 1 : Math.floor(index / itemsPerPage) + 1

            this.keepSelected = true

            if (this.paginationOptions.page === newPage)
              return this.fetchAlignments(cloneDeep(DEFAULT_FILTERS))

            this.appliedFilters = cloneDeep(DEFAULT_FILTERS)
            this.paginationOptions.page = newPage
          })
          .catch(err=> {
            this.showError(err)
            this.loading = false
          })
    },
    // Batch
    handleBatchRuleCreate(rule) {
      this.loading = true

      const url = rule.type === RULE_TYPES.BATCH_REMOVE.id
          ? `/pipelines/${this.pipelineId}/rules/batch-remove`
          : `/pipelines/${this.pipelineId}/rules/batch-modify`

      axios.post(url, rule)
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    handleBatchRuleDelete(rule) {
      this.loading = true

      const deleteData = [{id: rule.id, type: rule.type}]

      axios.delete(`/pipelines/${this.pipelineId}/rules`, {data: deleteData})
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    handleBatchRuleEdit(rule) {
      this.loading = true

      const url = rule.type === RULE_TYPES.BATCH_REMOVE.id
          ? `/pipelines/${this.pipelineId}/rules/batch-remove/${rule.id}`
          : `/pipelines/${this.pipelineId}/rules/batch-modify/${rule.id}`

      axios.put(url, rule)
          .then(this.reloadAll)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },

    closeDialogs() {
      this.dialog.modify = false
      this.dialog.split = false
      this.dialog.merge = false
      this.dialog.batch = false
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    }
  }
}
</script>

<style scoped>

</style>
