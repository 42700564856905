import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import {configureAxios} from "@/axios-config";
import router from "@/router";
import store from '@/store'
import {VueMasonryPlugin} from "vue-masonry";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.VUE_APP_SENTRY_DSN !== undefined && process.env.VUE_APP_SENTRY_DSN) {
  Sentry.setTag("scope", "frontend");
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
    ],
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: process.env.VUE_APP_REVISION,
    tracesSampleRate: 0.01,
  });
}

Vue.config.productionTip = false
Vue.use(VueMasonryPlugin)

configureAxios()

new Vue({
  VueMasonryPlugin,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
