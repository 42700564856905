<template>
  <v-dialog v-model="show" persistent max-width="60%">
    <v-form @submit.prevent="save" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Title" v-model="editedItem.title" :rules="rules.title"
                          autofocus required></v-text-field>
            <v-text-field label="Description" v-model="editedItem.description"></v-text-field>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-slider
                    v-model="editedItem.numSpiders" label="Max parallel spiders (pods)"
                    :thumb-size=24 thumb-label="always"
                    :min="1" :max="10" :rules="rules.numSpiders"></v-slider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-slider
                    v-model="editedItem.queuingInterval" label="Queuing interval (seconds)"
                    class="align-center"
                    :min="0.2" :max="60" step="0.2" :rules="rules.queuingInterval"
                    hide-details :thumb-size=24 thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="editedItem.queuingInterval"
                        class="mt-0 pt-0" hide-details single-line type="number"
                        :min="0.2" :max="60" step="0.2" :rules="rules.queuingInterval"
                        style="width: 60px">
                    </v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-slider
                    v-model="editedItem.maxDepth" label="Max depth"
                    :thumb-size=24 thumb-label="always"
                    :min="0" :max="10"></v-slider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-slider
                    v-model="editedItem.domainCrawlingInterval" label="Crawling interval for one domain (seconds)"
                    class="align-center"
                    :min="0.2" :max="60" step="0.2" :rules="rules.domainCrawlingInterval"
                    hide-details :thumb-size=24 thumb-label="always">
                  <template v-slot:append>
                    <v-text-field
                        v-model="editedItem.domainCrawlingInterval"
                        class="mt-0 pt-0" hide-details single-line type="number"
                        :min="0.2" :max="60" step="0.2" :rules="rules.domainCrawlingInterval"
                        style="width: 60px">
                    </v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" sm="6">
                <v-textarea v-model="editedItem.sources" label="Websites to crawl (one per line)"
                            no-resize outlined rows="12" :rules="rules.sources">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-checkbox
                    v-model="editedItem.runPipelinesWhenFinished"
                    label="Run pipelines when process is finished">
                </v-checkbox>
                <v-checkbox
                    v-model="editedItem.allowUpperLevelUrls"
                    label="Allow collecting upper level urls (match only domain)">
                </v-checkbox>
                <v-checkbox
                    v-model="editedItem.renderPages"
                    label="Render pages">
                </v-checkbox>
                <v-textarea v-model="editedItem.keywords" label="Keywords (split by new line, google syntax allowed)"
                            no-resize outlined rows="5" :rules="rules.keywords">
                </v-textarea>
                <!--                <v-checkbox-->
                <!--                    v-model="editedItem.collect_linguee_urls"-->
                <!--                    label="Collect Linguee urls">-->
                <!--                </v-checkbox>-->
              </v-col>
            </v-row>

            <v-select label="Owner" :items="owners" item-text="name" item-value="id" v-model="editedItem.ownerId"
                      :rules="rules.owner" required></v-select>
            <v-select v-model="editedItem.projectSources" :items="projectSources" :rules="rules.projectSources"
                      label="Sources" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="editedItem.projectSources.splice(index, 1)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-select label="Confidentiality level" :items="confidentialityLevels" item-text="name" item-value="id"
                      v-model="editedItem.confidentialityLevelId" :rules="rules.confidentialityLevel"
                      required></v-select>

            <v-row>
              <v-col cols="12" md="6">
                <v-menu ref="dateRangeStartMenu" v-model="dateRangeStartMenu" :close-on-content-click=false
                        :return-value.sync="editedItem.dateRangeStart" transition="scale-transition" offset-y
                        min-width="auto">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-model="editedItem.dateRangeStart" label="Date range start"
                                  prepend-icon="mdi-calendar"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.dateRangeStart" no-title scrollable>
                    <v-btn text color="error" @click="editedItem.dateRangeStart = null">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateRangeStartMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dateRangeStartMenu.save(editedItem.dateRangeStart)">OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu ref="dateRangeEndMenu" v-model="dateRangeEndMenu" :close-on-content-click=false
                        :return-value.sync="editedItem.dateRangeEnd" transition="scale-transition" offset-y
                        min-width="auto">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-model="editedItem.dateRangeEnd" label="Date range end" prepend-icon="mdi-calendar"
                                  readonly :rules="rules.dateRangeEnd" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.dateRangeEnd" no-title scrollable>
                    <v-btn text color="error" @click="editedItem.dateRangeEnd = null">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateRangeEndMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dateRangeEndMenu.save(editedItem.dateRangeEnd)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-select v-model="editedItem.tags" :items="tags" label="Tags" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="editedItem.tags.splice(index, 1)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-select v-model="editedItem.domains" :items="domains" label="Domains" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="editedItem.domains.splice(index, 1)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>Groups & permissions</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row v-for="(groupPermissions, index) in editedItem.groupPermissions"
                           :key="groupPermissions.groupId" align="center">
                      <v-btn @click="removeGroup(index)" class="mr-2">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-chip :color="stringToColor(groupById[groupPermissions.groupId].name)" class="mx-2"
                              :dark="isDark(stringToColor(groupById[groupPermissions.groupId].name))">
                        {{ groupById[groupPermissions.groupId].name }}
                      </v-chip>
                      <v-checkbox v-for="projectPermission in projectPermissions" class="mx-2"
                                  :key="projectPermissionToKey(projectPermission)"
                                  :label="projectPermissionToName(projectPermission)"
                                  v-model="editedItem.groupPermissions[index].permissions[projectPermissionToKey(projectPermission)]"
                                  @click="$refs.form.validate()">
                      </v-checkbox>
                    </v-row>
                    <v-row>
                      <v-select label="Add group" v-model="selectedGroup" :items="groupsWithoutPermissions"
                                item-text="name" item-value="id" @change="addGroup" class="flex-grow-0"
                                :rules="rules.groups"></v-select>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {capitalize, cloneDeep, groupBy} from "lodash";
import {isDark, stringToColor} from "@/util/color";

const DEFAULT_CRAWLING = {
  id: undefined,
  title: '',
  description: '',
  numSpiders: 1,
  queuingInterval: 0.2,
  domainCrawlingInterval: 0.2,
  maxDepth: 3,
  sources: '',
  keywords: '',
  runPipelinesWhenFinished: true,
  allowUpperLevelUrls: false,
  collectLingueeUrls: false,
  renderPages: true,
  ownerId: undefined,
  projectSources: [],
  confidentialityLevelId: undefined,
  dateRangeStart: undefined,
  dateRangeEnd: undefined,
  groupPermissions: [], // Example shape : [{groupId: 0, permissions: {admin_project: true, admin_pipelines: false}}]
  tags: [],
  domains: []
}

const projectPermissionFromKey = key => {
  const [permissionType, resource] = key.split('_', 2)
  return {permissionType, resource}
}

const flattenGroupPermissions = groupPermissions =>
    groupPermissions
        .flatMap(groupPermissions => Object.entries(groupPermissions.permissions)
            .filter(entry => entry[1]) // Keep only "checked" entries
            .map(([permissionKey]) => ({
              ...projectPermissionFromKey(permissionKey),
              groupId: groupPermissions.groupId
            })))

const sourcesToStr = sources => sources.join('\n')
const keywordsToStr = keywords => keywords?.initial?.join('\n') ?? ''

const validUrlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

const validateUrl = url => validUrlRegex.test(url)

const validateSources = function (sources) {
  const lines = sources.split(/\r?\n/)
  const invalidLines = []
  for (const [i, line] of lines.entries()) {
    if (line && !validateUrl(line))
      invalidLines.push(i + 1)
  }
  return !invalidLines.length || 'Invalid URL at lines: ' + invalidLines.join(', ')
}

export default {
  name: "CrawlingDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    owners: {type: Array, required: true},
    sources: {type: Array, required: true},
    confidentialityLevels: {type: Array, required: true},
    groups: {type: Array, required: true},
    projectSources: {type: Array, required: true},
    tags: {type: Array, required: true},
    domains: {type: Array, required: true},
    projectPermissions: {type: Array, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      editedItem: Object.assign({}, DEFAULT_CRAWLING),
      originalItem: Object.assign({}, DEFAULT_CRAWLING),
      selectedGroup: null,
      dateRangeStartMenu: false,
      dateRangeEndMenu: false
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (this.show)
        this.populateForm(newVal)
    },
    'editedItem.dateRangeStart'() {
      if (this.$refs.form)
        this.$refs.form.validate()
    },
    'editedItem.dateRangeEnd'() {
      if (this.$refs.form)
        this.$refs.form.validate()
    }
  },
  methods: {
    populateForm(item) {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      if (item) {
        this.editedItem = {
          ...DEFAULT_CRAWLING, ...cloneDeep(item), ...{
            groupPermissions: this.structureGroupPermissions(item.groupPermissions),
            sources: sourcesToStr(item.sources),
            keywords: keywordsToStr(item.keywords)
          }
        }
      } else
        this.editedItem = cloneDeep(DEFAULT_CRAWLING)

      this.originalItem = cloneDeep(this.editedItem)
      this.selectedGroup = null
    },
    addGroup(newGroup) {
      if (!newGroup)
        return

      this.editedItem.groupPermissions.push({groupId: newGroup, permissions: {}})
    },
    projectPermissionToKey(projectPermission) {
      return `${projectPermission.permissionType}_${projectPermission.resource}`
    },
    projectPermissionToName(projectPermission) {
      return capitalize(`${projectPermission.permissionType} ${projectPermission.resource}`)
    },
    save() {
      if (!this.$refs.form.validate())
        return

      this.onApply(Object.assign({}, this.editedItem,
          {groupPermissions: flattenGroupPermissions(this.editedItem.groupPermissions)}))
    },
    removeGroup(index) {
      this.editedItem.groupPermissions.splice(index, 1)
    },
    structureGroupPermissions(groupPermissions) {
      return Object.entries(groupBy(groupPermissions, 'groupId'))
          .map(([groupId, permissions]) => ({
            groupId,
            permissions: permissions.reduce((obj, permission) =>
                    Object.assign(obj, {[this.projectPermissionToKey(permission)]: true}),
                cloneDeep(this.emptyPermissions))
          }))
    },
    stringToColor,
    isDark
  },
  computed: {
    formTitle() {
      return this.editedItem?.id ? "Edit crawling" : "Create crawling"
    },
    groupById() {
      return this.groups.reduce((obj, group) => Object.assign(obj, {[group.id]: group}), {})
    },
    groupsWithoutPermissions() {
      const groupsWithPermissions = this.editedItem.groupPermissions
          .reduce((obj, permissions) => Object.assign(obj, {[permissions.groupId]: true}), {})
      return this.groups.filter(group => !(group.id in groupsWithPermissions))
    },
    emptyPermissions() {
      return this.projectPermissions.reduce((obj, permission) =>
          Object.assign(obj, {[this.projectPermissionToKey(permission)]: false}), {})
    },
    rules() {
      const sourceRules = []
      if (!this.editedItem.keywords)
        sourceRules.push(v => !!v || 'Required')
      sourceRules.push(validateSources)

      let dateRangeEndRules = []
      if (this.editedItem.dateRangeStart)
        dateRangeEndRules = [
          v => !!v || 'Required if range start is filled',
          v => this.editedItem.dateRangeStart <= v || 'Must be after the range start (or the same day)'
        ]

      return {
        title: [
          v => !!v || 'Required'
        ],
        owner: [
          v => !!v || 'Required'
        ],
        projectSources: [
          v => !!v.length || 'Required'
        ],
        confidentialityLevel: [
          v => !!v || 'Required'
        ],
        dateRangeEnd: dateRangeEndRules,
        numSpiders: [v => !!v || 'Required'],
        queuingInterval: [v => !!v || 'Required'],
        domainCrawlingInterval: [v => !!v || 'Required'],
        sources: sourceRules,
        keywords: []
      }
    }
  }
}
</script>

<style scoped>

</style>
