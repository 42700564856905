import store from "@/store";

export const CLOSE_NORMAL_CODE = 1000

const baseURL = process.env.NODE_ENV === 'production'
    ? `wss://${document.location.host}/api`
    : 'ws://localhost'

export const openWebSocket = (url, showError = undefined, authenticate = true) => {
    const websocket = new WebSocket(`${baseURL}${url}`)

    websocket.addEventListener('close', event => {
        if (event.code === CLOSE_NORMAL_CODE)
            return

        if (showError)
            showError(event)
        else
            console.error(event)
    })

    if (authenticate && store.state.auth.token) {
        const token = store.state.auth.token
        if (token)
            websocket.addEventListener('open',
                () => websocket.send(JSON.stringify({Authorization: `Bearer ${token}`})))
    }

    return websocket
}
