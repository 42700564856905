<template>
  <div>
    <v-stepper-step :step="3" :complete="step > 3" :editable="true">
      Find-replace parameters
    </v-stepper-step>
    <v-stepper-content :step="3">
      <div>
        <v-text-field label="Find this text" v-model="find"></v-text-field>
        <v-text-field label="Replace it with this text" v-model="replace"></v-text-field>
      </div>
      <div>
        <v-select :items="data.languages" v-model="language"
                  label="In this language (when none selected, all languages)"
                  :item-text="item=>capitalize(item.name)" item-value="id" clearable></v-select>
      </div>
      <v-btn color="primary" @click="findReplace" class="my-3" :loading="loading" :disabled="loading">
        Find & replace
      </v-btn>
    </v-stepper-content>

    <v-stepper-step :step="4" :complete="step === 4" :editable="step>=4">
      Execution
    </v-stepper-step>
    <v-stepper-content :step="4">
      <div>
        <v-icon color="info" class="review-icon">mdi-information</v-icon>
        {{ this.changed }} alignments have been modified
      </div>
      <v-btn color="primary" @click="reset">
        More actions ?
      </v-btn>
    </v-stepper-content>


  </div>
</template>

<script>
import {capitalize} from "lodash";
import axios from "axios";

export default {
  name: "FindReplace",
  components: {},
  props: {
    appliedFilters: {type: Object, required: true},
    stats: {type: Object, required: true},
    data: {type: Object, required: true},
    step: {type: Number, required: true},
    nextStep: {type: Function, required: true},
    reset: {type: Function, required: true},
    showError: {type: Function, required: true}
  },
  data() {
    return {
      loading: false,
      find: "",
      replace: "",
      language: "",
      changed: 0
    }
  },
  computed: {},
  methods: {
    capitalize,
    resetState() {
      this.loading = false
      this.downloaded = false
      this.reset()
    },
    findReplace() {
      this.loading = true
      this.changed = 0

      const params = {
        find: this.find,
        replace: this.replace,
      }

      if (this.language && this.language !== "")
        params.language = this.language

      const data = {
        filters: this.appliedFilters.filters,
        postFilters: this.appliedFilters.postFilters,
        params: params
      }

      axios.post('/segment-manager/find-replace', data)
          .then(response => {
            this.changed = response.data.occurrences
            this.loading = false
            this.nextStep()
          })
          .catch(err => {
            this.loading = false
            this.showError(err)
          })
    }
  }
}
</script>

<style scoped>
</style>
