<template>
  <div class="d-flex flex-column fill-height">
    <v-tabs v-model="selectedTab" grow>
      <v-tab v-if="importVisible" href="#imports">Import</v-tab>
      <v-tab v-if="useVisible" href="#filters">Filters</v-tab>
      <v-tab v-if="useVisible" href="#stats" :disabled="emptyAppliedFilters">Statistics</v-tab>
      <v-tab v-if="useVisible" href="#segments" :disabled="emptyAppliedFilters">Segments</v-tab>
      <v-tab v-if="useVisible" href="#actions" :disabled="emptyAppliedFilters">Actions</v-tab>
    </v-tabs>

    <v-alert v-if="currentlyMatching" type="warning" class="mt-2">
      A fuzzy matching is in progress : the fuzzy matches filters might give less results than expected.
    </v-alert>

    <v-tabs-items v-model="selectedTab" class="pt-8 fill-height">
      <v-tab-item v-if="importVisible" class="fill-height" value="imports">
        <import :enums-values="enumsValues"></import>
      </v-tab-item>
      <v-tab-item v-if="useVisible" class="fill-height" value="filters">
        <filters :on-filter-change="filtersChanged" :on-post-filters-change="postFiltersChanged"
                 :enums-values="enumsValues"></filters>
      </v-tab-item>
      <v-tab-item v-if="useVisible" value="stats">
        <stats :stats="stats" :enums-values="enumsValues"></stats>
      </v-tab-item>
      <v-tab-item v-if="useVisible" value="segments" class="fill-height">
        <segments :applied-filters="appliedFilters" :enums-values="enumsValues" :shown="selectedTab === 'segments'"
                  :disable-sorting="applyingFilters"></segments>
      </v-tab-item>
      <v-tab-item v-if="useVisible" value="actions">
        <actions :applied-filters="appliedFilters" :enums-values="enumsValues" :stats="stats"></actions>
      </v-tab-item>
    </v-tabs-items>

    <v-btn :loading="applyingFilters" fab dark large color="red" fixed right bottom
           class="mr-16 mb-16" @click="applyFilters" :disabled="selectedTab === 'imports'">
      <v-icon dark>mdi-filter</v-icon>
    </v-btn>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import Filters from "@/components/parts/segment-manager/Filters";
import Stats from "@/components/parts/segment-manager/Stats";
import Segments from "@/components/parts/segment-manager/segments/Segments";
import Actions from "@/components/parts/segment-manager/Actions";
import axios from "axios";
import {reduceById, reduceByField} from "@/util/js-utils";
import Import from "@/components/parts/segment-manager/Import";
import {mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import {CLOSE_NORMAL_CODE, openWebSocket} from "@/util/websockets";

const IMPORT_PERMISSIONS = ['admin_segment-manager', 'create_segment-manager']
const USE_PERMISSIONS = ['admin_segment-manager', 'use_segment-manager']

export default {
  name: "SegmentManager",
  components: {Import, Stats, Filters, Segments, Actions},
  data: () => ({
    selectedTab: "filters",
    filters: {
      filters: {},
      postFilters: {}
    },
    appliedFilters: {
      filters: {},
      postFilters: {}
    },
    stats: {},
    enumsValues: {},
    applyingFilters: false,
    hasError: false,
    error: null,
    currentlyMatching: false,
    matchingStatusWebSocket: null
  }),
  computed: {
    ...mapGetters(['getPermissions']),
    importVisible() {
      if (this.getPermissions !== undefined)
        for (const perm of IMPORT_PERMISSIONS)
          if (this.getPermissions[perm])
            return true
      return false
    },
    useVisible() {
      if (this.getPermissions !== undefined)
        for (const perm of USE_PERMISSIONS)
          if (this.getPermissions[perm])
            return true
      return false
    },
    emptyAppliedFilters() {
      return Object.keys(this.appliedFilters.filters).length === 0
          && Object.keys(this.appliedFilters.postFilters).length === 0
    }
  },
  created() {
    this.fetchEnums()
    this.openMatchingStatusWebsocket()
  },
  beforeDestroy() {
    this.matchingStatusWebSocket?.close(CLOSE_NORMAL_CODE)
  },
  methods: {
    fetchEnums() {
      const promises = [
        axios.get('/projects'),
        axios.get('/groups'),
        axios.get('/tags'),
        axios.get('/domains'),
        axios.get('/owners'),
        axios.get('/languages'),
        axios.get('/users'),
        axios.get('/importable-pipelines'),
        axios.get('/aligned-document-permissions'),
        axios.get('/crawlings'),
        axios.get('/confidentiality-levels')
      ]

      Promise.all(promises)
          .then(enumResponses => {
            const projects = enumResponses[0].data.map(project => ({
              ...project,
              id: project.id,
              name: project.title
            }))

            const groups = enumResponses[1].data.map(group => ({
              id: group.id,
              name: group.name
            }))

            const tags = enumResponses[2].data.map(tag => ({
              id: tag.id,
              name: tag.name
            }))

            const domains = enumResponses[3].data.map(domain => ({
              id: domain.id,
              name: domain.name
            }))

            const owners = enumResponses[4].data.map(owner => ({
              id: owner.id,
              name: owner.name
            }))

            const languages = enumResponses[5].data.map(language => ({
              id: language.id,
              name: language.name
            }))

            const users = enumResponses[6].data.map(user => ({
              id: user.id,
              name: user.username
            }))

            const pipelines = enumResponses[7].data.map(pipeline => ({
              ...pipeline,
              id: pipeline.id,
            }))

            const permissions = enumResponses[8].data

            const crawlings = enumResponses[9].data.map(crawling => ({
              ...crawling,
              name: crawling.title
            }))

            const confidentialityLevels = enumResponses[10].data

            this.enumsValues = {
              projects,
              projectById: reduceById(projects),
              groups,
              groupById: reduceById(groups),
              tags,
              tagById: reduceById(tags),
              domains,
              domainById: reduceById(domains),
              owners,
              ownerById: reduceById(owners),
              languages,
              languageById: reduceById(languages),
              users,
              usersById: reduceById(users),
              pipelines,
              pipelinesById: reduceById(pipelines),
              permissions,
              crawlingByProjectId: reduceByField(crawlings, 'projectId'),
              crawlings,
              confidentialityLevels,
              confidentialityLevelById: reduceById(confidentialityLevels)
            }
          })
          .catch(this.showError)
    },
    openMatchingStatusWebsocket() {
      if (this.matchingStatusWebSocket != null
          && (this.matchingStatusWebSocket.readyState === WebSocket.OPEN
              || this.matchingStatusWebSocket.readyState === WebSocket.CONNECTING))
        return

      this.matchingStatusWebSocket = openWebSocket('/segment-manager/matching-status', this.showError)

      this.matchingStatusWebSocket.onerror = this.showError
      this.matchingStatusWebSocket.onmessage = event => this.currentlyMatching = JSON.parse(event.data)
    },
    filtersChanged(newFilters) {
      this.$set(this.filters, 'filters', newFilters)
    },
    postFiltersChanged(newPostFilters) {
      this.$set(this.filters, 'postFilters', newPostFilters)
    },
    applyFilters() {
      if (this.applyingFilters)
        return

      this.applyingFilters = true

      const filters = this.filters.filters
      const postFilters = cloneDeep(this.filters.postFilters)
      postFilters.fuzzyMinSimilarityRatio = postFilters.fuzzyMinSimilarityPercents / 100
      delete postFilters.fuzzyMinSimilarityPercents

      const data = {
        filters,
        postFilters
      }

      axios.post('/segment-manager/stats', data)
          .then(response => {
            this.stats = response.data
            this.$set(this.appliedFilters, 'filters', filters)
            this.$set(this.appliedFilters, 'postFilters', postFilters)

            if (this.selectedTab === "filters")
              this.selectedTab = "stats"
          })
          .catch(this.showError)
          .finally(() => this.applyingFilters = false)
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    }
  }
}
</script>

<style>

</style>
