<template>
  <div>
    <document2-text-dialog :show="modals.document2text" :on-close="closeModal" :on-apply="applyConfig"
                           :item="selectedItem"></document2-text-dialog>
    <document-matcher-dialog :show="modals.documentMatcher" :on-close="closeModal" :on-apply="applyConfig"
                             :item="selectedItem" :is-crawler="isACrawlingPipeline">
    </document-matcher-dialog>
    <aligner-dialog :show="modals.aligner" :on-close="closeModal" :on-apply="applyConfig"
                    :item="selectedItem"></aligner-dialog>
    <tokenizer-dialog :show="modals.tokenizer" :on-close="closeModal" :on-apply="applyConfig"
                      :item="selectedItem" :language="stageLanguage"></tokenizer-dialog>

    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8">
          <div class="d-flex justify-center mb-3">
            <h1 class="text-h3 d-inline">{{ pipelineTitle }}</h1>
            <v-divider vertical inset class="mx-4" v-if="pipelineDescription !== ''"></v-divider>
            <span class="text-subtitle-1 my-auto">{{ pipelineDescription }}</span>
          </div>
          <v-data-table :headers="headers" :items="items" item-key="key" disable-sort disable-filtering
                        disable-pagination :items-per-page=-1 hide-default-footer>
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn :color="startStopBtn.color" :loading="loading" :disabled="loading"
                       @click="startStopBtn.action" class="mr-2">
                  <v-icon>{{ startStopBtn.icon }}</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :to="documentEditorHref" class="ml-5">
                  Document editor
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item="{item}">
              <tr @click="openModal(item)" class="clickable-tr" :key="item.key">
                <td>
                  {{ item.key }}
                </td>
                <td>
                  {{ item.config.name }}
                </td>
                <td class="text-end">
                  <v-tooltip top :open-delay=350>
                    <template v-slot:activator="{on, attrs}">
                    <span v-bind="attrs" v-on="on">
                      <span v-if="item.numPodsTotal !== undefined" class="font-weight-bold">
                        {{ item.numPodsSucceeded }}/{{ item.numPodsTotal }}
                      </span>
                      <v-icon :color="dotByState[item.state].color" small>mdi-circle</v-icon>
                    </span>
                    </template>
                    <span>{{ dotByState[item.state].tooltip }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-simple-checkbox v-model="forceRun[item.key]" :ripple=false></v-simple-checkbox>
                </td>
                <td class="text-end">
                  <v-icon @click.stop="viewIOs(item)">
                    {{ item.componentName === 'aligner' ? 'mdi-file-edit' : 'mdi-file-search' }}
                  </v-icon>
                </td>
              </tr>

            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
    <v-snackbar app color="success" v-model="hasSuccess">{{ success }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import Document2TextDialog from "@/components/parts/pipeline-dialogs/Document2TextDialog";
import AlignerDialog from "@/components/parts/pipeline-dialogs/AlignerDialog";
import TokenizerDialog from "@/components/parts/pipeline-dialogs/TokenizerDialog";
import DocumentMatcherDialog from "@/components/parts/pipeline-dialogs/DocumentMatcherDialog";
import {cloneDeep} from "lodash";
import {PIPELINE_STATUSES, STAGE_STATUSES} from "@/util/enums";
import {objectTruthyKeys} from "@/util/js-utils";
import {CLOSE_NORMAL_CODE, openWebSocket} from "@/util/websockets";

const KEEP_WEBSOCKET_PIPELINE_STATUSES = new Set([PIPELINE_STATUSES.RUNNING, PIPELINE_STATUSES.STARTING])

const CLOSE_WEBSOCKET_NON_RUNNING_TIMEOUT = 15000

const itemFromPipelineData = (pipelineData, stageKey, componentName) => {
  const {config: {[stageKey]: config}, stagesStatus, lastRunStart, lastRunEnd, currentStage, status} = pipelineData
  const {numPodsTotal, numPodsSucceeded, numPodsFailed} = stagesStatus?.[stageKey] ?? {}

  let state
  if (!lastRunStart)
    state = STAGE_STATUSES.NEW
  else if (lastRunEnd && lastRunEnd > lastRunStart) {
    // Pipeline is stopped --> state is stopped, failed or finished
    if (numPodsTotal === undefined || numPodsSucceeded + numPodsFailed < numPodsTotal)
      state = STAGE_STATUSES.STOPPED
    else if (numPodsTotal !== 0 && numPodsFailed === numPodsTotal)
      state = STAGE_STATUSES.FAILED
    else
      state = STAGE_STATUSES.FINISHED
  } else {
    if (status === PIPELINE_STATUSES.ERROR && currentStage === stageKey)
      state = STAGE_STATUSES.ERROR
    else if (status === PIPELINE_STATUSES.ERROR && numPodsTotal === undefined)
      state = STAGE_STATUSES.STOPPED

    // Pipeline is running --> state is running, pending, finished or failed
    else if (currentStage === stageKey)
      state = STAGE_STATUSES.RUNNING
    else if (numPodsTotal === undefined)
      state = STAGE_STATUSES.PENDING
    else if (numPodsTotal !== 0 && numPodsFailed === numPodsTotal)
      state = STAGE_STATUSES.FAILED
    else
      state = STAGE_STATUSES.FINISHED
  }

  return {
    key: stageKey,
    componentName,
    config,
    numPodsTotal,
    numPodsSucceeded,
    numPodsFailed,
    state
  }
}

export default {
  name: "Pipeline",
  components: {Document2TextDialog, AlignerDialog, TokenizerDialog, DocumentMatcherDialog},
  data() {
    return {
      initialPipelineData: null,
      pipelineData: null,
      error: null,
      hasError: false,
      success: null,
      hasSuccess: false,
      loading: false,
      selectedItem: null,
      stageLanguage: undefined,
      pipelineDetailsWebSocket: null,
      modals: {
        document2text: false,
        documentMatcher: false,
        tokenizer: false,
        aligner: false
      },
      forceRun: {},
      headers: [
        {text: 'Stage', value: 'key'},
        {text: 'Name', value: 'name'},
        {text: 'State', value: 'state', align: 'end'},
        {text: 'Force run', value: 'forceRun', align: ' start'},
        {text: 'Actions', value: 'actions', align: 'end'}
      ],
      dotByState: {
        [STAGE_STATUSES.NEW]: {color: '#bdbdbd', tooltip: 'New'},
        [STAGE_STATUSES.PENDING]: {color: '#2962ff', tooltip: 'Pending'},
        [STAGE_STATUSES.RUNNING]: {color: '#ffd600', tooltip: 'Running'},
        [STAGE_STATUSES.FINISHED]: {color: '#00c853', tooltip: 'Finished'},
        [STAGE_STATUSES.FAILED]: {color: '#d50000', tooltip: 'Failed'},
        [STAGE_STATUSES.STOPPED]: {color: '#ff6d00', tooltip: 'Stopped'},
        [STAGE_STATUSES.ERROR]: {color: '#d50000', tooltip: 'Crashed'},
      }
    }
  },
  created() {
    this.openPipelineDetailsWebsocket()
  },
  beforeDestroy() {
    this.pipelineDetailsWebSocket?.close(CLOSE_NORMAL_CODE)
  },
  computed: {
    startStopBtn() {
      return {
        color: this.pipelineRunning ? 'error' : 'success',
        icon: this.pipelineRunning ? 'mdi-stop' : 'mdi-play',
        action: this.pipelineRunning ? this.stopPipeline : this.runPipeline
      }
    },
    documentEditorHref() {
      if (this.isACrawlingPipeline) {
        return `/crawlings/${this.crawlingId}/${this.pipelineId}/document-editor`
      } else {
        return `/projects/${this.projectId}/${this.pipelineId}/document-editor`
      }
    },
    items() {
      if (!this.pipelineData)
        return []
      if (this.pipelineData.typeId === 1)
        return [
          itemFromPipelineData(this.pipelineData, 'document2text1', 'document2text',),
          itemFromPipelineData(this.pipelineData, 'document2text2', 'document2text',),
          itemFromPipelineData(this.pipelineData, 'tokenizer1', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'tokenizer2', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'documentMatcher', 'documentMatcher'),
          itemFromPipelineData(this.pipelineData, 'aligner', 'aligner')
        ]
      else if (this.pipelineData.typeId === 2)
        return [
          itemFromPipelineData(this.pipelineData, 'tokenizer1', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'tokenizer2', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'documentMatcher', 'documentMatcher'),
          itemFromPipelineData(this.pipelineData, 'aligner', 'aligner')
        ]
      else  // this.pipelineData.typeId === 3
        return [
          itemFromPipelineData(this.pipelineData, 'document2text1', 'document2text',),
          itemFromPipelineData(this.pipelineData, 'languageDetector', 'languageDetector',),
          itemFromPipelineData(this.pipelineData, 'tokenizer1', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'tokenizer2', 'tokenizer'),
          itemFromPipelineData(this.pipelineData, 'documentMatcher', 'documentMatcher'),
          itemFromPipelineData(this.pipelineData, 'aligner', 'aligner')
        ]
    },
    projectId() {
      return this.$route.params.projectId
    },
    crawlingId() {
      return this.$route.params.crawlingId
    },
    pipelineId() {
      return this.$route.params.pipelineId
    },
    pipelineRunning() {
      return this.pipelineData?.status === PIPELINE_STATUSES.RUNNING
    },
    pipelineTitle() {
      return this.pipelineData?.name
    },
    pipelineDescription() {
      return this.pipelineData?.description
    },
    pipelineLanguages() {
      return [this.pipelineData?.language1, this.pipelineData?.language2]
    },
    isACrawlingPipeline() {
      return !!this.initialPipelineData
          && (this.initialPipelineData.typeId === 2 || this.initialPipelineData.typeId === 3)
    }
  },
  errorCaptured(err, vm, info) {
    console.error(err, vm, info)
    this.showError("An error occurred. The pipeline configuration could be outdated.")
    return false
  },
  methods: {
    openModal(item) {
      if (item.key.endsWith('1'))
        this.stageLanguage = this.pipelineLanguages[0]
      else if (item.key.endsWith('2'))
        this.stageLanguage = this.pipelineLanguages[1]
      this.selectedItem = item
      this.modals[item.componentName] = true
    },
    viewIOs(item) {
      if (this.isACrawlingPipeline) {
        this.$router.push(`/crawlings/${this.crawlingId}/${this.pipelineId}/io/${item.key}`)
      } else {
        this.$router.push(`/projects/${this.projectId}/${this.pipelineId}/io/${item.key}`)
      }
    },
    openPipelineDetailsWebsocket() {
      if (this.pipelineDetailsWebSocket != null
          && (this.pipelineDetailsWebSocket.readyState === WebSocket.OPEN
              || this.pipelineDetailsWebSocket.readyState === WebSocket.CONNECTING))
        return

      this.pipelineDetailsWebSocket = openWebSocket(`/pipelines/${this.pipelineId}`, this.showError)

      this.pipelineDetailsWebSocket.onerror = this.showError

      let timeout

      this.pipelineDetailsWebSocket.onmessage = event => {
        this.pipelineData = JSON.parse(event.data)
        this.initialPipelineData = cloneDeep(this.pipelineData)

        this.loading = false

        if (KEEP_WEBSOCKET_PIPELINE_STATUSES.has(this.pipelineData?.status)) {
          clearTimeout(timeout)
          timeout = undefined
        } else
          timeout ??= setTimeout(() => this.pipelineDetailsWebSocket.close(CLOSE_NORMAL_CODE),
              CLOSE_WEBSOCKET_NON_RUNNING_TIMEOUT)
      }
    },
    fetchConfig() {
      this.error = null
      this.hasError = false

      return axios.get(`/pipelines/${this.pipelineId}`)
          .then(response => {
            this.pipelineData = response.data
            this.initialPipelineData = cloneDeep(response.data)
          })
          .catch(this.showError)
    },
    saveConfig(silent = false) {
      if (this.pipelineRunning)
        return this.showError("The configuration cannot be modified while the pipeline is running")

      const {config: pipelineConfig} = this.pipelineData

      const config = {
        version: '0.2',
        document2text1: pipelineConfig.document2text1,
        document2text2: pipelineConfig.document2text2,
        documentMatcher: pipelineConfig.documentMatcher,
        tokenizer1: pipelineConfig.tokenizer1,
        tokenizer2: pipelineConfig.tokenizer2,
        aligner: pipelineConfig.aligner,
        languageDetector: pipelineConfig.languageDetector
      }

      return axios.put(`/pipelines/${this.pipelineId}`, {config})
          .then(() => {
            if (!silent)
              this.showSuccess('The configuration has been saved')

            return this.fetchConfig()
          })
          .catch(this.showError)
    },
    runPipeline() {
      if (this.loading)
        return

      if (this.pipelineRunning)
        return this.showError("The pipeline is already running")

      this.loading = true
      this.error = null
      this.hasError = false

      const promise = this.saveConfig(true)
      if (promise)
        promise.then(() => axios.post(`/pipelines/${this.pipelineId}/start`, objectTruthyKeys(this.forceRun)))
            .then(this.openPipelineDetailsWebsocket)
            .catch(err => {
              this.showError(err)
              this.loading = false
            })
      else
        this.loading = false
    },
    stopPipeline() {
      if (this.loading)
        return

      this.loading = true
      axios.get(`/pipelines/${this.pipelineId}/stop`)
          .then(() => {
            this.loading = false
            this.showSuccess("The pipeline has been stopped")
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    closeModal() {
      this.modals[this.selectedItem.componentName] = false
      this.selectedItem = null
    },
    applyConfig(config) {
      if (this.pipelineRunning)
        return this.showError("The pipeline cannot be modified while it is running!")

      const selectedKey = this.selectedItem.key

      this.pipelineData.config[selectedKey] = config
      this.closeModal()
      this.saveConfig()
    },
    showSuccess(msg) {
      this.success = msg
      this.hasSuccess = true
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
  },
}

</script>

<style scoped>
.clickable-tr {
  cursor: pointer;
}
</style>
