<template>
  <div class="pa-3">
    <div v-for="(stat, key) of stats" :key="key" class="mb-2 pa-1">
      <h4 class="text-capitalize text-h6">{{ stat.title }}</h4>
      <v-progress-linear :value="stat.value / filtered * 100" color="lime" height="1.75em" rounded>
        {{ textForStat(stat) }}
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
import {round} from "lodash";

export default {
  name: "SimpleStats",
  props: {
    stats: {type: Array, required: true},
    filtered: {type: Number, required: true}
  },
  methods: {
    textForStat(stat) {
      return `${stat.value}/${this.filtered} alignments (${round(stat.value / this.filtered * 100, 1)}%)`
    }
  }
}
</script>

<style scoped>
</style>
