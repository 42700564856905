export const abbyyDefaults = {
    BACKGROUND_FILLING_COLOR: -1,
    BINARIZATION_THRESHOLD: -1,
    CORRECT_SKEW_PIM: true,
    CORRECT_SKEW_MODE_PIM: 0x10 | 0x20,
    ENHANCE_LOCAL_CONTRAST: false,
    INVERT_IMAGE: false,
    KEEP_ORIGINAL_COORDINATES_INFO: true,
    MIRROR_IMAGE: false,
    PHOTO_PROCESSING_MODE: 'Auto',
    RASTERIZE_FREE_TEXT: false,
    ROTATION: 'NoRotation',
    AUTO_OVERWRITE_RESOLUTION: true,
    OVERWRITE_RESOLUTION: false,
    X_RESOLUTION_TO_OVERWRITE: 300,
    Y_RESOLUTION_TO_OVERWRITE: 300,
    COMPRESS_IMAGE: 'Auto',
    CREATE_PREVIEW: false,
    DISCARD_COLOR_IMAGE: false,
    PREVIEW_HEIGHT: 90,
    PREVIEW_WIDTH: 64,
    USE_FAST_BINARIZATION: false,

    APPLY_SIGMA_FILTER: 'Auto',
    CORRECT_GEOMETRY: 'Auto',
    CORRECT_INVERTED_IMAGE: false,
    CORRECT_ORIENTATION: false,
    CORRECT_RESOLUTION: 'Auto',
    CORRECT_SHADOWS_AND_HIGHLIGHTS: 'Auto',
    CORRECT_SKEW_PPP: 'Auto',
    CORRECT_SKEW_MODE_PPP: 0x10 | 0x20,
    CROP_IMAGE: 'Auto',

    ORIENTATION_DETECTION_MODE: 'Normal',
    PROHIBIT_CLOCKWISE_ROTATION: false,
    PROHIBIT_COUNTERCLOCKWISE_ROTATION: false,
    PROHIBIT_UPSIDEDOWN_ROTATION: false,

    FAST_OBJECTS_EXTRACTION: false,
    PROHIBIT_COLOR_IMAGE: false,
    SOURCE_CONTENT_REUSE_MODE: 'Auto',
    REMOVE_GARBAGE: false,
    REMOVE_TEXTURE: true,
    DETECT_MATRIX_PRINTER: true,
    DETECT_POROUS_TEXT: true,
    DETECT_TEXT_ON_PICTURES: false,
    ENABLE_AGGRESSIVE_TEXT_EXTRACTION: false,
    PROHIBIT_DOTTED_SEPARATORS: false,

    LANGUAGE_DETECTION_MODE: 'Auto',
    TEXT_TYPES: 0x1,
    DETECT_TEXT_TYPES_INDEPENDENTLY: false,
    BALANCED_MODE: false,
    FAST_MODE: false,
    LOW_RESOLUTION_MODE: false,
    ONE_LINE_PER_BLOCK: false,
    ONE_WORD_PER_LINE: false,
    PROHIBIT_ITALIC: false,
    PROHIBIT_SUBSCRIPT: false,
    PROHIBIT_SUPERSCRIPT: false,
    PROHIBIT_HYPHENATION: false,
    PROHIBIT_INTERBLOCK_HYPHENATION: false,
    CASE_RECOGNITION_MODE: 'AutoCase',
    WRITING_STYLE: 'Auto',
    FIELD_MARKING_TYPE: 'SimpleText',
    CELLS_COUNT: 1,
    USE_BUILTIN_PATTERNS: true,
    HIGHLIGHT_SUSPICIOUS_CHARACTERS: true,
    EXACT_CONFIDENCE_CALCULATION: false,
    SAVE_CHARACTER_REGIONS: false,
    SAVE_CHARACTER_RECOGNITION_VARIANTS: false,
    SAVE_WORD_RECOGNITION_VARIANTS: false,

    SPLIT_TYPE: 'None',

    DETECT_TEXT: true,
    ENABLE_TEXT_EXTRACTION_MODE: false,
    ENABLE_EXHAUSTIVE_ANALYSIS_MODE: false,
    DETECT_TABLES: true,

    DETECT_CELLS_INVERSION: true,
    DETECT_CELLS_ORIENTATION: true,
    SINGLE_LINE_PER_CELL: false,
    SPLIT_ONLY_BY_SEPARATORS: false,
    AGGRESSIVE_TABLE_DETECTION: false,
    DETECT_BARCODES: false,
    TYPE_BARCODE: 0x1 | 0x2 | 0x4 | 0x8 | 0x10,
    ORIENTATION_BARCODE: 0x1 | 0x2 | 0x4 | 0x8,
    MIN_RATIO_TO_TEXT_HEIGHT: -1,
    CODE_PAGE_BARCODE: 'Null',
    CONTAINS_BINARY_DATA: false,
    ENABLE_ADVANCED_EXTRACTION_MODE: false,
    HAS_CHECKSUM: false,
    IS_CODE_39_WITHOUT_ASTERISK: false,
    SUPPLEMENT_TYPE: 0x1 | 0x2 | 0x4,
    DETECT_SEPARATORS: true,
    DETECT_PICTURES: true,
    DETECT_VECTOR_GRAPHICS: true,
    DETECT_MULTIPLE_BUSINESS_CARDS: false,
    NO_SHADOWS_MODE: false,
    PAPER_SIZE_DETECTION_MODE: 'Auto',
    DETECT_VERTICAL_EUROPEAN_TEXT: false,
    PROHIBIT_CJK_COLUMNS: false,
    PROHIBIT_DOUBLE_PAGE_MODE: false,
    PROHIBIT_MODEL_ANALYSIS: false,
    COLLECT_PDF_EXPORT_DATA: false,

    DETECT_DOCUMENT_LINKS: true,
    DETECT_FONT_FORMATTING_AT_PAGE_LEVEL: false,
    DETECT_HYPERLINKS: true,
    DETECT_BOLD_PAGE: true,
    DETECT_ITALIC_PAGE: true,
    DETECT_SUBSCRIPTS_SUPERSCRIPTS_PAGE: true,
    DETECT_UNDERLINE_STRIKEOUT_PAGE: true,
    DETECT_SMALL_CAPS_PAGE: true,
    DETECT_DROP_CAPS_PAGE: true,
    DETECT_FONT_FAMILY_PAGE: true,
    DETECT_FONT_SIZE_PAGE: true,
    DETECT_SERIFS_PAGE: true,
    DETECT_SCALING_PAGE: true,
    // DETECT_SPACING_PAGE: bool,
    MONOSPACE_DETECTION_MODE_PAGE: 'Auto',
    PARAGRAPH_EXTRACTION_MODE: 'NormalExtraction',
    SAVE_CHARACTER_COORDINATES_FOR_ALL_VARIANTS: false,
    SYNTHESIZE_BUSINESS_CARDS: false,
    DETECT_BACKGROUND_COLOR: 'Auto',
    ALLOW_GRAY_BACKGROUND_COLOR: 'Auto',
    DETECT_TEXT_COLOR: 'Auto',
    ALLOW_GRAY_TEXT_COLOR: 'Auto',
    CORRECT_DYNAMIC_RANGE: 'Auto',

    DETECT_DOCUMENT_STRUCTURE: true,
    CLASSIFY_SEPARATORS: true,
    DETECT_CAPTIONS: true,
    DETECT_COLUMNS: true,
    DETECT_FOOTNOTES: true,
    DETECT_HEADLINES: true,
    DETECT_LISTS: true,
    DETECT_OVERFLOWING_PARAGRAPHS: true,
    DETECT_RUNNING_TITLES: true,
    DETECT_TABLE_OF_CONTENTS: true,
    DETECT_FONT_FORMATTING: true,
    DETECT_BOLD_DOCUMENT: true,
    DETECT_ITALIC_DOCUMENT: true,
    DETECT_SUBSCRIPTS_SUPERSCRIPTS_DOCUMENT: true,
    DETECT_UNDERLINE_STRIKEOUT_DOCUMENT: true,
    DETECT_SMALL_CAPS_DOCUMENT: true,
    DETECT_DROP_CAPS_DOCUMENT: true,
    DETECT_FONT_FAMILY_DOCUMENT: true,
    DETECT_FONT_SIZE_DOCUMENT: true,
    DETECT_SERIFS_DOCUMENT: true,
    DETECT_SCALING_DOCUMENT: true,
    // DETECT_SPACING_DOCUMENT: bool,
    MONOSPACE_DETECTION_MODE_DOCUMENT: 'Auto',
    LOW_MEMORY_MODE: false,
    PAGE_POOL_SIZE: 64,

    USE_DOCUMENT_STRUCTURE: true,
    KEEP_LINES: false,
    WRITE_RUNNING_TITLES: true,
    WRITE_PICTURES: true,
    ENCODING_TYPE: 'Auto',
    CODE_PAGE_EXPORT: 'Null',

    PAGE_SYNTHESIS_MODE: 'RTFEditableCopy',
    KEEP_PAGE_BREAKS: 'Auto',
    REMOVE_SOFT_HYPHENS: false,
    FILTER_SEPARATORS: true,
    KEEP_TEXT_COLOR: true,
    BACKGROUND_COLOR_MODE: 'Color',
    INCREASE_PAPER_SIZE_TO_FIT_CONTENT: false,
    PAGE_ORIENTATION: 'Auto',
    USE_CUSTOM_PAGE_MARGINS: false,
    HIGHLIGHT_ERRORS_WITH_BACKGROUND_COLOR: false,
    HIGHLIGHT_ERRORS_WITH_TEXT_COLOR: false,
    ERROR_BACKGROUND_COLOR: -1,
    ERROR_TEXT_COLOR: -1,

    HTML_FORMAT_MODE: 'Format40',
    HTML_SYNTHESIS_MODE: 'FlexibleLayout',
    SEPARATE_PAGES: false,
    USE_CSS: true,
    SPLIT_DOCUMENT_TO_FILES: 'None',
    KEEP_TEXT_AND_BACKGROUND_COLOR: true,

    EXPORT_FORMAT: 'TXT',
    EXPORT_PARAGRAPHS_AS_ONE_LINE: false,
    INSERT_EMPTY_LINES_BETWEEN_PARAGRAPHS: false,
    LAYOUT_RETENTION_MODE: 'Auto',
    RETAIN_LAYOUT: false,
    USE_PAGE_BREAKS: false,
    APPEND_TO_END: false,
    WRITE_BOM_CHARACTER: true,
    TAB_SEPARATOR: '\t',

    WRITE_CHARACTER_RECOGNITION_VARIANTS: false,
    WRITE_CHAR_ATTRIBUTES: 'None',
    WRITE_CHAR_FORMATTING: false,
    WRITE_NONDESKEWED_COORDINATES: true,
    WRITE_PARAGRAPH_STYLES: false,
    WRITE_WORD_RECOGNITION_VARIANTS: false,
}
Object.freeze(abbyyDefaults)

export const datalambicProfiles = {
    simplePdf: {
        name: 'Simple (text) PDF -> text'
    },
    imagePdf: {
        name: 'Image PDF -> text'
    }
}

export const exportParams = {
    rtf: [
        {name: 'PAGE_SYNTHESIS_MODE', enum: 'rtfPageSynthesisModes'},
        {name: 'USE_DOCUMENT_STRUCTURE', type: 'bool'},
        {name: 'KEEP_PAGE_BREAKS', enum: 'threeStates'},
        {name: 'KEEP_LINES', type: 'bool'},
        {name: 'REMOVE_SOFT_HYPHENS', type: 'bool'},
        {name: 'WRITE_RUNNING_TITLES', type: 'bool'},
        {name: 'FILTER_SEPARATORS', type: 'bool'},
        {name: 'KEEP_TEXT_COLOR', type: 'bool'},
        {name: 'BACKGROUND_COLOR_MODE', enum: 'backgroundColorModes'},
        {name: 'WRITE_PICTURES', type: 'bool'},
        {name: 'INCREASE_PAPER_SIZE_TO_FIT_CONTENT', type: 'bool'},
        {name: 'PAGE_ORIENTATION', enum: 'pageOrientations'},
        {name: 'USE_CUSTOM_PAGE_MARGINS', type: 'bool'},
        {name: 'HIGHLIGHT_ERRORS_WITH_BACKGROUND_COLOR', type: 'bool'},
        {name: 'HIGHLIGHT_ERRORS_WITH_TEXT_COLOR', type: 'bool'},
        {name: 'ERROR_BACKGROUND_COLOR', type: 'int'},
        {name: 'ERROR_TEXT_COLOR', type: 'int'}
    ],
    html: [
        {name: 'HTML_FORMAT_MODE', enum: 'htmlFormats'},
        {name: 'HTML_SYNTHESIS_MODE', enum: 'htmlSynthesisModes'},
        {name: 'USE_DOCUMENT_STRUCTURE', type: 'bool'},
        {name: 'SEPARATE_PAGES', type: 'bool'},
        {name: 'USE_CSS', type: 'bool'},
        {name: 'SPLIT_DOCUMENT_TO_FILES', enum: 'htmlDocumentSplittingModes'},
        {name: 'KEEP_LINES', type: 'bool'},
        {name: 'KEEP_TEXT_AND_BACKGROUND_COLOR', type: 'bool'},
        {name: 'WRITE_RUNNING_TITLES', type: 'bool'},
        {name: 'ENCODING_TYPE', enum: 'textEncodingTypes'},
        {name: 'CODE_PAGE_EXPORT', enum: 'codePages'},
        {name: 'WRITE_PICTURES', type: 'bool'},
    ],
    txt: [
        {name: 'EXPORT_FORMAT', enum: 'txtExportFormats'},
        {name: 'EXPORT_PARAGRAPHS_AS_ONE_LINE', type: 'bool'},
        {name: 'INSERT_EMPTY_LINES_BETWEEN_PARAGRAPHS', type: 'bool'},
        {name: 'LAYOUT_RETENTION_MODE', enum: 'textLayoutRetentionModes'},
        {name: 'RETAIN_LAYOUT', type: 'bool'},
        {name: 'USE_PAGE_BREAKS', type: 'bool'},
        {name: 'APPEND_TO_END', type: 'bool'},
        {name: 'WRITE_BOM_CHARACTER', type: 'bool'},
        {name: 'WRITE_RUNNING_TITLES', type: 'bool'},
        {name: 'TAB_SEPARATOR', type: 'str'},
        {name: 'ENCODING_TYPE', enum: 'textEncodingTypes'},
        {name: 'CODE_PAGE_EXPORT', enum: 'codePages'}
    ],
    xml: [
        {name: 'WRITE_CHARACTER_RECOGNITION_VARIANTS', type: 'bool'},
        {name: 'WRITE_CHAR_ATTRIBUTES', enum: 'xmlCharAttributes'},
        {name: 'WRITE_CHAR_FORMATTING', type: 'bool'},
        {name: 'WRITE_NONDESKEWED_COORDINATES', type: 'bool'},
        {name: 'WRITE_PARAGRAPH_STYLES', type: 'bool'},
        {name: 'WRITE_WORD_RECOGNITION_VARIANTS', type: 'bool'},
    ]
}

export const advancedOptionsCategories = [
    {
        name: 'Prepare image mode',
        fields: [
            {name: 'BACKGROUND_FILLING_COLOR', type: 'int'},
            {name: 'BINARIZATION_THRESHOLD', type: 'int'},
            {name: 'CORRECT_SKEW_PIM', type: 'bool', label: 'Correct skew'},
            {name: 'CORRECT_SKEW_MODE_PIM', type: 'int', label: 'Correct skew mode'},
            {name: 'ENHANCE_LOCAL_CONTRAST', type: 'bool'},
            {name: 'INVERT_IMAGE', type: 'bool'},
            {name: 'KEEP_ORIGINAL_COORDINATES_INFO', type: 'bool'},
            {name: 'MIRROR_IMAGE', type: 'bool'},
            {name: 'PHOTO_PROCESSING_MODE', enum: 'photoProcessingModes'},
            {name: 'RASTERIZE_FREE_TEXT', type: 'bool'},
            {name: 'ROTATION', enum: 'rotationTypes'},
            {name: 'AUTO_OVERWRITE_RESOLUTION', type: 'bool'},
            {name: 'OVERWRITE_RESOLUTION', type: 'bool'},
            {name: 'X_RESOLUTION_TO_OVERWRITE', type: 'int'},
            {name: 'Y_RESOLUTION_TO_OVERWRITE', type: 'int'},
            {name: 'COMPRESS_IMAGE', enum: 'threeStates'},
            {name: 'CREATE_PREVIEW', type: 'bool'},
            {name: 'DISCARD_COLOR_IMAGE', type: 'bool'},
            {name: 'PREVIEW_HEIGHT', type: 'int'},
            {name: 'PREVIEW_WIDTH', type: 'int'},
            {name: 'USE_FAST_BINARIZATION', type: 'bool'}
        ]
    },
    {
        name: 'Page preprocessing',
        fields: [
            {name: 'APPLY_SIGMA_FILTER', enum: 'threeStates'},
            {name: 'CORRECT_GEOMETRY', enum: 'threeStates'},
            {name: 'CORRECT_INVERTED_IMAGE', type: 'bool'},
            {name: 'CORRECT_ORIENTATION', type: 'bool'},
            {name: 'CORRECT_RESOLUTION', enum: 'threeStates'},
            {name: 'CORRECT_SHADOWS_AND_HIGHLIGHTS', enum: 'threeStates'},
            {name: 'CORRECT_SKEW_PPP', enum: 'threeStates', label: 'Correct skew'},
            {name: 'CORRECT_SKEW_MODE_PPP', type: 'int', label: 'Correct skew mode'},
            {name: 'CROP_IMAGE', enum: 'threeStates'},
        ]
    },
    {
        name: 'Orientation detection',
        fields: [
            {name: 'ORIENTATION_DETECTION_MODE', enum: 'orientationDetectionModes'},
            {name: 'PROHIBIT_CLOCKWISE_ROTATION', type: 'bool'},
            {name: 'PROHIBIT_COUNTERCLOCKWISE_ROTATION', type: 'bool'},
            {name: 'PROHIBIT_UPSIDEDOWN_ROTATION', type: 'bool'},
        ]
    },
    {
        name: 'Objects extraction',
        fields: [
            {name: 'FAST_OBJECTS_EXTRACTION', type: 'bool'},
            {name: 'PROHIBIT_COLOR_IMAGE', type: 'bool'},
            {name: 'SOURCE_CONTENT_REUSE_MODE', enum: 'sourceContentReuseModes'},
            {name: 'REMOVE_GARBAGE', type: 'bool'},
            {name: 'REMOVE_TEXTURE', type: 'bool'},
            {name: 'DETECT_MATRIX_PRINTER', type: 'bool'},
            {name: 'DETECT_POROUS_TEXT', type: 'bool'},
            {name: 'DETECT_TEXT_ON_PICTURES', type: 'bool'},
            {name: 'ENABLE_AGGRESSIVE_TEXT_EXTRACTION', type: 'bool'},
            {name: 'PROHIBIT_DOTTED_SEPARATORS', type: 'bool'},
        ]
    },
    {
        name: 'Recognizer',
        fields: [
            // LANGUAGES moved
            {name: 'LANGUAGE_DETECTION_MODE', enum: 'threeStates'},
            {name: 'TEXT_TYPES', type: 'int'},
            {name: 'DETECT_TEXT_TYPES_INDEPENDENTLY', type: 'bool'},
            {name: 'BALANCED_MODE', type: 'bool'},
            {name: 'FAST_MODE', type: 'bool'},
            {name: 'LOW_RESOLUTION_MODE', type: 'bool'},
            {name: 'ONE_LINE_PER_BLOCK', type: 'bool'},
            {name: 'ONE_WORD_PER_LINE', type: 'bool'},
            {name: 'PROHIBIT_ITALIC', type: 'bool'},
            {name: 'PROHIBIT_SUBSCRIPT', type: 'bool'},
            {name: 'PROHIBIT_SUPERSCRIPT', type: 'bool'},
            {name: 'PROHIBIT_HYPHENATION', type: 'bool'},
            {name: 'PROHIBIT_INTERBLOCK_HYPHENATION', type: 'bool'},
            {name: 'CASE_RECOGNITION_MODE', enum: 'caseRecognitionModes'},
            {name: 'WRITING_STYLE', enum: 'writingStyles'},
            {name: 'FIELD_MARKING_TYPE', enum: 'fieldMarkingTypes'},
            {name: 'CELLS_COUNT', type: 'int'},
            {name: 'USE_BUILTIN_PATTERNS', type: 'bool'},
            {name: 'HIGHLIGHT_SUSPICIOUS_CHARACTERS', type: 'bool'},
            {name: 'EXACT_CONFIDENCE_CALCULATION', type: 'bool'},
            {name: 'SAVE_CHARACTER_REGIONS', type: 'bool'},
            {name: 'SAVE_CHARACTER_RECOGNITION_VARIANTS', type: 'bool'},
            {name: 'SAVE_WORD_RECOGNITION_VARIANTS', type: 'bool'},
        ]
    },
    {
        name: 'Page splitting',
        fields: [
            {name: 'SPLIT_TYPE', enum: 'pageSplitTypes'},
        ]
    },
    {
        name: 'Page analysis',
        fields: [
            {name: 'DETECT_TEXT', type: 'bool'},
            {name: 'ENABLE_TEXT_EXTRACTION_MODE', type: 'bool'},
            {name: 'ENABLE_EXHAUSTIVE_ANALYSIS_MODE', type: 'bool'},
            {name: 'DETECT_TABLES', type: 'bool'},

            {name: 'DETECT_CELLS_INVERSION', type: 'bool'},
            {name: 'DETECT_CELLS_ORIENTATION', type: 'bool'},
            {name: 'SINGLE_LINE_PER_CELL', type: 'bool'},
            {name: 'SPLIT_ONLY_BY_SEPARATORS', type: 'bool'},

            {name: 'AGGRESSIVE_TABLE_DETECTION', type: 'bool'},
            {name: 'DETECT_BARCODES', type: 'bool'},

            {name: 'TYPE_BARCODE', type: 'int', label: 'Type'},
            {name: 'ORIENTATION_BARCODE', type: 'int', label: 'Orientation'},
            {name: 'MIN_RATIO_TO_TEXT_HEIGHT', type: 'double'},
            {name: 'CODE_PAGE_BARCODE', enum: 'codePages', label: 'Code page'},
            {name: 'CONTAINS_BINARY_DATA', type: 'bool'},
            {name: 'ENABLE_ADVANCED_EXTRACTION_MODE', type: 'bool'},
            {name: 'HAS_CHECKSUM', type: 'bool'},
            {name: 'IS_CODE_39_WITHOUT_ASTERISK', type: 'bool'},
            {name: 'SUPPLEMENT_TYPE', type: 'int'},

            {name: 'DETECT_SEPARATORS', type: 'bool'},
            {name: 'DETECT_PICTURES', type: 'bool'},
            {name: 'DETECT_VECTOR_GRAPHICS', type: 'bool'},
            {name: 'DETECT_MULTIPLE_BUSINESS_CARDS', type: 'bool'},
            {name: 'NO_SHADOWS_MODE', type: 'bool'},
            {name: 'PAPER_SIZE_DETECTION_MODE', enum: 'paperSizeDetectionModes'},
            {name: 'DETECT_VERTICAL_EUROPEAN_TEXT', type: 'bool'},
            {name: 'PROHIBIT_CJK_COLUMNS', type: 'bool'},
            {name: 'PROHIBIT_DOUBLE_PAGE_MODE', type: 'bool'},
            {name: 'PROHIBIT_MODEL_ANALYSIS', type: 'bool'},
            {name: 'COLLECT_PDF_EXPORT_DATA', type: 'bool'},
        ]
    },
    {
        name: 'Page synthesis',
        fields: [
            {name: 'DETECT_DOCUMENT_LINKS', type: 'bool'},
            {name: 'DETECT_FONT_FORMATTING_AT_PAGE_LEVEL', type: 'bool'},
            {name: 'DETECT_HYPERLINKS', type: 'bool'},
            {name: 'DETECT_BOLD_PAGE', type: 'bool', label: 'Detect bold'},
            {name: 'DETECT_ITALIC_PAGE', type: 'bool', label: 'Detect italic'},
            {name: 'DETECT_SUBSCRIPTS_SUPERSCRIPTS_PAGE', type: 'bool', label: 'Detect subscripts superscripts'},
            {name: 'DETECT_UNDERLINE_STRIKEOUT_PAGE', type: 'bool', label: 'Detect underline strikeout'},
            {name: 'DETECT_SMALL_CAPS_PAGE', type: 'bool', label: 'Detect small caps'},
            {name: 'DETECT_DROP_CAPS_PAGE', type: 'bool', label: 'Detect drop caps'},
            {name: 'DETECT_FONT_FAMILY_PAGE', type: 'bool', label: 'Detect font family'},
            {name: 'DETECT_FONT_SIZE_PAGE', type: 'bool', label: 'Detect font size'},
            {name: 'DETECT_SERIFS_PAGE', type: 'bool', label: 'Detect serifs'},
            {name: 'DETECT_SCALING_PAGE', type: 'bool', label: 'Detect scaling'},
            // This property is currently not supported and should be left default
            // {name: 'DETECT_SPACING_PAGE', type: 'bool', label: 'Detect spacing'},
            {name: 'MONOSPACE_DETECTION_MODE_PAGE', enum: 'monospaceDetectionModes', label: 'Monospace detection mode'},
            {name: 'PARAGRAPH_EXTRACTION_MODE', enum: 'paragraphExtractionModes'},
            {name: 'SAVE_CHARACTER_COORDINATES_FOR_ALL_VARIANTS', type: 'bool'},
            {name: 'SYNTHESIZE_BUSINESS_CARDS', type: 'bool'},
            {name: 'DETECT_BACKGROUND_COLOR', enum: 'threeStates'},
            {name: 'ALLOW_GRAY_BACKGROUND_COLOR', enum: 'threeStates'},
            {name: 'DETECT_TEXT_COLOR', enum: 'threeStates'},
            {name: 'ALLOW_GRAY_TEXT_COLOR', enum: 'threeStates'},
            {name: 'CORRECT_DYNAMIC_RANGE', enum: 'threeStates'}
        ]
    },
    {
        name: 'Document synthesis',
        fields: [
            {name: 'DETECT_DOCUMENT_STRUCTURE', type: 'bool'},
            {name: 'CLASSIFY_SEPARATORS', type: 'bool'},
            {name: 'DETECT_CAPTIONS', type: 'bool'},
            {name: 'DETECT_COLUMNS', type: 'bool'},
            {name: 'DETECT_FOOTNOTES', type: 'bool'},
            {name: 'DETECT_HEADLINES', type: 'bool'},
            {name: 'DETECT_LISTS', type: 'bool'},
            {name: 'DETECT_OVERFLOWING_PARAGRAPHS', type: 'bool'},
            {name: 'DETECT_RUNNING_TITLES', type: 'bool'},
            {name: 'DETECT_TABLE_OF_CONTENTS', type: 'bool'},
            {name: 'DETECT_FONT_FORMATTING', type: 'bool'},
            {name: 'DETECT_BOLD_DOCUMENT', type: 'bool', label: 'Detect bold'},
            {name: 'DETECT_ITALIC_DOCUMENT', type: 'bool', label: 'Detect italic'},
            {name: 'DETECT_SUBSCRIPTS_SUPERSCRIPTS_DOCUMENT', type: 'bool', label: 'Detect subscripts superscripts'},
            {name: 'DETECT_UNDERLINE_STRIKEOUT_DOCUMENT', type: 'bool', label: 'Detect underline strikeout'},
            {name: 'DETECT_SMALL_CAPS_DOCUMENT', type: 'bool', label: 'Detect small caps'},
            {name: 'DETECT_DROP_CAPS_DOCUMENT', type: 'bool', label: 'Detect drop caps'},
            {name: 'DETECT_FONT_FAMILY_DOCUMENT', type: 'bool', label: 'Detect font family'},
            {name: 'DETECT_FONT_SIZE_DOCUMENT', type: 'bool', label: 'Detect font size'},
            {name: 'DETECT_SERIFS_DOCUMENT', type: 'bool', label: 'Detect serifs'},
            {name: 'DETECT_SCALING_DOCUMENT', type: 'bool', label: 'Detect scaling'},
            // This property is currently not supported and should be left default
            // {name: 'DETECT_SPACING_DOCUMENT', type: 'bool', label: 'Detect spacing'},
            {
                name: 'MONOSPACE_DETECTION_MODE_DOCUMENT', enum: 'monospaceDetectionModes',
                label: 'Monospace detection mode'
            },
            {name: 'LOW_MEMORY_MODE', type: 'bool'},
            {name: 'PAGE_POOL_SIZE', type: 'int'}
        ]
    },
    {
        name: 'Export',
        fields: []
    }
]

export const predefinedProfiles = [
    'DocumentConversion_Accuracy', 'DocumentConversion_Speed', 'DocumentArchiving_Accuracy', 'DocumentArchiving_Speed',
    'BookArchiving_Accuracy', 'BookArchiving_Speed', 'TextExtraction_Accuracy', 'TextExtraction_Speed',
    'FieldLevelRecognition', 'BarcodeRecognition_Accuracy', 'BarcodeRecognition_Speed', 'HighCompressedImageOnlyPdf',
    'BusinessCardsProcessing', 'MachineReadableZone', 'EngineeringDrawingsProcessing', 'Version9Compatibility'
]

export const enums = {
    threeStates: ['Yes', 'No', 'Auto'],
    photoProcessingModes: ['TreatAsPhoto', 'TreatAsNonPhoto', 'Auto'],
    rotationTypes: ['UnknownRotation', 'NoRotation', 'Clockwise', 'Counterclockwise', 'Upsidedown'],
    orientationDetectionModes: ['Fast', 'Normal', 'Thorough'],
    sourceContentReuseModes: ['ContentOnly', 'DoNotReuse', 'Auto'],
    caseRecognitionModes: ['AutoCase', 'SmallCase', 'CapitalCase'],
    writingStyles: ['DetectByLocale', 'American', 'German', 'Russian', 'Polish', 'Thai', 'Japanese', 'Arabic',
        'Baltic', 'British', 'Bulgarian', 'Canadian', 'Czech', 'Croatian', 'French', 'Greek', 'Hungarian', 'Italian',
        'Romanian', 'Slovak', 'Spanish', 'Turkish', 'Ukrainian', 'Common', 'Chinese', 'Azerbaijan', 'Kazakh',
        'Kirgiz', 'Latvian', 'Auto'],
    fieldMarkingTypes: ['SimpleText', 'UnderlinedText', 'TextInFrame', 'GrayBoxes', 'CharBoxSeries', 'SimpleComb',
        'CombInFrame', 'PartitionedFrame'],
    pageSplitTypes: ['None', 'DoublePageSplit', 'BusinessCardSplit'],
    paperSizeDetectionModes: ['Auto', 'Unknown', 'CloseToImageSize'],
    codePages: ['Null', 'Latin', 'Cyrillic', 'EasternEuropean', 'Baltic', 'Turkish', 'US_MSDOS', 'LatinI_MSDOS',
        'Russian_MSDOS', 'Baltic_MSDOS', 'Turkish_IBM', 'Slavic_MSDOS', 'Greek', 'Greek_737', 'Greek_869',
        'Latin_ISO', 'EasternEuropean_ISO', 'Turkish_ISO', 'Baltic_ISO', 'Cyrillic_ISO', 'Greek_ISO', 'KOI8', 'Tatar',
        'Tatar_MSDOS', 'Roman_Macintosh', 'Greek_Macintosh', 'Cyrillic_Macintosh', 'Ukrainian_Macintosh',
        'Latin2_Macintosh', 'Icelandic_Macintosh', 'Turkish_Macintosh', 'Croatian_Macintosh', 'Armenian',
        'Armenian_MSDOS', 'Armenian_Macintosh', 'Hebrew', 'Hebrew_MSDOS', 'Hebrew_Macintosh', 'Hebrew_ISO',
        'Latin5_ISO', 'Cyrillic_MSDOS', 'Bashkir', 'Chinese_Simpl_GB', 'Chinese_Simpl_Mac', 'Chinese_Trad_Big',
        'Chinese_Trad_Mac', 'Japan_Mac', 'Japan_SJIS', 'Korean', 'Korean_Johab', 'Korean_Mac', 'Mathematical',
        'Digits', 'Thai', 'Thai_Macintosh', 'Vietnamese'],
    monospaceDetectionModes: ['Auto', 'NotMonospace', 'Monospace', 'Ignore'],
    paragraphExtractionModes: ['NormalExtraction', 'RoughExtraction', 'SingleLineParagraphsWithWordSeparationOnly'],
    fileExportFormats: [
        'RTF', 'HTMLVersion10Defaults', 'HTMLUnicodeDefaults',
        //'XLS', 'PDF',
        'TextVersion10Defaults', 'TextUnicodeDefaults', 'XML', 'DOCX',
        //'XLSX', 'PPTX', 'ALTO', 'EPUB', 'FB2',
        'ODT',
        //'XPS'
    ],
    rtfPageSynthesisModes: ['RTFPlainText', 'RTFFormatParagraphs', 'RTFExactCopy', 'RTFEditableCopy'],
    backgroundColorModes: ['DontSave', 'BlackWhite', 'ColorForInverted', 'Color'],
    pageOrientations: ['Portrait', 'Landscape', 'Auto', 'MostFrequent'],
    htmlFormats: ['Format32', 'Format40', 'Format50'],
    htmlSynthesisModes: ['PlainText', 'FormattedStream', 'FlexibleLayout', 'ExactCopy'],
    htmlDocumentSplittingModes: ['None', 'Heading_1', 'Heading_2', 'Smart'],
    textEncodingTypes: ['Simple', 'UTF8', 'UTF16', 'Auto'],
    txtExportFormats: ['TXT', 'CSVFullLayout', 'CSVTablesOnly'],
    textLayoutRetentionModes: ['Auto', 'ExactCopy', 'LogicalStructure'],
    xmlCharAttributes: ['None', 'Ascii', 'Basic', 'Extended']
}
