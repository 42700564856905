<template>
  <div class="fill-height">
    <group-dialog :form-title="formTitle" :show="dialog" :item="editedItem" :on-apply="save" :on-close="closeModals"
                  :on-change-group="editGroup"></group-dialog>
    <simple-dialog :show="dialogDelete" :on-apply="confirmDelete" :on-close="closeModals" :item="editedItem">
      Are you sure you want to delete this group ?
    </simple-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="10" :md="mdSize">
          <d-data-table :items="groupsData" :headers="tableHeaders" class="mt-1"
                        :footer-props="footerProps" :items-per-page="itemsPerPage"
                        :loading="loading" :fill-height="!homePreview"
                        :hide-default-footer="homePreview" :disable-sort="homePreview">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Groups</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createGroup" class="ml-2" v-if="!homePreview">New group</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{item}">
              <v-chip top :open-delay=350 class="ma-1"
                      :color="stringToColor(item.name)"
                      :dark="isDark(stringToColor(item.name))">
                {{ item.name }}
              </v-chip>
            </template>
            <template v-slot:item.parent="{item}">
              <v-chip top :open-delay=350 class="ma-1"
                      v-if="groupById[item.parentId]"
                      :color="stringToColor(groupById[item.parentId].name)"
                      :dark="isDark(stringToColor(groupById[item.parentId].name))">
                {{ groupById[item.parentId].name }}
              </v-chip>
            </template>
            <template v-slot:item.children="{item}">
              <span v-if="item.children">{{ item.children.length }}</span>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editGroup(item)">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteGroup(item)">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {cloneDeep} from "lodash";
import SimpleDialog from "@/components/parts/manage-dialogs/SimpleDialog";
import GroupDialog from "@/components/parts/manage-dialogs/GroupDialog";
import DDataTable from "@/components/wrappers/DDataTable";
import {reduceById} from "@/util/js-utils";

export default {
  name: "Groups",
  components: {GroupDialog, SimpleDialog, DDataTable},
  props: {
    homePreview: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      projectId: this.$route.params.projectId,
      editedItem: undefined,
      groupsData: [],
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      sending: false,
      edit: false,
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1]
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedItem?.id ? "Edit group" : "Create group"
    },
    groupById() {
      return reduceById(this.groupsData)
    },
    mdSize() {
      return this.homePreview ? 12 : 8
    },
    itemsPerPage() {
      return this.homePreview ? 10 : 50
    },
    tableHeaders() {
      const headers = [
        {text: 'Group Name', value: 'name', align: 'left'},
        {text: 'Parent group', value: 'parent', align: 'left'},
        {text: 'Number of children', value: 'children', align: 'left'}
      ]
      if (!this.homePreview) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    }
  },
  created() {
    this.fetchGroupsData()
  },
  methods: {
    fetchGroupsData() {
      this.loading = true
      this.error = null
      this.hasError = false

      axios.get(`/groups`)
          .then(response => {
            this.groupsData = response.data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    createGroup() {
      this.editedItem = undefined
      this.edit = false
      this.dialog = true
    },
    editGroup(item) {
      this.editedItem = cloneDeep(item)
      this.edit = true
      this.dialog = true
    },
    deleteGroup(item) {
      this.editedItem = cloneDeep(item)
      this.dialogDelete = true
    },
    closeModals() {
      this.dialog = false
      this.dialogDelete = false
    },
    confirmDelete() {
      this.sending = true

      axios.delete(`/groups/${this.editedItem.id}`).then(() => this.thenHandler()).catch(this.catchHandler)
    },
    save(item, switchGroup = undefined) {
      this.sending = true
      const data = {
        id: item.id,
        name: item.name,
        parentId: item.parentId,
        permissions: item.permissions
      }

      if (item.id)
        axios.put(`/groups/${item.id}`, data)
            .then(() => this.thenHandler(switchGroup)).catch(this.catchHandler)
      else
        axios.post(`/groups`, data).then(() => this.thenHandler(switchGroup)).catch(this.catchHandler)
    },
    catchHandler(err) {
      this.sending = false
      this.error = err
      this.hasError = true
    },
    thenHandler(switchGroup = undefined) {
      this.sending = false
      if (switchGroup !== undefined) {
        this.editGroup(this.groupById[switchGroup])
      } else {
        this.dialog = false
        this.dialogDelete = false
        this.fetchGroupsData()
      }
    }
  }
}
</script>

<style scoped>
</style>
