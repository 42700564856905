<template>
  <v-dialog persistent :value="show" max-width="60%" @click:outside="onClose" @keydown.esc="onClose">
    <filter-dialog :filter="dialogFilter" :enums-values="enumsValues" :on-close="closeFilterDialog"
                   :on-apply="handleFilterDialogApply" :show="filterDialogShown"
                   :available-filter-details="filterDetails"></filter-dialog>

    <v-form ref="form">
      <v-card>
        <v-card-title>{{ item === null ? 'Add' : 'Edit' }} batch rule</v-card-title>
        <v-card-text>
          <filter-bloc :id="0" :show-dialog="showFilterDialog" :on-modify="handleFilterBlocModify" :depth="0"
                       :enums-values="enumsValues" :filter-bloc="editedItem.filters"></filter-bloc>
          <v-text-field v-model="editedItem.name" label="Rule name" :rules="rules.name"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" text @click="apply">Apply</v-btn>
          <v-btn color="error" text @click="onClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {cloneDeep} from "lodash";
import FilterDialog from "@/components/parts/filters/FilterDialog";
import {DEFAULT_FILTERS, DOCUMENT_EDITOR_FILTERS_DETAILS} from "@/components/parts/filters/filters-utils";
import FilterBloc from "@/components/parts/filters/FilterBloc";
import {RULE_TYPES} from "@/util/document-editor";

const DEFAULT_EDITED_ITEM = {
  type: RULE_TYPES.BATCH_REMOVE.id,
  filters: cloneDeep(DEFAULT_FILTERS),
  name: '',
  find: '',
  replace: ''
}

export default {
  name: "AddEditBatchRuleDialog",
  components: {FilterDialog, FilterBloc},
  props: {
    show: {type: Boolean, required: true},
    enumsValues: {type: Object, required: true},
    onClose: {type: Function, required: true},
    showError: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      dialogFilter: undefined,
      handleFilterDialogApply: null,
      filterDialogShown: false,
      editedItem: cloneDeep(DEFAULT_EDITED_ITEM),
      rules: {
        action: [
          v => v !== undefined || 'Required'
        ],
        name: [
          v => !!v || 'Required'
        ],
        find: [
          v => !!v || 'Required'
        ],
      },
      filterDetails: DOCUMENT_EDITOR_FILTERS_DETAILS
    }
  },
  watch: {
    item(newVal) {
      if (!this.show)
        return

      this.$refs.form?.resetValidation()
      this.editedItem = cloneDeep(newVal === null ? DEFAULT_EDITED_ITEM : newVal)
    },
    show(newVal) {
      if (!newVal)
        return

      this.$refs.form?.resetValidation()
      this.editedItem = cloneDeep(this.item === null ? DEFAULT_EDITED_ITEM : this.item)
    }
  },
  methods: {
    showFilterDialog(onDialogApply, filter = null) {
      this.dialogFilter = filter
      this.handleFilterDialogApply = onDialogApply
      this.filterDialogShown = true
    },
    closeFilterDialog() {
      this.dialogFilter = null
      this.handleFilterDialogApply = null
      this.filterDialogShown = false
    },
    handleFilterBlocModify(_, filterBloc) {
      this.editedItem.filters = cloneDeep(filterBloc)
    },
    apply() {
      if (!this.$refs.form.validate())
        return

      this.onApply(this.editedItem)
    },
  }
}
</script>

<style scoped>

</style>
