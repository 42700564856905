<template>
  <div class="fill-height">
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this pipeline ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click="confirmDelete" :disabled="sending">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="60%">
      <v-form @submit.prevent="save" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-text-field label="Name" v-model="editedItem.name" :rules="rules.name" autofocus
                            :disabled="sending"></v-text-field>
              <v-text-field label="Description" v-model="editedItem.description" :disabled="sending"></v-text-field>
              <v-select v-if="!edit" v-model="editedItem.type" :items="pipelineTypes" name="id" label="Pipeline type"
                        item-text="text" item-value="id" required :rules="rules.type"></v-select>
              <v-row>
                <v-col>
                  <v-select v-model="editedItem.language1" :disabled="edit" :items="languagesShort"
                            :label="editedItem.language1IsSource ? 'Source language' : 'Language 1'"
                            required :rules="rules.languages"></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="editedItem.language2" :disabled="edit" :items="languagesShort"
                            :label="editedItem.language1IsSource ? 'Target language' : 'Language 2'"
                            required :rules="rules.languages"></v-select>
                </v-col>
              </v-row>
              <v-checkbox v-if="!edit" label="Set languages as source -> target"
                          v-model="editedItem.language1IsSource"></v-checkbox>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="10" md="8" class="fill-height d-flex flex-column">
          <div v-if="projectData.ownerEnabled === false" class="mb-3">
            <v-alert type="warning">
              This project's owner is disabled, so it is locked and only projects administrators can see it
            </v-alert>
          </div>
          <h1 class="text-h3 text-center">{{ projectData.title }}</h1>
          <p class="text-subtitle-1 text-center">{{ projectData.description }}</p>
          <div class="d-flex flex-column align-center">
            <div class="d-flex flex-row align-center">
              <span class="d-flex flex-row align-center">Sources:</span>
              <v-chip v-for="source in projectData.projectSources" :key="source"
                      class="ma-2" small
                      :color="stringToColor(sourceById[source].name)"
                      :dark="isDark(stringToColor(sourceById[source].name))">
                {{ sourceById[source].name  }}
              </v-chip>
            </div>
            <span class="font-weight-bold">{{ confidentialityLevelName }}</span>
            <span v-if="projectData.dateRangeStart && projectData.dateRangeEnd">
              | Date range : {{ formatDateRange(projectData.dateRangeStart, projectData.dateRangeEnd) }}
            </span>
            <span>
              <span v-if="projectData.createdBy">
                Created by: <span class="font-weight-bold">{{ projectData.createdBy }}</span>
                at: <span class="font-weight-bold">{{ formatDateToSeconds(new Date(projectData.dateCreated)) }}</span>
              </span>
              <span v-if="projectData.createdBy && projectData.updatedBy"> | </span>
              <span v-if="projectData.updatedBy">
                Updated by: <span class="font-weight-bold">{{ projectData.updatedBy }}</span>
                at: <span class="font-weight-bold">{{ formatDateToSeconds(new Date(projectData.dateUpdated)) }}</span>
              </span>
            </span>
          </div>

          <d-data-table :items="projectData.pipelines" :headers="headers" class="row-pointer mt-1"
                        :footer-props="footerProps" sort-by="name" :items-per-page=50 :loading="loading"
                        @click:row="item => openPipeline(item)">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Pipelines</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn :to="`/projects/${projectId}/file-browser/input`" class="primary my-auto ml-4"
                        v-if="hasPermUseFiles">
                  <v-icon>mdi-file-search</v-icon>
                </v-btn>
                <v-btn color="primary" @click="createItem" class="ml-4" v-if="hasPermAdminPipelines">
                  New pipeline
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.languages="{item}">
              {{ item.language1 }}
              <v-icon v-if="item.language1IsSource">mdi-arrow-right</v-icon>
              <v-icon v-else>mdi-arrow-left-right</v-icon>
              {{ item.language2 }}
            </template>
            <template v-slot:item.status="{item}">
              <v-tooltip top :open-delay=350>
                <template v-slot:activator="{on, attrs}">
                    <span v-bind="attrs" v-on="on">
                      <v-icon :color="pipelineStatus(item).color">{{ pipelineStatus(item).icon }}</v-icon>
                    </span>
                </template>
                <span>{{ pipelineStatus(item).text }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editItem(item)" v-if="hasPermAdminPipelines">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteItem(item)" v-if="hasPermAdminPipelines">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {PIPELINE_STATUSES_ICONS} from "@/util/enums";
import {LANGUAGES_SHORT} from "@/util/enums";
import DDataTable from "@/components/wrappers/DDataTable";
import {formatDateRange, formatDateToSeconds} from "@/util/string-utils";
import {reduceById} from "@/util/js-utils";

const DEFAULT_NEW_PIPELINE = {
  name: '',
  description: '',
  type: 1,
  language1: null,
  language2: null,
  language1IsSource: false
}

export default {
  name: "Project",
  components: {DDataTable},
  data() {
    return {
      projectId: this.$route.params.projectId,
      editedItem: Object.assign({}, DEFAULT_NEW_PIPELINE),
      projectData: {},
      projectSources: [],
      confidentialityLevelName: '',
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      sending: false,
      edit: false,
      headers: [
        {text: 'Name', value: 'name', align: 'left'},
        {text: 'Languages', value: 'languages', align: 'left'},
        {text: 'ID', value: 'id', align: 'left'},
        {text: 'Status', value: 'status', align: 'left'},
        {text: 'Actions', value: 'actions', sortable: false, align: 'right'}
      ],
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },
      pipelineTypes: [
        {
          id: 1,
          text: "Document2text x2 -> DocumentMatcher -> Tokenizer x2 -> Aligner"
        }
      ],
      languagesShort: LANGUAGES_SHORT,
      rules: {
        name: [
          v => !!v || 'Required'
        ],
        type: [
          v => !!v || 'Required'
        ],
        languages: [
          v => !!v || 'Required',
          () => this.editedItem.language1 !== this.editedItem.language2 || 'Languages must be different'
        ]
      },
      isDark,
      stringToColor,
      userPermissions: [],
      hasPermUseFiles: false,
      hasPermAdminProject: false,
      hasPermAdminPipelines: false,
    }
  },
  computed: {
    formTitle() {
      return this.editedItem.id ? "Edit pipeline" : "Create pipeline"
    },
    sourceById() {
      return reduceById(this.projectSources)
    },
  },
  mounted() {
    this.fetchProjectData()
  },
  methods: {
    pipelineStatus(item) {
      return PIPELINE_STATUSES_ICONS[item.status]
    },
    fetchProjectData() {
      this.loading = true
      this.error = null
      this.hasError = false

      Promise.all([
        axios.get(`/projects/${this.projectId}`),
        axios.get('/sources'),
        axios.get('/confidentiality-levels')
      ])
          .then(responses => {
            this.projectData = responses[0].data
            this.projectSources = responses[1].data
            this.confidentialityLevelName = responses[2].data.find(
                confidentialityLevel => confidentialityLevel.id === this.projectData.confidentialityLevelId).name
            this.organizePermissions()
            this.checkPermAdminProject()
            this.checkPermUseFiles()
            this.checkPermAdminPipelines()
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
          .finally(() => this.loading = false)
    },
    organizePermissions() {
      this.projectData?.groupPermissions.forEach(permission => {
        let perm = `${permission?.permissionType} ${permission?.resource}`
        this.userPermissions.push(perm)
      })
    },
    checkPermAdminProject() {
      this.hasPermAdminProject =
          this.userPermissions.includes('admin project')
          || this.projectData.ownerEnabled
    },
    checkPermUseFiles() {
      this.hasPermUseFiles =
          this.userPermissions.includes('admin project')
          || this.userPermissions.includes('use files')
          || this.projectData.ownerEnabled
    },
    checkPermAdminPipelines() {
      this.hasPermAdminPipelines =
          this.userPermissions.includes('admin project')
          || this.userPermissions.includes('admin pipelines')
          || this.projectData.ownerEnabled
    },
    openPipeline(item) {
      if (this.hasPermAdminPipelines || this.projectData.ownerEnabled)
        this.$router.push({path:`/projects/${this.projectId}/${item.id}`})
    },
    createItem() {
      this.editedItem = Object.assign({}, DEFAULT_NEW_PIPELINE)
      this.edit = false
      this.dialog = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.edit = true
      this.dialog = true
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    confirmDelete() {
      this.sending = true

      axios.delete(`/pipelines/${this.editedItem.id}`)
          .then(() => {
            this.sending = false
            this.dialogDelete = false
            this.fetchProjectData()
          })
          .catch(err => {
            this.sending = false
            this.error = err
            this.hasError = true
          })
    },
    catchHandler(err) {
      this.sending = false
      this.error = err
      this.hasError = true
    },
    thenHandler() {
      this.sending = false
      this.dialog = false
      this.fetchProjectData()
    },
    save() {
      if (!this.$refs.form.validate())
        return

      this.sending = true

      const data = {
        name: this.editedItem.name,
        description: this.editedItem.description,
        type: this.editedItem.type,
        language1: this.editedItem.language1,
        language2: this.editedItem.language2,
        language1IsSource: this.editedItem.language1IsSource
      }

      if (this.editedItem.id)
        axios.put(`/pipelines/${this.editedItem.id}`, data)
            .then(this.thenHandler)
            .catch(this.catchHandler)
      else
        axios.post(`/projects/${this.projectId}/pipelines`, data)
            .then(this.thenHandler)
            .catch(this.catchHandler)
    },
    formatDateRange,
    formatDateToSeconds,
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
