<template>
  <v-dialog v-model="show" persistent width="60%" max-width="550px">
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title class="headline">
          {{ filter ? 'Modify a filter' : 'Add a new filter' }}
        </v-card-title>
        <v-card-text>
          <v-autocomplete v-model="selectedFilter" @change="filterChange" :items="autocompleteItems" item-value="id"
                          item-text="name" hide-details placeholder="Search filters..." autofocus></v-autocomplete>
          <div v-if="selectedFilter" class="mt-2">
            <div class="text-body-2 mb-2">{{ selectedFilterDetails.description }}</div>

            <v-text-field v-if="stringFilterSelected" label="Query" type="search" :rules="rules.searchQuery"
                          v-model="newFilter.params"></v-text-field>

            <v-select v-else-if="enumFilterSelected" multiple v-model="newFilter.params" :items="items"
                      :loading="loading" item-text="name" item-value="id" hide-details></v-select>

            <div v-else-if="numberFilterSelected" class="d-flex justify-center">
              <v-select :items="comparisonOperators" v-model="newFilter.params.operator" label="Operator"
                        class="filter-small-input mr-2"></v-select>
              <v-text-field type="number" v-model="newFilter.params.value" label="Value" :rules="rules.minMax"
                            class="filter-small-input ml-2"></v-text-field>
            </div>

            <div v-else-if="dateFilterSelected" class="d-flex justify-center">
              <v-select :items="comparisonOperators" v-model="newFilter.params.operator" label="Operator"
                        class="filter-small-input mr-2"></v-select>
              <v-menu v-model="showDatePicker" :close-on-content-click="false" :nudge-right="40" offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="newFilter.params.value" readonly v-bind="attrs" v-on="on"
                                class="filter-date-input ml-2"></v-text-field>
                </template>
                <v-date-picker v-model="newFilter.params.value" @input="showDatePicker = false"></v-date-picker>
              </v-menu>
            </div>

            <div v-else-if="searchFilterSelected">
              <v-text-field label="Query" type="search" v-model="newFilter.params.value"
                            :rules="rules.searchQuery"></v-text-field>
              <v-select :items="enumsValues.languages" v-model="newFilter.params.language" label="Language"
                        item-value="id" item-text="name" :rules="rules.language"></v-select>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="close">Cancel</v-btn>
          <v-btn color="primary" text type="submit">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  FILTER_CATEGORIES,
  FILTER_TYPES,
  filterFromName,
  FILTERS_DETAILS,
  FILTERS_OPERATORS
} from "@/components/parts/filters/filters-utils";
import {cloneDeep, groupBy} from "lodash";

export default {
  name: "FilterDialog",
  props: {
    show: {type: Boolean, required: true},
    availableFilterDetails: {type: Object, default: () => FILTERS_DETAILS},
    enumsValues: {type: Object, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: false},
    filter: {type: Object, required: false},
  },
  data: () => ({
    showDatePicker: false,
    selectedFilter: null,
    newFilter: null,
    items: undefined,
    loading: false
  }),
  watch: {
    show(newVal) {
      if (newVal)
        this.populateFields()
    },
  },
  computed: {
    stringFilterSelected() {
      return this.selectedFilterDetails?.filterType === FILTER_TYPES.STRING
    },
    enumFilterSelected() {
      return this.selectedFilterDetails?.filterType === FILTER_TYPES.ENUM
    },
    numberFilterSelected() {
      const filterType = this.selectedFilterDetails?.filterType
      return filterType === FILTER_TYPES.INTEGER || filterType === FILTER_TYPES.FLOAT
    },
    dateFilterSelected() {
      return this.selectedFilterDetails?.filterType === FILTER_TYPES.DATE
    },
    searchFilterSelected() {
      return this.selectedFilterDetails?.filterType === FILTER_TYPES.SEARCH
    },
    autocompleteItems() {
      let items = []

      const filtersByCategory = groupBy(
          Object.entries(this.availableFilterDetails).map(([key, value]) => ({
            id: key,
            name: value.name,
            category: value.category
          })), 'category')

      for (const [index, category] of Object.keys(FILTER_CATEGORIES).entries()) {
        let tempItems = []
        if (index !== 0)
          tempItems.push({
            divider: true
          })

        tempItems.push({
          header: FILTER_CATEGORIES[category].name
        })

        if (filtersByCategory[category])
          items = items.concat(tempItems.concat(filtersByCategory[category]))
      }

      return items
    },
    comparisonOperators() {
      return Object.values(FILTERS_OPERATORS)
    },
    selectedFilterDetails() {
      return this.selectedFilter && this.availableFilterDetails[this.selectedFilter]
    },
    min() {
      if (this.selectedFilterDetails
          && (this.selectedFilterDetails.filterType === FILTER_TYPES.FLOAT
              || this.selectedFilterDetails.filterType === FILTER_TYPES.INTEGER))
        return this.selectedFilterDetails.meta?.min

      return undefined
    },
    max() {
      if (this.selectedFilterDetails
          && (this.selectedFilterDetails.filterType === FILTER_TYPES.FLOAT
              || this.selectedFilterDetails.filterType === FILTER_TYPES.INTEGER))
        return this.selectedFilterDetails.meta.max

      return undefined
    },
    rules() {
      return {
        minMax: [
          v => !!v || 'Required',
          v => this.max === undefined || v <= this.max || 'The value should not exceed ' + this.max,
          v => this.min === undefined || v >= this.min || 'The value should not be smaller than ' + this.min
        ],
        language: [
          v => !!v || 'Required'
        ],
        searchQuery: [
          v => !!v || 'Required'
        ]
      }
    },
  },
  methods: {
    filterChange() {
      this.newFilter = filterFromName(this.selectedFilter)
      const filterDetails = this.availableFilterDetails[this.selectedFilter]

      if (filterDetails.filterType === FILTER_TYPES.ENUM)
        this.items = filterDetails.meta.enumValues ?? this.enumsValues[filterDetails.meta.enumKey]
    },
    populateFields() {
      // When this is a modification dialog
      if (!this.filter)
        return

      this.selectedFilter = this.filter.name
      this.newFilter = cloneDeep(this.filter)

      const filterDetails = this.availableFilterDetails[this.selectedFilter]
      if (filterDetails.filterType === FILTER_TYPES.ENUM)
        this.items = filterDetails.meta.enumValues ?? this.enumsValues[filterDetails.meta.enumKey]
    },
    apply() {
      if (!this.$refs.form.validate() || this.newFilter === null)
        return

      this.onApply(cloneDeep(this.newFilter))
      this.close()
    },
    close() {
      this.selectedFilter = undefined
      this.newFilter = undefined
      this.items = undefined
      this.onClose()
    },
  }
}
</script>

<style scoped>
.filter-small-input {
  flex-grow: 0;
  width: 60px;
}

.filter-date-input {
  flex-grow: 0;
  width: 100px;
}
</style>
