import axios from "axios";
import store from "@/store"
import {LOGOUT_REQUEST} from "@/store/action-types";
import router from "@/router";
import {SET_LAST_ROUTE} from "./store/action-types";

export const configureAxios = () => {
    const nodeEnv = process.env.NODE_ENV

    axios.defaults.baseURL = nodeEnv === 'production'
        ? document.location.origin + '/api'
        : 'http://localhost'

    axios.interceptors.request.use(config => {
        if (store.state.auth.token)
            config.headers['Authorization'] = `Bearer ${store.state.auth.token}`
        return config
    })

    axios.interceptors.response.use(undefined, error => {
        if (error?.response?.status === 401 && error?.response?.data?.detail === "Not authenticated") {
            if (router.currentRoute.path !== '/login')
                store.dispatch(LOGOUT_REQUEST)
                    .then(() => {
                        store.dispatch(SET_LAST_ROUTE, router.currentRoute).then(() => {
                            return router.push('/login')
                        })
                    })
            else
                return
        }
        return Promise.reject(error)
    })
}
