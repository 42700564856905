<template>
  <v-dialog v-model="show" max-width="80%" @click:outside="onClose">
    <v-card>
      <v-card-title>
        <span class="headline">Alignment</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="segment">
          <v-data-table :items="items" :headers="headers" :items-per-page="3" hide-default-footer
                        class="py-5"></v-data-table>
          <div class="text-h6">Details</div>
          <v-divider></v-divider>
          <div class="text-body-1">
            <div class="my-4">
              Imported from
              <span v-if="segment.pipelineId">
                <span class="text--primary">{{ projectLabel }}</span>
                and pipeline
                <span class="text--primary">{{ enumsValues.pipelinesById[pipelineName].name }}</span>
              </span>
              <span v-else-if="tmxLabel" class="text--primary">{{ tmxLabel }}</span>
              <span v-else>a deleted pipeline</span>
              by
              <span v-if="segment.importUser">
                user
                <span class="text--primary">{{ enumsValues.usersById[segment.importUser].name }}</span>
              </span>
              <span v-else>
                a deleted user
              </span>
              on
              <span class="text--primary">{{ segment.importDate }}</span>
            </div>
            <div v-if="segment.appliedRuleType">
              <span class="text--primary">"{{ rule }}"</span>
              in the document editor
            </div>
            <div v-if="segment.lastModificationDate">
              Last modified by
              <span v-if="segment.lastModificationUser" class="text--primary">
                {{ enumsValues.usersById[segment.lastModificationUser].name }}
              </span>
              <span v-else>
                a deleted user
              </span>
              on
              <span class="text--primary">{{ segment.lastModificationDate }}</span>
              in the segment manager
            </div>
            <div class="d-flex flex-column">
              <v-row>
                <v-col cols="3">Origin documents/URLs:</v-col>
                <v-col class="text--primary">{{ segment.left.documentOrigin }}</v-col>
                <v-col class="text--primary">{{ segment.right.documentOrigin }}</v-col>
              </v-row>
              <v-row v-if="segment.left.documentFriendlyName || segment.right.documentFriendlyName">
                <v-col cols="3">Origin document descriptions:</v-col>
                <v-col class="text--primary">{{ segment.left.documentFriendlyName }}</v-col>
                <v-col class="text--primary">{{ segment.right.documentFriendlyName }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">Document languages:</v-col>
                <v-col class="text--primary">{{ segment.left.documentLanguage }}</v-col>
                <v-col class="text--primary">{{ segment.right.documentLanguage }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">Detected languages:</v-col>
                <v-col class="text--primary">{{ segment.left.detectedLanguage || 'None' }}</v-col>
                <v-col class="text--primary">{{ segment.right.detectedLanguage || 'None' }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="3">Expected detected languages:</v-col>
                <v-col class="text--primary">{{ segment.left.expectedDetectedLanguage || 'None' }}</v-col>
                <v-col class="text--primary">{{ segment.right.expectedDetectedLanguage || 'None' }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="text-capitalize">
                    {{ segment.scoring }}:
                  </span>
                  <span class="text--primary">{{ formattedScoreDistance }}</span>
                </v-col>
                <v-col>
                  Confidentiality level:
                  <span class="text--primary">
                    {{ enumsValues.confidentialityLevelById[segment.confidentialityLevelId].name }}
                  </span>
                </v-col>
                <v-col>
                  Owner:
                  <v-chip :color="stringToColor(ownerName)" small :dark="isDark(stringToColor(ownerName))">
                    {{ ownerName }}
                  </v-chip>
                </v-col>
                <v-col>
                  Translated:
                  <span class="text--primary">{{ !!segment.isLeftSource ? 'YES' : 'NO' }}</span>
                </v-col>
              </v-row>
            </div>
            <div v-if="hasTags">
              Tags:
              <v-chip class="mr-1" v-for="(tag, key) in tagNames" :color="stringToColor(tag)" :key="key" small
                      :dark="isDark(stringToColor(tag))">
                {{ tag }}
              </v-chip>
            </div>
            <div v-if="hasDomains">
              Domains:
              <v-chip class="mr-1" v-for="(domain, index) in domainNames" :color="stringToColor(domain)" :key="index"
                      small :dark="isDark(stringToColor(domain))">
                {{ domain }}
              </v-chip>
            </div>
            <div v-if="hasGroups">
              Groups:
              <v-chip class="mr-1" v-for="(group, index) in groupNames" :color="stringToColor(group)" :key="index" small
                      :dark="isDark(stringToColor(group))">
                {{ group }}
              </v-chip>
            </div>
            <div v-if="hasNotes">
              Document notes:
              <div class="text--primary" v-for="(note, index) in segment.notes" :key="index">
                "{{ note }}"
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="this.segment" @click="openDoc" class="mr-4" color="primary" :disabled="!canOpenDoc">View Document
        </v-btn>
        <v-btn @click="onClose">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isDark, stringToColor} from "@/util/color";
import {round} from "lodash";

const RULES = {
  add: 'Added',
  modify: 'Modified',
  validate: 'Validated',
  merge: 'Merged'
}

export default {
  name: "SegmentDetails",
  props: {
    show: {type: Boolean, required: true},
    enumsValues: {type: Object, required: true},
    onClose: {type: Function, required: true},
    segment: {type: Object, default: null}
  },
  data() {
    return {
      isDark,
      stringToColor,
      headers: [
        {text: "Left Idx", value: "leftIndex", width: '7%', sortable: false},
        {text: "Left Segment", value: "left", width: '43%', sortable: false},
        {text: "Right Segment", value: "right", width: '43%', sortable: false},
        {text: "Right Idx", value: "rightIndex", width: '7%', sortable: false},
      ],
      switchGroup: undefined,
      dialogSwitchGroup: false,
      segmentDetails: undefined,
    }
  },
  computed: {
    rule() {
      return this.segment?.appliedRuleType ? RULES[this.segment.appliedRuleType] : ""
    },
    items() {
      if (!this.segment)
        return []

      const before = {
        leftIndex: '',
        left: this.segment.left.precedingSegmentsText.join(' '),
        rightIndex: '',
        right: this.segment.right.precedingSegmentsText.join(' ')
      }
      const segment = {
        leftIndex: this.segment.left.indexInDocument,
        left: this.segment.left.text,
        rightIndex: this.segment.right.indexInDocument,
        right: this.segment.right.text
      }
      const after = {
        leftIndex: '',
        left: this.segment.left.followingSegmentsText.join(' '),
        rightIndex: '',
        right: this.segment.right.followingSegmentsText.join(' ')
      }
      return [before, segment, after]
    },
    hasTags() {
      return this.segment?.tags.length > 0
    },
    tagNames() {
      return this.segment?.tags.map(tagId => this.enumsValues.tagById[tagId]?.name ?? '[unknown]')
    },
    hasDomains() {
      return this.segment?.domains.length > 0
    },
    domainNames() {
      return this.segment?.domains.map(domainId => this.enumsValues.domainById[domainId]?.name ?? '[unknown]')
    },
    hasGroups() {
      return this.segment?.groups.length > 0
    },
    hasNotes() {
      return this.segment?.notes.length > 0
    },
    groupNames() {
      return this.segment?.groups.map(groupId => this.enumsValues.groupById[groupId]?.name ?? '[unknown]')
    },
    ownerName() {
      return this.enumsValues.ownerById[this.segment?.ownerId]?.name ?? '[unknown]'
    },
    projectLabel() {
      const projectName = this.enumsValues.projectById[this.segment?.projectId]?.name
      const crawlingName = this.enumsValues.crawlingByProjectId[this.segment?.projectId]?.name
      if (projectName)
        return `project "${projectName}"`
      else if (crawlingName)
        return `crawling project "${crawlingName}"`
      return '[unknown]'
    },
    tmxLabel() {
      if (this.segment?.left.documentOrigin)
        return `tmx file "${this.segment.left.documentOrigin}"`
      return null
    },
    pipelineName() {
      // TODO (no "pipelines" in enumsValues yet)
      return this.segment?.pipelineId
    },
    formattedScoreDistance() {
      return this.segment?.scoreDistance >= 0 ? round(this.segment.scoreDistance, 3) : 'N/A'
    },
    canOpenDoc() {
      return this.segment !== null && this.segment !== undefined && this.segment.alignedDocumentId !== undefined
    }
  },
  methods: {
    openDoc() {
      if (this.canOpenDoc) {
        const route = this.$router.resolve({path: `/segment-manager/aligned-document/${this.segment.alignedDocumentId}`});
        window.open(route.href, '_blank');
      }
    }
  }
}
</script>

<style scoped>

</style>
