<template>
  <v-dialog v-model="show" max-width="60%" persistent>
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Tag name" v-model="editedItem.name" :rules="rules.name" autofocus></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {cloneDeep, isEqual} from "lodash";
import {isDark, stringToColor} from "@/util/color";

const DEFAULT_TAG = {
  id: undefined,
  name: ''
}

export default {
  name: "TagDialog",
  props: {
    formTitle: {type: String, required: false},
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      isDark,
      stringToColor,
      editedItem: Object.assign({}, DEFAULT_TAG),
      originalItem: Object.assign({}, DEFAULT_TAG),
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      this.populateForm(newVal)
    },
  },
  computed: {
    hasBeenModified() {
      return !isEqual(this.editedItem, this.originalItem)
    },
    title() {
      return this.item === undefined || this.item === null || this.item === '' ? "Create Tag" : "Edit Tag"
    },
    rules() {
      return {
        name: [
          v => !!v || 'Required',
          v => /^[a-zA-Z0-9_ -]+$/.test(v) || 'Name contains invalid characters'
        ]
      }
    }
  },
  methods: {
    populateForm(item) {
      if (item !== null && item !== undefined)
        this.editedItem = {...DEFAULT_TAG, ...cloneDeep(item)}
      else
        this.editedItem = cloneDeep(DEFAULT_TAG)

      this.originalItem = cloneDeep(this.editedItem)
    },
    apply() {
      if (!this.$refs.form.validate())
        return
      if (this.hasBeenModified) {
        this.onApply(this.editedItem)
      } else
        this.onClose()
    }
  }
}
</script>

<style scoped>

</style>
