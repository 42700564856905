<template>
  <div class="fill-height">
    <crawling-dialog :show="dialog" :item="editedItem" :owners="owners" :groups="groups"
                     :sources="sources" :projectSources="projectSources"
                     :confidentiality-levels="confidentialityLevels" :project-permissions="projectPermissions"
                     :tags="tags" :domains="domains" :on-apply="save" :on-close="closeDialog"></crawling-dialog>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this crawling?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click="confirmDelete" :disabled="sending">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center">
        <v-col cols="12" :md="mdSize" class="fill-height d-flex flex-column">
          <d-data-table :loading="loading" :items="filteredItems" :headers="tableHeaders"
                        :items-per-page="itemsPerPage"
                        :footer-props="footerProps" sort-by="title" class="row-pointer"
                        @click:row="item => $router.push({path:`/crawlings/${item.id}`})"
                        :fill-height="!homePreview"
                        :hide-default-footer="homePreview" :disable-sort="homePreview">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Crawlings</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createItem" v-if="!homePreview">New crawling</v-btn>
              </v-toolbar>
              <table-filters v-if="!homePreview"
                             v-model="filteredItems"
                             :items="crawlings"
                             :owners="owners" :groups="groups"
                             :project-permissions="projectPermissions" :tags="tags"
                             :domains="domains" :projectSources="projectSources"
                             :confidentiality-levels="confidentialityLevels"></table-filters>
            </template>
            <template v-slot:item.owner="{item}">
              <v-icon v-if="item.ownerEnabled === false">mdi-lock</v-icon>
              {{ ownerById[item.ownerId].name }}
            </template>
            <template v-slot:item.projectSources="{item}">
              <v-chip-group>
                <v-chip v-for="source in item.projectSources" :key="source.id"
                        class="ma-2" x-small column
                        :color="stringToColor(sourceById[source].name)"
                        :dark="isDark(stringToColor(sourceById[source].name))">
                  {{ sourceById[source].name  }}
                </v-chip>
              </v-chip-group>
            </template>
            <template v-slot:item.confidentialityLevel="{item}">
              {{ confidentialityLevelById[item.confidentialityLevelId].name }}
            </template>
            <template v-slot:item.dateRange="{item}">
              {{ formatDateRange(item.dateRangeStart, item.dateRangeEnd) }}
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editItem(item)" v-if="item.userHasAdminPermission">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteItem(item)" v-if="item.userHasAdminPermission">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import CrawlingDialog from "@/components/parts/crawling-dialogs/CrawlingDialog";
import TableFilters from "@/components/parts/filters/TableFilters";
import DDataTable from "@/components/wrappers/DDataTable";
import {reduceById} from "@/util/js-utils";
import {formatDateRange} from "@/util/string-utils";

const defaultNewItem = {
  title: '',
  description: '',
  owner: null,
  confidentialityLevelId: null,
  dateRangeStart: null,
  dateRangeEnd: null,
  groupPermissions: []
}

export default {
  name: "Crawlings",
  props: {
    size: {type: Number, required: false, default: 8},
    homePreview: {type: Boolean, required: false, default: false}
  },
  components: {DDataTable, TableFilters, CrawlingDialog},
  data() {
    return {
      loading: false,
      hasError: false,
      error: null,
      dialog: false,
      dialogDelete: false,
      sending: false,
      editedItem: Object.assign({}, defaultNewItem),
      crawlings: [],
      owners: [],
      sources: [],
      projectSources: [],
      confidentialityLevels: [],
      groups: [],
      projectPermissions: [],
      tags: [],
      domains: [],
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },
      filteredItems: []
    }
  },
  computed: {
    formTitle() {
      return this.editedItem.id ? "Edit crawling" : "Create crawling"
    },
    ownerById() {
      return reduceById(this.owners)
    },
    sourceById() {
      return reduceById(this.projectSources)
    },
    confidentialityLevelById() {
      return reduceById(this.confidentialityLevels)
    },
    mdSize() {
      return this.homePreview ? 12 : 8
    },
    itemsPerPage() {
      return this.homePreview ? 10 : 50
    },
    tableHeaders() {
      const headers = [
        {text: 'Title', value: 'title'},
        {text: 'Owner', value: 'owner'},
        {text: 'Sources', value: 'projectSources'},
        {text: 'Confidentiality', value: 'confidentialityLevel'},
        {text: 'Date range', value: 'dateRange'},
        {text: 'ID', value: 'id'}
      ]
      if (!this.homePreview) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    fetchData() {
      this.loading = true
      this.hasError = false
      this.error = null
      this.crawlings = []
      this.filteredItems = []

      Promise.all([
        axios.get('/crawlings'),
        axios.get('/owners'),
        axios.get('/groups'),
        axios.get('/crawlings/permissions'),
        axios.get('/tags'),
        axios.get('/domains'),
        axios.get('/sources'),
        axios.get('/confidentiality-levels')
      ])
          .then(responses => {
            this.loading = false
            this.crawlings = responses[0].data
            this.filteredItems = this.crawlings
            this.owners = responses[1].data
            this.groups = responses[2].data
            this.projectPermissions = responses[3].data
            this.tags = responses[4].data
            this.domains = responses[5].data
            this.projectSources = responses[6].data
            this.confidentialityLevels = responses[7].data
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    closeDialog() {
      this.dialog = false
    },
    createItem() {
      this.editedItem = null
      this.dialog = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    confirmDelete() {
      this.sending = true

      axios.delete(`/crawlings/${this.editedItem.id}`)
          .then(() => {
            this.sending = false
            this.dialogDelete = false
            this.fetchData()
          })
          .catch(err => {
            this.showError(err)
            this.sending = false
          })
    },
    save(item) {
      this.sending = true

      const catchHandler = err => {
        this.showError(err)
        this.sending = false
      }

      const thenHandler = () => {
        this.sending = false
        this.dialog = false
        this.fetchData()
      }

      const data = {
        title: item.title,
        description: item.description,
        sources: item.sources,
        keywords: item.keywords,
        numSpiders: item.numSpiders,
        queuingInterval: item.queuingInterval,
        domainCrawlingInterval: item.domainCrawlingInterval,
        maxDepth: item.maxDepth,
        runPipelinesWhenFinished: item.runPipelinesWhenFinished,
        allowUpperLevelUrls: item.allowUpperLevelUrls,
        collectLingueeUrls: item.collectLingueeUrls,
        renderPages: item.renderPages,
        ownerId: item.ownerId,
        projectSources: item.projectSources,
        confidentialityLevelId: item.confidentialityLevelId,
        dateRangeStart: item.dateRangeStart,
        dateRangeEnd: item.dateRangeEnd,
        groupPermissions: item.groupPermissions,
        tags: item.tags,
        domains: item.domains
      }

      if (item.id)
        axios.put(`/crawlings/${item.id}`, data).then(thenHandler).catch(catchHandler)
      else
        axios.post('/crawlings', data).then(thenHandler).catch(catchHandler)
    },
    formatDateRange
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
