<template>
  <div class="fill-height">
    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" md="10" class="fill-height d-flex flex-column">
          <div class="d-flex justify-center">
            <h1 class="text-h3 d-inline">{{ pipelineName }}</h1>
            <v-divider vertical inset class="mx-4"></v-divider>
            <h2 class="text-h3 d-inline">Results</h2>
          </div>
          <d-data-table :items="items" :headers="headers" :loading="loading" class="mt-5 row-pointer" :items-per-page=50
                        :footer-props="footerProps">
            <template v-slot:item.document1Origin="props">
              {{ props.item.document1Origin }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-edit-dialog
                      :return-value.sync="props.item.document1Origin"
                      large persistent
                      @save="save(props.item, true)">
                      <v-icon>mdi-pencil</v-icon>
                      <template v-slot:input>
                        <v-form ref="formDoc1">
                          <v-text-field
                            v-model="props.item.document1Origin"
                            :rules="[required, maxLength]"
                            label="Edit"
                            single-line
                            counter
                          ></v-text-field>
                        </v-form>
                      </template>
                    </v-edit-dialog>
                  </v-btn>
                </template>
                <span>Edit document name</span>
              </v-tooltip>
            </template>
            <template v-slot:item.document2Origin="props">
              {{ props.item.document2Origin }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-edit-dialog
                      :return-value.sync="props.item.document2Origin"
                      large persistent
                      @save="save(props.item, false)">
                      <v-icon>mdi-pencil</v-icon>
                      <template v-slot:input>
                        <v-form ref="formDoc2">
                          <v-text-field
                            v-model="props.item.document2Origin"
                            :rules="[required, maxLength]"
                            label="Edit"
                            single-line
                            counter
                          ></v-text-field>
                        </v-form>
                      </template>
                    </v-edit-dialog>
                  </v-btn>
                </template>
                <span>Edit document name</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="navigateToDocument(item)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Open document editor</span>
              </v-tooltip>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "AlignerResults",
  components: {DDataTable},
  data() {
    return {
      error: null,
      hasError: false,
      loading: false,
      items: [],
      headers: [
        {text: 'ID', value: 'id', width: "10%"},
        {text: 'Document 1', value: 'document1Origin', width: "40%"},
        {text: 'Document 2', value: 'document2Origin', width: "40%"},
        {text: 'Go to document editor', value: 'actions', width: "10%"},
      ],
      footerProps: {
        itemsPerPageOptions: [50, 100, 200, -1]
      },
      maxLength: v => v.length <= 256 || 'Value too long',
      required: v => !!v || 'Required',
      snack: false,
      snackColor: '',
      snackText: '',
    }
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    loadAlignedDocuments() {
      if (this.loading)
        return

      this.error = null
      this.hasError = false
      this.loading = true

      axios.get(`/pipelines/${this.pipelineId}/aligned-documents`)
          .then(response => {
            this.loading = false
            this.items = response.data
                .sort((a, b) => a.id - b.id)
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    navigateToDocument(item) {
      this.$router.push({
        path: `/${this.getUrlPart}/${this.pipelineId}/document-editor`,
        query: {alignedDocumentId: item.id}
      })
    },
    save (item, isDoc1) {
      let formRef = isDoc1 ? this.$refs.formDoc1 : this.$refs.formDoc2
      if (!formRef.validate()) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Invalid data';
        return
      }
      let docId = isDoc1 ? item.document1Id : item.document2Id
      let docName = isDoc1 ? item.document1Origin : item.document2Origin
      axios.put(`/pipelines/${this.pipelineId}/documents/${docId}`, {name: docName})
          .then(() => {
            this.snack = true;
            this.snackColor = 'success';
            this.snackText = 'Data saved';
          }).catch(err => {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = err;
      })
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    crawlingId() {
      return this.$route.params.crawlingId
    },
    pipelineId() {
      return this.$route.params.pipelineId
    },
    pipelineName() {
      return this.$store.state.navigation.pipeline.pipelineTitle
    },
    isACrawlingPipeline() {
      return !!this.crawlingId
    },
    getUrlPart() {
      if (this.isACrawlingPipeline) {
        return `crawlings/${this.crawlingId}`
      } else {
        return `projects/${this.projectId}`
      }
    }
  },
  created() {
    this.loadAlignedDocuments()
  }
}
</script>

<style scoped>
</style>
