<template>
  <v-dialog persistent :value="show" max-width="80%" @click:outside="onClose()">
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          Document2Text Configuration
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col sm="12" md="10">
                <div class="d-flex">
                  <v-text-field outlined label="Stage Name" v-model="form.stageName"
                                placeholder="Document2Text" class="mr-2"></v-text-field>
                  <v-text-field outlined label="Input folder" v-model="form.inputFolder"
                                placeholder="input/fr" class="ml-2"></v-text-field>
                </div>
                <v-slider v-model="form.maxParallelPods" label="Max parallel pods" :thumb-size=24 thumb-label="always"
                          :min=1 :max=10></v-slider>

                <v-select v-model="form.processFormats" :items="processableFormats" label="Formats to process"
                          multiple :rules="rules.processFormats"></v-select>

                <v-tabs v-model="tab" class="mt-2">
                  <v-tab :disabled="!processPdf">PDF</v-tab>
                  <v-tab :disabled="!processDocx">DOCX</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <div class="d-flex flex-column">
                      <v-select multiple chips deletable-chips label="Languages (All if empty)"
                                v-model="form.pdf.languages" :items="languages"
                                :menu-props="{ top: false, offsetY: true }"></v-select>

                      <v-checkbox v-model="form.pdf.pdfDocxText"
                                  label="Use intermediary docx (pdf->docx->text)"
                                  class="align-self-center"></v-checkbox>

                      <v-radio-group v-model="form.pdf.parametersMode" label="Parameters mode" row>
                        <v-radio value="advanced" label="Advanced options"></v-radio>
                        <v-radio value="abbyyProfile" label="ABBYY profile"></v-radio>
                        <v-radio value="datalambicProfile" label="Datalambic profile"></v-radio>
                      </v-radio-group>

                      <v-select v-if="abbyyProfileMode" v-model="form.pdf.abbyyProfile" label="ABBYY profile"
                                :items="abbyyPredefinedProfiles" :rules="rules.abbyyProfile"></v-select>

                      <v-select v-else-if="datalambicProfileMode" v-model="form.pdf.datalambicProfile"
                                label="Datalambic profile" :items="datalambicPredefinedProfiles" item-text="name"
                                item-value="key" :rules="rules.datalambicProfile"></v-select>

                      <div v-else-if="advancedOptionsMode" class="pa-3">
                        <v-btn @click="downloadUserManual" :disabled="downloading" outlined>
                          Download user manual
                        </v-btn>

                        <div v-for="category of advancedOptionsCategories" :key="category.name" class="mt-5">
                          <div class="text-h6">{{ category.name }}</div>
                          <div v-for="field of category.fields" :key="field.name">
                            <v-switch v-if="field.type === 'bool'" v-model="form.pdf.advanced[field.name]"
                                      :label="defineLabel(field)"></v-switch>
                            <v-text-field v-else-if="field.type === 'int' || field.type === 'double'"
                                          v-model="form.pdf.advanced[field.name]" type="number"
                                          :label="defineLabel(field)"></v-text-field>
                            <v-text-field v-else-if="field.type === 'str'"
                                          v-model="form.pdf.advanced[field.name]"
                                          :label="defineLabel(field)"></v-text-field>
                            <v-select v-else-if="field.enum" v-model="form.pdf.advanced[field.name]"
                                      :label="defineLabel(field)" :items="enums[field.enum]"></v-select>
                          </div>
                        </div>

                        <div v-for="field of exportParams" :key="field.name">
                          <v-switch v-if="field.type === 'bool'" v-model="form.pdf.advanced[field.name]"
                                    :label="defineLabel(field)"></v-switch>
                          <v-text-field v-else-if="field.type === 'int' || field.type === 'double'"
                                        v-model="form.pdf.advanced[field.name]" type="number"
                                        :label="defineLabel(field)"></v-text-field>
                          <v-text-field v-else-if="field.type === 'str'" v-model="form.pdf.advanced[field.name]"
                                        :label="defineLabel(field)"></v-text-field>
                          <v-select v-else-if="field.enum" v-model="form.pdf.advanced[field.name]"
                                    :label="defineLabel(field)" :items="enums[field.enum]"></v-select>
                        </div>
                      </div>
                    </div>
                  </v-tab-item>

                  <v-tab-item :disabled="!processDocx">
                    <div class="d-flex flex-column">
                      <v-switch v-model="form.docx.exportTables" label="Export tables"></v-switch>
                      <v-switch v-model="form.docx.exportTableOfContents" label="Export table of contents"></v-switch>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="onClose">Close</v-btn>
          <v-btn color="success" text type="submit">Apply & Save</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  advancedOptionsCategories,
  enums,
  predefinedProfiles,
  exportParams,
  abbyyDefaults,
  datalambicProfiles
} from "@/util/abbyy-params";
import {downloadFile, objectWithoutKeys} from "@/util/js-utils";
import {LANGUAGES} from "@/util/enums";

const defaultMaxParallelPods = 3

export default {
  name: "Document2TextDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      error: null,
      hasError: false,
      downloading: false,
      languages: LANGUAGES,
      enums,
      abbyyPredefinedProfiles: predefinedProfiles,
      advancedOptionsCategories,
      exportParams: exportParams.txt,
      processableFormats: ['pdf', 'docx'],
      tab: 'pdf',
      rules: {
        processFormats: [
          v => !!v && v.length > 0 || 'Select at least 1'
        ],
        abbyyProfile: [
          v => !!v || 'Required'
        ],
        datalambicProfile: [
          v => !!v || 'Required'
        ]
      },
      form: {
        stageName: '',
        inputFolder: 'input/fr',
        maxParallelPods: defaultMaxParallelPods,
        processFormats: ['pdf', 'docx'],
        pdf: {
          parametersMode: 'datalambicProfile',
          languages: [],
          abbyyProfile: 'TextExtraction_Accuracy',
          datalambicProfile: 'pdfDocxText',
          pdfDocxText: true,
          advanced: Object.assign({}, abbyyDefaults)
        },
        docx: {
          exportTables: true,
          exportTableOfContents: false
        }
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal && this.item)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (newVal && this.show)
        this.populateForm(newVal)
    },
    'form.processFormats'(newVal) {
      if (newVal.length === 0)
        this.tab = null
      else if (!newVal.includes(this.tab))
        this.tab = newVal[0]
    }
  },
  methods: {
    showError(error) {
      console.error(error)
      this.error = error
      this.hasError = true
    },
    defineLabel(field) {
      if (field.label)
        return field.label

      return field.name.substring(0, 1).toUpperCase() +
          field.name.substring(1).toLowerCase().replaceAll('_', ' ')
    },
    downloadUserManual() {
      if (this.downloading)
        return

      this.downloading = true

      downloadFile('/abbyy-user-guide', 'FREngine12UserGuide.pdf')
          .then(() => {
            this.downloading = false
          })
          .catch(err => {
            this.showError(err)
            this.downloading = false
          })
    },
    populateForm(item) {
      const {config: {env, name, inputFolder, maxParallelPods}} = item
      const languages = env.LANGUAGES?.split(',') ?? []

      this.form = {
        stageName: name,
        inputFolder,
        maxParallelPods: maxParallelPods ?? defaultMaxParallelPods,
        processFormats: env.PROCESS_FORMATS?.split(',') ?? ['pdf', 'docx'],
        pdf: {
          languages,
          parametersMode: env.LOAD_PREDEFINED_PROFILE ? 'abbyyProfile' :
              (env.DATALAMBIC_PROFILE ? 'datalambicProfile' : 'advanced'),
          abbyyProfile: env.LOAD_PREDEFINED_PROFILE ?? 'TextExtraction_Accuracy',
          datalambicProfile: env.DATALAMBIC_PROFILE ?? 'simplePdf',
          pdfDocxText: env.PDF_DOCX_TEXT,
          advanced: Object.assign({}, abbyyDefaults,
              objectWithoutKeys(env, ['LOAD_PREDEFINED_PROFILE', 'FILE_EXPORT_FORMAT']))
        },
        docx: {
          exportTables: env.DOCX_EXPORT_TABLES ?? true,
          exportTableOfContents: env.DOCX_EXPORT_TABLE_OF_CONTENTS ?? false
        }
      }
    },
    apply() {
      if (!this.$refs.form.validate())
        return

      const env = {PROCESS_FORMATS: this.form.processFormats.join(',')}

      if (this.processPdf) {
        env.PDF_DOCX_TEXT = this.form.pdf.pdfDocxText
        env.FILE_EXPORT_FORMAT = 'TextUnicodeDefaults'
        if (this.advancedOptionsMode)
          Object.assign(env, this.form.pdf.advanced)
        else if (this.abbyyProfileMode)
          Object.assign(env, {LOAD_PREDEFINED_PROFILE: this.form.pdf.abbyyProfile})
        else if (this.datalambicProfileMode)
          Object.assign(env, {DATALAMBIC_PROFILE: this.form.pdf.datalambicProfile})

        if (this.form.pdf.languages.length > 0)
          env.LANGUAGES = this.form.pdf.languages.join(',')
        else
          env.LANGUAGES = this.languages.join(',')
      }
      if (this.processDocx) {
        env.DOCX_EXPORT_TABLES = this.form.docx.exportTables
        env.DOCX_EXPORT_TABLE_OF_CONTENTS = this.form.docx.exportTableOfContents
      }

      const {maxParallelPods, inputFolder, stageName} = this.form

      const config = {
        ...this.item.config,
        name: stageName,
        maxParallelPods,
        inputFolder,
        env
      }

      this.onApply(config)
    }
  },
  computed: {
    advancedOptionsMode() {
      return this.form.pdf.parametersMode === 'advanced'
    },
    abbyyProfileMode() {
      return this.form.pdf.parametersMode === 'abbyyProfile'
    },
    datalambicProfileMode() {
      return this.form.pdf.parametersMode === 'datalambicProfile'
    },
    datalambicPredefinedProfiles() {
      return Object.keys(datalambicProfiles).map(key => ({key, name: datalambicProfiles[key].name}))
    },
    processPdf() {
      return this.form.processFormats.includes('pdf')
    },
    processDocx() {
      return this.form.processFormats.includes('docx')
    }
  }
}
</script>

<style scoped>

</style>
