<template>
  <div>
    <h2 v-if="emptyFilters" class="text-h3 text-center">
      Please apply a filter
    </h2>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="10" lg="8">
          <v-stepper v-model="step" vertical>
            <v-stepper-step :step="1" :complete="step > 1" :editable="true">
              Action selection
            </v-stepper-step>
            <v-stepper-content :step="1">
              <v-radio-group v-model="action" column label="Action to be applied on the filtered segments">
                <v-radio label="Export" :value="ACTIONS.EXPORT"></v-radio>
                <v-radio label="Find & Replace" :value="ACTIONS.FIND_REPLACE"></v-radio>
                <v-radio label="Term-Extractor" :value="ACTIONS.TERM_EXTRACTOR"></v-radio>
              </v-radio-group>
              <v-btn color="primary" @click="nextStep" class="my-3" :loading="loading" :disabled="loading">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :step="2" :complete="step > 2" :editable="true">
              Filters & results recapitulation
            </v-stepper-step>
            <v-stepper-content :step="2">
              <div>
                <h6 class="v-label">Selected filters (Read-Only)</h6>
                <div class="d-flex">
                  <filter-bloc :filter-bloc="appliedFilters.filters" :id="0" :enums-values="enumsValues"
                               :on-modify="noop" :show-dialog="noop" :depth="0" :disabled="true"></filter-bloc>
                </div>
              </div>
              <div class="mt-3">
                <h6 class="v-label">Filtered alignments</h6>
                <div>
                  <v-icon color="info" class="review-icon">mdi-information</v-icon>
                  {{ stats.filtered }} alignment have been filtered out of a total of {{ stats.total }}
                </div>
                <div v-if="stats.stats.confidential.value > 0">
                  <v-icon color="warning" class="review-icon">mdi-alert</v-icon>
                  {{ stats.stats.confidential.value }} alignments are flagged as confidential
                </div>
                <div v-if="stats.stats.superConfidential.value > 0">
                  <v-icon color="error" class="review-icon">mdi-alert</v-icon>
                  {{ stats.stats.superConfidential.value }} alignments are flagged as super confidential !
                </div>
                <div v-if="Object.keys(stats.stats.owners.value).length > 1">
                  <v-icon color="warning" class="review-icon">mdi-alert</v-icon>
                  The alignments belong to multiple owners
                </div>

              </div>
              <v-btn color="primary" @click="nextStep" class="my-3" :loading="loading" :disabled="loading">
                Continue
              </v-btn>
            </v-stepper-content>

            <export v-if="action===ACTIONS.EXPORT" :applied-filters="appliedFilters" :data="enumsValues" :step="step"
                    :next-step="nextStep" :reset="resetState" :show-error="showError"></export>
            <find-replace v-if="action===ACTIONS.FIND_REPLACE"
                          :applied-filters="appliedFilters" :stats="stats" :data="enumsValues" :step="step"
                          :next-step="nextStep" :reset="resetState" :show-error="showError"></find-replace>
            <term-extractor v-if="action===ACTIONS.TERM_EXTRACTOR" :applied-filters="appliedFilters" :data="enumsValues" :step="step"
                    :next-step="nextStep" :reset="resetState" :show-error="showError"></term-extractor>
          </v-stepper>

          <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterBloc from "@/components/parts/filters/FilterBloc";
import Export from "@/components/parts/segment-manager/actions/Export";
import FindReplace from "@/components/parts/segment-manager/actions/FindReplace";
import TermExtractor from "@/components/parts/segment-manager/actions/TermExtractor"

const ACTIONS = {
  EXPORT: 'EXPORT',
  DUPLICATE: 'DUPLICATE',
  FIND_REPLACE: 'FIND_REPLACE',
  METADATA: 'METADATA',
  TERM_EXTRACTOR: 'TERM_EXTRACTOR'
}

export default {
  name: "Actions",
  components: {TermExtractor, FindReplace, Export, FilterBloc},
  props: {
    stats: {type: Object, required: true},
    appliedFilters: {type: Object, required: true},
    components: {FilterBloc},
    enumsValues: {type: Object, required: true}
  },
  data() {
    return {
      step: 1,
      action: ACTIONS.EXPORT,
      loading: false,
      hasError: false,
      error: null,
      ACTIONS
    }
  },
  computed: {
    emptyFilters() {
      return Object.keys(this.appliedFilters).length === 0
    }
  },
  methods: {
    noop() {
      // Noop function used for callbacks that can never occur
    },
    nextStep() {
      this.step = this.step + 1
    },
    resetState() {
      this.step = 1
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
  }
}
</script>

<style scoped>
</style>
