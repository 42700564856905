import {stringToColor} from "@/util/color";
import {round} from "lodash";

const STATS_TYPES = {
    SINGLE_STATS: 'singleStat', // value: Number
    PIE: 'pie', // value: {[name]: Number, [name2]: Number, ...}
    BAR: 'bar' // value: [{qty: Number, end: end coordinate}, ...]
}

const statWithTitle = ([key, value]) => ({...value, title: ('' + key).split(/(?=[A-Z])/).join(' ')})

export function singleStatsWithTitle(serverData) {
    if (!serverData.stats)
        return []

    return Object.entries(serverData.stats)
        .filter(entry => entry[1].type === STATS_TYPES.SINGLE_STATS)
        .map(statWithTitle)
}

export function graphicsStatsWithTitle(serverData) {
    if (!serverData.stats)
        return []

    return Object.entries(serverData.stats)
        .filter(entry =>
            (entry[1].type === STATS_TYPES.PIE || entry[1].type === STATS_TYPES.BAR)
            && Object.keys(entry[1].value).length > 0)
        .map(statWithTitle)
}

export function statToPie(stat, enumsValues) {
    if (enumsValues[stat.title]) {
        enumsValues = enumsValues[stat.title]
            .reduce((obj, item) => Object.assign(obj, {[item.id]: item.name}), {})
    } else
        enumsValues = undefined

    const labels = Object.keys(stat.value)
        .map(val => {
            if (!isNaN(parseInt(val)))
                return enumsValues[val]

            return val
        })
    const values = Object.values(stat.value)

    return {
        labels: labels,
        datasets: [
            {
                backgroundColor: labels.map(label => (stringToColor(label))),
                data: values
            }
        ]
    }
}

export function statToBar(stat) {
    const values = stat.value.map(range => range.qty)
    const labels = stat.value.map(range => round(range.end, 2))

    return {
        labels: labels,
        datasets: [
            {
                backgroundColor: '#2196F3',
                data: values
            }
        ]
    }
}
