<template>
  <div class="fill-height">
    <document-editor-context-menu :context-menu-items="contextMenuItems" :show="contextualMenu.show"
                                  :mouse-x="contextualMenu.mouseX"
                                  :mouse-y="contextualMenu.mouseY"></document-editor-context-menu>

    <d-data-table :loading="loading" :items="alignmentsWithState" :headers="headers"
                  :value="selectedAlignments" item-key="id" show-select :footer-props="footerProps"
                  @input="onSelectionChanged" @contextmenu:row="showContextualMenu" :options="paginationOptions"
                  @update:options="onPaginationOptionsChanged" :items-per-page="paginationOptions.itemsPerPage"
                  :server-items-length="totalItems" page-select>
      <template v-slot:top>
        <v-toolbar flat class="my-1">
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="mr-5 d-inline-block">
                  <v-btn color="primary" @click="actions.refresh()" :loading="loading" :disabled="loading">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Reload</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
              <v-btn class="mx-1" :disabled="!allowedActions.add || loading" @click="actions.add()"
                     v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add alignment (unavailable for now)</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
              <v-btn class="mx-1" :disabled="!allowedActions.remove || loading"
                     @click="actions.remove(selectedAlignments)"
                     v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Remove alignment</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
              <v-btn class="mx-1" :disabled="!allowedActions.validate || loading"
                     @click="actions.validate(selectedAlignments)" v-on="on">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Validate alignment</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
              <v-btn class="mx-1" :disabled="!allowedActions.edit || loading"
                     @click="actions.modify(selectedAlignments)"
                     v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit alignment</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="ml-5 mr-1 d-inline-block">
                  <v-btn :disabled="!allowedActions.reset || loading" @click="actions.reset(selectedAlignments)">
                    <v-icon>mdi-undo</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Reset</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="mx-1 d-inline-block">
                  <v-btn :disabled="!allowedActions.merge || loading" @click="actions.merge(selectedAlignments)">
                    <v-icon>mdi-arrow-collapse-vertical</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Merge</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="mx-1 d-inline-block">
                  <v-btn :disabled="!allowedActions.split || loading" @click="actions.split(selectedAlignments)">
                    <v-icon>mdi-arrow-split-horizontal</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Split</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="ml-5 mr-1 d-inline-block">
                  <v-btn :disabled="!allowedActions.toggleUncertain || loading"
                         @click="actions.toggleUncertain(selectedAlignments)">
                    <v-icon>mdi-help</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Uncertain</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="ml-5 mr-5 d-inline-block">
                  <v-btn :disabled="!allowedActions.showWithoutFilters || loading"
                         @click="actions.showWithoutFilters(selectedAlignments)">
                    <v-icon>mdi-filter-off</v-icon>
                  </v-btn>
                </span>
            </template>
            <span>Show without filters</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on">
                  <v-btn class="ml-5 mr-1" color="primary" :disbaled="loading" @click="actions.openBatchDialog()" outlined>
                    Batch Rules
                  </v-btn>
                </span>
            </template>
            <span>Add/edit the Batch rules</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on">
                    <v-btn class="ml-5 mr-1" color="primary" @click="actions.showFilters">
                      {{ numAppliedFilters }} filter(s) applied
                    </v-btn>
                </span>
            </template>
            <span>Filter the alignments</span>
          </v-tooltip>
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on}">
                <span v-on="on" class="ml-5 mr-1">
                  <v-btn :disabled="loading" color="primary"
                         @click="actions.nextAlignmentWithoutRules()">
                    Next
                  </v-btn>
                </span>
            </template>
            <span>Jump to the first alignment without any rules</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.alignedDocumentId="{item}">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.alignedDocumentId }}
            </span>
          </template>
          <div>Doc 1: {{ enumsValues.pipelineAlignedDocumentById[item.alignedDocumentId].document_1_id }}</div>
          <div>Doc 2: {{ enumsValues.pipelineAlignedDocumentById[item.alignedDocumentId].document_2_id }}</div>
        </v-tooltip>
      </template>
      <template v-slot:item.state="{item}">
        <v-tooltip v-if="item.state !== ALIGNMENT_STATES.NO_STATE" top :open-delay=350>
          <template v-slot:activator="{on, attrs}">
            <v-icon :color="item.state.color" medium v-bind="attrs" v-on="on">
              {{ item.state.icon }}
            </v-icon>
            <span class="uncertain-wrapper">
              <v-icon v-if="item.uncertain" small class="uncertain-icon">mdi-help</v-icon>
            </span>
          </template>
          <span>{{ item.state.name }}{{ item.uncertain ? ' (uncertain)' : '' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.text1="{item}">
        {{
          item.appliedRule && REMOVE_RULE_TYPE_IDS.has(item.appliedRule.type)
              ? item.initialText1
              : item.modifiedText1
        }}
      </template>
      <template v-slot:item.text2="{item}">
        {{
          item.appliedRule && REMOVE_RULE_TYPE_IDS.has(item.appliedRule.type)
              ? item.initialText2
              : item.modifiedText2
        }}
      </template>
      <template v-slot:item.scoreDistance="{item}">
        {{ formatScoreDistance(item.scoreDistance) }}
      </template>
      <template v-slot:footer.prepend>
        {{ selectedAlignments.length }} alignments selected
      </template>
    </d-data-table>
  </div>
</template>

<script>
import {formatScoreDistance} from "@/util/js-utils"
import DDataTable from "@/components/wrappers/DDataTable"
import {ALIGNMENT_STATES, REMOVE_RULE_TYPE_IDS, RULE_TYPE_BY_ID} from "@/util/document-editor"
import DocumentEditorContextMenu from "./DocumentEditorContextMenu"
import {cloneDeep} from "lodash";

export default {
  name: "DocumentEditorTable",
  components: {DocumentEditorContextMenu, DDataTable},
  props: {
    alignments: {type: Array, required: true},
    numAppliedFilters: {type: Number, required: true},
    loading: {type: Boolean, required: true},
    actions: {type: Object, required: true},
    allowedActions: {type: Object, required: true},
    selectedAlignments: {type: Array, required: true},
    totalItems: {type: Number, required: true},
    enumsValues: {type: Object, required: true},
    paginationOptions: {type: Object, required: true}
  },
  data() {
    return {
      REMOVE_RULE_TYPE_IDS,
      ALIGNMENT_STATES,
      formatScoreDistance,
      contextualMenu: {
        show: false,
        mouseX: 0,
        mouseY: 0,
      },
      headers: [
        {text: 'Doc ID', value: 'alignedDocumentId'},
        {text: 'State', value: 'state'},
        {text: 'Index 1', value: 'idx1', sortable: false},
        {text: 'Index 2', value: 'idx2', sortable: false},
        {text: 'Text 1', value: 'text1', width: '40%', sortable: false},
        {text: 'Text 2', value: 'text2', width: '40%', sortable: false},
        {text: 'Score/distance', value: 'scoreDistance'}
      ],
      footerProps: {
        itemsPerPageOptions: [50, 100, 200, 500, -1]
      },
    }
  },
  computed: {
    contextMenuItems() {
      return [
        {icon: "mdi-close", title: "Remove", action: this.actions.remove, disabled: !this.allowedActions.remove},
        {icon: "mdi-check", title: "Validate", action: this.actions.validate, disabled: !this.allowedActions.validate},
        {icon: "mdi-pencil", title: "Edit", action: this.actions.modify, disabled: !this.allowedActions.edit},
        {icon: "mdi-undo", title: "Reset", action: this.actions.reset, disabled: !this.allowedActions.reset},
        {
          icon: "mdi-arrow-collapse-vertical",
          title: "Merge",
          action: this.actions.merge,
          disabled: !this.allowedActions.merge
        },
        {
          icon: "mdi-arrow-split-horizontal",
          title: "Split",
          action: this.actions.split,
          disabled: !this.allowedActions.split
        },
        {
          icon: "mdi-help",
          title: "Uncertain",
          action: this.actions.toggleUncertain,
          disabled: !this.allowedActions.toggleUncertain
        }
      ]
    },
    alignmentsWithState() {
      return this.alignments.map(value => {
        const res = cloneDeep(value)
        res.state = value.appliedRule ? RULE_TYPE_BY_ID[value.appliedRule?.type].state : ALIGNMENT_STATES.NO_STATE
        return res
      })
    }
  },
  methods: {
    showContextualMenu(e, selection) {
      e.preventDefault();
      this.contextualMenu.show = false;
      this.contextualMenu.mouseX = e.clientX;
      this.contextualMenu.mouseY = e.clientY;
      this.$nextTick(() => this.contextualMenu.show = true);
      if (selection !== undefined && selection.item !== undefined && !this.selectedAlignments.includes(selection.item))
        this.selectedAlignments.push(selection.item)
    },
    onSelectionChanged(newVal) {
      this.$emit('update:selectedAlignments', newVal)
    },
    onPaginationOptionsChanged(newVal) {
      this.$emit('update:paginationOptions', newVal)
    }
  }
}
</script>

<style scoped>
.uncertain-wrapper {
  width: 0;
  height: 0;
  position: relative;
  top: -5px;
  left: -5px;
}

.uncertain-icon {
  position: absolute;
  color: #555555;
}
</style>
