<template>
  <v-dialog v-model="show" :max-width="maxWidth" persistent>
    <v-form @submit.prevent="onApply(item)" ref="form">
      <v-card>
        <v-card-title class="headline">
          Are you sure you want to delete this rule ?
        </v-card-title>
        <v-card-text v-if="item">
          <p v-if="item.isGlobal">This is a global rule. It is used by all the pipelines.</p>
          <p v-else>
            This rule is used by {{ item.numAffectedProjects }} projects and {{ item.numAffectedPipelines }}
            pipelines.
          </p>
          <p>Are you sure you want to delete it ?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onClose">Cancel</v-btn>
          <v-btn v-if="onNo" text @click="onNo">No</v-btn>
          <v-btn color="error" text type="submit">DELETE</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

export default {
  name: "TokenizerRulesDeleteDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    onNo: {type: Function, required: false},
    item: {type: Object, default: null},
    maxWidth: {type: [String, Number], default: '550px'}
  },
}
</script>

<style scoped>
</style>
