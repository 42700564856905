<template>
  <h2 v-if="emptyStats" class="text-h3 text-center">
    Please apply a filter
  </h2>
  <h2 v-else-if="stats.filtered === 0" class="text-h3 text-center">
    No alignments match the applied filters
  </h2>
  <v-container v-else fluid fill-height>
    <v-row>
      <v-col cols="12" md="3">
        <v-sheet elevation="4" rounded outlined>
          <simple-stats :stats="simpleStats" :filtered="stats.filtered"></simple-stats>
        </v-sheet>
      </v-col>
      <v-col>
        <graphics :stats="graphicsStats" :enums-values="enumsValues" :filtered="stats.filtered"
                  :total="stats.total"></graphics>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Graphics from "@/components/parts/segment-manager/stats/Graphics";
import SimpleStats from "@/components/parts/segment-manager/stats/SimpleStats";
import {graphicsStatsWithTitle, singleStatsWithTitle} from "@/components/parts/segment-manager/stats/stats-utils";

export default {
  name: "Stats",
  components: {SimpleStats, Graphics},
  props: {
    stats: {type: Object, required: true},
    enumsValues: {type: Object, required: true}
  },
  computed: {
    emptyStats() {
      return Object.keys(this.stats).length === 0
    },
    graphicsStats() {
      return graphicsStatsWithTitle(this.stats)
    },
    simpleStats() {
      return singleStatsWithTitle(this.stats)
    }
  }
}
</script>

<style scoped>
</style>
