<template>
  <v-dialog persistent :value="show" max-width="80%" @click:outside="onClose()">
    <v-card>
      <v-card-title>
        Aligner Configuration
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col sm="12" md="10">
              <v-text-field outlined label="Stage Name" v-model="form.stageName" placeholder="Aligner"
                            hide-details></v-text-field>
              <v-slider v-model="form.maxParallelPods" label="Max parallel pods" :thumb-size=24 thumb-label="always"
                        :min=1 :max=10 class="mt-8"></v-slider>
              <div class="d-flex justify-center">
                <v-radio-group v-model="form.alignerType" label="Aligner" row>
                  <v-radio value="vecalign" label="Vecalign"></v-radio>
                  <v-radio value="hunalign" label="Hunalign"></v-radio>
                </v-radio-group>
              </div>
              <v-text-field v-if="form.alignerType === 'hunalign'" outlined label="Dictionary file path (optional)"
                            v-model="form.hunalignDictFile"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="onClose">Close</v-btn>
        <v-btn color="success" text @click="apply">Apply & Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AlignerDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      form: {
        stageName: '',
        maxParallelPods: 1,
        alignerType: 'vecalign',
        hunalignDictFile: null
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal && this.item)
        this.populateForm(this.item)
    },
    node(newVal) {
      if (newVal && this.show)
        this.populateForm(newVal)
    }
  },
  methods: {
    populateForm(item) {
      const {config: {env, name, maxParallelPods}} = item

      this.form = {
        stageName: name,
        maxParallelPods,
        alignerType: env.ALIGNER_TYPE,
        hunalignDictFile: env.HUNALIGN_DICT_FILE
      }
    },
    apply() {
      const {
        maxParallelPods,
        stageName,
        alignerType,
        hunalignDictFile
      } = this.form

      const env = {ALIGNER_TYPE: alignerType}

      if (alignerType === 'hunalign')
        env.HUNALIGN_DICT_FILE = hunalignDictFile

      const config = {
        ...this.item.config,
        name: stageName,
        maxParallelPods,
        env
      }

      this.onApply(config)
    }
  }
}
</script>

<style scoped>

</style>
