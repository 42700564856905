import VueRouter from "vue-router";
import Vue from "vue";
import store from "@/store"
import * as action_types from "@/store/action-types"
import Home from "@/components/pages/Home";
import Error404 from "@/components/pages/Error404";
import Login from "@/components/pages/Login";
import Projects from "@/components/pages/projects-pipelines/Projects";
import Project from "@/components/pages/projects-pipelines/Project";
import FileBrowser from "@/components/pages/projects-pipelines/FileBrowser";
import Pipeline from "@/components/pages/projects-pipelines/Pipeline";
import DocumentEditor from "@/components/pages/projects-pipelines/DocumentEditor";
import AlignerResults from "@/components/pages/projects-pipelines/AlignerResults";
import Users from "@/components/pages/manage/Users";
import Groups from "@/components/pages/manage/Groups";
import Domains from "@/components/pages/manage/Domains";
import Tags from "@/components/pages/manage/Tags";
import EditAccount from "@/components/pages/manage/EditAccount";
import Owners from "@/components/pages/manage/Owners";
import SegmentManager from "@/components/pages/segment-manager/SegmentManager";
import Crawlings from "@/components/pages/crawlings/Crawlings";
import Crawling from "@/components/pages/crawlings/Crawling";
import CrawlingResults from "@/components/pages/crawlings/CrawlingResults";
import AlignedDocument from "@/components/pages/segment-manager/AlignedDocument";
import PipelineStagesIO from "@/components/pages/projects-pipelines/stages-io/PipelineStagesIO";
import PipelineStagesIOViewer from "@/components/pages/projects-pipelines/stages-io/PipelineStagesIOViewer";
import TokenizerRulesTabs from "@/components/pages/manage/TokenizerRulesTabs";
import Chnell from "@/components/pages/chnell/Chnell"
import {SET_LAST_ROUTE} from "./store/action-types";
import Sources from "@/components/pages/manage/Sources";
import DownloadTerms from "@/components/pages/segment-manager/DownloadTerms"

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated)
        next()
    else if (store.state.lastRoute)
        next(store.state.lastRoute.path)
    else
        next('/')
}

const isAuthorized = (to, from, next) => {
    const permissions = to.meta.permissions
    if (permissions !== undefined && permissions !== [] && store.getters.getPermissions !== undefined) {
        let authorized = false
        for (let perm of permissions)
            if (store.getters.getPermissions[perm]) authorized = true
        if (!authorized) next(from)
    }

    if (store.state.auth.mustChangePassword && store.getters.isAuthenticated && to.path !== "/edit-account")
        next('/edit-account')

    if (store.getters.isAuthenticated) {
        next()
    } else {
        store.dispatch(SET_LAST_ROUTE, to).then(() => {
            next('/login')
        })
    }
}

const logout = (to, from, next) => {
    store.dispatch(action_types.LOGOUT_REQUEST)
        .then(() => next('/login'))
}

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/logout',
            beforeEnter: logout
        },
        {
            path: '/login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/home',
            component: Home,
            permissions: [],
            meta: {breadCrumb: 'Home'},
            beforeEnter: isAuthorized
        },
        {
            path: '/edit-account',
            component: EditAccount,
            beforeEnter: isAuthorized
        },


        // Management components
        {
            path: '/manage',
            beforeEnter: isAuthorized,
            component: {render: c => c('router-view')},
            meta: {breadCrumb: 'Manage', disabled: true},
            children: [
                {
                    path: '',
                    component: Error404,
                    beforeEnter: isAuthorized
                },
                {
                    path: 'users',
                    component: Users,
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Users', permissions: ['admin_users']},
                },
                {
                    path: 'groups',
                    component: Groups,
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Groups', permissions: ['admin_groups']},
                },
                {
                    path: 'owners',
                    component: Owners,
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Owners', permissions: ['admin_owners']},
                },
                {
                    path: 'domains',
                    component: Domains,
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Domains', permissions: ['admin_domains']},
                },
                {
                    path: 'tags',
                    component: Tags,

                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Tags', permissions: ['admin_tags']},
                },
                {
                    path: 'tokenizer-rules',
                    component: TokenizerRulesTabs,

                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Tokenizer Rules', permissions: ['admin_tokenizer-rules']},
                },
                {
                    path: 'sources',
                    component: Sources,

                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Sources', permissions: ['admin_sources']},
                },
            ],
        },
        // Chnell
        {
            path: '/chnell',
            component: Chnell,
            beforeEnter: isAuthorized,
            meta: {breadCrumb: 'CHnell'}
        },
        // Crawlings
        {
            path: '/crawlings',
            component: {render: c => c('router-view')},
            beforeEnter: isAuthorized,
            meta: {breadCrumb: 'Crawlings'},
            children: [
                {
                    path: '',
                    component: Crawlings,
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: 'Details'},
                },
                {
                    path: ':crawlingId',
                    meta: {breadCrumb: store => store.state.navigation.crawling.crawlingTitle},
                    component: {render: c => c('router-view')},
                    children: [
                        {
                            path: '',
                            component: Crawling,
                            beforeEnter: isAuthorized,
                        },
                        {
                            path: 'file-browser/:path*',
                            component: FileBrowser,
                            beforeEnter: isAuthorized,
                            meta: {breadCrumb: 'File browser'},
                        },
                        {
                            path: 'urls',
                            component: CrawlingResults,
                            beforeEnter: isAuthorized,
                            meta: {breadCrumb: 'Collected urls'},
                        },
                        {
                            path: ':pipelineId',
                            component: {render: c => c('router-view')},
                            beforeEnter: isAuthorized,
                            meta: {breadCrumb: store => store.state.navigation.pipeline.pipelineTitle},
                            children: [
                                {
                                    path: '',
                                    component: Pipeline,
                                    beforeEnter: isAuthorized
                                },
                                {
                                    path: 'file-browser/:path*',
                                    component: FileBrowser,
                                    beforeEnter: isAuthorized,
                                    meta: {breadCrumb: 'File browser'},
                                },
                                {
                                    path: 'io',
                                    component: {render: c => c('router-view')},
                                    beforeEnter: isAuthorized,
                                    children: [
                                        {
                                            path: '',
                                            component: Error404,
                                            beforeEnter: isAuthorized
                                        },
                                        {
                                            path: 'aligner',
                                            component: AlignerResults,
                                            beforeEnter: isAuthorized,
                                            meta: {breadCrumb: 'Pipeline results'}
                                        },
                                        {
                                            path: ':stageName',
                                            component: {render: c => c('router-view')},
                                            beforeEnter: isAuthorized,
                                            meta: {breadCrumb: 'Stage Input/Output'},
                                            children: [
                                                {
                                                    path: '',
                                                    component: PipelineStagesIO,
                                                    beforeEnter: isAuthorized
                                                },
                                                {
                                                    path: ':documentId',
                                                    component: PipelineStagesIOViewer,
                                                    beforeEnter: isAuthorized,
                                                    meta: {breadCrumb: 'IO Comparison'},
                                                },
                                            ]
                                        },
                                    ]
                                },
                                {
                                    path: 'document-editor',
                                    component: DocumentEditor,
                                    beforeEnter: isAuthorized,
                                    meta: {breadCrumb: 'Document Editor'}
                                }
                            ]
                        },
                    ]
                }
            ]
        },

        // Projects, pipelines, file-browser and rules-applied
        {
            path: '/projects',
            component: {render: c => c('router-view')},
            beforeEnter: isAuthorized,
            meta: {breadCrumb: 'Projects'},
            children: [
                {
                    path: '',
                    component: Projects,
                    beforeEnter: isAuthorized
                },
                {
                    path: ':projectId',
                    component: {render: c => c('router-view')},
                    beforeEnter: isAuthorized,
                    meta: {breadCrumb: store => store.state.navigation.project.projectTitle},
                    children: [
                        {
                            path: '',
                            component: Project,
                            beforeEnter: isAuthorized
                        },
                        {
                            path: 'file-browser/:path*',
                            component: FileBrowser,
                            beforeEnter: isAuthorized,
                            meta: {breadCrumb: 'File browser'},
                        },
                        {
                            path: ':pipelineId',
                            component: {render: c => c('router-view')},
                            beforeEnter: isAuthorized,
                            meta: {breadCrumb: store => store.state.navigation.pipeline.pipelineTitle},
                            children: [
                                {
                                    path: '',
                                    component: Pipeline,
                                    beforeEnter: isAuthorized
                                },
                                {
                                    path: 'file-browser/:path*',
                                    component: FileBrowser,
                                    beforeEnter: isAuthorized,
                                    meta: {breadCrumb: 'File browser'},
                                },
                                {
                                    path: 'io',
                                    component: {render: c => c('router-view')},
                                    beforeEnter: isAuthorized,
                                    children: [
                                        {
                                            path: '',
                                            component: Error404,
                                            beforeEnter: isAuthorized
                                        },
                                        {
                                            path: 'aligner',
                                            component: AlignerResults,
                                            beforeEnter: isAuthorized,
                                            meta: {breadCrumb: 'Pipeline results'}
                                        },
                                        {
                                            path: ':stageName',
                                            component: {render: c => c('router-view')},
                                            beforeEnter: isAuthorized,
                                            meta: {breadCrumb: 'Stage Input/Output'},
                                            children: [
                                                {
                                                    path: '',
                                                    component: PipelineStagesIO,
                                                    beforeEnter: isAuthorized
                                                },
                                                {
                                                    path: ':documentId',
                                                    component: PipelineStagesIOViewer,
                                                    beforeEnter: isAuthorized,
                                                    meta: {breadCrumb: 'IO Comparison'},
                                                },
                                            ]
                                        },
                                    ]
                                },
                                {
                                    path: 'document-editor',
                                    component: DocumentEditor,
                                    beforeEnter: isAuthorized,
                                    meta: {breadCrumb: 'Document Editor'}
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        {
            path: '/segment-manager',
            component: {render: c => c('router-view')},
            beforeEnter: isAuthorized,
            meta: {
                breadCrumb: 'Segment manager',
                permissions: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager']
            },
            children: [
                {
                    path: '',
                    component: SegmentManager,
                    beforeEnter: isAuthorized,
                    meta: {
                        permissions: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager']
                    }
                },
                {
                    path: 'aligned-document',
                    component: {render: c => c('router-view')},
                    beforeEnter: isAuthorized,
                    meta: {
                        breadCrumb: 'Document',
                        disabled: true,
                        permissions: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager']
                    },
                    children: [
                        {
                            path: '',
                            component: Error404,
                            beforeEnter: isAuthorized,
                            meta: {
                                permissions: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager']
                            }
                        },
                        {
                            path: ':docId',
                            component: AlignedDocument,
                            beforeEnter: isAuthorized,
                            meta: {
                                permissions: ['admin_segment-manager', 'use_segment-manager']
                            }
                        },
                    ]
                },
                {
                    path: 'term-extractor/:termsFileId',
                    component: DownloadTerms,
                    beforeEnter: isAuthorized,
                    meta: {
                        breadCrumb: 'Term-Extractor',
                        disabled: true,
                        permissions: ['admin_segment-manager', 'use_segment-manager']
                    },
                },
            ]
        },
        {path: '/', redirect: '/home', meta: {breadCrumb: 'Home'}},
        {path: '*', component: Error404}
    ]
})

router.beforeEach((to, from, next) => {
    if (to.params.projectId) store.dispatch(action_types.SET_PROJECT, to.params.projectId)
    if (to.params.crawlingId) store.dispatch(action_types.SET_CRAWLING, to.params.crawlingId)
    if (to.params.pipelineId)
        store.dispatch(action_types.SET_PIPELINE, {pipelineId: to.params.pipelineId})
    next()
})

export default router;
