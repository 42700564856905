<template>
  <v-dialog persistent :value="show" max-width="80%" @click:outside="onClose()">
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          Tokenizer Configuration
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center">
              <v-col sm="12" md="10" class="d-flex flex-column">
                <v-text-field outlined label="Stage Name" v-model="form.stageName" placeholder="Tokenizer"></v-text-field>
                <v-slider v-model="form.maxParallelPods" label="Max parallel pods" :thumb-size=24 thumb-label="always"
                          :min="1" :max="10"></v-slider>
                <v-switch label="Remove subscript numbers" v-model="form.removeSubscriptNumbers"></v-switch>
                <v-switch label="Remove superscript numbers" v-model="form.removeSuperscriptNumbers"></v-switch>
                <v-switch label="Merge consecutive whitespaces" v-model="form.mergeConsecutiveWhitespaces"></v-switch>
                <v-switch label="Merge hyphened words" v-model="form.mergeHyphenedWords"></v-switch>
                <v-switch label="Merge orphan enumerations" v-model="form.mergeOrphanEnumerations"></v-switch>
                <div>
                  <v-checkbox v-model="form.dontSplitShortGroups" @input="$refs.form.validate()"
                              label="(Global) Don't split short groups in brackets or quotes" hide-details></v-checkbox>
                  <v-text-field v-model="form.dontSplitShortGroupsMaxChars" type="number"
                                :rules="rules.dontSplitShortGroupsMaxChars"
                                class="optional-field ml-8" label="Max chars in group to not split"
                                :disabled="!form.dontSplitShortGroups"></v-text-field>
                </div>
                <div>
                  <v-checkbox v-model="form.splitBracketsAtEndOfSentences" @input="$refs.form.validate()"
                              label="(1) Split brackets at end of sentences" hide-details></v-checkbox>
                  <div class="d-flex ml-8">
                    <v-text-field v-model="form.splitBracketsAtEndOfSentencesSentenceMinChars" type="number"
                                  :rules="rules.splitBracketsAtEndOfSentencesSentenceMinChars" class="optional-field"
                                  label="Min sentence length"
                                  :disabled="!form.splitBracketsAtEndOfSentences"></v-text-field>
                    <v-text-field v-model="form.splitBracketsAtEndOfSentencesBracketsMinChars" type="number"
                                  :rules="rules.splitBracketsAtEndOfSentencesBracketsMinChars"
                                  class="optional-field ml-4" label="Min chars in brackets"
                                  :disabled="!form.splitBracketsAtEndOfSentences"></v-text-field>
                  </div>
                </div>
                <div>
                  <v-checkbox v-model="form.splitSemicolonsOutsideBrackets" @input="$refs.form.validate()"
                              label="(2) Split semicolons outside brackets" hide-details></v-checkbox>
                  <v-text-field v-model="form.splitSemicolonsOutsideBracketsSentenceMinChars" type="number"
                                :rules="rules.splitSemicolonsOutsideBracketsSentenceMinChars"
                                class="optional-field ml-8" label="Min sentence length"
                                :disabled="!form.splitSemicolonsOutsideBrackets"></v-text-field>
                </div>
                <div>
                  <v-checkbox v-model="form.splitSemicolonsInsideBrackets" @input="$refs.form.validate()"
                              label="(3) Split semicolons inside brackets" hide-details></v-checkbox>
                  <v-text-field v-model="form.splitSemicolonsInsideBracketsSentenceMinChars" type="number"
                                :rules="rules.splitSemicolonsInsideBracketsSentenceMinChars"
                                class="optional-field ml-8" label="Min sentence length"
                                :disabled="!form.splitSemicolonsInsideBrackets"></v-text-field>
                </div>
                <div>
                  <v-checkbox v-model="form.splitEnumerations" @input="$refs.form.validate()"
                              label="(4) Split enumerations" hide-details></v-checkbox>
                  <v-text-field v-model="form.splitEnumerationsSentenceMinChars" type="number"
                                :rules="rules.splitEnumerationsSentenceMinChars"
                                class="optional-field ml-8" label="Min sentence length"
                                :disabled="!form.splitEnumerations"></v-text-field>
                </div>
                <div>
                  <v-checkbox v-model="form.splitCommas" @input="$refs.form.validate()"
                              label="(5) Split commas" hide-details></v-checkbox>
                  <div class="d-flex ml-8">
                    <v-text-field v-model="form.splitCommasSentenceMinChars" type="number"
                                  :rules="rules.splitCommasSentenceMinChars" class="optional-field"
                                  label="Min sentence length"
                                  :disabled="!form.splitCommas"></v-text-field>
                    <v-text-field v-model="form.splitCommasDesiredSplitLength" type="number"
                                  :rules="rules.splitCommasDesiredSplitLength"
                                  class="optional-field ml-4" label="Desired split length"
                                  :disabled="!form.splitCommas"></v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="10" class="d-flex flex-column">
                <tokenizer-rules-tabs :is-page="false" :pipeline-id="pipelineId"
                                      :language="language"></tokenizer-rules-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="onClose">Close</v-btn>
          <v-btn color="success" text @click="apply">Apply & Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import TokenizerRulesTabs from "@/components/pages/manage/TokenizerRulesTabs";

const positiveIntegerRules = (context, enablerFormKey) => [
  v => (!context.form[enablerFormKey] || v !== '') || 'Required',
  v => (!context.form[enablerFormKey] || Number.isInteger(parseInt(v, 10))) || 'Must be an integer',
  v => (!context.form[enablerFormKey] || v > 0) || 'Must be positive'
]

export default {
  name: "TokenizerDialog",
  components: {TokenizerRulesTabs},
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null},
    language: {type: String, required: false}
  },
  data() {
    return {
      form: {
        stageName: '',
        maxParallelPods: 10,
        language: 'detect',
        removeSubscriptNumbers: false,
        removeSuperscriptNumbers: false,
        mergeConsecutiveWhitespaces: true,
        mergeHyphenedWords: true,
        dontSplitShortGroups: true,
        dontSplitShortGroupsMaxChars: 350,
        splitBracketsAtEndOfSentences: true,
        splitBracketsAtEndOfSentencesSentenceMinChars: 350,
        splitBracketsAtEndOfSentencesBracketsMinChars: 100,
        splitSemicolonsOutsideBrackets: true,
        splitSemicolonsOutsideBracketsSentenceMinChars: 350,
        splitSemicolonsInsideBrackets: true,
        splitSemicolonsInsideBracketsSentenceMinChars: 350,
        splitEnumerations: true,
        splitEnumerationsSentenceMinChars: 680,
        splitCommas: true,
        splitCommasSentenceMinChars: 700,
        splitCommasDesiredSplitLength: 700,
        mergeOrphanEnumerations: true
      },
      rules: {
        dontSplitShortGroupsMaxChars: positiveIntegerRules(this, 'dontSplitShortGroups'),
        splitBracketsAtEndOfSentencesSentenceMinChars:
            positiveIntegerRules(this, 'splitBracketsAtEndOfSentences'),
        splitBracketsAtEndOfSentencesBracketsMinChars:
            positiveIntegerRules(this, 'splitBracketsAtEndOfSentences'),
        splitSemicolonsOutsideBracketsSentenceMinChars:
            positiveIntegerRules(this, 'splitSemicolonsOutsideBrackets'),
        splitSemicolonsInsideBracketsSentenceMinChars:
            positiveIntegerRules(this, 'splitSemicolonsInsideBrackets'),
        splitEnumerationsSentenceMinChars: positiveIntegerRules(this, 'splitEnumerations'),
        splitCommasSentenceMinChars: positiveIntegerRules(this, 'splitCommas'),
        splitCommasDesiredSplitLength: positiveIntegerRules(this, 'splitCommas')
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal && this.item)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (newVal && this.show)
        this.populateForm(newVal)
    }
  },
  computed: {
    pipelineId() {
      return parseInt(this.$route.params.pipelineId)
    },
  },
  methods: {
    populateForm(item) {
      const {
        config: {
          env,
          name,
          maxParallelPods
        }
      } = item

      this.form = {
        stageName: name,
        maxParallelPods,
        removeSubscriptNumbers: env.REMOVE_SUBSCRIPT_NUMBERS ?? false,
        removeSuperscriptNumbers: env.REMOVE_SUPERSCRIPT_NUMBERS ?? false,
        mergeConsecutiveWhitespaces: env.MERGE_CONSECUTIVE_WHITESPACES ?? true,
        mergeHyphenedWords: env.MERGE_HYPHENED_WORDS ?? true,
        dontSplitShortGroups: env.DONT_SPLIT_SHORT_GROUPS ?? true,
        dontSplitShortGroupsMaxChars: env.DONT_SPLIT_SHORT_GROUPS_MAX_CHARS ?? 350,
        splitBracketsAtEndOfSentences: env.SPLIT_BRACKETS_AT_END_OF_SENTENCES ?? true,
        splitBracketsAtEndOfSentencesSentenceMinChars: env.SPLIT_BRACKETS_AT_END_OF_SENTENCES_SENTENCE_MIN_CHARS ?? 350,
        splitBracketsAtEndOfSentencesBracketsMinChars: env.SPLIT_BRACKETS_AT_END_OF_SENTENCES_BRACKETS_MIN_CHARS ?? 100,
        splitSemicolonsOutsideBrackets: env.SPLIT_SEMICOLONS_OUTSIDE_BRACKETS ?? true,
        splitSemicolonsOutsideBracketsSentenceMinChars: env.SPLIT_SEMICOLONS_OUTSIDE_BRACKETS_SENTENCE_MIN_CHARS ?? 350,
        splitSemicolonsInsideBrackets: env.SPLIT_SEMICOLONS_INSIDE_BRACKETS ?? true,
        splitSemicolonsInsideBracketsSentenceMinChars: env.SPLIT_SEMICOLONS_INSIDE_BRACKETS_SENTENCE_MIN_CHARS ?? 350,
        splitEnumerations: env.SPLIT_ENUMERATIONS ?? true,
        splitEnumerationsSentenceMinChars: env.SPLIT_ENUMERATIONS_SENTENCE_MIN_CHARS ?? 680,
        splitCommas: env.SPLIT_COMMAS ?? true,
        splitCommasSentenceMinChars: env.SPLIT_COMMAS_SENTENCE_MIN_CHARS ?? 700,
        splitCommasDesiredSplitLength: env.SPLIT_COMMAS_DESIRED_SPLIT_LENGTH ?? 700,
        mergeOrphanEnumerations: env.MERGE_ORPHAN_ENUMERATIONS ?? false
      }
    },
    apply() {
      const {
        stageName,
        maxParallelPods,
        removeSubscriptNumbers,
        removeSuperscriptNumbers,
        mergeConsecutiveWhitespaces,
        mergeHyphenedWords,
        dontSplitShortGroups,
        dontSplitShortGroupsMaxChars,
        splitBracketsAtEndOfSentences,
        splitBracketsAtEndOfSentencesSentenceMinChars,
        splitBracketsAtEndOfSentencesBracketsMinChars,
        splitSemicolonsOutsideBrackets,
        splitSemicolonsOutsideBracketsSentenceMinChars,
        splitSemicolonsInsideBrackets,
        splitSemicolonsInsideBracketsSentenceMinChars,
        splitEnumerations,
        splitEnumerationsSentenceMinChars,
        splitCommas,
        splitCommasSentenceMinChars,
        splitCommasDesiredSplitLength,
        mergeOrphanEnumerations
      } = this.form

      const env = {
        REMOVE_SUBSCRIPT_NUMBERS: removeSubscriptNumbers,
        REMOVE_SUPERSCRIPT_NUMBERS: removeSuperscriptNumbers,
        MERGE_CONSECUTIVE_WHITESPACES: mergeConsecutiveWhitespaces,
        MERGE_HYPHENED_WORDS: mergeHyphenedWords,
        DONT_SPLIT_SHORT_GROUPS: dontSplitShortGroups,
        DONT_SPLIT_SHORT_GROUPS_MAX_CHARS: dontSplitShortGroupsMaxChars,
        SPLIT_BRACKETS_AT_END_OF_SENTENCES: splitBracketsAtEndOfSentences,
        SPLIT_BRACKETS_AT_END_OF_SENTENCES_SENTENCE_MIN_CHARS: splitBracketsAtEndOfSentencesSentenceMinChars,
        SPLIT_BRACKETS_AT_END_OF_SENTENCES_BRACKETS_MIN_CHARS: splitBracketsAtEndOfSentencesBracketsMinChars,
        SPLIT_SEMICOLONS_OUTSIDE_BRACKETS: splitSemicolonsOutsideBrackets,
        SPLIT_SEMICOLONS_OUTSIDE_BRACKETS_SENTENCE_MIN_CHARS: splitSemicolonsOutsideBracketsSentenceMinChars,
        SPLIT_SEMICOLONS_INSIDE_BRACKETS: splitSemicolonsInsideBrackets,
        SPLIT_SEMICOLONS_INSIDE_BRACKETS_SENTENCE_MIN_CHARS: splitSemicolonsInsideBracketsSentenceMinChars,
        SPLIT_ENUMERATIONS: splitEnumerations,
        SPLIT_ENUMERATIONS_SENTENCE_MIN_CHARS: splitEnumerationsSentenceMinChars,
        SPLIT_COMMAS: splitCommas,
        SPLIT_COMMAS_SENTENCE_MIN_CHARS: splitCommasSentenceMinChars,
        SPLIT_COMMAS_DESIRED_SPLIT_LENGTH: splitCommasDesiredSplitLength,
        MERGE_ORPHAN_ENUMERATIONS: mergeOrphanEnumerations
      }

      const config = {
        ...this.item.config,
        name: stageName,
        maxParallelPods,
        env
      }

      this.onApply(config)
    }
  }
}
</script>

<style scoped>
.optional-field {
  max-width: 15em;
}
</style>
