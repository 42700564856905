<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="8">
        <v-stepper v-model="step" vertical>
          <v-stepper-step :step="1" :complete="step > 1" :editable="true">
            Choose TMX or Project import
          </v-stepper-step>
          <v-stepper-content :step="1">
            <v-radio-group v-model="tmxImport" row label="Import from">
              <v-radio label="Project & Pipelines" :value="false"></v-radio>
              <v-radio label="TMX files" :value="true"></v-radio>
            </v-radio-group>
            <v-btn color="primary" @click="nextStep" class="my-3" :loading="loading" :disabled="loading">
              Continue
            </v-btn>
          </v-stepper-content>

          <import-tmx-stepper v-if="tmxImport && enumsValues!==undefined" :enums-values="enumsValues" :loading="loading"
                              :step="step" :next-step="nextStep" :reset="resetState"
                              :show-error="showError"></import-tmx-stepper>
          <import-pipelines-stepper v-else-if="!tmxImport && enumsValues!==undefined" :enums-values="enumsValues"
                                    :loading="loading" :step="step" :next-step="nextStep" :reset="resetState"
                                    :show-error="showError"></import-pipelines-stepper>

        </v-stepper>

        <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImportPipelinesStepper from "@/components/parts/segment-manager/import/ImportPipelinesStepper";
import ImportTmxStepper from "@/components/parts/segment-manager/import/ImportTmxStepper";
import {LANGUAGE_BY_CODE} from "@/util/enums";

export default {
  name: "Import",
  components: {ImportTmxStepper, ImportPipelinesStepper},
  props: {
    enumsValues: {type: Object, required: false}
  },
  data() {
    return {
      step: 1,
      tmxImport: false,
      error: null,
      hasError: false,
      loading: false,
      data: {
        projects: [],
        pipelines: [],
        owners: [],
        tags: [],
        domains: [],
        groups: [],
        alignedDocumentPermissions: [],
        languages: Object.entries(LANGUAGE_BY_CODE).map(([value, text]) => ({text, value})),
        languageByCode: LANGUAGE_BY_CODE
      }
    }
  },
  methods: {
    nextStep() {
      this.step = this.step + 1
    },
    resetState() {
      this.step = 1
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    }
  },
}
</script>

<style scoped>
</style>
