<template>
  <div>
    <v-stepper-step :step="3" :complete="step > 3" :editable="true">
      Term-Extractor
    </v-stepper-step>
    <v-stepper-content :step="3">
      <v-btn color="primary" @click="extractTerms" class="my-3" :loading="loading">
        Extract the terms from the alignments
      </v-btn>
    </v-stepper-content>


    <v-stepper-step :step="4" :complete="step === 4 && termsFileId !== undefined" :editable="step>=4">
      Get the terms
    </v-stepper-step>
    <v-stepper-content :step="4">
      <div class="mb-3">
        <h6 class="text-h6">The terms are being computed. They will be downloadable on the following link:</h6>
      </div>
      <v-btn color="success" class="mr-2" @click="openDownloadLink" :disabled="loading" :loading="loading">
        Get the terms
      </v-btn>
      <v-btn color="primary" @click="resetState" :disabled="loading" :loading="loading">
        More actions ?
      </v-btn>
    </v-stepper-content>


  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "TermExtractor",
  components: {},
  props: {
    appliedFilters: {type: Object, required: true},
    data: {type: Object, required: true},
    step: {type: Number, required: true},
    nextStep: {type: Function, required: true},
    reset: {type: Function, required: true},
    showError: {type: Function, required: true}
  },
  data() {
    return {
      loading: false,
      termsFileId: undefined,
    }
  },
  methods: {
    resetState() {
      this.loading = false
      this.termsFileId = undefined
      this.reset()
    },
    openDownloadLink() {
      const downloadURL = `/#/segment-manager/term-extractor/${this.termsFileId}`
      window.open(downloadURL, '_blank');
    },
    extractTerms() {
      const data = {
        filters: this.appliedFilters.filters,
        postFilters: this.appliedFilters.postFilters,
      }
      this.loading = true
      axios.post('/segment-manager/extract-terms', data)
          .then(response => {
            this.loading = false
            this.termsFileId = response.data
          })
          .catch(err => {
            console.error(err)
            this.showError(err)
            this.loading = false
            this.termsFileId = undefined
          })
      this.nextStep()
    },
  }
}
</script>

<style scoped>
</style>
