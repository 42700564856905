<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-form @submit.prevent="login" ref="form" :disabled="sending">
          <v-card elevation="4" class="pa-3">
            <v-card-text>
              <div class="d-flex flex-column align-center">
                <v-avatar
                    color="rgb(133, 133, 133)"
                    size="60">
                  <v-icon color="white" large>mdi-account</v-icon>
                </v-avatar>
              </div>
              <h4 class="text-h5 text-center mt-4 mb-2"> LOGIN </h4>
              <v-text-field label="Username"
                            v-model="form.username"
                            :rules="rules.username"
                            required
                            placeholder=" "
                            persistent-placeholder></v-text-field>
              <v-text-field label="Password"
                            v-model="form.password"
                            :rules="rules.password"
                            type="password"
                            required
                            placeholder=" "
                            persistent-placeholder></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn class="mx-1 mb-2 px-9"
                     type="submit"
                     color="primary"
                     elevation="2"
                     :disabled="sending">LOGIN
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </v-container>
</template>

<script>
import {LOGIN_REQUEST} from "@/store/action-types";
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      rules: {
        username: [
          v => !!v || 'Required'
        ],
        password: [
          v => !!v || 'Required'
        ]
      },
      sending: false,
      hasError: false,
      error: null
    }
  },
  methods: {
    clearForm() {
      this.$refs.form.reset()
    },
    login() {
      if (!this.$refs.form.validate())
        return

      this.sending = true

      const {username, password} = this.form
      this.$store.dispatch(LOGIN_REQUEST, {username, password})
          .then(() => {
            this.hasError = false
            this.sending = false
            this.clearForm()
            if (store.getters.hasLastRoute)
              this.$router.push(store.state.navigation.lastRoute.path)
            else
              this.$router.push('/')
          })
          .catch(err => {
            if (err.response && err.response.status === 401)
              this.error = 'Invalid username and/or password'
            else
              this.error = err

            this.hasError = true
            this.sending = false
          })
    }
  }
}
</script>

<style scoped>

</style>
