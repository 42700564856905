<template>
  <h2 v-if="emptyFilters" class="text-h3 text-center">
    Please apply a filter
  </h2>
  <div v-else class="fill-height">
    <segment-details :show="dialogShown" :on-close="closeModal" :segment="selectedSegment"
                     :enums-values="enumsValues"></segment-details>

    <d-data-table :items="items" :items-per-page="10" :headers="headers" :footer-props="footerProps"
                  @click:row="showDialog" dense :page.sync="page" :server-items-length="total"
                  :loading="loading" :enable-resize="shown">
      <template v-slot:top>
        <v-toolbar flat>
          <div class="d-flex flex-grow-1 justify-center">
            <v-select hide-details label="Sort by" :items="sortFunctions" v-model="selectedSort"
                      item-value="value" item-text="name" class="sort-by-select mr-2"></v-select>
            <v-btn hide-details :loading="loading || disableSorting" color="primary" @click="fetchSamples" class="ml-2">
              Sort
            </v-btn>
          </div>
        </v-toolbar>
      </template>
    </d-data-table>
  </div>
</template>

<script>
import SegmentDetails from "@/components/parts/segment-manager/segments/SegmentDetails";
import axios from "axios";
import DDataTable from "@/components/wrappers/DDataTable";

const SORT_TO_PARAMS = {
  AZ_LEFT: {orderBy: "lexicographicLeft", orderAsc: true},
  ZA_LEFT: {orderBy: "lexicographicLeft", orderAsc: false},
  AZ_RIGHT: {orderBy: "lexicographicRight", orderAsc: true},
  ZA_RIGHT: {orderBy: "lexicographicRight", orderAsc: false},
  NATURAL: {orderBy: "natural", orderAsc: true},
  RANDOM: {orderBy: "random", orderAsc: true},
  // REP_RANDOM: {orderBy: "reproducibleRandom", orderAsc: true},
  SCORE_ASC: {orderBy: "scoreDistance", orderAsc: true},
  SCORE_DESC: {orderBy: "scoreDistance", orderAsc: false}
}

const MAX_SEGMENTS_PER_PAGE = 20

const DEFAULT_PARAMS = {
  offset: 0,
  limit: MAX_SEGMENTS_PER_PAGE,
  maxAdjacent: 5,
  orderBy: 'natural',
  orderAsc: true
}

export default {
  name: "Segments",
  components: {SegmentDetails, DDataTable},
  props: {
    appliedFilters: {type: Object, required: true},
    enumsValues: {type: Object, required: true},
    shown: {type: Boolean, required: true},
    disableSorting: {type: Boolean, required: true}
  },
  data: () => ({
    dialogShown: false,
    selectedSegment: undefined,
    loading: false,
    total: 0,
    offset: 0,
    page: 1,
    footerProps: {
      itemsPerPageOptions: [10],
    },
    headers: [
      {text: "Left Segment", value: "left.text", width: '40%', sortable: false},
      {text: "Left Lang.", value: "left.documentLanguage", width: '5%', sortable: false},
      {text: "Right Segment", value: "right.text", width: '40%', sortable: false},
      {text: "Right Lang.", value: "right.documentLanguage", width: '5%', sortable: false},
      {text: "Origin", value: "origin", width: '6%', sortable: false},
    ],
    selectedSort: "NATURAL",
    sortFunctions: [
      {name: "A->Z (Left)", value: "AZ_LEFT"},
      {name: "Z->A (Left)", value: "ZA_LEFT"},
      {name: "A->Z (Right)", value: "AZ_RIGHT"},
      {name: "Z->A (Right)", value: "ZA_RIGHT"},
      {name: "Default", value: "NATURAL"},
      {name: "Random order", value: "RANDOM"},
      // {name: "Reproducible Random", value: "REP_RANDOM"},
      {name: "Score/Distances ASC", value: "SCORE_ASC"},
      {name: "Score/Distances DESC", value: "SCORE_DESC"}
    ],
    items: []
  }),
  computed: {
    params() {
      return {...DEFAULT_PARAMS, ...SORT_TO_PARAMS[this.selectedSort], offset: this.offset}
    },
    emptyFilters() {
      return Object.keys(this.appliedFilters).length === 0
    }
  },
  mounted() {
    this.fetchSamples()
  },
  watch: {
    appliedFilters: {
      handler() {
        this.fetchSamples()
      },
      deep: true
    },
    page(newVal) {
      this.offset = (newVal * 10) - 10
      this.fetchSamples()
    }
  },
  methods: {
    showDialog(item) {
      this.dialogShown = true
      this.selectedSegment = item
    },
    closeModal() {
      this.dialogShown = false
      this.selectedSegment = undefined
    },
    fetchSamples() {
      if (this.loading || this.disableSorting)
        return

      this.loading = true
      const data = {
        filters: this.appliedFilters.filters,
        postFilters: this.appliedFilters.postFilters,
        params: this.params
      }

      axios.post('/segment-manager/samples', data)
          .then(response => {
            this.items = response.data.samples.map(item => ({
              ...item,
              origin: this.enumsValues.projectById[item.projectId]?.name
                  ?? this.enumsValues.crawlingByProjectId[item.projectId]?.name
                  ?? item.alignedDocumentName
            }))
            this.total = response.data.filtered
            this.loading = false
          })
          .catch(err => {
            console.error(err)
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>
.sort-by-select {
  flex-grow: 0;
  width: 250px;
}
</style>
