<template>
  <v-dialog v-model="show" :max-width="maxWidth" persistent>
    <v-form @submit.prevent="onApply(item)" ref="form">
      <v-card>
        <v-card-title class="headline">
          <slot></slot>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onClose">Cancel</v-btn>
          <v-btn v-if="onNo" text @click="onNo">No</v-btn>
          <v-btn color="primary" text type="submit">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

export default {
  name: "SimpleDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    onNo: {type: Function, required: false},
    item: {type: Object, default: null},
    maxWidth: {type: [String, Number], default: '550px'}
  },
}
</script>

<style scoped>
</style>
