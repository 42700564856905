<template>
  <h1 class="text-h3 text-center">404 not found</h1>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>

</style>
