<template>
  <v-dialog persistent :value="show" max-width="60%" @click:outside="onClose" @keydown.esc="onClose">
    <add-edit-batch-rule-dialog :enums-values="enumsValues" :on-close="handleSubDialogClose" :show="showSubDialog"
                                :show-error="showError" :on-apply="handleSubDialogApply"
                                :item="editedItem"></add-edit-batch-rule-dialog>

    <v-card>
      <v-card-text>
        <d-data-table :headers="headers" height="60vh" :items="batchRules" :loading="loading" :fill-height="false"
                      :enable-resize="false">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Batch rules</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="createBatchRule" class="ml-2" :disabled="loading">
                New batch remove rule
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{item}">
            <v-icon @click="editItem(item)" :disabled="loading">mdi-pencil</v-icon>
            <v-icon @click="onDeleteRule(item)" :disabled="loading">mdi-delete</v-icon>
          </template>
        </d-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="onClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddEditBatchRuleDialog from "@/components/parts/document-editor/AddEditBatchRuleDialog";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "BatchRulesDialog",
  components: {AddEditBatchRuleDialog, DDataTable},
  props: {
    show: {type: Boolean, required: true},
    enumsValues: {type: Object, required: true},
    onClose: {type: Function, required: true},
    showError: {type: Function, required: true},
    batchRules: {type: Array, required: true},
    loading: {type: Boolean, required: true},
    onCreate: {type: Function, required: true},
    onEdit: {type: Function, required: true},
    onDeleteRule: {type: Function, required: true}
  },
  data() {
    return {
      showSubDialog: false,
      editedItem: null,
      headers: [
        {text: 'Type', value: 'type'},
        {text: 'Name', value: 'name'},
        {text: 'Actions', value: 'actions', sortable: false, align: 'right'}
      ]
    }
  },
  methods: {
    createBatchRule() {
      this.editedItem = null
      this.showSubDialog = true
    },
    editItem(item) {
      this.editedItem = item
      this.showSubDialog = true
    },
    handleSubDialogClose() {
      this.showSubDialog = false
    },
    handleSubDialogApply(batchRule) {
      this.showSubDialog = false

      if (this.editedItem)
        this.onEdit(batchRule)
      else
        this.onCreate(batchRule)
    }
  }
}
</script>

<style scoped>

</style>
