<template>
  <v-dialog persistent :value="show" max-width="60%" @click:outside="onClose" @keydown.esc="onClose">
    <v-form ref="form">
      <v-card>
        <v-card-title>Edit alignment</v-card-title>
        <v-card-text>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea v-model="alignment.initialText1" label="Initial text 1" disabled no-resize auto-grow
                          outlined rows="1"></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea v-model="alignment.initialText2" label="Initial text 2" disabled no-resize auto-grow
                          outlined rows="1"></v-textarea>
            </v-col>
          </div>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea v-model="text1" label="Text 1" no-resize auto-grow outlined rows="1"
                          :rules="rules.text" @keydown.enter.prevent></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea v-model="text2" label="Text 2" no-resize auto-grow outlined rows="1"
                          :rules="rules.text" @keydown.enter.prevent></v-textarea>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="onClose">Close</v-btn>
          <v-btn color="success" text @click="apply">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {ALIGNMENT_STATES} from "@/util/document-editor";

export default {
  name: "ModifyDialog",
  props: {
    show: {type: Boolean, required: true},
    alignment: {type: Object, default: null},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    pipelineAlignedDocuments: {type: Array, required: true}
  },
  data() {
    return {
      text1: '',
      text2: '',
      rules: {
        text: [
          v => !!v || 'Required',
          v => !/[\n\r]/.test(v) || 'No linebreak allowed',
          () => !this.sameAsInitial || 'Texts are identical to the initial ones'
        ]
      }
    }
  },
  watch: {
    alignment(newVal) {
      if (!this.show)
        return

      this.populateForm(newVal)
    },
    show(newVal) {
      if (!newVal)
        return

      this.populateForm(this.alignment)
    }
  },
  computed: {
    sameAsInitial() {
      return this.alignment?.initialText1 === this.text1 && this.alignment?.initialText2 === this.text2
    }
  },
  methods: {
    apply() {
      if (!this.$refs.form.validate())
        return

      const rule = {
        segment1: this.alignment.initialText1,
        segment2: this.alignment.initialText2,
        modifiedSegment1: this.text1,
        modifiedSegment2: this.text2
      }

      this.$refs.form?.resetValidation()
      this.onApply(rule)
    },
    populateForm(alignment) {
      const batchRemoved = alignment?.state === ALIGNMENT_STATES.BATCH_REMOVED
      this.text1 = (batchRemoved ? alignment?.initialText1 : alignment?.modifiedText1) ?? ''
      this.text2 = (batchRemoved ? alignment?.initialText2 : alignment?.modifiedText2) ?? ''
    }
  }
}
</script>

<style scoped>

</style>
