<template>
  <v-sheet v-if="filter" elevation="2" rounded class="mx-2 my-1 d-inline-flex flex-row" :style="filterStyle">
    <!-- Delete Button -->
    <div>
      <v-btn @click="onDelete" block class="px-1 delete-filter-button" depressed color="error" :disabled="disabled">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
    <!-- Change Filter Type -->
    <div>
      <v-btn @click="edit" block class="px-1 edit-filter-button" depressed color="primary" :disabled="disabled">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <div class="d-inline-flex align-center py-1 px-2 ">
      {{ filterText }}
    </div>
  </v-sheet>
</template>

<script>
import {BLUE_COLORS} from "@/util/color";
import {FILTER_TYPES, FILTERS_DETAILS} from "@/components/parts/filters/filters-utils";
import {cloneDeep} from "lodash";

export default {
  name: "SingleFilter",
  props: {
    filter: {type: Object, required: true},
    enumsValues: {type: Object, required: true},
    id: {type: Number, required: true},
    depth: {type: Number, required: true},
    disabled: {type: Boolean, required: false, default: false},
    onModify: {type: Function, required: true},
    showDialog: {type: Function, required: true},
    onDelete: {type: Function, required: true}
  },
  computed: {
    filterStyle() {
      return "background-color:" + BLUE_COLORS[this.depth % BLUE_COLORS.length]
    },
    filterDetails() {
      return FILTERS_DETAILS[this.filter.name]
    },
    enumNames() {
      const filterDetails = this.filterDetails

      if (filterDetails.filterType !== FILTER_TYPES.ENUM)
        return {}

      const values = filterDetails.meta.enumValues ?? this.enumsValues[filterDetails.meta.enumKey]
      return values?.reduce((obj, item) => Object.assign(obj, {[item.id]: item.name}), {}) ?? {}
    },
    filterText() {
      const filterDetails = this.filterDetails
      const filterName = filterDetails.name.toLowerCase()
      const params = this.filter.params

      switch (filterDetails.filterType) {
        case FILTER_TYPES.BOOLEAN:
          return filterName
        case FILTER_TYPES.FLOAT:
        case FILTER_TYPES.INTEGER:
        case FILTER_TYPES.DATE:
          return `${filterName} ${params.operator} ${params.value}`
        case FILTER_TYPES.STRING:
          return `${filterName} = ${params}`
        case FILTER_TYPES.ENUM:
          return `${filterName} = [${Object.values(params).map(val => this.enumNames[val] ?? '').join(', ')}]`
        case FILTER_TYPES.SEARCH:
          return `${filterName} = ${params.value} (${params.language})`
      }

      return ''
    }
  },
  methods: {
    modifyChild(id, newVal) {
      const struct = this.filter
      struct.children[id] = newVal
      this.onModify(this.id, struct)
    },
    deleteChild(id) {
      const struct = this.filter
      struct.children.splice(id, 1)
      this.onModify(this.id, struct)
    },
    edit() {
      this.showDialog(this.editCallback, this.filter)
    },
    editCallback(editedItem) {
      this.onModify(this.id, cloneDeep(editedItem))
    },
    showSeparator(index) {
      return index < this.filter.children.length - 1
    }
  }
}
</script>

<style scoped>
.delete-filter-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.edit-filter-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
