export const getReadableFileSizeString = fileSizeInBytes => {
    if (fileSizeInBytes < 1024)
        return fileSizeInBytes + ' B'

    const byteUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = -1;
    do {
        fileSizeInBytes = fileSizeInBytes / 1024
        i++
    } while (fileSizeInBytes > 1024 && i < byteUnits.length)

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + ' ' + byteUnits[i]
}

export const formatDateToSeconds = (date = new Date()) => date.toLocaleString('fr-CH')

export const formatDateRange = (dateRangeStart, dateRangeEnd) => {
    if (!dateRangeStart || !dateRangeEnd)
        return ''

    return `${dateRangeStart} - ${dateRangeEnd}`
}

export const contentTypeFromFileName = name => {
    if (name.endsWith('pdf'))
        return 'application/pdf'
    if (name.endsWith('doc'))
        return 'application/msword'
    if (name.endsWith('docx'))
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    if (name.endsWith('xml'))
        return 'application/xml'
    if (name.endsWith('txt'))
        return 'text/plain'
    if (name.endsWith('tmx'))
        return 'application/x-tmx+xml'
    return 'application/octet-stream'
}
