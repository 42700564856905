<template>
  <div class="fill-height">
    <user-dialog :form-title="formTitle" :modify="modify" :show="dialog" :item="editedItem" :on-apply="save"
                 :on-close="closeModals" :groups="groupsData"></user-dialog>
    <simple-dialog :show="dialogDelete" :on-apply="confirmDelete" :on-close="closeModals" :item="editedItem">
      Are you sure you want to delete this user ?
    </simple-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="10" :md="mdSize">
          <d-data-table :items="usersData" :headers="tableHeaders" class="mt-1 pt-1"
                        :footer-props="footerProps" sort-by="name" :items-per-page="itemsPerPage"
                        :loading="loading" :fill-height="!homePreview" :hide-default-footer="homePreview"
                        :disable-sort="homePreview">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Users</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createUser" class="ml-2" v-if="!homePreview">New user</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.groups="{item}">
              <v-chip v-for="group in item.groups" :key="group"
                      :color="stringToColor(groupById[group].name)" class="ma-1"
                      :dark="isDark(stringToColor(groupById[group].name))">
                {{ groupById[group].name }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editUser(item)">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {cloneDeep} from "lodash";
import UserDialog from "@/components/parts/manage-dialogs/UserDialog";
import SimpleDialog from "@/components/parts/manage-dialogs/SimpleDialog";
import DDataTable from "@/components/wrappers/DDataTable";
import {reduceById} from "@/util/js-utils";

export default {
  name: "Users",
  components: {UserDialog, SimpleDialog, DDataTable},
  props: {
    homePreview: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      usersData: [],
      groupsData: [],
      editedItem: undefined,
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      sending: false,
      modify: false,
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1]
      },
    }
  },
  computed: {
    formTitle() {
      return this.modify ? "Edit user" : "Create user"
    },
    groupById() {
      return reduceById(this.groupsData)
    },
    mdSize() {
      return this.homePreview ? 12 : 8
    },
    itemsPerPage() {
      return this.homePreview ? 10 : 50
    },
    tableHeaders() {
      const headers = [
        {text: 'Username', value: 'username', align: 'left'},
        {text: 'Email', value: 'email', align: 'left'},
        {text: 'Groups', value: 'groups', align: 'left'}
      ]
      if (!this.homePreview) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.error = null
      this.hasError = false

      Promise.all([axios.get(`/users`), axios.get('/groups')])
          .then(responses => {
            this.usersData = responses[0].data
            this.groupsData = responses[1].data
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.error = err
            this.hasError = true
          })
    },
    createUser() {
      this.modify = false
      this.editedItem = undefined
      this.dialog = true
    },
    editUser(item) {
      this.modify = true
      this.editedItem = cloneDeep(item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedItem = cloneDeep(item)
      this.dialogDelete = true
    },
    closeModals() {
      this.dialog = false
      this.dialogDelete = false
    },
    confirmDelete(item) {
      this.sending = true

      axios.delete(`/users/${item.id}`)
          .then(this.thenHandler)
          .catch(this.catchHandler)
    },
    save(item) {
      this.sending = true

      const data = {
        username: item.username,
        password: item.password,
        email: item.email,
        mustChangePassword: item.mustChangePassword,
        groups: item.groups
      }

      if (this.modify)
        axios.put(`/users/${item.id}`, data)
            .then(this.thenHandler)
            .catch(this.catchHandler)
      else
        axios.post(`/users`, data)
            .then(this.thenHandler)
            .catch(this.catchHandler)
    },
    catchHandler(err) {
      this.sending = false
      this.error = err
      this.hasError = true
    },
    thenHandler() {
      this.sending = false
      this.dialog = false
      this.dialogDelete = false
      this.fetchData()
    }
  }
}
</script>

<style scoped>
</style>
