<template>
  <div>
    <v-stepper-step :step="2" :editable="step !== 4" :complete="step > 2">
      Choose TMX files
    </v-stepper-step>
    <v-stepper-content :step="2">
      <v-form ref="inputForm" v-model="inputFormValid">
        <v-file-input counter multiple chips label="Input TMX files" accept=".tmx" :rules="rules.inputFiles"
                      v-model="inputFiles"></v-file-input>

        <v-btn color="primary" @click="inputContinue" class="my-3">
          Continue
        </v-btn>
      </v-form>
    </v-stepper-content>

    <v-stepper-step :step="3" :editable="step !== 4 && inputFormValid" :complete="step > 3">
      Options
    </v-stepper-step>
    <v-stepper-content :step="3">
      <v-form ref="optionsForm" v-model="optionsFormValid">
        <div class="d-flex">
          <v-simple-checkbox v-model="filterLanguages" :ripple=false class="mr-4"
                             @input="$refs.optionsForm.validate()"></v-simple-checkbox>
          <v-select :items="enumsValues.languages" v-model="selectedLanguages" label="Filter languages"
                    :item-text="item=>capitalize(item.name)" item-value="id"
                    :disabled="!filterLanguages" :rules="rules.selectedLanguages"
                    multiple chips deletable-chips :menu-props="{ top: false, offsetY: true }"></v-select>
        </div>
        <v-checkbox v-model="fileMarkedAsTranslated" :ripple=false class="mr-4"
                    label="Consider file as translated"></v-checkbox>
        <v-select :items="enumsValues.owners" v-model="owner" label="Owner" :rules="rules.owner" item-text="name"
                  item-value="id"></v-select>
        <v-select :items="enumsValues.confidentialityLevels" v-model="confidentialityLevel"
                  label="Confidentiality level" :rules="rules.confidentialityLevel"
                  item-text="name" item-value="id"></v-select>
        <v-expansion-panels focusable :value=0 class="pa-1">
          <v-expansion-panel>
            <v-expansion-panel-header>Groups & permissions</v-expansion-panel-header>
            <v-expansion-panel-content class="py-2">
              <div v-for="(groupPermissions, index) in alignedDocumentGroupPermissions"
                   :key="groupPermissions.groupId" class="d-flex align-center">
                <v-btn @click="alignedDocumentGroupPermissions.splice(index, 1)" class="mr-2">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-chip :color="stringToColor(enumsValues.groupById[groupPermissions.groupId].name)" class="mx-2"
                        :dark="isDark(stringToColor(enumsValues.groupById[groupPermissions.groupId].name))">
                  {{ enumsValues.groupById[groupPermissions.groupId].name }}
                </v-chip>
                <v-checkbox v-for="permission in enumsValues.permissions" class="mx-2"
                            :key="permissionToKey(permission)" :label="permissionToName(permission)"
                            v-model="alignedDocumentGroupPermissions[index].permissions[permissionToKey(permission)]">
                </v-checkbox>
              </div>
              <div class="d-flex">
                <v-select label="Add group" v-model="selectedGroup" :items="groupsWithoutPermissions" hide-details
                          item-text="name" item-value="id" @change="addGroup" @click="selectedGroup = null"
                          class="flex-grow-0"></v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-select :items="enumsValues.tags" v-model="selectedTags" label="Tags" item-text="name" item-value="id"
                  multiple chips deletable-chips>
          <template #selection="{ item, index }">
            <v-chip :color="stringToColor(item.name)" :dark="isDark(stringToColor(item.name))" close
                    @click:close="selectedTags.splice(index, 1)">
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
        <v-select :items="enumsValues.domains" v-model="selectedDomains" label="Domains" item-text="name"
                  item-value="id" multiple chips deletable-chips>
          <template #selection="{ item, index }">
            <v-chip :color="stringToColor(item.name)" :dark="isDark(stringToColor(item.name))" close
                    @click:close="selectedDomains.splice(index, 1)">
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
        <div v-for="({}, index) in notes" :key="index">
          <v-textarea :label="`Note ${index+1}`" v-model="notes[index]" :rows="2" auto-grow
                      :rules="rules.notes" validate-on-blur>
            <template v-slot:append-outer>
              <v-btn @click="notes.splice(index, 1)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </div>
        <v-btn @click="notes.push('')" class="d-block mx-1 my-3">
          <v-icon>mdi-plus</v-icon>
          Add a note
        </v-btn>
        <v-btn color="primary" @click="confirmImport" class="d-block mx-1 mt-8 mb-3" :loading="sending"
               :disabled="sending">
          Import {{ inputFiles.length }} TMX files
        </v-btn>
      </v-form>
    </v-stepper-content>

    <v-stepper-step :step="4" :complete="step === 4">
      Done
    </v-stepper-step>
    <v-stepper-content :step="4">
      <v-alert type="info">
        Alignments are being imported...
      </v-alert>
      <v-btn color="primary" @click="resetState">
        Import more
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import axios from "axios";
import {formDataFromObject} from "@/util/js-utils";
import {capitalize} from "lodash";
import {isDark, stringToColor} from "@/util/color";

const permissionFromKey = key => {
  const [permissionType, resource] = key.split('_', 2)
  return {permissionType, resource}
}

const flattenGroupPermissions = groupPermissions =>
    groupPermissions
        .flatMap(groupPermissions => Object.entries(groupPermissions.permissions)
            .filter(entry => entry[1]) // Keep only "checked" entries
            .map(([permissionKey]) => ({
              ...permissionFromKey(permissionKey),
              groupId: groupPermissions.groupId
            })))

export default {
  name: "ImportTmxStepper",
  props: {
    enumsValues: {type: Object, required: true},
    loading: {type: Boolean, required: true},
    step: {type: Number, required: true},
    nextStep: {type: Function, required: true},
    reset: {type: Function, required: true},
    showError: {type: Function, required: true}
  },
  data() {
    return {
      inputFiles: [],
      owner: null,
      confidentialityLevel: null,
      filterLanguages: false,
      alignedDocumentGroupPermissions: [],
      selectedGroup: null,
      selectedLanguages: [],
      selectedTags: [],
      selectedDomains: [],
      notes: [],
      fileMarkedAsTranslated: true,
      connectAll: false,
      sending: false,
      inputFormValid: false,
      optionsFormValid: false,
      rules: {
        inputFiles: [
          v => v.length > 0 || 'Required'
        ],
        owner: [
          v => !!v || 'Required'
        ],
        confidentialityLevel: [
          v => v !== null || 'Required'
        ],
        selectedLanguages: [
          v => (!this.filterLanguages || v.length >= 2) || 'Select at least 2 languages'
        ],
        notes: [
          v => !!v && v.length >= 3 || 'Notes must be at least 3 characters long'
        ]
      },
    }
  },
  methods: {
    capitalize,
    resetState() {
      this.inputFiles = []
      this.owner = null
      this.confidentialityLevel = null
      this.filterLanguages = false
      this.alignedDocumentGroupPermissions = []
      this.selectedGroup = null
      this.selectedLanguages = []
      this.selectedTags = []
      this.selectedDomains = []
      this.notes = []

      this.$refs.inputForm.resetValidation()
      this.$refs.optionsForm.resetValidation()

      this.reset()
    },
    inputContinue() {
      if (this.$refs.inputForm.validate())
        this.nextStep()
    },
    addGroup(newGroup) {
      if (!newGroup)
        return

      this.alignedDocumentGroupPermissions.push({groupId: newGroup, permissions: {}})
    },
    permissionToKey(projectPermission) {
      return `${projectPermission.permissionType}_${projectPermission.resource}`
    },
    permissionToName(projectPermission) {
      return capitalize(`${projectPermission.permissionType} ${projectPermission.resource}`)
    },
    confirmImport() {
      if (this.sending || !this.$refs.inputForm.validate() || !this.$refs.optionsForm.validate())
        return

      const formData = formDataFromObject({
        owner: this.owner,
        confidentialityLevel: this.confidentialityLevel,
        fileMarkedAsTranslated: this.fileMarkedAsTranslated,
        connectAll: this.connectAll,
      })

      for (const file of this.inputFiles) {
        formData.append('files', file)
      }
      formData.append('tags', JSON.stringify(this.selectedTags))
      formData.append('domains', JSON.stringify(this.selectedDomains))
      formData.append('notes', JSON.stringify(this.notes))
      formData.append('groupPermissions',
          JSON.stringify(flattenGroupPermissions(this.alignedDocumentGroupPermissions)))

      if (this.filterLanguages)
        formData.append('filterLanguages', JSON.stringify(this.selectedLanguages))

      this.sending = true
      axios.post('/segment-manager/import-tmx', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(() => {
            this.nextStep()
            this.sending = false
          })
          .catch(err => {
            this.showError(err)
            this.sending = false
          })
    },
    stringToColor,
    isDark
  },
  computed: {
    groupsWithoutPermissions() {
      const groupsWithPermissions = this.alignedDocumentGroupPermissions
          .reduce((obj, permissions) => Object.assign(obj, {[permissions.groupId]: true}), {})
      return this.enumsValues.groups.filter(group => !(group.id in groupsWithPermissions))
    },
  }
}
</script>

<style scoped>

</style>
