<template>
  <v-dialog v-model="show" persistent max-width="60%">
    <v-form @submit.prevent="save" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <div class="pa-3">
            <v-text-field label="Title" v-model="editedItem.title" :rules="rules.title" autofocus
                          required></v-text-field>
            <v-text-field label="Description" v-model="editedItem.description"></v-text-field>
            <v-select label="Owner" :items="owners" item-text="name" item-value="id" v-model="editedItem.ownerId"
                      :rules="rules.owner" required></v-select>
            <v-select v-model="editedItem.projectSources" :items="projectSources" :rules="rules.projectSources"
                      label="Sources" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="editedItem.projectSources.splice(index, 1)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-select label="Confidentiality level" :items="confidentialityLevels" item-text="name" item-value="id"
                      v-model="editedItem.confidentialityLevelId" :rules="rules.confidentialityLevel"
                      required></v-select>

            <v-row>
              <v-col cols="12" md="6">
                <v-menu ref="dateRangeStartMenu" v-model="dateRangeStartMenu" :close-on-content-click=false
                        :return-value.sync="editedItem.dateRangeStart" transition="scale-transition" offset-y
                        min-width="auto">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-model="editedItem.dateRangeStart" label="Date range start"
                                  prepend-icon="mdi-calendar"
                                  readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.dateRangeStart" no-title scrollable>
                    <v-btn text color="error" @click="editedItem.dateRangeStart = null">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateRangeStartMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dateRangeStartMenu.save(editedItem.dateRangeStart)">OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu ref="dateRangeEndMenu" v-model="dateRangeEndMenu" :close-on-content-click=false
                        :return-value.sync="editedItem.dateRangeEnd" transition="scale-transition" offset-y
                        min-width="auto">
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field v-model="editedItem.dateRangeEnd" label="Date range end" prepend-icon="mdi-calendar"
                                  readonly :rules="rules.dateRangeEnd" v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedItem.dateRangeEnd" no-title scrollable>
                    <v-btn text color="error" @click="editedItem.dateRangeEnd = null">Clear</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dateRangeEndMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.dateRangeEndMenu.save(editedItem.dateRangeEnd)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-select v-model="editedItem.tags" :items="tags" label="Tags" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="removeGroup(index)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-select v-model="editedItem.domains" :items="domains" label="Domains" multiple chips deletable-chips
                      item-text="name" item-value="id">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="editedItem.domains.splice(index, 1)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-expansion-panels focusable :value=0>
              <v-expansion-panel>
                <v-expansion-panel-header>Groups & permissions</v-expansion-panel-header>
                <v-expansion-panel-content class="py-2">
                  <div v-for="(groupPermissions, index) in editedItem.groupPermissions"
                       :key="groupPermissions.groupId" class="d-flex align-center">
                    <v-btn @click="editedItem.groupPermissions.splice(index, 1)" class="mr-2">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-chip :color="stringToColor(groupById[groupPermissions.groupId].name)" class="mx-2"
                            :dark="isDark(stringToColor(groupById[groupPermissions.groupId].name))">
                      {{ groupById[groupPermissions.groupId].name }}
                    </v-chip>
                    <v-checkbox v-for="permission in projectPermissions" class="mx-2"
                                :key="permissionToKey(permission)" :label="permissionToName(permission)"
                                v-model="editedItem.groupPermissions[index].permissions[permissionToKey(permission)]">
                    </v-checkbox>
                  </div>
                  <div class="d-flex">
                    <v-select label="Add group" v-model="selectedGroup" :items="groupsWithoutPermissions" hide-details
                              item-text="name" item-value="id" @change="addGroup" @click="selectedGroup = null"
                              class="flex-grow-0"></v-select>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

import {capitalize, cloneDeep, groupBy} from "lodash";
import {isDark, stringToColor} from "@/util/color";
import {reduceById} from "@/util/js-utils";

const DEFAULT_PROJECT = {
  id: undefined,
  title: '',
  description: '',
  ownerId: undefined,
  confidentialityLevelId: undefined,
  dateRangeStart: undefined,
  dateRangeEnd: undefined,
  groupPermissions: [], // Example shape : [{groupId: 0, permissions: {admin_project: true, admin_pipelines: false}}]
  projectSources: [],
  tags: [],
  domains: []
}

const permissionFromKey = key => {
  const [permissionType, resource] = key.split('_', 2)
  return {permissionType, resource}
}

const flattenGroupPermissions = groupPermissions =>
    groupPermissions
        .flatMap(groupPermissions => Object.entries(groupPermissions.permissions)
            .filter(entry => entry[1]) // Keep only "checked" entries
            .map(([permissionKey]) => ({
              ...permissionFromKey(permissionKey),
              groupId: groupPermissions.groupId
            })))

export default {
  name: "ProjectDialog",
  props: {
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    owners: {type: Array, required: true},
    groups: {type: Array, required: true},
    tags: {type: Array, required: true},
    domains: {type: Array, required: true},
    projectSources: {type: Array, required: true},
    confidentialityLevels: {type: Array, required: true},
    projectPermissions: {type: Array, required: true},
    item: {type: Object, default: null}
  },
  data() {
    return {
      editedItem: Object.assign({}, DEFAULT_PROJECT),
      originalItem: Object.assign({}, DEFAULT_PROJECT),
      selectedGroup: null,
      dateRangeStartMenu: false,
      dateRangeEndMenu: false
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      if (this.show)
        this.populateForm(newVal)
    }
  },
  methods: {
    populateForm(item) {
      if (this.$refs.form)
        this.$refs.form.resetValidation()

      if (item) {
        this.editedItem = {
          ...DEFAULT_PROJECT, ...cloneDeep(item), ...{
            groupPermissions: this.structureGroupPermissions(item.groupPermissions)
          }
        }
      } else
        this.editedItem = cloneDeep(DEFAULT_PROJECT)

      this.originalItem = cloneDeep(this.editedItem)
      this.selectedGroup = null
    },
    addGroup(newGroup) {
      if (!newGroup)
        return

      this.editedItem.groupPermissions.push({groupId: newGroup, permissions: {}})
    },
    permissionToKey(projectPermission) {
      return `${projectPermission.permissionType}_${projectPermission.resource}`
    },
    permissionToName(projectPermission) {
      return capitalize(`${projectPermission.permissionType} ${projectPermission.resource}`)
    },
    save() {
      if (!this.$refs.form.validate())
        return

      this.onApply(Object.assign({}, this.editedItem,
          {groupPermissions: flattenGroupPermissions(this.editedItem.groupPermissions)}))
    },
    removeGroup(index) {
      this.editedItem.groupPermissions.splice(index, 1)
      this.selectedGroup = null
    },
    structureGroupPermissions(groupPermissions) {
      return Object.entries(groupBy(groupPermissions, 'groupId'))
          .map(([groupId, permissions]) => ({
            groupId,
            permissions: permissions.reduce((obj, permission) =>
                    Object.assign(obj, {[this.permissionToKey(permission)]: true}),
                cloneDeep(this.emptyPermissions))
          }))
    },
    stringToColor,
    isDark
  },
  computed: {
    formTitle() {
      return this.editedItem?.id ? "Edit project" : "Create project"
    },
    groupById() {
      return reduceById(this.groups)
    },
    groupsWithoutPermissions() {
      const groupsWithPermissions = this.editedItem.groupPermissions
          .reduce((obj, permissions) => Object.assign(obj, {[permissions.groupId]: true}), {})
      return this.groups.filter(group => !(group.id in groupsWithPermissions))
    },
    emptyPermissions() {
      return this.projectPermissions.reduce((obj, permission) =>
          Object.assign(obj, {[this.permissionToKey(permission)]: false}), {})
    },
    rules() {
      let dateRangeEndRules = []
      if (this.editedItem.dateRangeStart)
        dateRangeEndRules = [
          v => !!v || 'Required if range start is filled',
          v => this.editedItem.dateRangeStart <= v || 'Must be after the range start (or the same day)'
        ]

      return {
        title: [
          v => !!v || 'Required'
        ],
        owner: [
          v => !!v || 'Required'
        ],
        projectSources: [
          v => !!v.length || 'Required'
        ],
        confidentialityLevel: [
          v => !!v || 'Required'
        ],
        dateRangeEnd: dateRangeEndRules
      }
    }
  }
}
</script>

<style scoped>

</style>
