<template>
  <div>
    <v-stepper-step :step="2" :editable="step !== 5" :complete="step > 2">
      Choose projects/crawlings/pipelines
    </v-stepper-step>
    <v-stepper-content :step="2">
      <d-data-table :items="enumsValues.pipelines" :headers="headers" show-select :footer-props="footerProps"
                    :items-per-page=50 :loading="loading" group-by="projectId" v-model="selectedPipelines"
                    :fill-height="false" :enable-resize="false" :page.sync="page">
        <template v-slot:group.header="{group, toggle, headers, isOpen}">
          <!-- Override to disable grouping removal and allow select -->
          <td :colspan="headers.length" @click="toggle" class="project-grouping" :ref="`project-${group}`"
              :data-open="isOpen">
            <div class="d-flex">
              <v-simple-checkbox class="mr-4" :ripple=false
                                 :indeterminate="projectOrCrawlingById[group].partiallySelected"
                                 :value="projectOrCrawlingById[group].fullySelected"
                                 @input="changeProjectOrCrawlingSelection(group)"></v-simple-checkbox>
              <span class="font-weight-bold">
                  {{ projectOrCrawlingById[group].title }} | {{ pipelinesByProjectOrCrawling[group].length }} pipelines
                </span>
              <v-spacer></v-spacer>
              <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </div>
          </td>
        </template>
        <template v-slot:item.status="{item}">
          <v-tooltip top :open-delay=350>
            <template v-slot:activator="{on, attrs}">
                    <span v-bind="attrs" v-on="on">
                      <v-icon :color="pipelineStatus(item).color">
                        {{ pipelineStatus(item).icon }}
                      </v-icon>
                    </span>
            </template>
            <span>{{ pipelineStatus(item).text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.lastRunEnd="{item}">
          {{ formatDateToSeconds(new Date(item.lastRunEnd)) }}
        </template>
        <template v-slot:footer.prepend>
          <span class="text-body-1">{{ selectedPipelines.length }} pipelines selected</span>
        </template>
      </d-data-table>

      <v-tooltip top :open-delay=350 :disabled="selectedPipelines.length > 0">
        <template v-slot:activator="{on, attrs}">
              <span v-bind="attrs" v-on="on" class="d-inline-block">
                <v-btn color="primary" @click="nextStep" :disabled="selectedPipelines.length === 0" class="my-3">
                  Continue
                </v-btn>
              </span>
        </template>
        <span>At least 1 pipeline must be selected</span>
      </v-tooltip>
    </v-stepper-content>

    <v-stepper-step :step="3" :editable="step !== 5 && selectedPipelines.length > 0" :complete="step > 3">
      Options
    </v-stepper-step>
    <v-stepper-content :step="3">
      <v-form ref="optionsForm" v-model="optionsFormValid">
        <div class="d-flex">
          <v-simple-checkbox v-model="overrideOwner" :ripple=false class="mr-4"
                             @input="$refs.optionsForm.validate()"></v-simple-checkbox>
          <v-select :items="enumsValues.owners" v-model="owner" label="Override owner" :disabled="!overrideOwner"
                    :rules="rules.owner" item-text="name" item-value="id"></v-select>
        </div>
        <div class="d-flex">
          <v-simple-checkbox v-model="overrideConfidentialityLevel" :ripple=false class="mr-4"
                             @input="$refs.optionsForm.validate()"></v-simple-checkbox>
          <v-select :items="enumsValues.confidentialityLevels" v-model="confidentialityLevel"
                    label="Override confidentiality level" :rules="rules.confidentialityLevel"
                    :disabled="!overrideConfidentialityLevel" item-text="name" item-value="id"></v-select>
        </div>
        <div class="d-flex">
          <v-simple-checkbox v-model="filterLanguages" :ripple=false class="mr-4"
                             @input="$refs.optionsForm.validate()"></v-simple-checkbox>
          <v-select :items="enumsValues.languages" v-model="selectedLanguages" label="Filter languages"
                    :disabled="!filterLanguages" :rules="rules.selectedLanguages"
                    :item-text="item=>capitalize(item.name)" item-value="id"
                    multiple chips deletable-chips :menu-props="{ top: false, offsetY: true }"></v-select>
        </div>
        <v-expansion-panels focusable :value=0 class="pa-1">
          <v-expansion-panel>
            <v-expansion-panel-header>Groups & permissions</v-expansion-panel-header>
            <v-expansion-panel-content class="py-2">
              <div v-for="(groupPermissions, index) in alignedDocumentGroupPermissions"
                   :key="groupPermissions.groupId" class="d-flex align-center">
                <v-btn @click="alignedDocumentGroupPermissions.splice(index, 1)" class="mr-2">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-chip :color="stringToColor(enumsValues.groupById[groupPermissions.groupId].name)" class="mx-2"
                        :dark="isDark(stringToColor(enumsValues.groupById[groupPermissions.groupId].name))">
                  {{ enumsValues.groupById[groupPermissions.groupId].name }}
                </v-chip>
                <v-checkbox v-for="permission in enumsValues.permissions" class="mx-2"
                            :key="permissionToKey(permission)" :label="permissionToName(permission)"
                            v-model="alignedDocumentGroupPermissions[index].permissions[permissionToKey(permission)]">
                </v-checkbox>
              </div>
              <div class="d-flex">
                <v-select label="Add group" v-model="selectedGroup" :items="groupsWithoutPermissions" hide-details
                          item-text="name" item-value="id" @change="addGroup" @click="selectedGroup = null"
                          class="flex-grow-0"></v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-select :items="enumsValues.tags" v-model="selectedTags" label="Additional tags" item-text="name"
                  item-value="id"
                  multiple chips deletable-chips>
          <template #selection="{ item, index }">
            <v-chip :color="stringToColor(item.name)" :dark="isDark(stringToColor(item.name))" close
                    @click:close="selectedTags.splice(index, 1)">
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
        <v-select :items="enumsValues.domains" v-model="selectedDomains" label="Additional domains" item-text="name"
                  item-value="id" multiple chips deletable-chips>
          <template #selection="{ item, index }">
            <v-chip :color="stringToColor(item.name)" :dark="isDark(stringToColor(item.name))" close
                    @click:close="selectedDomains.splice(index, 1)">
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
        <div v-for="({}, index) in notes" :key="index">
          <v-textarea :label="`Note ${index+1}`" v-model="notes[index]" :rows="2" auto-grow
                      :rules="rules.notes" validate-on-blur>
            <template v-slot:append-outer>
              <v-btn @click="notes.splice(index, 1)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-textarea>
        </div>
        <v-btn @click="notes.push('')" class="d-block mx-1 my-3">
          <v-icon>mdi-plus</v-icon>
          Add a note
        </v-btn>
        <v-btn color="primary" @click="optionsContinue" class="d-block mx-1 mt-8 mb-3">
          Continue
        </v-btn>
      </v-form>
    </v-stepper-content>

    <v-stepper-step :step="4" :editable="step !== 5 && selectedPipelines.length > 0 && optionsFormValid"
                    :complete="step > 4">
      Review & confirm
    </v-stepper-step>
    <v-stepper-content :step="4">
      <div>
        <v-icon color="info" class="review-icon">mdi-information</v-icon>
        You selected {{ numSelected.alignedDocuments }} pairs of aligned documents from {{ selectedPipelines.length }}
        pipelines
        from {{ numSelected.projectsAndCrawlings }} projects and crawlings
      </div>
      <div>
        <v-icon v-if="overrideOwner" color="warning" class="review-icon">mdi-alert</v-icon>
        <v-icon v-else color="info" class="review-icon">mdi-information</v-icon>
        These projects and crawlings belong to {{ numSelected.owners }} different owners.
        <span v-if="overrideOwner">
            These owners will be replaced by
            <span class="font-weight-bold">
              {{ owner && enumsValues.ownerById[owner].name }}
            </span>
            in the imported data (no change will be made to existing data)
          </span>
        <span v-else>
            The imported data will keep their original owner
          </span>
      </div>
      <div>
        <v-icon v-if="overrideConfidentialityLevel" color="warning" class="review-icon">mdi-alert</v-icon>
        <v-icon v-else color="info" class="review-icon">mdi-information</v-icon>
        <span v-if="overrideConfidentialityLevel">
            The confidentiality level will be set to
            <span class="font-weight-bold">
              {{ confidentialityLevel && enumsValues.confidentialityLevelById[confidentialityLevel].name }}
            </span>
            in the imported data (no change will be made to existing data)
          </span>
        <span v-else>
            The imported data will keep their original confidentiality level
          </span>
      </div>
      <div>
        <v-icon v-if="filterLanguages" color="warning" class="review-icon">mdi-alert</v-icon>
        <v-icon v-else color="info" class="review-icon">mdi-information</v-icon>
        <span v-if="filterLanguages">
            Only the documents that contain a pair of the following languages will be kept :
            <span class="font-weight-bold">
              {{ selectedLanguages.map(languageCode => enumsValues.languageById[languageCode].name).join(', ') }}.
            </span>
            <br>
            {{ numSelected.filteredDocuments }} documents belong to one of the {{ this.filteredPipelines.length }}
            pipelines that match these languages. Only these documents will be imported
          </span>
        <span v-else>
            You have not specified any language filter
          </span>
      </div>
      <div>
        <v-icon color="info" class="review-icon">mdi-information</v-icon>
        A total of {{ numSelected.totalPermissions }} additional permissions will be given to
        {{ numSelected.groupsWithPermissions }} different groups. The existing project/crawling permissions will be
        copied and mapped
      </div>
      <div>
        <v-icon color="info" class="review-icon">mdi-information</v-icon>
        {{ selectedTags.length }} tags, {{ selectedDomains.length }} domains and {{ notes.length }} notes will be
        added to the imported data. The existing project/crawling tags and domains will be copied
      </div>
      <div class="py-5">
        <v-alert v-if="numSelected.filteredDocuments === 0" type="warning">
          According to your filters, no document can be imported
          (they have all been filtered, or the pipelines you chose did not contain any document)
        </v-alert>
        <span v-else>
            You can still change the options by clicking on the previous steps
          </span>
      </div>
      <v-btn color="primary" @click="confirmImport" class="mx-1 my-3" :disabled="numSelected.filteredDocuments === 0"
             :loading="sending">
        Import {{ numSelected.filteredDocuments }} pairs of aligned documents
      </v-btn>
    </v-stepper-content>

    <v-stepper-step :step="5" :complete="step === 5">
      Done
    </v-stepper-step>
    <v-stepper-content :step="5">
      <v-alert type="info">
        Alignments are being imported...
      </v-alert>
      <v-btn color="primary" @click="resetState">
        Import more
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>
import axios from "axios";
import {PIPELINE_STATUSES_ICONS} from "@/util/enums";
import {formatDateToSeconds} from "@/util/string-utils";
import {capitalize, groupBy} from "lodash";
import {stringToColor, isDark} from "@/util/color";
import {reduceById} from "@/util/js-utils";
import DDataTable from "@/components/wrappers/DDataTable";

const permissionFromKey = key => {
  const [permissionType, resource] = key.split('_', 2)
  return {permissionType, resource}
}

const flattenGroupPermissions = groupPermissions =>
    groupPermissions
        .flatMap(groupPermissions => Object.entries(groupPermissions.permissions)
            .filter(entry => entry[1]) // Keep only "checked" entries
            .map(([permissionKey]) => ({
              ...permissionFromKey(permissionKey),
              groupId: groupPermissions.groupId
            })))

export default {
  name: "ImportPipelinesStepper",
  components: {DDataTable},
  props: {
    enumsValues: {type: Object, required: true},
    loading: {type: Boolean, required: true},
    step: {type: Number, required: true},
    nextStep: {type: Function, required: true},
    reset: {type: Function, required: true},
    showError: {type: Function, required: true}
  },
  data() {
    return {
      selectedPipelines: [],
      overrideOwner: false,
      owner: null,
      overrideConfidentialityLevel: false,
      confidentialityLevel: null,
      filterLanguages: false,
      filterAlignments: false,
      filterXfirst: 0,
      filterXlast: 0,
      alignedDocumentGroupPermissions: [],
      selectedGroup: null,
      selectedLanguages: [],
      selectedTags: [],
      selectedDomains: [],
      notes: [],
      sending: false,
      optionsFormValid: false,
      page: 1,
      rules: {
        owner: [
          v => (!this.overrideOwner || !!v) || 'Required'
        ],
        confidentialityLevel: [
          v => (!this.overrideConfidentialityLevel || !!v) || 'Required'
        ],
        selectedLanguages: [
          v => (!this.filterLanguages || v.length >= 2) || 'Select at least 2 languages'
        ],
        filterFirstAlignments: [
          v => (!this.filterAlignments || v >= 0) || 'The smallest value is 0'
        ],
        filterLastAlignments: [
          v => (!this.filterAlignments || v >= 0) || 'The smallest value is 0'
        ],
        notes: [
          v => !!v && v.length >= 3 || 'Notes must be at least 3 characters long'
        ]
      },
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },
      headers: [
        {text: '', value: 'projectId'}, // Only used for grouping
        {text: 'Name', value: 'name'},
        {text: 'Language 1', value: 'language1'},
        {text: 'Language 2', value: 'language2'},
        {text: 'ID', value: 'id'},
        {text: 'Status', value: 'status'},
        {text: 'Last run end', value: 'lastRunEnd'},
        {text: 'Number of aligned documents', value: 'numAlignedDocuments'},
      ],
    }
  },
  watch: {
    enumsValues: {
      handler() {
        this.$nextTick(this.collapseProjectsAndCrawlings)
      },
      deep: true
    },
    page() {
      this.$nextTick(this.collapseProjectsAndCrawlings)
    }
  },
  mounted() {
    this.$nextTick(this.collapseProjectsAndCrawlings)
  },
  methods: {
    collapseProjectsAndCrawlings() {
      // Collapse all projects and crawlings by default
      for (const project of this.projectsAndCrawlings) {
        const headerCell = this.$refs[`project-${project.id}`]
        if (headerCell?.getAttribute('data-open') === 'true')
          headerCell.click?.()
      }
    },
    capitalize,
    resetState() {
      this.selectedPipelines = []
      this.overrideOwner = false
      this.owner = null
      this.overrideConfidentialityLevel = false
      this.confidentialityLevel = null
      this.filterLanguages = false
      this.alignedDocumentGroupPermissions = []
      this.selectedGroup = null
      this.selectedLanguages = []
      this.selectedTags = []
      this.selectedDomains = []
      this.notes = []

      this.$refs.optionsForm.resetValidation()

      this.collapseProjectsAndCrawlings()
      this.reset()
    },
    projectOrCrawlingPartiallySelected(projectId) {
      return this.pipelinesByProjectOrCrawling[projectId]?.some(pipeline =>
          Boolean(this.selectedPipelineById[pipeline.id]))
    },
    projectOrCrawlingFullySelected(projectId) {
      return this.pipelinesByProjectOrCrawling[projectId]?.every(pipeline =>
          Boolean(this.selectedPipelineById[pipeline.id]))
    },
    pipelineStatus(item) {
      return PIPELINE_STATUSES_ICONS[item.status]
    },
    changeProjectOrCrawlingSelection(projectId) {
      if (!this.projectOrCrawlingById[projectId].fullySelected)
        this.selectedPipelines = this.selectedPipelines.concat(this.pipelinesByProjectOrCrawling[projectId])
      else
        this.selectedPipelines = this.selectedPipelines.filter(pipeline => pipeline.projectId !== projectId)
    },
    optionsContinue() {
      if (this.$refs.optionsForm.validate() && this.selectedPipelines.length > 0)
        this.nextStep()
    },
    addGroup(newGroup) {
      if (!newGroup)
        return

      this.alignedDocumentGroupPermissions.push({groupId: newGroup, permissions: {}})
    },
    permissionToKey(projectPermission) {
      return `${projectPermission.permissionType}_${projectPermission.resource}`
    },
    permissionToName(projectPermission) {
      return capitalize(`${projectPermission.permissionType} ${projectPermission.resource}`)
    },
    confirmImport() {
      if (this.sending)
        return

      if (this.numSelected.filteredDocuments === 0 || !this.$refs.optionsForm.validate())
          // Should never happen
        this.showError('Invalid options. Check the previous steps')

      this.sending = true

      const data = {
        pipelines: this.filteredPipelines.map(pipeline => pipeline.id),
        tags: this.selectedTags,
        domains: this.selectedDomains,
        notes: this.notes,
        groupPermissions: flattenGroupPermissions(this.alignedDocumentGroupPermissions)
      }
      if (this.overrideOwner)
        data.owner = this.owner
      if (this.overrideConfidentialityLevel)
        data.confidentialityLevel = this.confidentialityLevel

      axios.post('/segment-manager/import-pipelines', data)
          .then(() => {
            this.nextStep()
            this.sending = false
          })
          .catch(err => {
            this.showError(err)
            this.sending = false
          })
    },
    formatDateToSeconds,
    stringToColor,
    isDark
  },
  computed: {
    projectsAndCrawlings() {
      return this.enumsValues.crawlings.map(crawling => ({
        ...crawling,
        id: crawling.projectId
      }))
          .concat(this.enumsValues.projects)
    },
    projectOrCrawlingById() {
      const projectsAndCrawlingsWithSelectedState = this.projectsAndCrawlings.map(projectOrCrawling => {
        const fullySelected = this.projectOrCrawlingFullySelected(projectOrCrawling.id)
        return Object.assign(projectOrCrawling, {
          fullySelected,
          partiallySelected: !fullySelected
              && this.projectOrCrawlingPartiallySelected(projectOrCrawling.id)
        })
      })

      return reduceById(projectsAndCrawlingsWithSelectedState)
    },
    pipelinesByProjectOrCrawling() {
      return Object.assign(
          this.projectsAndCrawlings.reduce((obj, projectOrCrawling) =>
              Object.assign(obj, {[projectOrCrawling.id]: []})),
          groupBy(this.enumsValues.pipelines, 'projectId'))
    },
    selectedPipelineById() {
      return reduceById(this.selectedPipelines)
    },
    groupsWithoutPermissions() {
      const groupsWithPermissions = this.alignedDocumentGroupPermissions
          .reduce((obj, permissions) => Object.assign(obj, {[permissions.groupId]: true}), {})
      return this.enumsValues.groups.filter(group => !(group.id in groupsWithPermissions))
    },
    filteredPipelines() {
      if (this.filterLanguages) {
        const selectedLanguagesMap = this.selectedLanguages
            .reduce((obj, language) => Object.assign(obj, {[language]: true}), {})
        return this.selectedPipelines
            .filter(pipeline => selectedLanguagesMap[pipeline.language1] && selectedLanguagesMap[pipeline.language2])
      }

      return this.selectedPipelines
    },
    numSelected() {
      const groupsWithAssignedPermissions = this.alignedDocumentGroupPermissions
          .filter(groupPermissions => Object.values(groupPermissions.permissions).some(checked => checked))

      return {
        alignedDocuments: this.selectedPipelines.reduce((total, pipeline) => total + pipeline.numAlignedDocuments, 0),
        projectsAndCrawlings: Object.keys(this.selectedPipelines.reduce((obj, pipeline) =>
            Object.assign(obj, {[pipeline.projectId]: true}), {})).length,
        owners: Object.keys(this.selectedPipelines.reduce((obj, pipeline) =>
            Object.assign(obj, {[this.projectOrCrawlingById[pipeline.projectId].ownerId]: true}), {})).length,
        filteredDocuments: this.filteredPipelines.reduce((total, pipeline) => total + pipeline.numAlignedDocuments, 0),
        totalPermissions: Object.values(groupsWithAssignedPermissions)
            .reduce((total, groupPermissions) =>
                total + Object.values(groupPermissions.permissions)
                    .filter(checked => checked).length, 0),
        groupsWithPermissions: Object.keys(groupsWithAssignedPermissions).length
      }
    }
  }
}
</script>

<!-- For some reason, the first tr with the class project-grouping is not affected if style is scoped -->
<style>
.project-grouping {
  cursor: pointer;
  background: #e3f2fd;
}

.review-icon {
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 12px;
}
</style>
