<template>
  <v-app>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="drawerExpanded = !drawerExpanded"
                          v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" class="text-decoration-none">
          Datalambic v{{ appVersion }}
        </router-link>
      </v-toolbar-title>
      <v-breadcrumbs
          :items="breadCrumbs()"
          divider="/"
          large
      >
        <template v-slot:divider>
          <v-icon>mdi-arrow-right</v-icon>
        </template>
        <template v-slot:item="props">
          <router-link :to="props.item.href" v-if="!props.item.disabled" class="text-decoration-none">
            <v-breadcrumbs-item
                :class="[props.item.disabled && 'disabled']"
            >
              {{ props.item.text }}
            </v-breadcrumbs-item>
          </router-link>
          <v-breadcrumbs-item :class="[props.item.disabled && 'disabled']" v-else>
            {{ props.item.text }}
          </v-breadcrumbs-item>

        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-menu bottom min-width="150px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="rgb(133, 133, 133)" size="39">
              <v-icon color="white">mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <template v-for="item in items">
            <v-list-item v-if="!item.subItems" link :to="item.to" :key="item.title">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group v-else :key="item.title" :prepend-icon="item.icon">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="subItem in item.subItems" link :to="subItem.to" :key="subItem.title">
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-if="showDrawer" app clipped v-model="drawerExpanded"
                         :expand-on-hover="$vuetify.breakpoint.lgAndUp" :width="200">
      <drawer></drawer>
    </v-navigation-drawer>
    <v-main>
      <div class="pa-4 fill-height">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "@/components/parts/Drawer";
import {stringToColor} from '@/util/color';
import {mapGetters, mapState} from "vuex";
import {isFunction} from "lodash";
import {version} from '../package'

const allItems = [
  {title: 'Login', icon: 'mdi-login', to: '/login', shown: ctx => !ctx.isAuthenticated && !ctx.authLoading},
  {title: 'Edit Account', icon: 'mdi-pencil', to: '/edit-account', shown: ctx => ctx.isAuthenticated},
  {title: 'Logout', icon: 'mdi-logout', to: '/logout', shown: ctx => ctx.isAuthenticated},
]

export default {
  name: 'App',
  components: {Drawer},
  data() {
    return {
      drawerExpanded: false,
      stringToColor,
      appVersion: version
    }
  },
  watch: {
    mustChangePassword(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.$router.currentRoute.path !== '/edit-account')
        this.$router.push('/edit-account')
    },
    '$vuetify.breakpoint.name'() {
      if (this.$vuetify.breakpoint.lgAndUp)
        this.drawerExpanded = true
    }
  },
  mounted() {
    this.drawerExpanded = this.$vuetify.breakpoint.lgAndUp
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      authLoading: state => state.authStatus === 'loading',
      mustChangePassword: state => state.auth.mustChangePassword
    }),
    items() {
      return allItems.filter(item => item.shown === undefined || item.shown(this))
    },
    showDrawer() {
      return this.isAuthenticated && !this.mustChangePassword
    }
  },
  methods: {
    breadCrumbs() {
      const pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadCrumbs = []
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = ''
      let href = ''
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}/${pathArray[i]}`

        const routeRecord = this.$route.matched[i]
        if (!routeRecord?.meta?.breadCrumb)
          continue

        href = href + breadcrumb

        const text = isFunction(routeRecord.meta.breadCrumb)
            ? routeRecord.meta.breadCrumb(this.$store, this.$route)
            : routeRecord.meta.breadCrumb

        const disabled = i + 1 === pathArray.length || this.$route.matched[i].meta.disabled
        breadCrumbs.push({
          href: href,
          disabled: disabled,
          text: text
        })
        breadcrumb = ''
      }
      return breadCrumbs
    }
  }
}
</script>

<style>
/*noinspection CssUnusedSymbol*/
.container.fill-height {
  /* Reset Vuetify custom value to initial default */
  align-items: stretch !important;
}
</style>
