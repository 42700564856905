<template>
  <div>
    <v-container fluid>
      <h1 class="text-h3 text-center">Home</h1>
      <v-row class="mt-12" justify="space-around" v-masonry>
        <v-col cols="12" lg="6" xl="4">
          <router-link class="tile-router-link" to="/projects" v-if="!mustChangePassword">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <projects ref="projects" @hook:updated="redraw" home-preview></projects>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4">
          <router-link class="tile-router-link" to="/crawlings" v-if="!mustChangePassword">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <crawlings ref="crawlings" @hook:updated="redraw" home-preview></crawlings>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.users">
          <router-link class="tile-router-link" to="/manage/users">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <users ref="users" @hook:updated="redraw" home-preview></users>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.groups">
          <router-link class="tile-router-link" to="/manage/groups">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <groups ref="groups" @hook:updated="redraw" home-preview></groups>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.tags">
          <router-link class="tile-router-link" to="/manage/tags">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <tags ref="tags" @hook:updated="redraw" home-preview></tags>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.domains">
          <router-link class="tile-router-link" to="/manage/domains">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <domains ref="domains" @hook:updated="redraw" home-preview></domains>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.owners">
          <router-link class="tile-router-link" to="/manage/owners">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <owners ref="owners" @hook:updated="redraw" home-preview></owners>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" lg="6" xl="4" v-if="visible.sources">
          <router-link class="tile-router-link" to="/manage/sources">
            <v-card width="100%" hover>
              <v-card-text class="tile-card-text">
                <sources ref="sources" @hook:updated="redraw" home-preview></sources>
              </v-card-text>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Projects from "@/components/pages/projects-pipelines/Projects";
import Users from "@/components/pages/manage/Users";
import Groups from "@/components/pages/manage/Groups";
import Tags from "@/components/pages/manage/Tags";
import Domains from "@/components/pages/manage/Domains";
import Owners from "@/components/pages/manage/Owners";
import {mapGetters, mapState} from "vuex";
import store from "@/store";
import Crawlings from "@/components/pages/crawlings/Crawlings";
import Sources from "@/components/pages/manage/Sources";

export default {
  name: 'Home',
  components: {Crawlings, Domains, Tags, Groups, Users, Projects, Owners, Sources},
  data() {
    return {
      permissions: {
        users: ['admin_users'],
        groups: ['admin_groups'],
        domains: ['admin_domains'],
        tags: ['admin_tags'],
        owners: ['admin_owners'],
        chnell: ['admin_chnell', 'use_chnell'],
        segmentManager: ['admin_segment-manager', 'use_segment-manager', 'create_segment-manager'],
        sources: ['admin_sources']
      }
    }
  },
  computed: {
    ...mapGetters(['getPermissions']),
    ...mapState(['mustChangePassword']),
    visible() {
      if (!this.mustChangePassword)
        return {
          users: this.authorized(this.permissions.users),
          groups: this.authorized(this.permissions.groups),
          domains: this.authorized(this.permissions.domains),
          tags: this.authorized(this.permissions.tags),
          owners: this.authorized(this.permissions.owners),
          chnell: this.authorized(this.permissions.chnell),
          segmentManager: this.authorized(this.permissions.segmentManager),
          sources: this.authorized(this.permissions.sources),
          projects: !this.mustChangePassword,
          crawlings: !this.mustChangePassword
        }
      return {
        users: false,
        groups: false,
        domains: false,
        tags: false,
        owners: false,
        chnell: false,
        segmentManager: false,
        projects: false,
        crawlings: false
      }
    }
  },
  methods: {
    redraw() {
      this.$redrawVueMasonry()
    },
    authorized(permissions) {
      if (permissions !== undefined && permissions !== [] && this.getPermissions !== undefined)
        for (const perm of permissions)
          if (store.getters.getPermissions[perm])
            return true

      return false
    }
  },
  mounted() {
    this.$watch("$refs.projects.projects", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.crawlings.projects", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.users.usersData", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.groups.groupsData", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.tags.tagsData", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.domains.domainsData", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.owners.ownersData", () => (this.$redrawVueMasonry()));
    this.$watch("$refs.sources.sourcesData", () => (this.$redrawVueMasonry()));
  },
}
</script>

<style scoped>
.tile-router-link {
  text-decoration: none;
  color: inherit;
}

.tile-card-text {
  pointer-events: none
}
</style>
