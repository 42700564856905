<template>
  <div v-resize="setInitialParentHeight" class="fill-height" ref="wrapper">
    <!-- Wrapped data-table -->
    <v-data-table v-bind="$attrs" v-on="$listeners" :height="tableHeight" :fixed-header="computedFixedHeader"
                  ref="table" :page.sync="tablePage" :server-items-length="serverItemsLength" :items="items"
                  :items-per-page.sync="tableItemsPerPage">

      <template v-slot:footer.page-text v-if="pageSelect">
        <div class="d-flex align-center">
          Page:
          <v-select v-model="tablePage" class="page-select" hide-details :items="pageItems"></v-select>
          <span v-if="numItems === 0">–</span>
          <span v-else>
            {{ firstItemIndex }}-{{ lastItemIndex }} of {{ numItems }}
          </span>
        </div>
      </template>

      <!-- Pass down slots -->
      <template v-for="({}, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"></slot>
      </template>

    </v-data-table>
  </div>
</template>

<script>
// /!\ Only works properly if properties are in pixels /!\
import {range} from "lodash";

const getStyleYProperty = (style, property) =>
    parseFloat(style.getPropertyValue(`${property}-top`))
    + parseFloat(style.getPropertyValue(`${property}-bottom`))

const getStyleYMargin = style =>
    getStyleYProperty(style, 'margin')

const getStyleYPadding = style =>
    getStyleYProperty(style, 'padding')

const getElementFullHeight = element => {
  const style = window.getComputedStyle(element)
  return element.clientHeight + getStyleYMargin(style)
}


export default {
  name: "d-data-table",
  inheritAttrs: false,
  data() {
    return {
      tableHeight: 0,
      headerFooterHeight: 0,
      idealParentHeight: 0,
      resizeObserver: null,
      internalPage: 1,
      internalItemsPerPage: this.itemsPerPage,
      previousFirstItemIndex: this.firstItemIndex
    }
  },
  props: {
    height: {type: [Number, String], required: false},
    fillHeight: {type: Boolean, required: false, default: true},
    fixedHeader: {type: Boolean, required: false, default: undefined},
    enableResize: {type: Boolean, required: false, default: true},
    pageSelect: {type: Boolean, required: false, default: false},
    page: {type: Number, required: false, default: undefined},
    serverItemsLength: {type: Number, required: false, default: -1},
    items: {type: Array, required: false, default: () => []},
    itemsPerPage: {type: Number, required: false, default: undefined}
  },
  computed: {
    computedFixedHeader() {
      return this.fixedHeader !== undefined ? this.fixedHeader : this.fillHeight
    },
    tablePage: {
      get() {
        return this.page !== undefined ? this.page : this.internalPage
      },
      set(newVal) {
        this.previousFirstItemIndex = this.firstItemIndex
        this.internalPage = newVal
        this.$emit('update:page', newVal)
      }
    },
    tableItemsPerPage: {
      get() {
        return this.internalItemsPerPage
      },
      set(newVal) {
        this.internalItemsPerPage = newVal
        this.$emit('update:itemsPerPage', newVal)

        // Keep firstItemIndex on the current page
        if (newVal !== -1 && this.previousFirstItemIndex)
          this.$nextTick(() => this.tablePage = Math.ceil(this.previousFirstItemIndex / newVal))
      }
    },
    numItems() {
      return this.serverItemsLength !== -1 ? this.serverItemsLength : this.items?.length ?? 0
    },
    pageItems() {
      if (this.tableItemsPerPage === -1)
        return [1]

      const numPages = Math.ceil(this.numItems / this.tableItemsPerPage)
      return range(1, numPages + 1)
    },
    firstItemIndex() {
      if (this.tableItemsPerPage === -1)
        return 1

      return (this.tablePage - 1) * this.tableItemsPerPage + 1
    },
    lastItemIndex() {
      if (this.tableItemsPerPage === -1)
        return this.numItems

      return Math.min(this.tablePage * this.tableItemsPerPage, this.numItems)
    }
  },
  watch: {
    enableResize() {
      this.$nextTick(this.setInitialParentHeight)
    },
    itemsPerPage(newVal) {
      this.internalItemsPerPage = newVal
    }
  },
  mounted() {
    if (!this.fillHeight)
      return

    this.resizeObserver = new ResizeObserver(this.computeTableHeight)
    // Observe the wrapper to know when to grow the table
    this.resizeObserver.observe(this.$refs.wrapper)
    // Observe the parent to know when to shrink the table
    this.resizeObserver.observe(this.$refs.wrapper.parentElement)


    // This only needs to be done once when first rendering the table (event without content)
    this.headerFooterHeight = this.computeHeaderFooterHeight()
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  },
  methods: {
    setInitialParentHeight() {
      if (!this.fillHeight || !this.enableResize) {
        this.tableHeight = this.height
        return
      }

      this.tableHeight = 0

      this.$nextTick(() => {
        // The parent height is now "ideal" (as big as possible without potential scrollbar caused by this table)
        this.idealParentHeight = this.$refs.wrapper.parentElement.clientHeight
        this.computeTableHeight()
      })
    },
    computeHeaderFooterHeight() {
      const tableElement = this.$refs.table.$el

      const tableHeaderElement = tableElement.querySelector('header')
      const tableHeaderHeight = tableHeaderElement ? getElementFullHeight(tableHeaderElement) : 0

      const tableFooterElement = tableElement.querySelector('.v-data-footer')
      const tableFooterHeight = tableFooterElement ? getElementFullHeight(tableFooterElement) : 0

      // At least "+ 1" is required for some reason (footer top border ?)
      // (but using "+ 2" to have a small safety margin)
      return tableHeaderHeight + tableFooterHeight + 2
    },
    computeTableHeight() {
      if (!this.enableResize)
        return

      const wrapperElement = this.$refs.wrapper
      const wrapperStyle = window.getComputedStyle(wrapperElement)
      let availableHeight = wrapperElement.clientHeight
          - getStyleYMargin(wrapperStyle) - getStyleYPadding(wrapperStyle)

      const currentParentHeight = wrapperElement.parentElement.clientHeight
      if (currentParentHeight > this.idealParentHeight)
          // Shrink to avoid overextending the parent
        availableHeight -= (currentParentHeight - this.idealParentHeight)

      const innerTableHeight = availableHeight - this.headerFooterHeight

      if (innerTableHeight <= 0) {
        if (process.env.NODE_ENV === 'development')
          console.warn(`d-data-table computed a negative height (${innerTableHeight}) for the table content. `
              + 'Please ensure that it can use all the available space.')
      } else
        this.tableHeight = innerTableHeight
    }
  }
}
</script>

<style scoped>

.page-select {
  width: 3.3rem;
  font-size: 0.75rem;
  padding: 0;
  margin: 0 34px;
}

</style>
