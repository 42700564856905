<script>
import {Bar, mixins} from 'vue-chartjs'

const {reactiveProp} = mixins

const DEFAULT_OPTIONS = {
  legend: {
    display: false
  },
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  }
}

export default {
  name: 'BarChart',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    options: {type: Object, required: false, default: () => DEFAULT_OPTIONS},
  },
  mounted() {
    // The chartData prop is declared in the mixin
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped>
</style>
