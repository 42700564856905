import {cloneDeep, pick} from "lodash";
import {RULE_TYPES} from "@/util/document-editor";

export const EMPTY_BLOCK = {
    type: "bloc",
    operator: "AND",
    children: []
}

export const BLOC_OPERATORS = {
    AND: 'AND',
    OR: 'OR',
    NOT: 'NOT'
}

export const FILTER_TYPES = {
    BOOLEAN: 'BOOLEAN',
    INTEGER: 'INTEGER',
    FLOAT: 'FLOAT',
    STRING: 'STRING',
    DATE: 'DATE',
    ENUM: 'ENUM',
    SEARCH: 'SEARCH'
}

export const FILTERS_OPERATORS = {
    SMALLER: '<',
    GREATER: '>',
    SMALLER_EQUAL: '<=',
    GREATER_EQUAL: '>=',
    EQUAL: '=',
}

export const DEFAULT_FILTERS = {
    type: 'bloc',
    operator: BLOC_OPERATORS.AND,
    children: []
}

export const FILTER_PARAMS = {
    [FILTER_TYPES.BOOLEAN]: undefined,
    [FILTER_TYPES.INTEGER]: {
        value: 0,
        operator: FILTERS_OPERATORS.SMALLER
    },
    [FILTER_TYPES.FLOAT]: {
        value: 0,
        operator: FILTERS_OPERATORS.SMALLER
    },
    [FILTER_TYPES.STRING]: '',
    [FILTER_TYPES.SEARCH]: {
        value: '',
        language: null
    },
    [FILTER_TYPES.DATE]: {
        value: undefined,
        operator: FILTERS_OPERATORS.EQUAL
    },
    [FILTER_TYPES.ENUM]: []
}

export const FILTER_CATEGORIES = {
    FILES: {ID: 'FILES', name: 'Project, Pipelines, Documents'},
    GROUPS: {ID: 'GROUPS', name: 'Document specific filters'},
    LANGUAGES: {ID: 'LANGUAGES', name: 'Languages'},
    COUNTS: {ID: 'COUNTS', name: 'Word and characters'},
    SCORES: {ID: 'SCORES', name: 'Scores and distances'},
    DUPLICATES: {ID: 'DUPLICATES', name: 'Duplicates'},
    SEARCH: {ID: 'SEARCH', name: 'Search'},
    META: {ID: 'META', name: 'Metadata (date, user) filters'},
    TILDE: {ID: 'TILDE', name: "Tilde's filters"},
}

export const FILTERS_DETAILS = {
    // Project, Pipelines, Documents
    // TODO: Re-enable these filters
    // projects: {
    //     name: "Projects",
    //     category: FILTER_CATEGORIES.FILES.ID,
    //     description: "Filters the alignments coming from the following projects",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'projects'
    //     }
    // },
    // pipeline: {
    //     name: "Pipeline",
    //     category: FILTER_CATEGORIES.FILES.ID,
    //     description: "Filters the alignments coming from the following pipelines",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'pipelines'
    //     }
    // },
    // document: {
    //     name: "Document",
    //     category: FILTER_CATEGORIES.FILES.ID,
    //     description: "Filters the alignments coming from the following documents",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'documents'
    //     }
    // },
    pipelineAlignedDocuments: {
        name: "Pipeline aligned documents",
        category: FILTER_CATEGORIES.FILES.ID,
        description: "Filters the aligned documents with these ids",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'pipelineAlignedDocuments'
        }
    },

    // Groups, tags, domains, owners and confidentiality
    confidentialityLevel: {
        name: "Confidentiality level",
        category: FILTER_CATEGORIES.GROUPS.ID,
        description: "Filters the alignments by confidentiality level",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'confidentialityLevels'
        }
    },
    groups: {
        name: "Groups",
        category: FILTER_CATEGORIES.GROUPS.ID,
        description: "Filters the alignments by the groups that can see them",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'groups'
        }
    },
    tags: {
        name: "Tags",
        category: FILTER_CATEGORIES.GROUPS.ID,
        description: "Filters the alignments that are tagged with the following tags",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'tags'
        }
    },
    domains: {
        name: "Domains",
        category: FILTER_CATEGORIES.GROUPS.ID,
        description: "Filters the alignments that are part of the following domains",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'domains'
        }
    },
    owners: {
        name: "Owners",
        category: FILTER_CATEGORIES.GROUPS.ID,
        description: "Filters the alignments that are possessed by the following owners",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'owners'
        }
    },

    // Languages
    language: { // TODO: Not sure about this one
        name: "Languages",
        category: FILTER_CATEGORIES.LANGUAGES.ID,
        description: "Filters the alignments that contains the following languages in either the source or the target",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'languages'
        }
    },
    // sourceLanguage: {
    //     name: "Source Languages",
    //     category: FILTER_CATEGORIES.LANGUAGES.ID,
    //     description: "Filters the alignments that have the following languages as a source",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'languages'
    //     }
    // },
    // targetLanguage: {
    //     name: "Target Languages",
    //     category: FILTER_CATEGORIES.LANGUAGES.ID,
    //     description: "Filters the alignments that have the following languages as a target",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'languages'
    //     }
    // },
    detectedLanguage: { // TODO: Not sure about this one
        name: "Detected Languages",
        category: FILTER_CATEGORIES.LANGUAGES.ID,
        description: "Filters the alignments that contains the following detected languages in either the source or the target",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'languages' // TODO: Detected language can be outside of the list as well !
        }
    },
    // detectedSource: {
    //     name: "Detected Source Language",
    //     category: FILTER_CATEGORIES.LANGUAGES.ID,
    //     description: "Filters the alignments that have the following detected languages as a source",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'languages' // TODO: Detected language can be outside of the list as well !
    //     }
    // },
    // detectedTarget: {
    //     name: "Detected Target Language",
    //     category: FILTER_CATEGORIES.LANGUAGES.ID,
    //     description: "Filters the alignments that have the following detected languages as a target",
    //     filterType: FILTER_TYPES.ENUM,
    //     meta: {
    //         enumKey: 'languages' // TODO: Detected language can be outside of the list as well !
    //     }
    // },
    languageDelta: {
        name: "Language Delta",
        category: FILTER_CATEGORIES.LANGUAGES.ID,
        description: "Filters the alignments that have a different detected language than the specified language",
        filterType: FILTER_TYPES.BOOLEAN
    },

    // Word and characters
    wordCount: {
        name: "Word Count",
        category: FILTER_CATEGORIES.COUNTS.ID,
        description: "Filters the alignments based on the word count",
        filterType: FILTER_TYPES.INTEGER,
        meta: {
            min: 0
        }
    },
    charCount: {
        name: "Character Count",
        category: FILTER_CATEGORIES.COUNTS.ID,
        description: "Filters the alignments based on the character count",
        filterType: FILTER_TYPES.INTEGER,
        meta: {
            min: 0
        }
    },
    wordRatio: {
        name: "Word ratio",
        category: FILTER_CATEGORIES.COUNTS.ID,
        description: "Filters the alignments based on the ratio of words between the source and the target",
        filterType: FILTER_TYPES.FLOAT,
        meta: {
            min: 0,
            max: 1
        }
    },
    charRatio: {
        name: "Character ratio",
        category: FILTER_CATEGORIES.COUNTS.ID,
        description: "Filters the alignments based on the ratio of characters between the source and the target",
        filterType: FILTER_TYPES.FLOAT,
        meta: {
            min: 0,
            max: 1
        }
    },
    containLetters: {
        name: "Contain letters",
        category: FILTER_CATEGORIES.COUNTS.ID,
        description: "Filters the alignments that contain at least 1 letter",
        filterType: FILTER_TYPES.BOOLEAN
    },

    // Scores and distances
    // scores: {
    //     name: "Scores",
    //     category: FILTER_CATEGORIES.SCORES.ID,
    //     description: "Filters the alignments based on their alignment score",
    //     filterType: FILTER_TYPES.FLOAT,
    //     meta: {
    //         min: 0,
    //         max: 1
    //     }
    // },
    distances: {
        name: "Distances",
        category: FILTER_CATEGORIES.SCORES.ID,
        description: "Filters the alignments based on their alignment distance",
        filterType: FILTER_TYPES.FLOAT,
        meta: {
            min: 0
        }
    },

    // Duplicates
    // duplicates: {
    //     name: "Duplicates",
    //     category: FILTER_CATEGORIES.DUPLICATES.ID,
    //     description: "Filters the alignments that are duplicated",
    //     filterType: FILTER_TYPES.BOOLEAN
    // },
    // conflictingDuplicates: {
    //     name: "Conflicting duplicates",
    //     category: FILTER_CATEGORIES.DUPLICATES.ID,
    //     description: "Filters the alignments that have the same src/trg but a different trg/src",
    //     filterType: FILTER_TYPES.BOOLEAN
    // },
    empty: {
        name: "Empty segments",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that have an empty source or an empty target",
        filterType: FILTER_TYPES.BOOLEAN
    },

    // Search Queries
    search: {
        name: "Search",
        category: FILTER_CATEGORIES.SEARCH.ID,
        description: "Filters the alignments that match the following query",
        filterType: FILTER_TYPES.SEARCH
    },
    // sourceSearch: {
    //     name: "Source Search",
    //     category: FILTER_CATEGORIES.SEARCH.ID,
    //     description: "Filters the alignments that match the following query in the source",
    //     filterType: FILTER_TYPES.SEARCH
    // },
    // targetSearch: {
    //     name: "Target Search",
    //     category: FILTER_CATEGORIES.SEARCH.ID,
    //     description: "Filters the alignments that match the following query in the target",
    //     filterType: FILTER_TYPES.SEARCH
    // },

    strictSearch: {
        name: "Strict search",
        category: FILTER_CATEGORIES.SEARCH.ID,
        description: "Filters the alignments that strictly match the following query",
        filterType: FILTER_TYPES.STRING
    },
    // strictSourceSearch: {
    //     name: "Strict source Search",
    //     category: FILTER_CATEGORIES.SEARCH.ID,
    //     description: "Filters the alignments that strictly match the following query in the source",
    //     filterType: FILTER_TYPES.STRING
    // },
    // strictTargetSearch: {
    //     name: "Strict target Search",
    //     category: FILTER_CATEGORIES.SEARCH.ID,
    //     description: "Filters the alignments that strictly match the following query in the target",
    //     filterType: FILTER_TYPES.STRING
    // },

    // Meta queries
    importUser: {
        name: "Importation user",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments that have been imported by the following users",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'users'
        }
    },
    modifUser: {
        name: "Last modification user",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments that have been last modified by the following users",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumKey: 'users'
        }
    },
    importDate: {
        name: "Importation date",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments by the importation date",
        filterType: FILTER_TYPES.DATE
    },
    modifDate: {
        name: "Last modification date",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments by the last modification date",
        filterType: FILTER_TYPES.DATE
    },
    modified: {
        name: "Has been modified",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments that have been modified",
        filterType: FILTER_TYPES.BOOLEAN
    },

    // Document-editor
    pipelineAlignmentAppliedRuleType: {
        name: "Alignment state",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments that have one of the given states",
        filterType: FILTER_TYPES.ENUM,
        meta: {
            enumValues: Object.values(RULE_TYPES)
                .map(type => ({id: type.id, name: type.state.name}))
        }
    },
    pipelineAlignmentUncertain: {
        name: "Alignment uncertain",
        category: FILTER_CATEGORIES.META.ID,
        description: "Filters the alignments that are flagged as uncertain",
        filterType: FILTER_TYPES.BOOLEAN
    },

    // TILDE's FILTERS
    identical: {
        name: "(Tilde) Identical source-target",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that have the same src and trg",
        filterType: FILTER_TYPES.BOOLEAN
    },
    // tildeDuplicates: {
    //     name: "(Tilde) Duplicate segments",
    //     category: FILTER_CATEGORIES.TILDE.ID,
    //     description: "Filters the alignments that are duplicates",
    //     filterType: FILTER_TYPES.BOOLEAN
    // },
    // tildeEmpty: {
    //     name: "(Tilde) Empty segments",
    //     category: FILTER_CATEGORIES.TILDE.ID,
    //     description: "Filters the alignments that have an empty source or an empty target",
    //     filterType: FILTER_TYPES.BOOLEAN
    // },
    tildeCharRatio: {
        name: "(Tilde) Character ratio",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments by the ratio of character > 1/3",
        filterType: FILTER_TYPES.BOOLEAN
    },
    tildeWordRatio: {
        name: "(Tilde) Word ratio",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments by the ratio of words > 1/3",
        filterType: FILTER_TYPES.BOOLEAN
    },
    tildeMaxChar: {
        name: "(Tilde) Max characters",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that have more than 1000 characters",
        filterType: FILTER_TYPES.BOOLEAN
    },
    tildeBiggestWord: {
        name: "(Tilde) Biggest word",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that have words with more than 50 characters",
        filterType: FILTER_TYPES.BOOLEAN
    },
    tildeWordCount: {
        name: "(Tilde) Word count",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that have more than 400 words",
        filterType: FILTER_TYPES.BOOLEAN
    },
    // tildeMismatchedDigits: {
    //     name: "(Tilde) Mismatched digits",
    //     category: FILTER_CATEGORIES.TILDE.ID,
    //     description: "Filters the alignments that have different digits in the source and the target",
    //     filterType: FILTER_TYPES.BOOLEAN
    // },
    tildeTranslatedWordRatio: {
        name: "(Tilde) Not translated words ratio",
        category: FILTER_CATEGORIES.TILDE.ID,
        description: "Filters the alignments that more than 50% of the words that were not translated",
        filterType: FILTER_TYPES.BOOLEAN
    }
}

const SEGMENT_MANAGER_FILTER_NAMES = [
    'confidentialityLevel', 'groups', 'tags', 'domains', 'owners',
    'language', 'detectedLanguage', 'languageDelta',
    'wordCount', 'charCount', 'wordRatio', 'charRatio', 'containLetters',
    'distances',
    'search', 'strictSearch',
    'importUser', 'modifUSer', 'importDate', 'modifDate', 'modified',
    'pipelineAlignmentAppliedRuleType', 'pipelineAlignmentUncertain',
    'empty', 'identical',
    'tildeCharRatio', 'tildeWordRatio', 'tildeMaxChar', 'tildeBiggestWord', 'tildeWordCount', 'tildeTranslatedWordRatio'
]

export const SEGMENT_MANAGER_FILTERS_DETAILS = pick(FILTERS_DETAILS, SEGMENT_MANAGER_FILTER_NAMES)

const DOCUMENT_EDITOR_FILTER_NAMES = [
    'pipelineAlignedDocuments',
    'distances', 'containLetters',
    'wordCount', 'charCount', 'wordRatio', 'charRatio', 'search', 'strictSearch',
    'pipelineAlignmentAppliedRuleType', 'pipelineAlignmentUncertain',
    'empty', 'identical',
    'tildeCharRatio', 'tildeWordRatio', 'tildeMaxChar', 'tildeBiggestWord', 'tildeWordCount', 'tildeTranslatedWordRatio'
]

export const DOCUMENT_EDITOR_FILTERS_DETAILS = pick(FILTERS_DETAILS, DOCUMENT_EDITOR_FILTER_NAMES)

export function filterFromName(filterName) {
    const filterDetails = FILTERS_DETAILS[filterName]
    const filterParams = cloneDeep(FILTER_PARAMS[filterDetails.filterType])
    return {
        type: 'filter',
        name: filterName,
        params: filterParams
    }
}

function filterToText(filter) {
    const name = filter.name
    const filterDetails = FILTERS_DETAILS[name]
    switch (filterDetails.filterType) {
        case FILTER_TYPES.BOOLEAN:
            return name
        case FILTER_TYPES.INTEGER:
        case FILTER_TYPES.FLOAT:
        case FILTER_TYPES.DATE:
            return `${name} ${filter.params.operator} ${filter.params.value}`
        case FILTER_TYPES.STRING:
            return `${name} = "${filter.params.replaceAll('"', '')}"`
        case FILTER_TYPES.ENUM:
            return `${name} = [${filter.params.join(', ')}]`
        case FILTER_TYPES.SEARCH:
            return `${name} = "${filter.params.value.replaceAll('"', '')}" (${filter.params.language})`
    }
}

function indentText(indentation) {
    let indent = ''
    for (let i = 0; i < indentation; i++)
        indent += '    '
    return indent
}

export function filterBlocToText(filterBloc, indentation = 0) {
    if (!filterBloc)
        return ''

    if (filterBloc.type === 'filter')
        return indentText(indentation) + filterToText(filterBloc)

    let text = indentText(indentation) + filterBloc.operator + "(\n"
    const children = filterBloc.children

    for (const [index, child] of children.entries())
        text += filterBlocToText(child, indentation + 1) + (index < children.length - 1 ? ',\n' : '\n')

    text += indentText(indentation) + ')'
    return text
}

function blocOperator(text) {
    if (text.startsWith(BLOC_OPERATORS.AND))
        return BLOC_OPERATORS.AND

    if (text.startsWith(BLOC_OPERATORS.OR))
        return BLOC_OPERATORS.OR

    if (text.startsWith(BLOC_OPERATORS.NOT))
        return BLOC_OPERATORS.NOT
}

function isBloc(text) {
    return !!blocOperator(text)
}

function parseBloc(text) {
    let remainingText = text.trim()
    const operator = blocOperator(remainingText)

    // Remove operator
    remainingText = remainingText.substring(operator.length).trimStart()

    // Remove first parenthesis
    remainingText = remainingText.substring(1).trimStart()

    // Create new Bloc object
    const parsedBloc = {...cloneDeep(EMPTY_BLOCK), operator}

    // Loop over children
    while (remainingText[0] !== ')') {
        const [child, newRemainingText] = parseBlocOrFilter(remainingText.trimStart())
        parsedBloc.children.push(child)
        remainingText = newRemainingText.trimStart()

        if (remainingText[0] === ',')
            remainingText = remainingText.substring(1).trimStart()
    }

    // Remove closing parenthesis
    return [parsedBloc, remainingText.substring(1).trimStart()]
}

function parseFilter(text) {
    let remainingText = text.trimStart()
    const filterName = remainingText.match(/([a-zA-Z]+)/)[0]
    if (!(filterName in FILTERS_DETAILS))
        throw new Error(`Unknown filter name : ${filterName}`)

    remainingText = remainingText.substring(filterName.length).trimStart()

    const filterType = FILTERS_DETAILS[filterName].filterType
    const parsedFilter = filterFromName(filterName)

    switch (filterType) {
        case FILTER_TYPES.BOOLEAN:
            break
        case FILTER_TYPES.STRING: {
            remainingText = remainingText.substring(1).trimStart() // remove operator
            const query = remainingText.match(/(".*?(?<!\\)")/)[1] // Get the search query
            remainingText = remainingText.substring(query.length).trimStart() // Remove the query from the remaining text
            parsedFilter.params = query.slice(1, -1).replace('\\', '') // Remove the quotes from the query
            break
        }
        case FILTER_TYPES.INTEGER: {
            parsedFilter.params.operator = remainingText.match(/([<>=]{1,2})/)[1]
            remainingText = remainingText.substring((parsedFilter.params.operator.length)).trimStart()
            const value = remainingText.match(/(\d+)/)[1]
            remainingText = remainingText.substring(value.length).trimStart()
            parsedFilter.params.value = parseInt(value)
            break
        }
        case FILTER_TYPES.FLOAT: {
            parsedFilter.params.operator = remainingText.match(/([<>=]{1,2})/)[1]
            remainingText = remainingText.substring((parsedFilter.params.operator.length)).trimStart()
            const value = remainingText.match(/(\d+\.\d+)/)[1]
            remainingText = remainingText.substring(value.length).trimStart()
            parsedFilter.params.value = parseFloat(value)
            break
        }
        case FILTER_TYPES.DATE: {
            parsedFilter.params.operator = remainingText.match(/([<>=]{1,2})/)[1]
            remainingText = remainingText.substring((parsedFilter.params.operator.length)).trimStart()
            const value = remainingText.match(/(\d{4}-\d{2}-\d{2})/)[1]
            remainingText = remainingText.substring(value.length).trimStart()
            parsedFilter.params.value = value
            break
        }
        case FILTER_TYPES.ENUM: {
            remainingText = remainingText.substring(1).trimStart() // remove operator
            const strValue = remainingText.match(/(\[.*?])/)[1]
            remainingText = remainingText.substring(strValue.length).trimStart()

            parsedFilter.params = strValue
                .substring(1, strValue.length - 1) // Remove brackets
                .trim()
                .split(/\s*,\s*/)
                .map(item => { // Try to parse it, otherwise keep it as string
                    const parsedItem = parseInt(item)
                    return isNaN(parsedItem) ? item : parsedItem
                })

            break
        }
        case FILTER_TYPES.SEARCH: {
            remainingText = remainingText.substring(1).trimStart()
            const query = remainingText.match(/(".*?(?<!\\)")/)[1]
            remainingText = remainingText.substring(query.length).trimStart()
            remainingText = remainingText.substring(1).trimStart() // Remove the opening parenthesis
            const language = remainingText.substring(0, 2)
            remainingText = remainingText.substring(language.length).trimStart() // Remove the 2-letters language
            remainingText = remainingText.substring(1).trimStart() // Remove the closing parenthesis
            parsedFilter.params.value = query.slice(1, -1)  // Remove quotes
            parsedFilter.params.language = language
        }
    }

    return [parsedFilter, remainingText.trimStart()]
}

export function parseBlocOrFilter(text) {
    const trimmedText = text.trimStart()

    if (isBloc(trimmedText))
        return parseBloc(trimmedText)

    return parseFilter(trimmedText)
}

export const numFiltersInBloc = filterBloc => {
    let numFilters = 0

    for (let child of filterBloc.children) {
        if (child.type === 'bloc')
            numFilters += numFiltersInBloc(child)
        else
            numFilters++
    }

    return numFilters
}
