<template>
  <div class="fill-height">
    <h2 class="text-center">{{ title }}</h2>
    <div v-if="!data || !data.content" class="fill-height d-flex align-center justify-center">
      <h2 class="pa-0 ma-auto">{{ noFileMessage }}</h2>
    </div>
    <div v-else-if="loading" class="fill-height d-flex align-center justify-center">
      <v-progress-circular :size="40" indeterminate></v-progress-circular>
    </div>
    <div v-else class="fill-height" style="background-color: #f0f0f0">
      <object v-if="url" :data="url" type="text/plain"
              width="100%" class="fill-height">
        <a :href="url">No Support?</a>
      </object>
    </div>
  </div>
</template>

<script>
import {capitalize} from "lodash";

const STAGE_TYPES = {
  DOCUMENT_2_TEXT: "document2text",
  TOKENIZER: "tokenizer",
  DOCUMENT_MATCHER: "documentMatcher"
}

export default {
  name: "TextViewer",
  components: {},
  props: {
    data: {type: Object, required: false},
    isInput: {type: Boolean, required: true},
    showError: {type: Function, required: true},
  },
  data() {
    return {
      error: null,
      hasError: false,
      url: undefined,
      loading: true,
    }
  },
  watch: {
    data() {
      if (this.data && this.data.content && this.url === undefined)
        this.createTextFile()
    }
  },
  beforeDestroy() {
    if (this.url !== undefined) {
      window.URL.revokeObjectURL(this.url)
      this.url = undefined
    }
  },
  mounted() {
    if (this.data && this.data.content && this.url === undefined)
      this.createTextFile()
  },
  computed: {
    title() {
      const io = this.isInput ? "input" : "output"
      return capitalize(this.stageType) + " " + io
    },
    noFileMessage() {
      const io = this.isInput ? "input" : "output"
      return "There is no " + capitalize(this.stageType) + " " + io + " for this file"
    },
    stage() {
      return this.$route.params.stageName
    },
    stageType() {
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_2_TEXT))
        return STAGE_TYPES.DOCUMENT_2_TEXT
      if (this.stage.includes(STAGE_TYPES.TOKENIZER))
        return STAGE_TYPES.TOKENIZER
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_MATCHER))
        return STAGE_TYPES.DOCUMENT_MATCHER
      return undefined
    },
  },
  methods: {
    createTextFile() {
      let data = new Blob([this.data.content], {type: 'text/plain'});

      if (this.url !== null && this.url !== undefined) {
        window.URL.revokeObjectURL(this.url);
      }

      this.url = window.URL.createObjectURL(data);
      this.loading = false
    }
  }
}
</script>

<style scoped>
/* width */
#parent::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#parent::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#parent::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#parent::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
