<template>
  <div>
    <h3 class="text-h3 text-center">{{ title }}</h3>
    <v-container fluid class="mt-4">
      <v-row>
        <v-col v-for="(item, key) in stats" :key="key" cols="12" md="6" lg="4" xl="3">
          <base-chart :data="item" :enums-values="enumsValues"></base-chart>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseChart from "@/components/parts/segment-manager/stats/BaseChart";

export default {
  name: "Graphics",
  components: {BaseChart},
  props: {
    stats: {type: Array, required: true},
    filtered: {type: Number, required: true},
    total: {type: Number, required: true},
    enumsValues: {type: Object, required: true}
  },
  computed: {
    title() {
      return `Alignments: ${this.filtered}/${this.total} (${this.ratio}%)`
    },
    ratio() {
      return Math.round(this.filtered / this.total * 1000.0) / 10
    },
  }
}
</script>

<style scoped>
</style>
