<template>
  <div>
    <filter-dialog :filter="dialogFilter" :enums-values="enumsValues" :on-close="closeFilterDialog"
                   :on-apply="handleFilterDialogApply" :show="filterDialogShown"
                   :available-filter-details="filterDetails"></filter-dialog>

    <v-expansion-panels v-model="expandedPanel">
      <v-expansion-panel>
        <v-expansion-panel-header class="text-h6" v-if="expandedPanel!==undefined">
          {{ numAppliedFilters }}
          filter(s) applied on
          <strong class="flex-grow-0 font-weight-bold mx-1">{{ appliedOnModified ? 'modified' : 'initial' }}</strong>
          data
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <filter-bloc :id="0" :show-dialog="showFilterDialog" :on-modify="handleFilterBlocModify" :depth="0"
                         :enums-values="enumsValues" :filter-bloc="filters"></filter-bloc>
          </div>
          <v-switch v-model="filterOnModified" label="Apply filters on modified data"></v-switch>
          <v-btn large color="primary" @click="applyFilters" :disabled="filtersAlreadyApplied || loading"
                 :loading="loading">
            Apply filters
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import {cloneDeep, isEqual} from "lodash"
import {
  BLOC_OPERATORS,
  DOCUMENT_EDITOR_FILTERS_DETAILS,
  numFiltersInBloc
} from "@/components/parts/filters/filters-utils"
import FilterBloc from "../filters/FilterBloc"
import FilterDialog from "../filters/FilterDialog"

const DEFAULT_FILTERS = {
  type: 'bloc',
  operator: BLOC_OPERATORS.OR,
  children: [
    {
      type: 'filter',
      name: 'empty',
    },
    {
      type: 'filter',
      name: 'identical',
    },
    {
      type: 'filter',
      name: 'tildeCharRatio',
    },
    {
      type: 'filter',
      name: 'tildeWordRatio',
    },
    {
      type: 'filter',
      name: 'tildeMaxChar',
    },
    {
      type: 'filter',
      name: 'tildeBiggestWord',
    },
    {
      type: 'filter',
      name: 'tildeWordCount',
    },
    {
      type: 'filter',
      name: 'tildeTranslatedWordRatio',
    }
  ]
}

export default {
  name: "DocumentEditorFilters",
  components: {FilterBloc, FilterDialog},
  props: {
    appliedFilters: {type: Object, required: true},
    appliedOnModified: {type: Boolean, required: true},
    enumsValues: {type: Object, required: true},
    fetchAlignments: {type: Function, required: true},
    loading: {type: Boolean, required: true},
  },
  data() {
    return {
      expandedPanel: undefined,
      dialogFilter: undefined,
      handleFilterDialogApply: null,
      filterDialogShown: false,
      filters: {},
      filterOnModified: false,
      filterDetails: DOCUMENT_EDITOR_FILTERS_DETAILS,
      firstAppliedFiltersChange: true
    }
  },
  watch: {
    appliedFilters(newVal) {
      if (this.firstAppliedFiltersChange) {
        this.firstAppliedFiltersChange = false
        this.filters = cloneDeep(DEFAULT_FILTERS)
      } else
        this.filters = cloneDeep(newVal)
    },
  },
  beforeMount() {
    this.filters = cloneDeep(this.appliedFilters)
  },
  computed: {
    numAppliedFilters() {
      if (!this.appliedFilters?.children)
        return 0

      return numFiltersInBloc(this.appliedFilters)
    },
    filtersAlreadyApplied() {
      return this.filterOnModified === this.appliedOnModified && isEqual(this.filters, this.appliedFilters)
    }
  },
  methods: {
    togglePanel() {
      // Because `expandedPanel` is either undefined or 0, this "dirty" line of code is necessary
      this.expandedPanel = this.expandedPanel === undefined ? 0 : undefined
    },
    hidePanel() {
      this.expandedPanel = undefined
    },
    showFilterDialog(onDialogApply, filter = null) {
      this.dialogFilter = filter
      this.handleFilterDialogApply = onDialogApply
      this.filterDialogShown = true
    },
    closeFilterDialog() {
      this.dialogFilter = null
      this.handleFilterDialogApply = null
      this.filterDialogShown = false
    },
    handleFilterBlocModify(_, filterBloc) {
      this.filters = cloneDeep(filterBloc)
    },
    applyFilters() {
      this.fetchAlignments(cloneDeep(this.filters), this.filterOnModified)
    }
  }
}
</script>

<style scoped>

</style>
