import axios from "axios";
import {contentTypeFromFileName} from "@/util/string-utils";

/**
 *
 * @param url
 * @param downloadName
 * @param contentType The content type of the response. Set to `null` to force it being undefined
 * @param method
 * @param data
 * @param revoke
 * @param revokeM$Timeout
 * @returns {Promise<AxiosResponse<any>>}
 */
export const downloadFile = (url, downloadName, contentType = undefined, method = 'get', data = undefined, revoke = false, revokeM$Timeout = 10000) => {
    const type = contentType || contentType !== null && contentTypeFromFileName(url)
    const options = type ? {type} : undefined

    return axios({method, url, responseType: 'arraybuffer', data})
        .then(response => {
            const objectUrl = window.URL.createObjectURL(new Blob([response.data], options))

            const anchor = document.createElement("a")
            document.body.appendChild(anchor)
            anchor.href = objectUrl
            anchor.target = '_blank'
            anchor.download = downloadName
            anchor.click()

            if (revoke) {
                const userAgent = window.navigator.userAgent
                if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Edge") > -1)
                    // Delay the revocation on IE/Edge, as it blocks the download otherwise
                    setTimeout(() => window.URL.revokeObjectURL(objectUrl), revokeM$Timeout)
                else
                    window.URL.revokeObjectURL(objectUrl)
            }
        })
}

export const formDataFromObject = (obj, exceptions = []) => {
    const formData = new FormData()
    addObjectToFormData(formData, obj, exceptions)
    return formData
}

export const addObjectToFormData = (formData, obj, exceptions = []) => {
    for (const [key, value] of Object.entries(obj)) {
        if (value === null || exceptions.indexOf(key) !== -1)
            continue

        if (value instanceof Array)
            throw new Error(`Cannot add an array to a form data. Please do it manually. Key : ${key}`)

        if (value instanceof File)
            formData.append(key, value)
        else
            formData.append(key, '' + value)
    }
}

export const objectWithoutKeys = (obj, omitKeys = []) => {
    const res = {}

    for (const [key, value] of Object.entries(obj))
        if (omitKeys.indexOf(key) === -1)
            res[key] = value

    return res
}

export const reduceByField = (array, field) =>
    array.reduce((obj, data) => Object.assign(obj, {[data[field]]: data}), {})

export const reduceById = array => reduceByField(array, 'id')

export const objectTruthyKeys = obj => Object.keys(obj).filter(key => obj[key])

export const formatScoreDistance = scoreDistance => {
    if (scoreDistance === undefined || scoreDistance === null)
        return ''

    const decimals = 3
    const power = Math.pow(10, decimals)

    return String(Math.round(scoreDistance * power) / power)
}
