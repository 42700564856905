<template>
  <v-sheet elevation="2" rounded class="mx-2 my-1 d-inline-flex flex-row" :style=blocStyle>

    <!-- Delete button -->
    <div class="d-inline-flex flex-column">
      <v-btn v-if="onDelete" @click="onDelete" block class="px-1 delete-bloc-button" depressed color="error"
             :disabled="disabled">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>

    <!-- Operator button -->
    <div class="d-inline-flex flex-column">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn block class="px-1 " depressed color="primary" v-bind="attrs" v-on="on" :style="operatorButtonStyle"
                 :disabled="disabled">
            {{ filterBloc.operator }}
            <v-icon small>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense class="pa-0">
          <v-list-item v-for="(operator, index) in operators" @click="changeOperator(operator)" :key="index">
            <v-list-item-title>{{ operator }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- CHILDREN -->
    <div class="d-inline-block py-1 px-2">
      <div class="d-inline-flex" v-for="(child, index) in children" :key="index">
        <!-- Children is a Bloc -->
        <filter-bloc v-if="child !== undefined && child.type==='bloc'" :id="index" :filter-bloc="child"
                     :enums-values="enumsValues" :on-modify="modifyChild" :show-dialog="showDialog" :depth="depth+1"
                     :on-delete="() => deleteChild(index)" :disabled="disabled"></filter-bloc>
        <single-filter v-else-if="child !== undefined && child.type==='filter'" :id="index" :filter="child"
                       :enums-values="enumsValues" :on-modify="modifyChild" :show-dialog="showDialog" :depth="depth+1"
                       :on-delete="() => deleteChild(index)" :disabled="disabled"></single-filter>
        <div v-if="showSeparator(index) && child !== undefined" class="d-inline-flex flex-column justify-center">
          <span>{{ filterBloc.operator }}</span>
        </div>
        <div v-if="index===children.length-1 && canHaveChildren" class="d-inline-flex flex-column justify-center">
          <div class="flex-grow-1 d-inline-flex flex-column py-1 ml-2">
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn block class="px-2" depressed color="success" v-bind="attrs" v-on="on" :disabled="disabled">
                <span>
                  <v-icon small>mdi-plus</v-icon>
                </span>
                </v-btn>
              </template>
              <v-list dense class="pa-0">
                <v-list-item v-for="(item, i) in addTypes" @click="add(item)" :key="i">
                  <v-list-item-title class="text-capitalize">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import {BLUE_COLORS} from "@/util/color";
import SingleFilter from "@/components/parts/filters/SingleFilter";
import {BLOC_OPERATORS, EMPTY_BLOCK} from "@/components/parts/filters/filters-utils";
import {cloneDeep} from "lodash";

export default {
  name: "FilterBloc",
  components: {SingleFilter},
  props: {
    filterBloc: {type: Object, required: true},
    enumsValues: {type: Object, required: true},
    id: {type: Number, required: true},
    depth: {type: Number, required: true},
    disabled: {type: Boolean, required: false, default: false},
    onModify: {type: Function, required: true},
    showDialog: {type: Function, required: true},
    onDelete: {type: Function, required: false}
  },
  data: () => ({
    operators: Object.values(BLOC_OPERATORS),
    addTypes: ['bloc', 'filter'],
  }),
  computed: {
    blocStyle() {
      return 'background-color:' + BLUE_COLORS[this.depth % BLUE_COLORS.length]
    },
    operatorButtonStyle() {
      return this.onDelete ? 'border-top-left-radius: 0; border-bottom-left-radius: 0;' : ''
    },
    canHaveChildren() {
      return (this.filterBloc.operator !== BLOC_OPERATORS.NOT || this.filterBloc.children.length === 0)
    },
    children() {
      return this.filterBloc.children.length === 0 ? [undefined] : this.filterBloc.children
    }
  },
  methods: {
    modifyChild(id, newVal) {
      this.filterBloc.children[id] = newVal
      this.onModify(this.id, this.filterBloc)
    },
    deleteChild(id) {
      this.filterBloc.children.splice(id, 1)
      this.onModify(this.id, this.filterBloc)
    },
    changeOperator(operator) {
      this.filterBloc.operator = operator
      this.onModify(this.id, this.filterBloc)
    },
    add(type) {
      if (type === 'bloc')
        this.addBloc()
      else
        this.addFilter()
    },
    addBloc() {
      this.filterBloc.children.push(cloneDeep(EMPTY_BLOCK))
      this.onModify(this.id, this.filterBloc)
    },
    addFilter() {
      this.showDialog(this.addFilterCallback)
    },
    addFilterCallback(newFilter) {
      this.filterBloc.children.push(cloneDeep(newFilter))
      this.onModify(this.id, this.filterBloc)
    },
    showSeparator(index) {
      return index < this.children.length && this.filterBloc.operator !== BLOC_OPERATORS.NOT
    }
  }
}
</script>

<style scoped>
.delete-bloc-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
