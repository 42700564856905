import * as mutation_types from "@/store/mutation-types"
import * as action_types from "@/store/action-types"
import axios from "axios";

const LOCAL_STORAGE_KEY = 'datalambic-jwt'

const state = () => ({
    token: localStorage.getItem(LOCAL_STORAGE_KEY) || '',
    status: '',
    permissions: undefined,
    user: undefined,
    mustChangePassword: false,
})
const getters = {
    authStatus: state => state.status,
    getPermissions: state => state.permissions,
    isAuthenticated: state => Boolean(state.token),
}

const actions = {
    [action_types.LOGIN_REQUEST]: ({commit, dispatch}, credentials) =>
        new Promise((resolve, reject) => {
            commit(mutation_types.LOGIN_REQUEST)

            const formData = new FormData()
            formData.append('username', credentials.username)
            formData.append('password', credentials.password)

            axios.post('/access-token', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    const jwt = response.data['accessToken']
                    localStorage.setItem(LOCAL_STORAGE_KEY, jwt)
                    commit(mutation_types.LOGIN_SUCCESS, jwt)
                    dispatch(mutation_types.PERMISSIONS_REQUESTS)
                    resolve(jwt)
                })
                .catch(err => {
                    commit(mutation_types.LOGIN_ERROR)
                    localStorage.removeItem(LOCAL_STORAGE_KEY)
                    reject(err)
                })
        }),
    [action_types.LOGOUT_REQUEST]: ({commit}) =>
        new Promise((resolve => {
            commit(mutation_types.LOGOUT_REQUEST)
            commit(mutation_types.CLEAR_PERMISSIONS)
            commit(mutation_types.CLEAR_USER)
            commit(mutation_types.CLEAR_CHANGE_PASSWORD)
            localStorage.removeItem(LOCAL_STORAGE_KEY)
            resolve();
        })),
    [action_types.PERMISSIONS_REQUESTS]: ({commit}) =>
        new Promise((resolve, reject) => {
            axios.get('/user')
                .then(response => {
                    let user = response.data
                    commit(mutation_types.SET_USER, user)
                    commit(mutation_types.SET_CHANGE_PASSWORD, user['mustChangePassword'])

                    let permissions = response.data['applicationPermissions']
                    if (permissions !== null && permissions !== undefined) {
                        permissions = permissions.reduce((obj, permission) =>
                            Object.assign(obj, {[`${permission.permissionType}_${permission.resource}`]: true}), {})
                        commit(mutation_types.SET_PERMISSIONS, permissions)
                        resolve(permissions)
                    } else {
                        reject()
                    }
                })
                .catch(err => {
                    commit(mutation_types.CLEAR_PERMISSIONS)
                    reject(err)
                })
        }),
}
const mutations = {
    [mutation_types.LOGIN_REQUEST]: state => {
        state.status = 'loading'
    },
    [mutation_types.LOGIN_SUCCESS]: (state, jwt) => {
        state.status = 'success'
        state.token = jwt
    },
    [mutation_types.LOGIN_ERROR]: state => {
        state.status = 'error'
        state.token = ''
    },
    [mutation_types.LOGOUT_REQUEST]: state => {
        state.status = ''
        state.token = ''
        state.permissions = undefined
    },
    [mutation_types.SET_PERMISSIONS]: (state, permissions) => {
        state.permissions = permissions || []
    },
    [mutation_types.CLEAR_PERMISSIONS]: state => {
        state.permissions = undefined
    },
    [mutation_types.SET_USER]: (state, user) => {
        state.user = user || undefined
    },
    [mutation_types.CLEAR_USER]: state => {
        state.user = undefined
    },
    [mutation_types.SET_CHANGE_PASSWORD]: (state, mustChangePassword) => {
        state.mustChangePassword = mustChangePassword
    },
    [mutation_types.CLEAR_CHANGE_PASSWORD]: (state) => {
        state.mustChangePassword = false
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};
