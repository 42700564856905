var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"fill-height d-flex flex-column",attrs:{"cols":"12","md":"10"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('h1',{staticClass:"text-h3 d-inline"},[_vm._v(_vm._s(_vm.pipelineName))]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":"","inset":""}}),_c('h2',{staticClass:"text-h3 d-inline"},[_vm._v("Results")])],1),_c('d-data-table',{staticClass:"mt-5 row-pointer",attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":50,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.document1Origin",fn:function(props){return [_vm._v(" "+_vm._s(props.item.document1Origin)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-edit-dialog',{attrs:{"return-value":props.item.document1Origin,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "document1Origin", $event)},"update:return-value":function($event){return _vm.$set(props.item, "document1Origin", $event)},"save":function($event){return _vm.save(props.item, true)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"formDoc1"},[_c('v-text-field',{attrs:{"rules":[_vm.required, _vm.maxLength],"label":"Edit","single-line":"","counter":""},model:{value:(props.item.document1Origin),callback:function ($$v) {_vm.$set(props.item, "document1Origin", $$v)},expression:"props.item.document1Origin"}})],1)]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Edit document name")])])]}},{key:"item.document2Origin",fn:function(props){return [_vm._v(" "+_vm._s(props.item.document2Origin)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-edit-dialog',{attrs:{"return-value":props.item.document2Origin,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(props.item, "document2Origin", $event)},"update:return-value":function($event){return _vm.$set(props.item, "document2Origin", $event)},"save":function($event){return _vm.save(props.item, false)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"formDoc2"},[_c('v-text-field',{attrs:{"rules":[_vm.required, _vm.maxLength],"label":"Edit","single-line":"","counter":""},model:{value:(props.item.document2Origin),callback:function ($$v) {_vm.$set(props.item, "document2Origin", $$v)},expression:"props.item.document2Origin"}})],1)]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Edit document name")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.navigateToDocument(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Open document editor")])])]}}])})],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }