import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth";
import navigation from "@/store/modules/navigation";
import filters from "@/store/modules/filters";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        auth,
        navigation,
        filters,
    },
    strict: debug
})
