<template>
  <v-container fluid class="fill-height">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-row class="fill-height" v-if="!loading">
      <!-- xs12 and sm12 to make it responsive = 12 columns on mobile and 6 columns from medium to XL layouts -->
      <v-col cols="12" md="6" class="fill-height">
        <PDFViewer v-if="this.stageType === STAGE_TYPES.DOCUMENT_2_TEXT" :is-input="true"
                   :show-error="showError" :data="data.left"></PDFViewer>
        <TextViewer v-if="this.stageType === STAGE_TYPES.TOKENIZER" :is-input="true"
                    :show-error="showError" :data="data.left"></TextViewer>
        <TableViewer v-if="this.stageType === STAGE_TYPES.DOCUMENT_MATCHER" :is-input="true"
                     :show-error="showError" :data="data.left"></TableViewer>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="6" class="fill-height">
        <TextViewer v-if="this.stageType === STAGE_TYPES.DOCUMENT_2_TEXT" :is-input="false"
                    :show-error="showError" :data="data.right"></TextViewer>
        <TableViewer v-if="this.stageType === STAGE_TYPES.TOKENIZER || this.stageType === STAGE_TYPES.DOCUMENT_MATCHER"
                     :is-input="false" :show-error="showError" :data="data.right"></TableViewer>
      </v-col>
    </v-row>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </v-container>
</template>

<script>

import axios from "axios";
import PDFViewer from "@/components/parts/stages-io/PDFViewer";
import TextViewer from "@/components/parts/stages-io/TextViewer";
import TableViewer from "@/components/parts/stages-io/TableViewer";

const STAGE_TYPES = {
  DOCUMENT_2_TEXT: "document2text",
  TOKENIZER: "tokenizer",
  DOCUMENT_MATCHER: "documentMatcher"
}

export default {
  name: "PipelineStagesIOViewer",
  components: {TableViewer, TextViewer, PDFViewer},
  data() {
    return {
      data: {
        left: undefined,
        right: undefined
      },
      loading: false,
      error: null,
      hasError: false,
      STAGE_TYPES,
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    pipelineId() {
      return this.$route.params.pipelineId
    },
    pipelineName() {
      return this.$store.state.navigation.pipeline.pipelineTitle
    },
    stage() {
      return this.$route.params.stageName
    },
    stageType() {
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_2_TEXT))
        return STAGE_TYPES.DOCUMENT_2_TEXT
      if (this.stage.includes(STAGE_TYPES.TOKENIZER))
        return STAGE_TYPES.TOKENIZER
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_MATCHER))
        return STAGE_TYPES.DOCUMENT_MATCHER
      return undefined
    },
    documentId() {
      return this.$route.params.documentId
    }
  },
  created() {
    this.loadIO()
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    loadIO() {
      if (this.loading)
        return

      this.error = null
      this.hasError = false
      this.loading = true

      axios.get(`/pipelines/${this.pipelineId}/io/${this.stage}/${this.documentId}`)
          .then(response => {
            this.data.left = response.data.left
            this.data.right = response.data.right
            this.loading = false
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>
