<template>
  <div class="fill-height">
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this pipeline ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click="confirmDelete" :disabled="loading">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="60%">
      <v-form @submit.prevent="save" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-text-field label="Name" v-model="editedItem.name" :rules="rules.name" autofocus
                            :disabled="loading"></v-text-field>
              <v-text-field label="Description" v-model="editedItem.description" :disabled="loading"></v-text-field>
              <v-select v-if="!edit" v-model="editedItem.type" :items="pipelineTypes" name="id" label="Pipeline type"
                        item-text="text" item-value="id" required :rules="rules.type"></v-select>
              <v-row>
                <v-col>
                  <v-select v-model="editedItem.language1" :disabled="edit" :items="languagesShort"
                            :label="editedItem.language1IsSource ? 'Source language' : 'Language 1'"
                            required :rules="rules.languages"></v-select>
                </v-col>
                <v-col>
                  <v-select v-model="editedItem.language2" :disabled="edit" :items="languagesShort"
                            :label="editedItem.language1IsSource ? 'Target language' : 'Language 2'"
                            required :rules="rules.languages"></v-select>
                </v-col>
              </v-row>
              <v-checkbox v-if="!edit" label="Set languages as source -> target"
                          v-model="editedItem.language1IsSource"></v-checkbox>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Cancel</v-btn>
            <v-btn color="primary" type="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogRestart" max-width="550px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to restart this crawling?</v-card-title>
        <v-card-text>All already collected data will be erased!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogRestart = false">Cancel</v-btn>
          <v-btn color="error" text @click="restartCrawling" :disabled="loading">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="10" md="8" class="fill-height d-flex flex-column">
          <h1 class="text-h3 text-center">{{ crawlingData.title }}</h1>
          <p class="text-subtitle-1 text-center">{{ crawlingData.description }}</p>
          <div class="d-flex flex-column align-center mb-3">
            <div class="d-flex flex-row align-center">
              <span class="d-flex flex-row align-center">Sources:</span>
              <v-chip v-for="source in crawlingData.projectSources" :key="source"
                      class="ma-2" small
                      :color="stringToColor(sourceById[source].name)"
                      :dark="isDark(stringToColor(sourceById[source].name))">
                {{ sourceById[source].name  }}
              </v-chip>
            </div>
            <span class="font-weight-bold">{{ confidentialityLevelName }}</span>
            <span v-if="crawlingData.dateRangeStart && crawlingData.dateRangeEnd">
              | Date range : {{ formatDateRange(crawlingData.dateRangeStart, crawlingData.dateRangeEnd) }}
            </span>
            <span>
              <span v-if="crawlingData.createdBy">
                Created by: <span class="font-weight-bold">{{ crawlingData.createdBy }}</span>
                at: <span class="font-weight-bold">{{ formatDateToSeconds(new Date(crawlingData.dateCreated)) }}</span>
              </span>
              <span v-if="crawlingData.createdBy && crawlingData.updatedBy"> | </span>
              <span v-if="crawlingData.updatedBy">
                Updated by: <span class="font-weight-bold">{{ crawlingData.updatedBy }}</span>
                at: <span class="font-weight-bold">{{ formatDateToSeconds(new Date(crawlingData.dateUpdated)) }}</span>
              </span>
            </span>
          </div>
          <div v-if="crawlingData.ownerEnabled === false" class="mb-3">
            <v-alert type="warning">
              This crawling's owner is disabled, so it is locked and only projects administrators can see it
            </v-alert>
          </div>
          <v-divider horizontal/>
          <br>
          <v-row justify="center" class="mb-3">
            <v-divider vertical inset class="mx-4"/>
            <span>Spiders: {{ crawlingData.numSpiders }}</span>
            <v-divider vertical inset class="mx-4"/>
            <span>Queuing interval: {{ crawlingData.queuingInterval }}s</span>
            <v-divider vertical inset class="mx-4"/>
            <span>Domain Crawling Interval: {{ crawlingData.domainCrawlingInterval }}s</span>
            <v-divider vertical inset class="mx-4"/>
            <span>Max Depth: {{ crawlingData.maxDepth }}</span>
            <v-divider vertical inset class="mx-4"/>
          </v-row>
          <v-divider horizontal/>
          <d-data-table :items="crawlingData.pipelines" :headers="headers" class="row-pointer mt-1"
                        :footer-props="footerProps" sort-by="name" :items-per-page=50 :loading="loading"
                        @click:row="item => openPipeline(item)">
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn :color="startStopBtn.color" :loading="loading" :disabled="loading"
                       @click="startStopBtn.action" class="mr-4" v-if="hasPermAdminProject">
                  {{ startStopBtn.text }}
                  <v-icon>{{ startStopBtn.icon }}</v-icon>
                </v-btn>
                <v-btn color="default" class="mr-4" v-if="crawlingData.status !== 'new' && hasPermAdminProject"
                       @click="showRestartDialog" >
                  Restart
                </v-btn>
                <v-btn color="secondary"
                       v-if="countResults"
                       :to="`/crawlings/${crawlingId}/urls`"
                       class="mr-4">
                  {{ countResults }} urls found
                </v-btn>
                <v-btn :to="`/crawlings/${crawlingId}/file-browser/crawled_files`"
                       v-if="countFiles"
                       class="secondary mr-4">
                  {{ countFiles }} files found
                </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="createItem" class="ml-2" v-if="hasPermAdminPipelines">
                  New pipeline
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.languages="{item}">
              {{ item.language1 }}
              <v-icon v-if="item.language1IsSource">mdi-arrow-right</v-icon>
              <v-icon v-else>mdi-arrow-left-right</v-icon>
              {{ item.language2 }}
            </template>
            <template v-slot:item.status="{item}">
              <v-tooltip top :open-delay=350>
                <template v-slot:activator="{on, attrs}">
                    <span v-bind="attrs" v-on="on">
                      <v-icon :color="pipelineStatus(item).color">{{ pipelineStatus(item).icon }}</v-icon>
                    </span>
                </template>
                <span>{{ pipelineStatus(item).text }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editItem(item)" v-if="hasPermAdminPipelines">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteItem(item)" v-if="hasPermAdminPipelines">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="success" v-model="hasSuccess">{{ success }}</v-snackbar>
    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>


<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {CRAWLING_STATUSES, LANGUAGES_SHORT, PIPELINE_STATUSES_ICONS} from "@/util/enums";
import DDataTable from "@/components/wrappers/DDataTable";
import {formatDateRange, formatDateToSeconds} from "@/util/string-utils";
import {CLOSE_NORMAL_CODE, openWebSocket} from "@/util/websockets";
import {reduceById} from "@/util/js-utils";

const DEFAULT_NEW_PIPELINE = {
  name: '',
  description: '',
  type: 2,
  language1: null,
  language2: null,
  language1IsSource: false
}

const CLOSE_WEBSOCKET_NON_RUNNING_TIMEOUT = 15000

export default {
  name: "Crawling",
  components: {DDataTable},
  data() {
    return {
      crawlingId: this.$route.params.crawlingId,
      editedItem: Object.assign({}, DEFAULT_NEW_PIPELINE),
      crawlingData: {},
      projectSources: [],
      confidentialityLevelName: '',
      loading: false,
      error: null,
      hasError: false,
      success: null,
      hasSuccess: false,
      crawlingStatusWebSocket: null,
      dialog: false,
      dialogDelete: false,
      dialogRestart: false,
      edit: false,
      headers: [
        {text: 'Name', value: 'name', align: 'left'},
        {text: 'Languages', value: 'languages', align: 'left'},
        {text: 'ID', value: 'id', align: 'left'},
        {text: 'Status', value: 'status', align: 'left'},
        {text: 'Actions', value: 'actions', sortable: false, align: 'right'}
      ],
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, -1]
      },
      urls: [],
      countResults: null,
      countFiles: null,
      pipelineTypes: [
        {
          id: 2,
          text: "Crawled pages (Tokenizer x2 -> Document Matcher -> Aligner)"
        },
        {
          id: 3,
          text: "Crawled files (Document2text -> Language Detector -> Tokenizer x2 -> Document Matcher -> Aligner)"
        }
      ],
      languagesShort: LANGUAGES_SHORT,
      rules: {
        name: [
          v => !!v || 'Required'
        ],
        type: [
          v => !!v || 'Required'
        ],
        languages: [
          v => !!v || 'Required',
          () => this.editedItem.language1 !== this.editedItem.language2 || 'Languages must be different'
        ]
      },
      buttonByStatus: {
        [CRAWLING_STATUSES.NEW]: {color: 'primary', icon: 'mdi-play', text: 'start', action: this.runCrawling},
        [CRAWLING_STATUSES.RUNNING]: {color: 'error', icon: 'mdi-stop', text: 'stop', action: this.stopCrawling},
        [CRAWLING_STATUSES.FINISHED]: {color: 'success', icon: 'mdi-check', text: 'done', action: this.noAction},
        [CRAWLING_STATUSES.STOPPED]: {color: 'primary', icon: 'mdi-play', text: 'continue', action: this.runCrawling},
      },
      isDark,
      stringToColor,
      userPermissions: [],
      hasPermUseResults: false,
      hasPermAdminProject: false,
      hasPermAdminPipelines: false,
    }
  },
  computed: {
    startStopBtn() {
      if (this.crawlingData.status)
        return this.buttonByStatus[this.crawlingData.status]
      else
        return this.buttonByStatus[CRAWLING_STATUSES.NEW]
    },
    formTitle() {
      return this.editedItem.id ? "Edit pipeline" : "Create pipeline"
    },
    projectId() {
      return this.crawlingData.projectId
    },
    sourceById() {
      return reduceById(this.projectSources)
    },
  },
  created() {
    this.fetchCrawlingData()
  },
  beforeDestroy() {
    this.crawlingStatusWebSocket?.close(CLOSE_NORMAL_CODE)
  },
  methods: {
    fetchCrawlingData() {
      this.loading = true
      this.error = null
      this.hasError = false

      Promise.all([
        axios.get(`/crawlings/${this.crawlingId}`),
        axios.get('/sources'),
        axios.get('/confidentiality-levels')
      ])
          .then(responses => {
            this.crawlingData = responses[0].data
            this.projectSources = responses[1].data
            this.confidentialityLevelName = responses[2].data.find(
                confidentialityLevel => confidentialityLevel.id === this.crawlingData.confidentialityLevelId).name
            this.organizePermissions()
            this.checkPermAdminProject()
            this.checkPermUseResults()
            this.checkPermAdminPipelines()
            if (this.hasPermAdminProject || this.hasPermUseResults)
              this.openCrawlingStatusWebsocket()
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    organizePermissions() {
      this.crawlingData?.groupPermissions.forEach(permission => {
        let perm = `${permission?.permissionType} ${permission?.resource}`
        this.userPermissions.push(perm)
      })
    },
    checkPermAdminProject() {
      this.hasPermAdminProject =
          this.userPermissions.includes('admin project')
          || this.crawlingData.ownerEnabled
    },
    checkPermUseResults() {
      this.hasPermUseResults =
          this.userPermissions.includes('admin project')
          || this.userPermissions.includes('use results')
          || this.crawlingData.ownerEnabled
    },
    checkPermAdminPipelines() {
      this.hasPermAdminPipelines =
          this.userPermissions.includes('admin project')
          || this.userPermissions.includes('admin pipelines')
          || this.crawlingData.ownerEnabled
    },
    openPipeline(item) {
      if (this.hasPermAdminPipelines || this.crawlingData.ownerEnabled)
        this.$router.push({path:`/crawlings/${this.crawlingId}/${item.id}`})
    },
    openCrawlingStatusWebsocket() {
      if (this.crawlingStatusWebSocket != null
          && (this.crawlingStatusWebSocket.readyState === WebSocket.OPEN
              || this.crawlingStatusWebSocket.readyState === WebSocket.CONNECTING))
        return

      this.crawlingStatusWebSocket = openWebSocket(`/crawlings/${this.crawlingId}/status`, this.showError)

      this.crawlingStatusWebSocket.onerror = this.showError

      let timeout

      this.crawlingStatusWebSocket.onmessage = event => {
        const data = JSON.parse(event.data)

        this.countResults = data.count
        this.countFiles = data.countFiles
        this.crawlingData.status = data.status

        if (data.status === CRAWLING_STATUSES.RUNNING) {
          clearTimeout(timeout)
          timeout = undefined
        } else
          timeout ??= setTimeout(() => this.crawlingStatusWebSocket?.close(CLOSE_NORMAL_CODE),
              CLOSE_WEBSOCKET_NON_RUNNING_TIMEOUT)
      }
    },
    runCrawling() {
      if (this.loading)
        return
      if (this.crawlingData.status === CRAWLING_STATUSES.RUNNING)
        return this.showError("The crawler is already running")

      this.loading = true
      this.error = null
      this.hasError = false
      this.success = null
      this.hasSuccess = false

      axios.post(`/crawlings/${this.crawlingId}/start`)
          .then(() => {
            this.success = 'Crawling process started'
            this.hasSuccess = true

            this.openCrawlingStatusWebsocket()
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    stopCrawling() {
      if (this.loading)
        return
      if (this.crawlingData.status !== CRAWLING_STATUSES.RUNNING)
        return this.showError("The crawler is already stopped")

      this.loading = true
      this.error = null
      this.hasError = false
      this.success = null
      this.hasSuccess = false

      axios.post(`/crawlings/${this.crawlingId}/stop`)
          .then(() => {
            this.success = 'Crawling process stopped'
            this.hasSuccess = true
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    restartCrawling() {
      if (this.loading)
        return

      this.dialogRestart = false
      this.loading = true
      this.error = null
      this.hasError = false
      this.success = null
      this.hasSuccess = false
      this.countResults = 0
      this.countFiles = 0

      axios.post(`/crawlings/${this.crawlingId}/restart`)
          .then(() => {
            this.success = 'Crawling process restarted'
            this.hasSuccess = true

            this.openCrawlingStatusWebsocket()
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    noAction() {
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = err
    },
    createItem() {
      this.editedItem = Object.assign({}, DEFAULT_NEW_PIPELINE)
      this.edit = false
      this.dialog = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.edit = true
      this.dialog = true
    },
    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },
    showRestartDialog() {
      this.dialogRestart = true
    },
    confirmDelete() {
      this.loading = true

      axios.delete(`/pipelines/${this.editedItem.id}`)
          .then(() => {
            this.dialogDelete = false
            this.fetchCrawlingData()
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    save() {
      if (!this.$refs.form.validate())
        return

      this.loading = true

      const data = {
        name: this.editedItem.name,
        description: this.editedItem.description,
        type: this.editedItem.type,
        language1: this.editedItem.language1,
        language2: this.editedItem.language2,
        language1IsSource: this.editedItem.language1IsSource
      }

      const request = this.editedItem.id
          ? axios.put(`/pipelines/${this.editedItem.id}`, data)
          : axios.post(`/projects/${this.projectId}/pipelines`, data)

      request
          .then(this.fetchCrawlingData)
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    pipelineStatus(item) {
      return PIPELINE_STATUSES_ICONS[item.status]
    },
    formatDateRange,
    formatDateToSeconds,
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
