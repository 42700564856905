<template>
  <v-dialog persistent :value="show" max-width="60%" @click:outside="onClose" @keydown.esc="onClose">
    <v-form ref="form">
      <v-card>
        <v-card-title>{{ rule ? 'Edit split' : 'Split alignment' }}</v-card-title>
        <v-card-text v-if="alignment">
          <h5 class="text-h5 mb-2">Initial data</h5>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea :value="initialText1" label="Initial text 1" disabled no-resize auto-grow
                          outlined rows="1"></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea :value="initialText2" label="Initial text 2" disabled no-resize auto-grow
                          outlined rows="1"></v-textarea>
            </v-col>
          </div>
          <h5 class="text-h5 mb-2">Split data</h5>
          <div class="d-flex">
            <v-col md="6">
              <v-textarea v-model="text1" label="Text 1 (one segment per line)" no-resize auto-grow outlined
                          :rules="rules.splitText"></v-textarea>
            </v-col>
            <v-col md="6">
              <v-textarea v-model="text2" label="Text 2 (one segment per line)" no-resize auto-grow outlined
                          :rules="rules.splitText"></v-textarea>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="onClose">Close</v-btn>
          <v-btn color="success" text @click="apply">Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {range} from "lodash";

export default {
  name: "SplitDialog",
  props: {
    show: {type: Boolean, required: true},
    alignment: {type: Object, default: null},
    rule: {type: Object, default: null},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true}
  },
  data() {
    return {
      text1: '',
      text2: '',
      rules: {
        splitText: [
          v => !!v || 'Required',
          () => this.splitText1.length === this.splitText2.length || 'Keep the same number of segments for both sides'
        ]
      }
    }
  },
  computed: {
    initialText1() {
      return this.rule ? this.rule.segment1 : this.alignment.initialText1
    },
    initialText2() {
      return this.rule ? this.rule.segment2 : this.alignment.initialText2
    },
    splitText1() {
      return this.text1.split('\n').map(segment => segment.trim()).filter(segment => !!segment)
    },
    splitText2() {
      return this.text2.split('\n').map(segment => segment.trim()).filter(segment => !!segment)
    }
  },
  watch: {
    alignment() {
      if (!this.show)
        return

      this.populateForm()
    },
    show(newVal) {
      if (!newVal)
        return

      this.populateForm()
    }
  },
  methods: {
    apply() {
      if (!this.$refs.form.validate())
        return

      const numSplits = this.splitText1.length
      if (numSplits <= 1)
        return this.onClose()

      const rule = {
        id: this.rule?.id,
        segment1: this.initialText1,
        segment2: this.initialText2,
        splitAlignments: range(numSplits).map(index => ({segment1: this.splitText1[index], segment2: this.splitText2[index]}))
      }

      this.onApply(rule)
    },
    populateForm() {
      this.text1 = this.rule
          ? this.rule.splitAlignments.map(al => al.segment1).join('\n')
          : this.alignment.initialText1 ?? ''
      this.text2 = this.rule
          ? this.rule.splitAlignments.map(al => al.segment2).join('\n')
          : this.alignment.initialText2 ?? ''
      this.$refs.form?.resetValidation()
    }
  }
}
</script>

<style scoped>

</style>
