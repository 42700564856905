import * as mutation_types from "@/store/mutation-types"
import * as action_types from "@/store/action-types"

const state = () => ({
    filtersQuery: 'AND()'
})

const getters = {
    getfiltersQuery: state => state.filtersQuery,
}

const actions = {
    [action_types.SET_FILTERS]: ({commit}, filtersQuery) =>
        commit(mutation_types.SET_FILTERS, filtersQuery)
}
const mutations = {
    [mutation_types.SET_FILTERS]: (state, filtersQuery) => state.filtersQuery = filtersQuery,
}

export default {
    state,
    getters,
    actions,
    mutations
};
