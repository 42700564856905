<template>
  <div v-resize="refreshChartSize">
    <v-card class="pa-1">
      <v-card-title class="justify-center pt-2 pb-0">
        <h4 class="text-h6 text-capitalize">{{ data.title }}</h4>
      </v-card-title>
      <v-card-text class="pa-1">
        <div v-if="chartData" class="mx-2" ref="chartWrapper">
          <pie-chart v-if="data.type==='pie'" :chart-data="chartData" :width="chartSize"
                     :height="chartSize"></pie-chart>
          <bar-chart v-else-if="data.type==='bar'" :chart-data="chartData" :width="chartSize"
                     :height="chartSize"></bar-chart>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BarChart from "@/components/parts/segment-manager/stats/charts/BarChart";
import PieChart from "@/components/parts/segment-manager/stats/charts/PieChart";
import {statToBar, statToPie} from "@/components/parts/segment-manager/stats/stats-utils";

export default {
  name: "BaseChart",
  components: {PieChart, BarChart},
  props: {
    data: {type: Object, required: true},
    enumsValues: {type: Object, required: true}
  },
  data() {
    return {
      chartSize: undefined
    }
  },
  computed: {
    chartData() {
      return this.formatData(this.data)
    }
  },
  methods: {
    formatData(data) {
      if (!data)
        return

      if (data.type === 'pie')
        return statToPie(data, this.enumsValues)

      if (data.type === 'bar')
        return statToBar(data)
    },
    refreshChartSize() {
      if (this.$refs.chartWrapper.clientWidth > 0)
        this.chartSize = this.$refs.chartWrapper.clientWidth
    }
  },
}
</script>

<style scoped>
</style>
