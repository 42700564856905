<template>
  <v-dialog v-model="show" max-width="60%" persistent>
    <v-form @submit.prevent="apply" ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field label="Name" v-model="editedItem.username" :disabled="modify"
                          :rules="rules.username"></v-text-field>
            <v-text-field label="Email" v-model="editedItem.email" type="email" :rules="rules.email"></v-text-field>

            <v-select v-model="editedItem.groups" :items="groups" label="User groups" multiple chips deletable-chips
                      item-text="name" item-value="id" hint="Select the groups">
              <template #selection="{ item, index }">
                <v-chip :color="stringToColor(item.name)"
                        :dark="isDark(stringToColor(item.name))" close
                        @click:close="deleteChip(index)">
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
            <v-row>
              <v-col md="auto">
                <v-btn @click="generatePassword">GENERATE NEW PASSWORD</v-btn>
              </v-col>
              <v-text-field v-if="editedItem.password!==undefined" v-model="editedItem.password"
                            label="Password (read-only)" readonly :rules="rules.password"></v-text-field>
              <v-col md="auto" class="pt-3">
                <v-btn v-if="editedItem.password !== undefined"
                       :disabled="editedItem.password === ''"
                       @click="copyToClipboard">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-checkbox v-if="editedItem.password !== undefined"
                        v-model="editedItem.mustChangePassword"
                        :true-value="true"
                        :false-value="false"
                        label="The user must change this password"></v-checkbox>
            <v-expansion-panels focusable class="mt-3" v-if="modify">
              <v-expansion-panel>
                <v-expansion-panel-header>Application Permissions</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table :items="editedItem.applicationPermissions"
                                :headers="headers"
                                class="mt-1"
                                dense
                                disable-sort
                                hide-default-footer>
                    <template v-slot:item.admin="{item}">
                      <v-simple-checkbox v-if="item.admin" :value=true dense :ripple=false disabled></v-simple-checkbox>
                    </template>
                    <template v-slot:item.create="{item}">
                      <v-simple-checkbox v-if="item.create" :value=true dense :ripple=false
                                         disabled></v-simple-checkbox>
                    </template>
                    <template v-slot:item.use="{item}">
                      <v-simple-checkbox v-if="item.use" :value=true dense :ripple=false disabled></v-simple-checkbox>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onClose">Cancel</v-btn>
          <v-btn color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {cloneDeep, groupBy, isEqual} from "lodash";
import {isDark, stringToColor} from "@/util/color";

const PASSWORD_LENGTH = 12
const PASSWORD_GENERATE_LENGTH = 64
const DEFAULT_USER = {
  username: '',
  password: undefined,
  mustChangePassword: true,
  groups: [],
  applicationPermissions: [] // Shape : [{resource: string, admin: true, create: false, ...}, ...]
}
const DEFAULT_NEW_USER = {...DEFAULT_USER, password: '', email: ''}

export default {
  name: "UserDialog",
  props: {
    formTitle: {type: String, required: true},
    modify: {type: Boolean, required: true},
    show: {type: Boolean, required: true},
    onClose: {type: Function, required: true},
    onApply: {type: Function, required: true},
    item: {type: Object, default: null},
    groups: {type: Array, required: true}
  },
  data() {
    return {
      isDark,
      stringToColor,
      editedItem: Object.assign({}, DEFAULT_NEW_USER),
      originalItem: Object.assign({}, DEFAULT_NEW_USER),
      headers: [
        {text: 'Resource', value: 'resource', align: 'left', width: '50%'},
        {text: 'Admin', value: 'admin', align: 'left', width: '1%'},
        {text: 'Create', value: 'create', align: 'left', width: '1%'},
        {text: 'Use', value: 'use', align: 'left', width: '1%'},
      ],
      rules: {
        username: [
          v => !!v || 'Required',
          v => v.length >= 3 || "The username should contain at least 3 characters"
        ],
        password: [
          v => !!v && v.length >= 6 || "Please generate a new password"
        ],
        email: [
          v => !!v || 'Required',
          v => /^.+@.+$/.test(v) || 'Invalid email address'
        ]
      }
    }
  },
  watch: {
    show(newVal) {
      if (newVal)
        this.populateForm(this.item)
    },
    item(newVal) {
      this.populateForm(newVal)
    },
  },
  computed: {
    hasBeenModified() {
      return !isEqual(this.editedItem, this.originalItem)
    }
  },
  methods: {
    populateForm(item) {
      this.loading = true
      this.error = null
      this.hasError = false

      if (item !== null && item !== undefined) {
        this.editedItem = {
          ...cloneDeep(DEFAULT_USER), ...cloneDeep(item), ...{
            applicationPermissions: this.structurePermissions(item.applicationPermissions)
          }
        }
      } else
        this.editedItem = cloneDeep(DEFAULT_NEW_USER)

      this.originalItem = cloneDeep(this.editedItem)
    },
    structurePermissions(permissions) {
      return Object.entries(groupBy(permissions, 'resource'))
          .map(([resource, permissions]) => ({
            resource,
            ...permissions.reduce((obj, permission) =>
                Object.assign(obj, {[permission.permissionType]: true}), {})
          }))
    },
    deleteChip(index) {
      this.editedItem.groups.splice(index, 1)
    },
    generatePassword() {
      const possibilities = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*()_+:;?><,.-="
      let password = ""
      while (password.length < PASSWORD_GENERATE_LENGTH) {
        possibilities.split('').sort(() => 0.5 - Math.random()).join('')
        password += possibilities[Math.floor(possibilities.length * Math.random())]
      }
      password = password.split('').sort(() => 0.5 - Math.random()).join('')
      const offset = Math.floor(Math.random * PASSWORD_GENERATE_LENGTH) - PASSWORD_LENGTH
      this.editedItem.password = password.substr(offset >= 0 ? offset : offset + PASSWORD_LENGTH, PASSWORD_LENGTH)
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.editedItem.password).catch(this.showError)
    },
    apply() {
      if (!this.$refs.form.validate())
        return

      if (this.hasBeenModified)
        this.onApply(this.editedItem)
      else
        this.onClose()
    }
  }
}
</script>

<style scoped>
</style>
