<template>
  <!-- flex-grow-1 useful for display in Home page -->
  <div class="flex-grow-1">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="10" :md="mdSize">
          <div v-if="!homePreview" class="mb-12">
            <h1 class="text-h3 text-center">Edit Account</h1>
          </div>

          <v-card :hover="homePreview">
            <v-form @submit.prevent="modifyUserSelf" ref="form" :disabled="loading">
              <v-card-text :style="cardStyle">
                <div class="d-flex flex-column">
                  <v-avatar color="rgb(133, 133, 133)" size="60" class="align-self-center">
                    <v-icon color="white" large>mdi-account</v-icon>
                  </v-avatar>
                  <span class="text-h5 text-center mt-4">{{ userData.username }}</span>

                  <v-text-field v-model="userData.email" type="email"
                                label="Email" :rules="rules.email" v-if="!homePreview">
                  </v-text-field>
                  <span class="v-label theme--light pa-3" v-else>Email: {{ userData.email }}</span>

                  <div>
                    <span class="v-label theme--light pa-3">Groups</span>
                    <v-chip class="ma-4"
                            v-for="groupId in userData.groups"
                            :color="stringToColor(groupById[groupId].name)"
                            :key="groupId"
                            :dark="isDark(stringToColor(groupById[groupId].name))">
                      {{ groupById[groupId].name }}
                    </v-chip>
                  </div>
                  <div>
                    <div v-if="userData.mustChangePassword">
                      <v-sheet class="py-1 px-2 mb-3" color="error" rounded width="100%" dark elevation="2">
                        You must change your password
                      </v-sheet>
                    </div>
                    <div v-if="userData.password===undefined && !userData.mustChangePassword">
                      <v-btn elevation="2" @click="resetPassword" width="100%" v-if="!homePreview">RESET PASSWORD
                      </v-btn>
                    </div>
                    <div v-else class="pa-0 pt-1">
                      <v-text-field v-model="userData.password"
                                    placeholder="Current password"
                                    label="Current password"
                                    :rules="rules.password"></v-text-field>
                      <v-text-field v-model="userData.newPassword"
                                    placeholder="New password"
                                    label="New Password"
                                    :rules="rules.password"></v-text-field>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions :style="cardStyle">
                <v-spacer></v-spacer>
                <v-btn class="mr-1" color="primary" elevation="2" type="submit" v-if="!homePreview">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
    <v-snackbar app color="success" v-model="hasSuccess">{{ success }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {cloneDeep} from "lodash";
import {isDark, stringToColor} from "@/util/color";
import {reduceById} from "@/util/js-utils";
import {SET_CHANGE_PASSWORD} from "@/store/mutation-types";

const DEFAULT_USER_DATA = {
  username: "",
  password: undefined,
  email: '',
  newPassword: undefined,
  mustChangePassword: false,
  groups: []
}

export default {
  name: "EditAccount",
  props: {
    homePreview: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      loading: false,
      error: null,
      hasError: false,
      success: null,
      hasSuccess: false,
      userData: Object.assign({}, DEFAULT_USER_DATA),
      groups: [],
      stringToColor,
      isDark,
      rules: {
        password: [
          v => !!v && v.length >= 6 || 'The password should be at least 6 characters long'
        ],
        email: [
          v => !!v || 'Required',
          v => /^.+@.+$/.test(v) || 'Invalid email address'
        ]
      },
    }
  },
  created() {
    this.loadUserData()
  },
  computed: {
    cardStyle() {
      return this.homePreview ? "pointer-events: none" : undefined
    },
    groupById() {
      return reduceById(this.groups)
    },
    mdSize() {
      return this.homePreview ? 12 : 4
    }
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    resetPassword() {
      this.userData.password = ''
    },
    loadUserData() {
      if (this.loading)
        return

      this.error = null
      this.hasError = false
      this.loading = true

      Promise.all([axios.get('/user'), axios.get('/groups')])
          .then(responses => {
            this.loading = false
            this.userData = {...DEFAULT_USER_DATA, ...cloneDeep(responses[0].data)}
            this.groups = responses[1].data
            this.$store.commit(SET_CHANGE_PASSWORD, this.userData.mustChangePassword)
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    modifyUserSelf() {
      if (this.loading || !this.$refs.form.validate())
        return

      this.error = null
      this.success = null
      this.hasError = false
      this.hasSuccess = false
      this.loading = true

      const data = {
        password: this.userData.password,
        newPassword: this.userData.newPassword,
        email: this.userData.email
      }
      axios.put(`/user`, data)
          .then(() => {
            this.loading = false
            this.success = "You account was successfully updated !"
            this.hasSuccess = true
            this.loadUserData()
          })
          .catch(err => {
            if (err.response && err.response.status === 403)
              this.error = 'Invalid current password'
            else
              this.showError(err)
            this.hasError = true
            this.loading = false
          })
    },
  },
}
</script>

<style scoped>
</style>
