<template>
  <div class="fill-height d-flex flex-column">

    <v-dialog v-model="dialogCreateFolder" max-width="350px">
      <v-card>
        <v-form @submit.prevent="createFolder">
          <v-card-title class="headline">Create folder</v-card-title>
          <v-card-text>
            <v-text-field autofocus label="Folder name" v-model="createFolderName" hide-details></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogCreateFolder = false">Cancel</v-btn>
            <v-btn color="primary" type="submit">Create</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRename" max-width="550px">
      <v-card>
        <v-form @submit.prevent="renameSelected">
          <v-card-title class="headline">Rename/move {{
              selected.length === 1 && selected[0].isDir ? 'folder' : 'file'
            }}
          </v-card-title>
          <v-card-text>
            <v-text-field autofocus label="New full path" v-model="renameNewPath" hide-details></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogRename = false">Cancel</v-btn>
            <v-btn color="primary" type="submit">Rename/move</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete these files ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="primary" text @click="deleteSelected">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="loadFolder(paramsPath)" rounded class="ma-1" :disabled="loading" :loading="loading"
                 v-on="on">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="openCreateFolderDialog" rounded class="ma-1" v-on="on">
            <v-icon>mdi-folder-plus</v-icon>
          </v-btn>
        </template>
        <span>Create directory</span>
      </v-tooltip>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="$refs.inputUpload.click()" :disabled="uploading" rounded class="ma-1" :loading="uploading"
                 v-on="on">
            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
        </template>
        <span>Upload files</span>
      </v-tooltip>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="openRenameDialog" :disabled="!allowRename" rounded class="ma-1" v-on="on">
            <v-icon>{{ selected.length === 1 && selected[0].isDir ? 'mdi-folder-move' : 'mdi-file-move' }}</v-icon>
          </v-btn>
        </template>
        <span>Rename/move file</span>
      </v-tooltip>
      <input v-show="false" ref="inputUpload" type="file" @change="uploadFiles" multiple>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="downloadSelected" :disabled="selected.length === 0 || downloading" rounded class="ma-1"
                 :loading="downloading" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Download selected files</span>
      </v-tooltip>
      <v-tooltip top :open-delay=350>
        <template v-slot:activator="{on}">
          <v-btn @click="dialogDelete = true" :disabled="selected.length === 0 || deleting" rounded class="ma-1"
                 :loading="deleting" v-on="on">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete selected files</span>
      </v-tooltip>
    </div>

    <d-data-table :loading="loading" :headers="headers" :search="search" :items="items" v-model="selected"
                  item-key="name" show-select class="mt-1" :items-per-page=50 :footer-props="footerProps">
      <template v-slot:top>
        <v-toolbar flat>
          <v-container fluid>
            <v-row>
              <v-col cols="8">
                <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="search" label="Search current folder" append-icon="mdi-magnify" class="mr-1"
                              hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{item}">
        <router-link v-if="item.isDir" :to="`${fullPath}/${item.name}`" v-slot="{navigate}" custom>
            <span @click="navigate" class="data-table-link">
              <v-icon class="mr-2">mdi-folder</v-icon>
              {{ item.name }}
            </span>
        </router-link>
        <span v-else>
            <v-icon class="mr-2">mdi-file</v-icon>
            {{ item.name }}
          </span>
      </template>
      <template v-slot:item.size="{item}">
        {{ item.isDir ? '' : getReadableFileSizeString(item.size) }}
      </template>
      <template v-slot:item.lastModified="{item}">
        {{ item.isDir ? '' : formatDateToSeconds(new Date(item.lastModified)) }}
      </template>
    </d-data-table>

    <v-snackbar app color="success" v-model="hasSuccess">{{ success }}</v-snackbar>
    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {formatDateToSeconds, getReadableFileSizeString} from "@/util/string-utils";
import {downloadFile} from "@/util/js-utils";
import DDataTable from "@/components/wrappers/DDataTable";

export default {
  name: "FileBrowser",
  components: {DDataTable},
  props: {
    crawling: {type: Boolean, default: false}
  },
  data() {
    return {
      error: null,
      hasError: false,
      success: null,
      hasSuccess: false,
      loading: false,
      uploading: false,
      search: '',
      dialogCreateFolder: false,
      createFolderName: '',
      dialogRename: false,
      renameNewPath: '',
      dialogDelete: false,
      downloading: false,
      deleting: false,
      renaming: false,
      selected: [],
      items: [],
      headers: [
        {text: 'Name', value: 'name'},
        {text: 'Size', value: 'size'},
        {text: 'Last modified', value: 'lastModified'}
      ],
      footerProps: {
        itemsPerPageOptions: [50, 100, 200, -1]
      }
    }
  },
  mounted() {
    this.loadFolder(this.paramsPath)
  },
  beforeRouteUpdate(to, from, next) {
    this.loadFolder(to.params.path)
    next()
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    showSuccess(msg) {
      this.success = msg
      this.hasSuccess = true
    },
    loadFolder(path) {
      if (this.loading)
        return

      this.selected = []
      this.loading = true
      this.error = null
      this.hasError = false

      // Avoids path being undefined
      path ??= ''

      axios.get(`${this.basePath}/folder?path=${encodeURIComponent(path)}`)
          .then(response => {
            this.loading = false
            this.items = response.data
                .sort((a, b) => {
                  if (a.isDir && !b.isDir)
                    return -1
                  if (!a.isDir && b.isDir)
                    return 1
                  return a.name - b.name
                })
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    uploadFiles(event) {
      if (this.uploading)
        return

      const formData = new FormData()
      for (const file of event.target.files)
        formData.append('files', file)

      const path = this.paramsPath || ''

      this.uploading = true

      axios.put(`${this.basePath}/upload?path=${encodeURIComponent(path)}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(() => {
            this.showSuccess('File(s) successfully submitted')
            this.uploading = false
            this.loadFolder(path)
          })
          .catch(err => {
            this.showError(err)
            this.uploading = false
          })

      event.target.value = ''
    },
    openCreateFolderDialog() {
      this.dialogCreateFolder = true
      this.createFolderName = ''
    },
    openRenameDialog() {
      if (!this.allowRename)
        return

      this.renameNewPath = this.selectedFullPath
      this.dialogRename = true
    },
    renameSelected() {
      this.dialogRename = false

      if (!this.allowRename)
        return

      if (this.selectedFullPath === this.renameNewPath)
        return

      this.renaming = true

      axios.put(`${this.basePath}/rename?` +
          `old=${encodeURIComponent(this.selectedFullPath)}&new=${encodeURIComponent(this.renameNewPath)}`)
          .then(() => {
            this.showSuccess('File/folder successfully renamed')
            this.renaming = false
            this.loadFolder(this.paramsPath || '')
          })
          .catch(err => {
            this.showError(err)
            this.renaming = false
          })
    },
    createFolder() {
      this.$router.push(`${this.fullPath}/${this.createFolderName}`)
      this.dialogCreateFolder = false
    },
    deleteSelected() {
      this.dialogDelete = false

      if (this.deleting || this.selected.length === 0)
        return

      const path = this.paramsPath ?? ''
      const data = this.selected.map(item => item.name + (item.isDir ? '/' : ''))

      this.deleting = true

      axios.delete(`${this.basePath}/files?path=${encodeURIComponent(path)}`, {data})
          .then(() => {
            this.showSuccess('File(s) successfully deleted')
            this.deleting = false
            this.loadFolder(path)
          })
          .catch(err => {
            this.showError(err)
            this.deleting = false
          })
    },
    downloadSelected() {
      if (this.downloading || this.selected.length === 0)
        return

      this.downloading = true

      const path = this.paramsPath ?? ''
      const data = this.selected.map(item => item.name + (item.isDir ? '/' : ''))

      const downloadName = this.selected.length === 1
          ? (this.selected[0].name + (this.selected[0].isDir ? '.zip' : ''))
          : `${this.isCrawling ? 'crawling' : 'project'}-${this.id}-download.zip`

      downloadFile(`${this.basePath}/files` +
          `?path=${encodeURIComponent(path)}&files=${encodeURIComponent(JSON.stringify(data))}`,
          downloadName, null)
          .then(() => {
            this.downloading = false
          })
          .catch(err => {
            this.showError(err)
            this.downloading = false
          })
    },
    formatDateToSeconds,
    getReadableFileSizeString
  },
  computed: {
    selectedFullPath() {
      if (this.selected.length !== 1)
        return ''

      let path = this.paramsPath
      if (path)
        path += '/'
      else
        path = ''
      path += this.selected[0].name

      return path
    },
    allowRename() {
      return this.selected?.length === 1 && !this.renaming
    },
    id() {
      return this.$route.params.crawlingId ?? this.$route.params.projectId
    },
    isCrawling() {
      return !!this.$route.params.crawlingId;
    },
    basePath() {
      return `/${this.isCrawling ? 'crawlings' : 'projects'}/${this.id}`
    },
    fullPath() {
      return this.$route.path
    },
    paramsPath() {
      return this.$route.params.path
    },
    breadcrumbsItems() {
      const path = this.paramsPath
      const baseTo = `${this.basePath}/file-browser`
      const items = [{
        to: this.basePath,
        text: this.isCrawling ? 'Crawling' : 'Project',
        exact: true
      }, {
        to: baseTo,
        text: 'file browser',
        exact: true
      }]

      if (path) {
        let currentPath = ''
        for (const part of path.split('/')) {
          currentPath += `/${part}`
          items.push({
            to: `${baseTo}${currentPath}`,
            text: part,
            exact: true
          })
        }
      }

      return items
    }
  }
}
</script>

<style scoped>

.data-table-link {
  cursor: pointer;
}

</style>
