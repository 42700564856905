<template>
  <div class="fill-height">
    <tokenizer-rules-delete-dialog :show="dialogDelete" :on-apply="confirmDelete" :on-close="closeModals"
                                   :item="editedItem"></tokenizer-rules-delete-dialog>
    <tokenizer-rules-dialog :show="dialog" :on-apply="save" :on-close="closeModals" :item="editedItem"
                            :is-replacement="isReplacement" :pipeline-id="pipelineId"
                            :language="language"></tokenizer-rules-dialog>

    <v-container :fluid="!isPage" class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" :md="mdSize">
          <d-data-table :items="rulesData" :headers="tableHeaders" class="mt-1" :footer-props="footerProps"
                        :items-per-page="10" :loading="loading" :fill-height="isPage" :options.sync="paginationOptions"
                        :server-items-length="totalItems" page-select>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-divider class="mx-4" vertical inset></v-divider>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="createRule" class="ml-2">{{ buttonTitle }}</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:item.exception="{item}">
              <template v-if="item.value">
                <template v-if="item.value.type === 'nextToken'">
                  <span>Next token:</span>
                  <span v-for="(exception, index) in item.value.value" :key="index">
                    <template v-if="exception.type === 'predefined'">
                      <v-chip v-if="exception.value === 'startsWithUppercase'" class="mx-1" small outlined>
                        starts with an uppercase letter
                      </v-chip>
                      <v-chip v-else-if="exception.value === 'startsWithLowercase'" class="mx-1" small outlined>
                        starts with a lowercase letter
                      </v-chip>
                      <v-chip v-else-if="exception.value === 'startsWithDigit'" class="mx-1" small outlined>
                        starts with a digit
                      </v-chip>
                    </template>
                    <v-chip v-else class="mx-1" small outlined>
                      is "{{ exception.value }}"
                    </v-chip>
                    <template v-if="index < item.value.value.length-1">
                      OR
                    </template>
                  </span>
                </template>
                <template v-else>
                  {{ item.value.type }}
                </template>
              </template>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click.stop="editRule(item)">mdi-pencil</v-icon>
              <v-icon @click.stop="deleteRule(item)">mdi-delete</v-icon>
            </template>
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import {cloneDeep} from "lodash";
import DDataTable from "@/components/wrappers/DDataTable";
import {TOKENIZER_RULES_TYPES} from "@/util/enums";
import TokenizerRulesDialog from "@/components/parts/manage/TokenizerRulesDialog";
import TokenizerRulesDeleteDialog from "@/components/parts/manage/TokenizerRulesDeleteDialog";


const RULES_SCOPES = {
  ANY: undefined, // Gives all rules
  GLOBAL: 'global', // Gives only the global rules
  PROJECT: 'project', // Gives global + project-specific rules (rules that affect this project)
  PIPELINE: 'pipeline' // Gives global + project-specific + pipeline-specific rules WITH LANGUAGE MATCH (rules that affect this pipeline)
}

export default {
  name: "TokenizerRules",
  components: {TokenizerRulesDialog, TokenizerRulesDeleteDialog, DDataTable},
  props: {
    tokenizerRulesType: {type: String, required: true},
    isPage: {type: Boolean, required: false, default: true},
    pipelineId: {type: Number, required: false},
    language: {type: String, required: false},
  },
  data() {
    return {
      TOKENIZER_RULES_TYPES,
      editedItem: undefined,
      rulesData: [],
      loading: false,
      error: null,
      hasError: false,
      dialog: false,
      dialogDelete: false,
      paginationOptions: {},
      totalItems: 0,
      isDark,
      stringToColor,
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1]
      },
    }
  },
  computed: {
    mdSize() {
      return this.isPage ? 10 : 12
    },
    tableHeaders() {
      const headers = [
        {text: 'ID', value: 'id'},
        {text: 'Language', value: 'languageId'},
      ]
      if (!this.isReplacement) {
        headers.push({text: 'Do not split', value: 'search'})
        headers.push({text: 'When', value: 'exception', sortable: false})
      } else {
        headers.push({text: 'Replace', value: 'search'})
        headers.push({text: 'By', value: 'value', sortable: false})
      }
      headers.push({text: 'Comment', value: 'comment'})
      if (this.isPage) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    },
    title() {
      return this.isReplacement ? "Replacement Rules" : "Exceptions"
    },
    buttonTitle() {
      return this.isReplacement ? "New Replacement Rule" : "New Exception"
    },
    isReplacement() {
      return this.tokenizerRulesType === TOKENIZER_RULES_TYPES.REPLACEMENTS
    }
  },
  watch: {
    language() {
      this.fetchRules()
    },
    paginationOptions() {
      this.fetchRules()
    }
  },
  methods: {
    fetchRules() {
      const {itemsPerPage, page, sortBy, sortDesc} = this.paginationOptions

      if (!itemsPerPage || !page)
        return

      this.loading = true
      this.error = null
      this.hasError = false

      const limit = itemsPerPage === -1 ? undefined : itemsPerPage
      const offset = itemsPerPage === -1 ? 0 : (page - 1) * limit
      const params = {
        offset,
        limit,
        orderBy: sortBy[0],
        orderDesc: sortDesc[0]
      }

      if (this.pipelineId)
        Object.assign(params, {
          scope: RULES_SCOPES.PIPELINE,
          scopeId: this.pipelineId,
          language: this.language
        })
      else
        params.scope = RULES_SCOPES.ANY

      const url = `/tokenizer-rules/${this.isReplacement ? 'replacement' : 'exception'}`

      axios.get(url, {params})
          .then(response => {
            const {rules, totalItems} = response.data
            this.rulesData = rules
            this.totalItems = totalItems
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
    },
    createRule() {
      this.editedItem = undefined
      this.dialog = true
    },
    editRule(item) {
      this.editedItem = cloneDeep(item)
      this.dialog = true
    },
    deleteRule(item) {
      this.editedItem = cloneDeep(item)
      this.dialogDelete = true
    },
    closeModals() {
      this.dialog = false
      this.dialogDelete = false
    },
    confirmDelete() {
      axios.delete(`/tokenizer-rules/${this.editedItem.id}`)
          .then(this.thenHandler)
          .catch(this.showError)
    },
    save(item) {
      if (item.id)
        axios.put(`/tokenizer-rules/${item.id}`, item)
            .then(this.thenHandler)
            .catch(this.showError)
      else
        axios.post(`/tokenizer-rules`, item)
            .then(this.thenHandler)
            .catch(this.showError)
    },
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    thenHandler() {
      this.fetchRules()
      this.dialog = false
      this.dialogDelete = false
    }
  }
}
</script>

<style scoped>
</style>
