<template>
  <div class="fill-height">
    <v-container fluid class="fill-height">
      <v-row justify="center" class="fill-height">
        <v-col cols="12" sm="10" md="8" class="fill-height d-flex flex-column">
          <div class="d-flex justify-center">
            <h1 class="text-h3 d-inline">Collected urls</h1>
            <v-divider vertical inset class="mx-4"></v-divider>
            <h2 class="text-h3 d-inline">results</h2>
          </div>
          <d-data-table :items="urls" :headers="headers" :loading="loading" class="mt-5" :items-per-page=50
                        :footer-props="footerProps" :server-items-length="totalUrls" :options.sync="options">
          </d-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import DDataTable from "@/components/wrappers/DDataTable";
import {CLOSE_NORMAL_CODE, openWebSocket} from "@/util/websockets";

export default {
  name: "CrawlingResults",
  components: {DDataTable},
  data() {
    return {
      loading: false,
      error: null,
      hasError: false,
      crawlingResultsWebSocket: null,
      headers: [
        {text: 'Url', value: 'url', width: "40%"},
        {text: 'Parent url', value: 'baseUrl', width: "40%"},
        {text: 'Language', value: 'language', width: "5%"},
        {text: 'Depth', value: 'depth', width: "5%"}
      ],
      urls: [],
      totalUrls: 0,
      options: {},
      footerProps: {
        itemsPerPageOptions: [50, 100, 200, 500]
      }
    }
  },
  computed: {
    crawlingId() {
      return this.$route.params.crawlingId
    },
    paginationOptions() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      return {
        items: itemsPerPage,
        page: page,
        sortBy: sortBy?.[0],
        sortDesc: sortDesc?.[0]
      }
    }
  },
  mounted() {
    this.openCrawlingResultsWebsocket()
  },
  beforeDestroy() {
    this.crawlingResultsWebSocket?.close(CLOSE_NORMAL_CODE)
  },
  watch: {
    paginationOptions: {
      handler() {
        this.sendNewConfig()
      },
      deep: true
    },
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = err
    },
    openCrawlingResultsWebsocket() {
      if (this.crawlingResultsWebSocket != null
          && (this.crawlingResultsWebSocket.readyState === WebSocket.OPEN
              || this.crawlingResultsWebSocket.readyState === WebSocket.CONNECTING))
        return

      this.loading = true

      this.crawlingResultsWebSocket = openWebSocket(`/crawlings/${this.crawlingId}/results`, this.showError)

      this.crawlingResultsWebSocket.onerror = err => {
        this.showError(err)
        this.loading = false
      }

      // Only send the initial config once, after the first message is received (after auth)
      this.crawlingResultsWebSocket.onopen = this.sendNewConfig

      this.crawlingResultsWebSocket.onmessage = event => {
        this.loading = false

        const data = JSON.parse(event.data)

        this.urls = data.results
        this.totalUrls = data.count
      }
    },
    sendNewConfig() {
      if (!this.crawlingResultsWebSocket || this.crawlingResultsWebSocket.readyState !== WebSocket.OPEN)
        return

      this.loading = true
      this.crawlingResultsWebSocket.send(JSON.stringify(this.paginationOptions))
    }
  }
}
</script>
