<template>
  <div class="fill-height">

    <v-container class="fill-height">
      <v-row justify="center">
        <v-col cols="12" class="fill-height d-flex flex-column">
          <h1 class="text-h3 text-center">CHnell</h1>
          <v-form ref="form" @submit.prevent="submitForm">
            <v-row>
              <v-col cols="12" md="1" offset-md="1">
                <v-select label="Source language" :items="languages" item-text="name" item-value="value"
                          v-model="selectedSrcLang" return-object>
                </v-select>
              </v-col>
              <v-col cols="12" md="1">
                <v-select label="Target language" :items="languages" item-text="name" item-value="value"
                          v-model="selectedDestLang" return-object>
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="searchQuery" label="Search term" type="string"
                              append-icon="mdi-magnify"
                              @click:append="submitForm">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select label="Filter by TMX" :items="tmxes" item-text="name" item-value="name"
                          v-model="selectedTmx" clearable>
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select label="Translation options" :items="translateOptions"
                          item-text="name" item-value="value"
                          v-model="selectedTranslateOption">
                </v-select>
              </v-col>
              <v-col cols="1">
                <v-btn color="primary" class="mt-3" type="submit">Search</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <d-data-table :loading="loading" :items="matches" :headers="tableHeaders"
                        :items-per-page="itemsPerPage"
                        :footer-props="footerProps" sort-by="title" class="row-pointer"
                        :server-items-length="totalMatches"
                        :options.sync="options">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ selectedSrcLang.name }}
                  <v-icon>mdi-arrow-right-thin</v-icon>
                  {{ selectedDestLang.name }}
                  ({{ totalMatches }} results)
                </v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.leftSegments="{item}">
              <div v-html="item.left.value" class="aligned-segment"></div>
              <div class="text-caption py-2">
                <a :href="createSourceUrl(item.left, item.projectId)" v-if="item.projectId">{{
                    item.left.docName
                  }}</a>
                <span v-else>{{ item.left.docName }}</span><br>
                <v-chip v-for="domain in splitItems(item.domains)" :key="domain"
                        :color="stringToColor(domain)" class="ma-1"
                        :dark="isDark(stringToColor(domain))"
                        x-small>
                  {{ domain }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.rightSegments="{item}">
              <div v-html="item.right.value" class="aligned-segment"></div>
              <div class="text-caption py-2">
                <a :href="createSourceUrl(item.right, item.projectId)" v-if="item.projectId">{{
                    item.right.docName
                  }}</a>
                <span v-else>{{ item.right.docName }}</span><br>
                <v-chip v-for="domain in splitItems(item.domains)" :key="domain"
                        :color="stringToColor(domain)" class="ma-1"
                        :dark="isDark(stringToColor(domain))"
                        x-small>
                  {{ domain }}
                </v-chip>
              </div>
            </template>
          </d-data-table>


        </v-col>
      </v-row>
    </v-container>

    <v-snackbar app color="error" v-model="hasError">{{ error }}</v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {isDark, stringToColor} from "@/util/color";
import DDataTable from "@/components/wrappers/DDataTable";
import {LANGUAGE_BY_CODE} from "@/util/enums";

export default {
  name: "Chnell",
  components: {DDataTable},
  data() {
    return {
      loading: false,
      hasError: false,
      error: null,
      matches: [],
      selectedSrcLang: {name: 'German', value: 'de'},
      selectedDestLang: {name: 'French', value: 'fr'},
      searchQuery: '',
      selectedTmx: '',
      translateOptions: [
        {name: 'Show all matches', value: null},
        {name: 'Match in source language only', value: false},
        {name: 'Match in target language only', value: true},
      ],
      selectedTranslateOption: null,
      options: {},
      totalMatches: 0,
      footerProps: {
        itemsPerPageOptions: [20, 50, 100, 250]
      },
      itemsPerPage: 50,
      languages: Object.entries(LANGUAGE_BY_CODE).map(([code, lang]) => ({name: lang, value: code})),
      tmxes: [],
      isDark,
      stringToColor
    }
  },
  computed: {
    tableHeaders() {
      if (this.selectedSrcLang.value < this.selectedDestLang.value)
        return [
          {text: this.selectedSrcLang.name, value: 'leftSegments', width: '50%'},
          {text: this.selectedDestLang.name, value: 'rightSegments', width: '50%'},
        ]
      else
        return [
          {text: this.selectedSrcLang.name, value: 'rightSegments', width: '50%'},
          {text: this.selectedDestLang.name, value: 'leftSegments', width: '50%'},
        ]
    }
  },
  created() {
    this.fetchInitialData()
  },
  watch: {
    options: {
      handler() {
        this.fetchData()
      },
      deep: true,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    showError(err) {
      console.error(err)
      this.error = err
      this.hasError = true
    },
    submitForm() {
      this.fetchData(true)
    },
    fetchData(firstPage = false) {
      this.loading = true
      this.hasError = false
      this.error = null
      this.matches = []
      if (firstPage)
        this.options.page = 1
      const page = this.options.page
      const limit = this.options.itemsPerPage === -1 ? undefined : this.options.itemsPerPage
      const offset = this.options.itemsPerPage === -1 ? 0 : (page - 1) * limit
      axios.get(`/chnell/${this.selectedSrcLang.value}-${this.selectedDestLang.value}/translation`,
          {
            params: {
              q: this.searchQuery, tmx: this.selectedTmx, reverse: this.selectedTranslateOption,
              offset: offset, limit: limit,
              startHl: '<b class="hl">', endHl: '</b>',
            }
          })
          .then(response => {
            this.loading = false
            this.matches = response.data?.matches
            this.totalMatches = response.data?.count
          })
          .catch(err => {
            this.showError(err)
            this.loading = false
          })
    },
    fetchInitialData() {
      axios.get(`/chnell/tmxes`)
          .then(response => {
            this.tmxes = response.data
          })
    },
    createSourceUrl(item, projectId) {
      const docName = item.docName
      const language = item.language
      if (docName.toLowerCase().startsWith('http://') || docName.toLowerCase().startsWith('https://')) {
        return docName
      } else if (docName.toLowerCase().endsWith('.tmx')) {
        return ''
      } else {
        return '/#/projects/' + projectId + '/file-browser/input/' + language
      }
    },
    splitItems(domains) {
      if (domains) {
        return domains.split(', ')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr .aligned-segment b.hl {
  font-weight: normal;
  background-color: lightblue;
}

.row-pointer >>> tbody tr a {
  text-decoration: none;
  color: lightslategrey;
}
</style>
