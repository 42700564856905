<template>
  <div class="fill-height">
    <h2 class="text-center">{{ title }}</h2>
    <div v-if="!data || !data.content" class="fill-height d-flex align-center justify-center">
      <h2 class="pa-0 ma-auto">{{ noFileMessage }}</h2>
    </div>
    <div v-else-if="loading" class="fill-height d-flex align-center justify-center">
      <v-progress-circular :size="40" indeterminate></v-progress-circular>
    </div>
    <div v-else-if="documentType === DOCUMENT_TYPES.PDF" class="fill-height">
      <object v-if="url" :data="url" type="application/pdf" :name="data.documentOrigin"
              width="100%" class="fill-height">
        <div class="d-flex align-center flex-column">
          <h3 class="flex-shrink-1 text-center">Browser limitation</h3>
          <p class="flex-shrink-1 text-center">The file "{{ data.documentOrigin }}" cannot be displayed in your browser.
            It seems that your browser does not support PDFs. You can download the file to view it.</p>
          <v-btn class="flex-shrink-1 text-center" :href="url" :download="data.documentOrigin">Download</v-btn>
        </div>
      </object>
    </div>
    <div v-else-if="documentType === DOCUMENT_TYPES.OTHER"
         class="fill-height d-flex align-center justify-center flex-column">
      <div class="d-flex align-center flex-column">
        <h3 class="flex-shrink-1 text-center">Cannot display the file</h3>
        <p class="flex-shrink-1 text-center">The file "{{ data.documentOrigin }}" is in a format that cannot be
          displayed in the Browser. But you can download it.</p>
        <v-btn class="flex-shrink-1 text-center" :href="url" :download="data.documentOrigin">Download</v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import {capitalize} from "lodash";

const STAGE_TYPES = {
  DOCUMENT_2_TEXT: "document2text",
  TOKENIZER: "tokenizer",
  DOCUMENT_MATCHER: "documentMatcher"
}

const DOCUMENT_TYPES = {
  PDF: "PDF",
  OTHER: "OTHER",
  NO_DOCUMENT: "NO_DOCUMENT"
}

export default {
  name: "PDFViewer",
  components: {},
  props: {
    data: {type: Object, required: false},
    isInput: {type: Boolean, required: true},
    showError: {type: Function, required: true},
  },
  data() {
    return {
      error: null,
      hasError: false,
      url: undefined,
      loading: true,
      page: 1,
      pageCount: 1,
      showAllPages: true,
      DOCUMENT_TYPES,
    }
  },
  beforeDestroy() {
    if (this.url !== undefined) {
      window.URL.revokeObjectURL(this.url)
      this.url = undefined
    }
  },
  watch: {
    data() {
      if (this.data && this.data.content && this.url === undefined)
        this.getPDF()
    }
  },
  mounted() {
    if (this.data && this.data.content && this.url === undefined)
      this.getPDF()
  },
  computed: {
    title() {
      const io = this.isInput ? "input" : "output"
      return capitalize(this.stageType) + " " + io
    },
    noFileMessage() {
      const io = this.isInput ? "input" : "output"
      return "There is no " + capitalize(this.stageType) + " " + io + " for this file"
    },
    stage() {
      return this.$route.params.stageName
    },
    documentType() {
      if (this.data && this.data.documentOrigin && this.url !== undefined) {
        if (this.data.documentOrigin.endsWith(".pdf") || this.data.documentOrigin.endsWith(".PDF"))
          return DOCUMENT_TYPES.PDF
        else
          return DOCUMENT_TYPES.OTHER
      }
      return DOCUMENT_TYPES.NO_DOCUMENT
    },
    stageType() {
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_2_TEXT))
        return STAGE_TYPES.DOCUMENT_2_TEXT
      if (this.stage.includes(STAGE_TYPES.TOKENIZER))
        return STAGE_TYPES.TOKENIZER
      if (this.stage.includes(STAGE_TYPES.DOCUMENT_MATCHER))
        return STAGE_TYPES.DOCUMENT_MATCHER
      return undefined
    },
  },
  methods: {
    getPDF() {
      axios({method: "GET", url: this.data.content, responseType: 'arraybuffer', undefined}).then(response => {
        if (this.data.documentOrigin.endsWith(".pdf") || this.data.documentOrigin.endsWith(".PDF"))
          this.url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
        else
          this.url = window.URL.createObjectURL(new Blob([response.data]))
        this.loading = false
      }).catch(err => {
        this.showError(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
