<template>
  <div class="fill-height">
    <document-details :show="modal" :on-close="closeModal" :document="document"
                      :enums-values="enumsValues"></document-details>

    <d-data-table :items="alignments" :items-per-page="10" :headers="headers" :footer-props="footerProps"
                  dense :loading="loading">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Document</v-toolbar-title>
          <v-divider class="mx-4" vertical inset></v-divider>
          <v-toolbar-title>{{ documentName }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn color="primary" class="ml-2" @click="openModal" :loading="loading">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </d-data-table>
  </div>
</template>

<script>
import axios from "axios";
import DDataTable from "@/components/wrappers/DDataTable";
import {reduceById, reduceByField} from "@/util/js-utils";
import DocumentDetails from "@/components/parts/segment-manager/segments/DocumentDetails";

export default {
  name: "AlignedDocument",
  components: {DocumentDetails, DDataTable},
  data() {
    return {
      docId: this.$route.params.docId,
      modal: false,
      selectedSegment: undefined,
      loading: false,
      footerProps: {
        itemsPerPageOptions: [50, 200, 500, -1],
      },
      headers: [
        {text: "Left Index", value: "left.indexInDocument", width: '6%', sortable: true},
        {text: "Left Segment", value: "left.text", width: '40%', sortable: true},
        {text: "Right Segment", value: "right.text", width: '40%', sortable: true},
        {text: "Right Index", value: "right.indexInDocument", width: '6%', sortable: true},
        {text: "Score / Distance", value: "scoreDistance", width: '8%', sortable: true},
      ],
      alignments: [],
      document: undefined,
      enumsValues: undefined
    }
  },
  computed: {
    documentName() {
      if (this.document === undefined || this.document.alignedDocumentName === undefined)
        return this.docId
      return this.document.alignedDocumentName
    }
  },
  mounted() {
    this.fetchData()
  },
  beforeDestroy() {
    this.alignments = []
    this.document = undefined
    this.enumsValues = undefined
  },
  watch: {
    page(newVal) {
      this.offset = (newVal * 10) - 10
      this.fetchData()
    }
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    fetchData() {
      this.error = null
      this.hasError = false

      const promises = [
        axios.get('/projects'),
        axios.get('/groups'),
        axios.get('/tags'),
        axios.get('/domains'),
        axios.get('/owners'),
        axios.get('/languages'),
        axios.get('/users'),
        axios.get('/importable-pipelines'),
        axios.get(`/segment-manager/aligned-document/${this.docId}`),
        axios.get('/confidentiality-levels'),
        axios.get('/crawlings'),
      ]

      Promise.all(promises)
          .then(responses => {
            const projects = responses[0].data.map(project => ({
              id: project.id,
              name: project.title
            }))

            const groups = responses[1].data.map(group => ({
              id: group.id,
              name: group.name
            }))

            const tags = responses[2].data.map(tag => ({
              id: tag.id,
              name: tag.name
            }))

            const domains = responses[3].data.map(domain => ({
              id: domain.id,
              name: domain.name
            }))

            const owners = responses[4].data.map(owner => ({
              id: owner.id,
              name: owner.name
            }))

            const languages = responses[5].data.map(language => ({
              id: language.id,
              name: language.name
            }))

            const users = responses[6].data.map(user => ({
              id: user.id,
              name: user.username
            }))

            const pipelines = responses[7].data.map(pipeline => ({
              ...pipeline,
              id: pipeline.id,
            }))

            this.alignments = responses[8].data.alignments
            this.document = responses[8].data.document

            const confidentialityLevels = responses[9].data

            const crawlings = responses[10].data.map(crawling => ({
              ...crawling,
              name: crawling.title
            }))

            this.enumsValues = {
              projects,
              projectById: reduceById(projects),
              groups,
              groupById: reduceById(groups),
              tags,
              tagById: reduceById(tags),
              domains,
              domainById: reduceById(domains),
              owners,
              ownerById: reduceById(owners),
              languages,
              users,
              usersById: reduceById(users),
              pipelines,
              pipelinesById: reduceById(pipelines),
              confidentialityLevels,
              confidentialityLevelById: reduceById(confidentialityLevels),
              crawlingByProjectId: reduceByField(crawlings, 'projectId'),
              crawlings,
            }
            this.loading = false
          })
          .catch(err => {
            this.error = err
            this.hasError = true
            console.error(err)
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
</style>
