var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('document-editor-context-menu',{attrs:{"context-menu-items":_vm.contextMenuItems,"show":_vm.contextualMenu.show,"mouse-x":_vm.contextualMenu.mouseX,"mouse-y":_vm.contextualMenu.mouseY}}),_c('d-data-table',{attrs:{"loading":_vm.loading,"items":_vm.alignmentsWithState,"headers":_vm.headers,"value":_vm.selectedAlignments,"item-key":"id","show-select":"","footer-props":_vm.footerProps,"options":_vm.paginationOptions,"items-per-page":_vm.paginationOptions.itemsPerPage,"server-items-length":_vm.totalItems,"page-select":""},on:{"input":_vm.onSelectionChanged,"contextmenu:row":_vm.showContextualMenu,"update:options":_vm.onPaginationOptionsChanged},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"my-1",attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mr-5 d-inline-block"},on),[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.actions.refresh()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]}}])},[_c('span',[_vm._v("Reload")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.allowedActions.add || _vm.loading},on:{"click":function($event){return _vm.actions.add()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add alignment (unavailable for now)")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.allowedActions.remove || _vm.loading},on:{"click":function($event){return _vm.actions.remove(_vm.selectedAlignments)}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Remove alignment")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.allowedActions.validate || _vm.loading},on:{"click":function($event){return _vm.actions.validate(_vm.selectedAlignments)}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Validate alignment")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.allowedActions.edit || _vm.loading},on:{"click":function($event){return _vm.actions.modify(_vm.selectedAlignments)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit alignment")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-5 mr-1 d-inline-block"},on),[_c('v-btn',{attrs:{"disabled":!_vm.allowedActions.reset || _vm.loading},on:{"click":function($event){return _vm.actions.reset(_vm.selectedAlignments)}}},[_c('v-icon',[_vm._v("mdi-undo")])],1)],1)]}}])},[_c('span',[_vm._v("Reset")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mx-1 d-inline-block"},on),[_c('v-btn',{attrs:{"disabled":!_vm.allowedActions.merge || _vm.loading},on:{"click":function($event){return _vm.actions.merge(_vm.selectedAlignments)}}},[_c('v-icon',[_vm._v("mdi-arrow-collapse-vertical")])],1)],1)]}}])},[_c('span',[_vm._v("Merge")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"mx-1 d-inline-block"},on),[_c('v-btn',{attrs:{"disabled":!_vm.allowedActions.split || _vm.loading},on:{"click":function($event){return _vm.actions.split(_vm.selectedAlignments)}}},[_c('v-icon',[_vm._v("mdi-arrow-split-horizontal")])],1)],1)]}}])},[_c('span',[_vm._v("Split")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-5 mr-1 d-inline-block"},on),[_c('v-btn',{attrs:{"disabled":!_vm.allowedActions.toggleUncertain || _vm.loading},on:{"click":function($event){return _vm.actions.toggleUncertain(_vm.selectedAlignments)}}},[_c('v-icon',[_vm._v("mdi-help")])],1)],1)]}}])},[_c('span',[_vm._v("Uncertain")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-5 mr-5 d-inline-block"},on),[_c('v-btn',{attrs:{"disabled":!_vm.allowedActions.showWithoutFilters || _vm.loading},on:{"click":function($event){return _vm.actions.showWithoutFilters(_vm.selectedAlignments)}}},[_c('v-icon',[_vm._v("mdi-filter-off")])],1)],1)]}}])},[_c('span',[_vm._v("Show without filters")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-5 mr-1",attrs:{"color":"primary","disbaled":_vm.loading,"outlined":""},on:{"click":function($event){return _vm.actions.openBatchDialog()}}},[_vm._v(" Batch Rules ")])],1)]}}])},[_c('span',[_vm._v("Add/edit the Batch rules")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-5 mr-1",attrs:{"color":"primary"},on:{"click":_vm.actions.showFilters}},[_vm._v(" "+_vm._s(_vm.numAppliedFilters)+" filter(s) applied ")])],1)]}}])},[_c('span',[_vm._v("Filter the alignments")])]),_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-5 mr-1"},on),[_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.actions.nextAlignmentWithoutRules()}}},[_vm._v(" Next ")])],1)]}}])},[_c('span',[_vm._v("Jump to the first alignment without any rules")])])],1)]},proxy:true},{key:"item.alignedDocumentId",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.alignedDocumentId)+" ")])]}}],null,true)},[_c('div',[_vm._v("Doc 1: "+_vm._s(_vm.enumsValues.pipelineAlignedDocumentById[item.alignedDocumentId].document_1_id))]),_c('div',[_vm._v("Doc 2: "+_vm._s(_vm.enumsValues.pipelineAlignedDocumentById[item.alignedDocumentId].document_2_id))])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state !== _vm.ALIGNMENT_STATES.NO_STATE)?_c('v-tooltip',{attrs:{"top":"","open-delay":350},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.state.color,"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.state.icon)+" ")]),_c('span',{staticClass:"uncertain-wrapper"},[(item.uncertain)?_c('v-icon',{staticClass:"uncertain-icon",attrs:{"small":""}},[_vm._v("mdi-help")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.state.name)+_vm._s(item.uncertain ? ' (uncertain)' : ''))])]):_vm._e()]}},{key:"item.text1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appliedRule && _vm.REMOVE_RULE_TYPE_IDS.has(item.appliedRule.type) ? item.initialText1 : item.modifiedText1)+" ")]}},{key:"item.text2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.appliedRule && _vm.REMOVE_RULE_TYPE_IDS.has(item.appliedRule.type) ? item.initialText2 : item.modifiedText2)+" ")]}},{key:"item.scoreDistance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatScoreDistance(item.scoreDistance))+" ")]}},{key:"footer.prepend",fn:function(){return [_vm._v(" "+_vm._s(_vm.selectedAlignments.length)+" alignments selected ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }